<div>
    <div *ngIf="!listLayout; else list"
        class="grid grid-cols-1 gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 bg-reopera-white">

        <app-navigazione-card (selectedItem)="onSelectedItem.emit(item)"
            *ngFor="let item of items | paginate: {itemsPerPage: 12 , currentPage: p}"
            (selectedAction)="launchAction($event, item,navigationIdx, paramsAndNotes)" [title]="navigation.name"
            [nextTitle]="nextButtonTitle" [selectable]="!item.raw._disabled && navigation.isSelectable"
            [details]="populateDetails(navigation.details|| [], item.raw || {})"
            [actions]="populateActions(navigation.actions || [])"
            [disabledWarning]="item.raw._disabled && navigation.disabledWarning">
        </app-navigazione-card>
    </div>
    <ng-template #list>
        <app-navigazione-listview [actions]="populateActions(navigation.actions || [])"
            [attr.data-cy]="'listview-' + navigationIdx" [details]="navigation.details || []"
            [nextButton]="nextButtonTitle" [selectable]="navigation.isSelectable"
            (onAction)="launchAction($event.action, $event.item, navigationIdx, paramsAndNotes)"
            [disabledWarning]="navigation.disabledWarning" (onNextNavigation)="onSelectedItem.emit($event)"
            [hideDisabled]="navigation.hideDisabled" [rows]="items | paginate: {itemsPerPage: 25 , currentPage: p}"
            [compact]="compact">
        </app-navigazione-listview>
    </ng-template>
    <pagination-template #q="paginationApi"
        *ngIf="items && items.length > (listLayout? 25: 12) && currentNavigation <= navigationIdx"
        class="flex justify-center text-black dark:text-white gap-2 mt-2 border-t-secondary pt-2 border-t"
        (pageChange)="p = $event" data-cy="pagination">

        <button data-cy="pagination-first"
            class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors" *ngIf="!q.isFirstPage()"
            (click)="q.previous()" [class.disabled]="q.isFirstPage()">
            < </button>

                <div data-cy="pagination-item" class="flex" *ngFor="let page of q.pages">
                    <button class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                        (click)="q.setCurrent(page.value)" *ngIf="q.getCurrent() !== page.value">
                        <span>{{ page.label }}</span>
                    </button>
                    <div data-cy="pagination-current" class="flex" *ngIf="q.getCurrent() === page.value">
                        <span class="px-4 py-2 rounded-md bg-secondary text-white">{{
                            page.label }}</span>
                    </div>
                </div>

                <button data-cy="pagination-last" *ngIf="!q.isLastPage()" (click)="q.next()"
                    class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                    [class.disabled]="q.isLastPage()"> > </button>
    </pagination-template>
    <app-submission-confirm [showPanel]="showSubmissionConfirm" (onStay)="showSubmissionConfirm=false"
        [buttonsConfig]="confirmActionData.submissionButtons || undefined">
    </app-submission-confirm>
</div>