import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styles: [
  ]
})
export class SelectComponent implements OnInit {
  @ViewChild('theInput') theInput!: ElementRef<HTMLInputElement>
  @Input() title: string = ''
  @Input() items: { text: string, value: any }[] = []
  @Output() selected = new EventEmitter()
  @Input() initialValue?: { text: string, value: any }
  @Input() clearable?: boolean = true
  selectedItem?: { text: string, value: any }
  isOpen: boolean = false

  constructor() { }

  ngOnInit(): void {
    this.selectedItem = this.initialValue
  }
  selectEvent(item: any) {
    // do something with selected item
    this.selectedItem = item
    this.isOpen = false
    this.selected.emit(item.value)
    
  }

  clear() {
    this.selectedItem = undefined
    this.selected.emit(null)
  }

}
