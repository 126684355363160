import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Route } from '@angular/router';
import { map } from 'rxjs';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';

@Component({
  selector: 'app-macroservice',
  templateUrl: './macroservice.component.html',
  styleUrls: []
})
export class MacroserviceComponent implements OnInit {

  constructor(public subscriptionService: SubscriptionService) {
  }

  @Input() items: Route[] = []
  @Input() serviceNameList: string[] = []
  @Input() columns?: number
  @Input() preventRoute: boolean = false
  @Output() onServiceSelected = new EventEmitter()

  filteredItems: Route[] = []
  ngOnInit(): void {
    this.subscriptionService.availableServices$.pipe(
      map(services => {
        this.filteredItems = this.items?.filter(
          i => i.data?.['name'] && (i.data?.['serviceSlug'] ? services.includes(i.data?.['serviceSlug']) : true))
      })).subscribe()
  }

}
