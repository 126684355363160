import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-base-card',
  templateUrl: './base-card.component.html',
  styles: [` 
  :host{
   
    position: relative;
}`],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('500ms ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('500ms ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class BaseCardComponent implements OnInit {
  @Input() showPrice: boolean = false;
  @Input() showDetails: boolean = false;
  constructor() { }
  open: boolean = false
  ngOnInit(): void {
  }
  toggleOpen() {
    this.open = !this.open
  }
}
