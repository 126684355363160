<nav class=" px-5 py-3 w-full">
    <ol class="list-reset flex text-sm ">
        <li *ngFor="let item of elements; let i = index" class="mx-2 flex">
            <ng-container *ngIf="i < elements.length-1">
                <svg class="w-4 h-4 mx-2 text-black dark:text-white -rotate-90 transition-transform" role="img"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="currentColor"
                        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                    </path>
                </svg>
                <span class=" text-black dark:text-white hover:text-primary transition-colors duration-500"><i
                        class="w-4 h-4 fa-solid mr-1.5" [ngClass]="[item.icon || 'home']" *ngIf="item.icon"></i>
                    <a [routerLink]="item.url" routerLinkActive="!border-gray-200">{{item.name}}</a>
                </span>
            </ng-container>
        </li>

    </ol>
</nav>