<div class="flex w-full ">
    <form class="" [formGroup]="formControlGroup" (ngSubmit)="emitFormValues()" class="w-3/5 ">
        <div class="space-y-2 px-4 pb-4 pt-8 flex flex-wrap justify-end items-end ">
            <ng-container *ngFor="let field of fields">
                <div *ngIf="areDependenciesFullfilled(field)" [attr.data-cy]="'field-'+ field.name"
                    class="items-center justify-between py-4 text-gray-500  md:inline-flex md:space-y-0 flex-grow"
                    [ngClass]="{'!hidden': field.type == 'hidden', 'w-full': !field.half, 'w-1/2': field.half, 'flex-row-reverse': field.half =='right'}">
                    <label [for]="field.name" class="text-black dark:text-white flex-grow flex-shrink-0 mx-auto"
                        [ngClass]="[field.half ?'w-3/4':'w-1/3', field.half == 'left'? 'text-right' : 'text-left']"
                        [innerHTML]="field.description" [class.font-bold]="field.type=='separator'">
                        <span *ngIf="field.required" class="text-red-600">*</span>
                        <div class="text-xs" [title]="field.note" *ngIf="field.note"><i
                                class="fa fa-solid fa-circle-info w-4 h-4 hover:text-secondary transition-colors text-primary"></i>
                        </div>
                    </label>
                    <div class="max-w-2xl mx-auto" [ngClass]="[field.half?'w-1/4':'w-2/3']"
                        *ngIf="field.type!='separator'&& field.type!='label'">
                        <div class="relative "
                            [ngClass]="[field.half ? field.half == 'right'? 'flex justify-start' : 'flex justify-end': '']">
                            <input *ngIf="!field.type || field.type == 'text'|| field.type == 'string'"
                                [id]="field.name"
                                class=" rounded-lg flex-1 appearance-none border border-gray-300 w-full py-4 px-4 bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
                                [placeholder]="field.description || field.name" [formControlName]="field.name"
                                [required]="!!field.required" [pattern]="fp[field.pattern || 'NONE']">
                            <input *ngIf="field.type == 'switch'" type="checkbox" [id]="field.name"
                                class="mx-2 form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-reopera-500 checked:border-transparent focus:outline-none"
                                [placeholder]="field.description || field.name" [formControlName]="field.name"
                                [required]="!!field.required">
                            <input *ngIf="field.type == 'date'" type="date" [id]="field.name"
                                class="rounded-lg flex-1 appearance-none border border-gray-300 w-full py-4 px-4 bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
                                [placeholder]="field.description || field.name" [formControlName]="field.name"
                                [required]="!!field.required">
                            <div *ngIf="field.type== 'autocomplete' ">
                                <app-autocomplete [channel]="field.inputChannel || ''" [title]="field.description || ''"
                                    [valueField]="field.extra?.valueField || ''"
                                    (selected)="setField(field.name, $event)"
                                    (selectedRaw)="setCascadeFields(field.extra?.cascadeFields || [], $event)">
                                    >
                                </app-autocomplete>
                                <input type="hidden" [id]="field.name" [formControlName]="field.name"
                                    [required]="!!field.required">
                            </div>
                            <div *ngIf="field.type== 'select'">
                                <app-autocomplete mode="select" [channel]="field.inputChannel || ''"
                                    [title]="field.description || ''" [valueField]="field.extra?.valueField || ''"
                                    [selectParams]="collectRequiredParams(field.extra?.requiredParams || [])"
                                    (selected)="setField(field.name, $event)"
                                    (selectedRaw)="setCascadeFields(field.extra?.cascadeFields || [], $event)">
                                </app-autocomplete>
                            </div>
                            <div *ngIf="field.type =='select-static'" class="flex justify-between items-center">
                                <select [formControlName]="field.name" [id]="field.name" [required]="!!field.required"
                                    class="flex-grow block w-52 text-gray-700 py-4 px-4 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500">
                                    <option *ngFor="let option of field.items || []" [value]="option.value">
                                        {{option.text}}
                                    </option>
                                </select>
                            </div>
                            <div *ngIf="field.type== 'radio'" class="flex items-center gap-8">
                                <label *ngFor="let radioItem of field.items" class="inline-flex items-center">
                                    <input type="radio" [id]="field.name" [formControlName]="field.name"
                                        [value]="radioItem.value" class="h-5 w-5 text-primary"
                                        [required]="!!field.required" />
                                    <span class="ml-2 text-gray-700 dark:text-white">
                                        {{radioItem.text}}
                                    </span>
                                </label>
                            </div>
                            <input *ngIf="field.type =='hidden'" type="hidden" [id]="field.name"
                                [formControlName]="field.name">

                            <app-upload *ngIf="field.type == 'upload'" [id]="field.name"
                                (onUploadComplete)="setUploadLocation(field.name,$event)"
                                [required]="!!field.required "></app-upload>
                            <div class="text-red-700 text-sm min-h-3">
                                <!-- <span *ngIf="formControlGroup.get(field.name)?.hasError('required')">Campo
                                        richiesto!</span> -->
                                <span *ngIf="formControlGroup.get(field.name)?.hasError('pattern')">Campo
                                    non corretto!</span>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="flex items-center w-full mt-4" [ngClass]="[paymentText? 'justify-around' : 'justify-start']">
            <div *ngIf="paymentText"
                class="py-1 px-4 text-center text-sm rounded-full text-yellow-600  bg-yellow-200  shadow-yellow-600 shadow">
                {{paymentText}}
            </div>
            <button type="submit" [disabled]="formControlGroup.invalid" data-cy="submit"
                class="flex justify-center items-center py-4 px-8 disabled:bg-opacity-50 disabled:cursor-not-allowed bg-primary hover:bg-white hover:text-primary text-white hover:bg-white-700 focus:ring-light-100 focus:ring-offset-light-700  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                <div>{{submitText || 'Ricerca'}}</div>

            </button>

        </div>
    </form>
    <ng-content></ng-content>
</div>