import { NavigationActionsConfig, NavigationConfig, NavigationDetailsConfig } from "src/app/models/navigation.shared.model"

export class MappeConfig {
    actions: NavigationActionsConfig[] = []
    details: NavigationDetailsConfig[] = []
}

export const terreniConfig: NavigationConfig = {
    isSelectable: false,
    name: 'Beni',
    channel: '',
    nameAfterSelect: 'bene',
    details: [
        { title: "Ubicazione", description: "", icon: "fa-solid fa-location-dot", field: "nome_comune", colspan: 2 },
        { title: "Classamento", description: "", icon: "fa-solid fa-tag", field: "_classamento" },
        //    { title: "Tipo catasto", description: "", icon: "fa-solid fa-tree-city", field: "tipo_catasto" },
        { title: "Identificazione", description: "Foglio/Particella", icon: "fa-regular fa-building", field: ['foglio', 'particella'], separator: "/" }]
    ,
    actions: [{
        title: 'Scheda Catastale', slug: 'scheda-catastale-immobile', type: 'request',
        params: [
            { requestParam: 'id_bene', userParam: 'id_bene' },
            { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
            { userParam: "foglio", requestParam: "foglio" },
            { userParam: "particella", requestParam: "particella" },
            { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
            { userParam: "nome_comune", requestParam: "nome_comune" },
        ]
    },
    {
        title: "Visura catastale", slug: "visura-catastale-immobile", type: 'request',
        params: [
            { requestParam: 'id_bene', userParam: 'id_bene' },
            { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
            { userParam: "foglio", requestParam: "foglio" },
            { userParam: "particella", requestParam: "particella" },
            { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
            { userParam: "nome_comune", requestParam: "nome_comune" },
        ]
    },
    ]
}


export const fabbricatiConfig: NavigationConfig = {
    isSelectable: false,
    name: 'Beni',
    channel: '',
    hideDisabled: true,
    nameAfterSelect: 'bene',
    details: [
        { title: "Ubicazione", description: "", icon: "fa-solid fa-location-dot", field: "ubicazione", colspan: 2 },
        { title: "Classamento", description: "", icon: "fa-solid fa-tag", field: "_classamento" },
        { title: "Consistenza", description: "", icon: "fa-solid fa-ruler", field: "_consistenza" },
        { title: "Rendita", description: "", icon: "fa-solid fa-coins", field: "_rendita", suffix: "€" },
        { title: "Tipo catasto", description: "", icon: "fa-solid fa-tree-city", field: "tipo_catasto" },
        { title: "Identificazione", description: "Foglio/Part./Sub.", icon: "fa-regular fa-building", field: ['foglio', 'particella', 'subalterno'], separator: "/" }]
    ,
    actions: [
        ...(terreniConfig.actions || []),
        {
            title: "Inserisci in Monitoraggio", slug: "monitoraggio-immobili", type: 'request',
            params: [
                { requestParam: 'id_bene', userParam: 'id_bene' },
                { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
                { userParam: "foglio", requestParam: "foglio" },
                { userParam: "particella", requestParam: "particella" },
                { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
                { userParam: "nome_comune", requestParam: "nome_comune" },
            ]
        }]
}