import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationActionsConfig, NavigationConfig, NavigationDetailsConfig } from 'src/app/models/navigation.shared.model';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-navigazione-listview',
  templateUrl: './navigazione-listview.component.html',
  styles: [
  ]
})
export class NavigazioneListviewComponent implements OnInit {
  @Input() compact: boolean = false
  @Input() currentEntrypoint!: any
  @Input() actions!: NavigationActionsConfig[]
  @Input() details!: NavigationDetailsConfig[]
  @Input() nextButton!: string
  @Input() selectable!: boolean
  @Input() disabledWarning?: string
  @Input() hideDisabled?: boolean
  @Input() rows!: any[]
  @Output() onAction = new EventEmitter()
  @Output() onNextNavigation = new EventEmitter()


  constructor(private clipboard: Clipboard) { }

  ngOnInit(): void {
  }
  populateDetails(details: NavigationDetailsConfig[], item: any) {
    let populated = details.map(d => ({ ...d, value: "" }))
    populated.forEach(p => p.value = Array.isArray(p.field) ? p.field.map(f => item[f] || '').join(p.separator || " ") : item[p.field])
    return populated
  }
  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }
}
