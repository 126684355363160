<div class="w-full h-full" *ngIf="ready && supportedBrowserIFrame.includes(browserName)">
    <button (click)="openInNewWindow()" target="securesuite"
        class="absolute t-0 l-0 rounded-br px-2 py-1 text-white bg-secondary text-sm hover:bg-primary transition-colors">
        <i class="fa-solid fa-up-right-from-square w-4 h-4"></i></button>
    <iframe [src]="loginUrl" class="w-full h-full" *ngIf="supportedBrowserIFrame.includes(browserName)"></iframe>
</div>
<div class="text-black dark:text-white text-xl flex items-center justify-center" *ngIf="error">
    <i class="fa-solid fa-triangle-exclamation text-primary w-10 h-10 m-2"></i>{{error}}
</div>
<p *ngIf="ready && !supportedBrowserIFrame.includes(browserName)"
    class="text-black dark:text-white text-xl text-center">
    Il tuo browser non riesce ad aprire il servizio in questa finestra,<br>
    <a [href]="loginUrl" target="securesuite" class="px-1 bg-primary text-white hover:bg-secondary transition-colors">
        clicca qui per aprire il servizio in una nuova finestra</a>
</p>