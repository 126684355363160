<div class="flex items-center justify-between">
    <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white"> Contatore richieste </div>
    <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white" *ngIf="months$ | async as months;">
        <app-select title="Seleziona mese" [items]="months" [initialValue]="months[0]" [clearable]="false"
            (selected)="selectedMonth.next($event)"></app-select>
    </div>
</div>
<div class="w-full">
    <div class="-mx-4 sm:-mx-8 px-4 sm:px-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <thead>

                    <tr>
                        <th *ngFor="let det of details" scope="col" [attr.colspan]="det.span || 1"
                            class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                            {{det.title}}
                            <div class="text-xs normal-case" *ngIf="det.description">
                                {{det.description}}</div>
                        </th>

                    </tr>
                </thead>
                <tbody class="" *ngIf="items$ | async as rows; else nodata;">

                    <tr *ngFor="let item of rows.service_usage_by_company"
                        class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">
                        <td class="px-4 py-3 border-b border-gray-200 text-sm" colspan="2">
                            {{item.name}}
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{periodMap[item.limit_type|| ''] || ''}}
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{item.limit_value || '-'}}
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{item.count}} ({{getPersonalUsage(item, rows.service_usage_by_user)}})
                        </td>

                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{item.limit_type != 'NONE' ? ((item.limit_value ||0) - (item.count
                            || 0)) >= 0 ? ((item.limit_value || 0) - (item.count || 0)) : 0 : '-'
                            }}
                        </td>

                    </tr>
                </tbody>
                <ng-template #nodata>
                    <tbody data-cy="no-results">
                        <tr class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">

                            <td [attr.colspan]="details.length"
                                class="font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                Nessun risultato per i dati inseriti</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>

        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <thead>

                    <tr>
                        <th *ngFor="let det of details" scope="col" [attr.colspan]="det.span || 1"
                            class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                            {{det.title}}
                            <div class="text-xs normal-case" *ngIf="det.description">
                                {{det.description}}</div>
                        </th>

                    </tr>
                </thead>
                <tbody class="" *ngIf="items$ | async as rows; else nodata;">
                    <tr class="bg-white hover:bg-gray-100 even:bg-gray-50 relative" *ngIf="rows.service_usage_by_user">
                        <td class="px-4 py-3 border-b border-gray-200 text-sm" colspan="2">
                            Monitoraggi
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            Globale
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            <span *ngIf="monitoraggio$| async as monitoraggio; else zero"> {{monitoraggio.limit_value||
                                0}}</span>
                            <ng-template #zero>0</ng-template>
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{rows.myScreeners.aggregate?.count || 0}}
                            ({{rows.companyScreeners.aggregate?.count}})
                        </td>

                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            <span *ngIf="monitoraggio$| async as monitoraggio; else zero"> {{(monitoraggio.limit_value||
                                0) - (rows.companyScreeners.aggregate?.count || 0)}}</span>
                            <ng-template #zero>0</ng-template>
                        </td>

                    </tr>
                </tbody>
                <ng-template #nodata>
                    <tbody data-cy="no-results">
                        <tr class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">

                            <td [attr.colspan]="details.length"
                                class="font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                Nessun risultato per i dati inseriti</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>