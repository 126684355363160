<ng-container *ngIf="ready">
    <div *ngIf="currentWorkarea?.introText && showIntroText; else theContent"
        class="w-full h-full px-6 py-6 text-black dark:text-white flex flex-col justify-center items-center">
        <div [innerHTML]="currentWorkarea?.introText"></div>
        <div class="flex-grow"></div>
        <button
            class="mt-10 w-56 h-16 bg-primary rounded-lg text-white hover:border border-primary hover:bg-white hover:text-primary hover:shadow-lg transition-all text-center font-bold text-lg"
            (click)="showIntroText=false">Prosegui</button>
    </div>
    <ng-template #theContent>
        <app-macroservice [items]="menuitem?.children || []"
            [columns]="menuitem?.data?.['columns'] || 4"></app-macroservice>
    </ng-template>
</ng-container>