import { Component, OnInit } from '@angular/core';
import { gql } from 'apollo-angular'
import { combineLatest, map, Observable, Subject, tap } from 'rxjs';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { MyRequestParamsGQL, MyRequestsGQL, MyRequestsQuery } from 'src/graphql/generated';
import { read, utils, writeFileXLSX } from "xlsx";
import { UserService } from '../user.service';

const REQUESTS = gql`
query MyRequests(
  $status: request_status_enum_comparison_exp = {}, 
  $serviceId: Int_comparison_exp = {}, 
  $order_by: [request_order_by!] = {createdAt: desc}, 
  $_gte: timestamp = "2022/01/01", 
  $_lte: timestamp = "2099/01/01") {
  request(where: {
    status: $status, 
    serviceId: $serviceId, 
    createdAt: {_gte: $_gte, _lte: $_lte}}, 
    order_by: $order_by) {
    service {
      name
      fields
    }
    id
    queryResult
    createdAt
    params
    status
    notes
    transactions {
      amount
      pending
    }
  }
}`

const REQUEST_PARAMS = gql`query MyRequestParams {
  request(distinct_on:[status]) {
    status
  }
  service(distinct_on: name, where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}) {
    id
    name
  }
}
`

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: []
})
export class RequestsComponent implements OnInit {
  stateMap: any = {
    'registered': 'Inserito',
    'queued': 'In attesa di elaborazione',
    'processing': 'In lavorazione',
    'printing': 'Generazione stampa',
    'failed': 'Completato (nessun dato trovato)',
    'completed': 'Completato',
    'error': 'In errore'
  }
  constructor(private getRequests: MyRequestsGQL, private getRequestParams: MyRequestParamsGQL, public userService: UserService) {
    combineLatest([this.selectedService, this.selectedStatus, this.orderCondition, this.selectedStartDate, this.selectedEndDate]).subscribe(a => {
      this.items$ = this.getRequests.watch(
        {
          serviceId: a[0] || undefined,
          status: a[1] || undefined,
          _gte: a[3]?.text || undefined,
          _lte: a[4]?.text || undefined,
          order_by: a[2] || undefined
        }).valueChanges.pipe(
          map(x => x.data.request)
        )
    })
    this.params$ = this.getRequestParams.watch({}).valueChanges.pipe(
      map(x => x.data),
      map(x => (
        {
          statuses: x.request.map(r => ({ text: this.stateMap[r.status], value: { _eq: r.status } })),
          services: x.service.map(s => ({ text: s.name, value: { _eq: s.id } }))
        }
      ))
    )
    this.selectedStartDate.subscribe(x => {
      this.minTo = x?.text || ''
    })
    this.selectedEndDate.subscribe(x => {
      this.maxFrom = x?.text || ''
    })
    this.selectedService.next(null)
    this.selectedStatus.next(null)
    this.orderCondition.next(null)
    this.selectedStartDate.next(null)
    this.selectedEndDate.next(null)

  }
  minTo: string = ''
  maxFrom: string = ''
  p: number = 1;
  items$!: Observable<MyRequestsQuery['request']>
  params$!: Observable<any>
  orderCondition = new Subject<any>()
  selectedStatus = new Subject<any>()
  selectedService = new Subject<any>()
  selectedStartDate = new Subject<any>()
  selectedEndDate = new Subject<any>()
  details: { title: string, description?: string, order: number, sortable?: boolean, asc?: any, desc?: any }[] = [
    {
      description: "",
      title: "ID",
      sortable: false,
      order: 0,
      asc: { id: 'asc' },
      desc: { id: 'desc' }
    },
    {
      description: "",
      title: "Data",
      sortable: true,
      order: 0,
      asc: { createdAt: 'asc' },
      desc: { createdAt: 'desc' },
    },
    {
      description: "",
      title: "Servizio",
      sortable: true,
      order: 0,
      asc: { service: { name: 'asc' } },
      desc: { service: { name: 'desc' } }
    },
    {
      description: "",
      title: "Note",
      order: 0
    },
    {
      description: "",
      title: "Stato",
      order: 0
    },
    {
      description: "",
      title: "Costo",
      order: 0
    }]

  ngOnInit(): void {

  }


  sortBy(idx: number) {
    this.details.forEach((item, idy) => {
      if (idx == idy) {
        item.order = item.order <= 0 ? 1 : -1
      } else {
        item.order = 0
      }
    }
    )
    let item = this.details[idx]
    this.orderCondition.next(item?.order == -1 ? item.asc : item?.order == 1 ? item.desc : {})

  }
  xmlExport(items: MyRequestsQuery['request']) {

    /* flatten objects */
    const rows = items.map(row => ({
      createdAt: row.createdAt,
      service: row.service?.name || '',
      notes: this.userService._getParamByFieldPosition(row.service.fields, row, 'title'),
      status: row.status,
      amount: row.transactions.map(l => l.amount).join(' ')
    }));

    /* generate worksheet and workbook */
    const ws = utils.json_to_sheet(rows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");


    /* calculate column width */
    const max_width = rows.reduce((w: any, r: any) => Math.max(w, r.service?.name?.length || 10), 10);
    ws["!cols"] = [{ wch: max_width }];

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFileXLSX(wb, "ServiziRichiesti.xlsx");

  }
}
