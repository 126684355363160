import { AlertModel } from "./alert.model"

export const FieldPatterns: any = {
    "CF": /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
    "EXTENDED_CF": /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$|^[0-9]{11}$/i,
    "COMPANY_CF": /^[0-9]{11}$/i,
    "EMAIL": /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
    "NUMBER": /^[0-9]*$/,
    "NONE": ""
}
export interface ServiceParamFieldModel {
    name: string
    description?: string
    note?: string
    type?: string
    items?: { text: string, value: string }[]
    inputChannel?: string
    dependencies?: ({ fieldName: string, value: string } | string)[]
    defaultValue?: string
    extra?: any
    required?: boolean
    pattern?: string
    half?: string
    cardPosition?: string
    cardPrefix?: string
    cardSuffix?: string
}
export interface PriceCalculationFields {
    channel?: string
    params: string[]
}

export interface ButtonConfirm {
    label: string
    destination: string
    justDismiss?: boolean
}

export class ServiceNote {
    content!: String
    title?: string
}
export interface ServiceModel {
    id: number | null
    name?: string
    isActive?: boolean
    channel?: string
    provider?: string
    description?: string
    priceDescription?: string
    internalDescription?: string
    fields?: ServiceParamFieldModel[]
    alerts?: AlertModel[]
    slug?: string
    priceCalculation?: PriceCalculationFields
    executionPolicy?: string
    targets?: string[]
    dataChannel?: string
    submitText?: string
    postConfirmButtons?: ButtonConfirm[]
    status?: string
    notes?: ServiceNote[]
}
