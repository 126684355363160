import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationDetailsConfig, NavigationActionsConfig } from 'src/app/models/navigation.shared.model';
@Component({
  selector: 'app-navigazione-card',
  templateUrl: './navigazione-card.component.html',
  styles: [
  ]
})

export class NavigazioneCardComponent implements OnInit {
  @Input() details: NavigationDetailsConfig[] = [];
  @Input() actions: NavigationActionsConfig[] | null = null
  @Input() nextTitle: string = '';
  @Input() selectable: boolean = false;
  @Input() disabledWarning: boolean | string = false;
  @Output() selectedAction = new EventEmitter();
  @Output() selectedItem = new EventEmitter();
  constructor() {
  }

  ngOnInit(): void {
    console.log(this.actions)
  }

  getInitials(title: string) {
    return title.split(' ').map(p => p[0]).join('').substring(0, 2)
  }


}
