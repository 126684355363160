<section class="" *ngIf="subscription$ | async as subscription"
    class="rounded-lg bg-white dark:bg-black border-t-2 py-4 border-black shadow-xl px-6">
    <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white">
        {{subscription.service?.name}}
    </div>
    <div class=" flex w-full relative space-x-4" *ngIf="subscription.service?.status != 'COMINGSOON'; else wip">

        <app-navigazione-form [fields]="subscription.service?.fields || []"
            [submitText]="subscription.service?.submitText ? subscription.service?.submitText : subscription.price==0 ? 'RICHIEDI': 'VERIFICA COSTO E RICHIEDI'"
            (onSubmit)="onFormSubmit($event)">
            <div class="w-1/2 flex flex-col self-stretch bg-contain bg-no-repeat bg-center"
                style="background-image: url(assets/catasto.png);">
                <div *ngIf="notes" class="flex flex-wrap justify-center">
                    <div *ngFor="let note of notes"
                        class="h-60 w-60 p-4 text-blue-900 block rotate-6 duration-150 ease-linear bg-[#ffc]  shadow-md hover:shadow-lg hover:rotate-0 hover:scale-125 ">
                        <div *ngIf="note.title" class="text-xl mb-2 uppercase font-bold">{{ note.title}}</div>
                        <div class="font-light" [innerHTML]="note.content"></div>
                    </div>
                </div>
                <div *ngIf="subscription.service?.internalDescription"
                    class="text-justify leading-10 bg-gray-100 bg-opacity-70  text-black dark:text-white  pt-16 pb-8 px-6 text-lg mb-8 rounded-md h-full"
                    [innerHtml]="subscription.service?.internalDescription">
                </div>
            </div>
        </app-navigazione-form>

    </div>
    <ng-template #wip>
        <app-work-in-progress></app-work-in-progress>
    </ng-template>
    <app-submission-confirm [showPanel]="showSubmissionConfirm" (onStay)="showSubmissionConfirm=false"
        [buttonsConfig]="subscription.service?.postConfirmButtons || undefined">
    </app-submission-confirm>
    <div class="text-white flex flex-col justify-center items-center fixed inset-0 z-40 bg-black bg-opacity-80"
        *ngIf="showReportPreview">
        <button type="button" (click)="showReportPreview=undefined"
            class="absolute right-10 top-10 w-8 h-8 text-white rounded-full hover:scale-150 transition-transform duration-300">
            <i class="fa fa-solid fa-close"></i>
        </button>
        <app-report-preview [request]="showReportPreview" (onClose)="this.showSubmissionConfirm=true"
            class="w-[800px] h-[80vh] overflow-scroll">
        </app-report-preview>
    </div>

</section>