import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export class ActionComponentData {
  subscriptionId?: number
  title?: string
  description?: string
  price?: number
  priceOnFail?: number
  showZeroPrice?: boolean
  type?: string
  channel?: string
  params?: { userParam?: string, requestParam: string, value?: string }[]
  extra?: { params: any, notes: any }
  submissionButtons?: any
}

@Component({
  selector: 'app-actionpopup',
  templateUrl: './actionpopup.component.html',
  styles: [
  ]
})
export class ActionpopupComponent implements OnInit {
  @Input() showPanel: boolean = false
  @Input() data: ActionComponentData | undefined
  @Input() priceLabel: string = 'Costo'
  @Input() buttonLabel: string = 'RICHIEDI'
  @Input() disabled: boolean = false
  @Output() onConfirm = new EventEmitter()
  @Output() onCancel = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}
