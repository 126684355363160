import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { QueriesService } from 'src/app/pages/vv/services/queries.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: []
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('theInput') theInput!: ElementRef<HTMLInputElement>
  @Input() title: string = ''
  @Input() channel: string = 'catasto:ricerca:comuni'
  @Input() valueField: string = ''
  @Input() mode: string = 'autocomplete'
  @Input() selectParams: any
  @Input() itemPrefix: string = ''

  @Output() selected = new EventEmitter()
  @Output() selectedRaw = new EventEmitter()
  selectedItem: string = ''
  items: { text: string, value: string }[] = []
  isLoading: boolean = false
  isOpen: boolean = true
  keyword: string = ''
  constructor(
    private queriesService: QueriesService
  ) { }

  selectEvent(item: any) {
    // do something with selected item
    this.selectedItem = item.value
    this.isOpen = false
    this.keyword = item.text
    this.selected.emit(item.value)
    this.selectedRaw.emit(item.raw)
  }

  clear() {
    this.keyword = ''
    this.selectedItem = ''
    this.selected.emit(null)
    this.selectedRaw.emit(null)
  }

  onChangeSearch(data: any) {

    this.selected.emit(undefined)
    this.selectedRaw.emit(undefined)
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.isLoading = true

    return this.queriesService.post({
      channel: this.channel,
      data
    })

  }

  onFocused(e: any) {
    // do something when input is focused
    this.isOpen = true
  }

  ngOnInit(): void {
    if (this.mode == 'select') {
      this.onChangeSearch(this.selectParams)
        .subscribe((d: any) => {
          this.isLoading = false
          if (d.items) {
            this.isOpen = true
            this.items = this.valueField ? d.items.map((i: any) => ({ ...i, value: i.raw[this.valueField] || i.value })) : d.items
          }
        })
    }
  }

  ngAfterViewInit(): void {
    if (this.mode == 'autocomplete') {
      fromEvent(this.theInput.nativeElement, 'input').pipe(
        map(e => (e.target as HTMLInputElement).value),
        filter(text => text.length > 2),
        debounceTime(800),
        distinctUntilChanged(),
        switchMap((term) => this.onChangeSearch(term))
      ).subscribe((d: any) => {
        this.isLoading = false
        if (d.items) {
          this.isOpen = true
          this.items = this.valueField ? d.items.map((i: any) => ({ ...i, value: i.raw[this.valueField] || i.value })) : d.items
        }
      })
    }
  }

}
