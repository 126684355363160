import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Route } from '@angular/router';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData, SharedService } from '../../shared.service';
import { map } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ServiceModel, ServiceParamFieldModel } from 'src/app/models/service.model';

const kebabize = (str: string) => str.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
const camelize = (text: string) => {
  const a = text.toLowerCase()
    .replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
  return a.substring(0, 1).toLowerCase() + a.substring(1);
}
@Component({
  selector: 'app-service-selection-popup',
  templateUrl: './service-selection-popup.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class ServiceSelectionPopupComponent {
  @Input() showPanel: boolean = false
  @Input() services!: Route[] | null | undefined
  @Input() title: string = ''
  @Input() data?: any = {}
  @Output() onCancel = new EventEmitter()

  constructor(private sharedService: SharedService) { }
  findFieldDataByNameOrAlias(field: ServiceParamFieldModel, data: any) {
    return data[field.name] || data[kebabize(field.name) || data[camelize(field.name)]]
  }
  onServiceSelected(subscriptionData: SubscriptionModel) {
    // console.log("SELECTED", subscriptionData)
    let fields = subscriptionData.service?.fields?.filter(f => f.required)
    // console.log("REQUIRED FIELDS", fields)
    // console.log("AVAILABLE DATA", this.data)
    let matchedFields = fields?.reduce((vars, f) => ({ ...vars, [f.name]: this.findFieldDataByNameOrAlias(f, this.data) }), {})
    // console.log("MATCHED DATA", matchedFields)
    let confirmData: ActionComponentData = {
      price: subscriptionData.price,
      description: subscriptionData.service?.description,
      title: subscriptionData.service?.name,
      subscriptionId: subscriptionData.id || 0,
      channel: subscriptionData.service?.dataChannel,
      extra: { params: matchedFields, notes: '' }
    }
    this.sharedService.showActionPopup({
      data: confirmData
    }).subscribe(() => this.makeRequest(confirmData))
  }

  makeRequest(configData: ActionComponentData) {
    // make request to proper service and await response
    let params = configData.extra?.params
    let notes = configData.extra?.notes
    this.sharedService.submitRequestWithParamsAndNotes(
      params,
      notes,
      configData,
      'Caricamento...'
    ).pipe(
      map((res: any) => {
        this.sharedService.loadingMsg$ = ""
      })).subscribe()
  }

}
