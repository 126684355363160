import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { ServiceParamFieldModel } from '../models/service.model';
import { gql } from 'apollo-angular';
import { MyCompleteBalanceGQL, MyUsageQuery, MyUserGQL, MyUserQuery } from 'src/graphql/generated';
import { Observable } from '@apollo/client';

const COMPLETEBALANCE = gql`subscription MyCompleteBalance {
  company_balance {
    balance
    accountBalance
  }
}`

const GETUSER = gql`query MyUser {
  user {
    id
    firstName
    lastName
    email
    isActive
    company {name active clientType }
  }
}`

@Injectable({
  providedIn: 'root'
})
export class UserService {
  static URL = '/api/v1';
  constructor(
    public http: HttpClient,
    private completeBalance: MyCompleteBalanceGQL,
    private myUser: MyUserGQL) { }
  getProfile() {
    return this.myUser.watch().valueChanges.pipe(
      shareReplay(1),
      map((d) => d.data.user[0])
    )
  }
  isActive() {
    return this.getProfile().pipe(
      map(u => u.isActive && u.company?.active)
    )
  }
  getBalance() {
    return this.completeBalance.subscribe().pipe(
      map((v) => ({
        balance: v.data?.company_balance[0]?.balance || 0,
        accountBalance: v.data?.company_balance[0]?.accountBalance || 0
      })),
      shareReplay(1)
    )
  }

  passwordReset() {
    return this.http.post<any>(`${UserService.URL}/reset`, { body: null })
  }

  _getParamByFieldPosition(fields: ServiceParamFieldModel[] = [], request: any = { params: {}, notes: {} }, position: string): string {
    let { params, notes } = request
    // find field with requested position
    let filteredFields: Map<string, ServiceParamFieldModel> = fields.filter(f => f.cardPosition == position).reduce((a, v) => ({ ...a, [v.name]: v }), {}) as Map<string, ServiceParamFieldModel>
    // get its name and extract data from params
    return Object.values(filteredFields).map(
      (ff: ServiceParamFieldModel) => {
        let content = params[ff.name] || notes[ff.name]
        if (ff.type == 'radio' || ff.type == 'select-static') {
          content = ff.items?.find(i => i.value == content)?.text || content
        }
        return content ? `${ff.cardPrefix || ''}${content}${ff.cardSuffix || ' '}` : ''
      })
      .filter(a => !!a).join("")
  }

  _getParamFromFields(fields: ServiceParamFieldModel[] = [], request: any = { params: {}, notes: {} }): { title: string, value: string }[] {
    let { params, notes } = request
    // find field with requested position
    let filteredFields = fields.filter(f => f.type != 'hidden').filter(f => params?.[f.name] || notes[f.name])
    // get its name and extract data from params

    return filteredFields.map(
      ff => ({
        title: ff.description || '',
        value: params[ff.name] || notes[ff.name] ? `${ff.cardPrefix || ''}${notes[ff.name] || params[ff.name]}${ff.cardSuffix || ' '}` : ''
      })
    )
  }

}
