import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { EventType } from 'src/app/models/event.model';
import { UploadService } from 'src/app/pages/vv/services/upload.service';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  constructor(
    private uploadService: UploadService,
    private sharedService: SharedService,
  ) { }
  ngOnInit(): void {
  }
  @Input() multiple: boolean = false
  @Input() required: boolean = false
  @Output() onUploadComplete = new EventEmitter()

  @Output() onFileDeleted = new EventEmitter()
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler({ files }: any) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
    this.onUploadComplete.emit(index)
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: FileList) {
    for (let i = 0; i <= files.length; i++) {
      let item = files.item(i) as any
      if (!item) {
        continue
      } else {
        item.progress = 0;
        if (this.multiple) {
          this.files.push({ item });
        } else {
          this.files = [item]
        }
      }
    }
    this.fileDropEl.nativeElement.value = "";
    this.uploadFilesSimulator(0);
    this.uploadService.single(this.files[0]).pipe(
      map(res => {
        this.files[0].progress = 100
        this.onUploadComplete.emit(res)
      }),
      catchError(err => {
        this.sharedService.publishLocalEvent({
          type: EventType.WARNING,
          raw: {
            title: 'Upload fallito!',
            errorDescription: "Riprovare tra poco o contattare l'assistenza"
          }
        })
        return of(false)
      }),
    ).subscribe()

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
}
