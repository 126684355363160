import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { icon } from 'leaflet';
import { delay, filter, tap } from 'rxjs';
import { RequestModel } from 'src/app/models/request.model';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styles: []
})
export class ToasterComponent implements OnInit {

  constructor(private sharedService: SharedService) { }
  showToast: boolean = false
  data: { title: string, subTitle: string, message: string, type?: 'alert' | 'warning' | 'notification', icon?: string } = { title: '', subTitle: '', message: '' }
  iconMap = {
    'notification': 'fa-solid fa-wand-magic-sparkles',
    'alert': 'fa-solid fa-triangle-exclamation',
    'warning': 'fa-solid fa-eye'
  }
  ngOnInit(): void {
    this.sharedService.events$.pipe(
      tap(console.log),
      filter(this.toasterFilter),
      tap((x) => {
        this.data.title = this.collectTitle(x)
        this.data.subTitle = this.collectSubtitle(x)
        this.data.message = this.collectMessage(x)
        this.data.type = this.switchType(x)
        this.showToast = true
      }),
      tap(() => { console.log(this.data) }),
      delay(5000),
      tap(() => this.showToast = false)
    ).subscribe()
  }

  toasterFilter(event: any) {
    switch (event.type) {
      case ('REQUEST'):
        return event.status == 'failed'
      case ('WARNING'):
      case ('ERROR'):
      case ('USER'):
        return true
      default:
        return false
    }
  }

  switchType(event: any): 'alert' | 'notification' | 'warning' {
    if (event.type == 'ERROR' || event?.raw?.errorDescription) {
      return 'alert'
    }
    if (event.type == 'WARNING') {
      return 'warning'
    }

    return 'notification'
  }

  collectTitle(event: { raw: RequestModel | any }): string {
    if (event?.raw?.service) {
      return event.raw.service.name
    }
    return event.raw?.title || ''
  }

  collectSubtitle(event: { raw: RequestModel | any }): string {
    return ''

  }

  collectMessage(event: { raw: RequestModel | any }): string {
    if (event?.raw?.errorDescription) {
      return event.raw.errorDescription
    }
    if (event?.raw?.description) {
      return event.raw.description
    }
    return ''
  }

}
