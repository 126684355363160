import { EntryPoint, NavigationConfig } from "src/app/models/navigation.shared.model"
import { FieldPatterns } from "src/app/models/service.model"
const catasto_navigation_intestati: NavigationConfig = {
    name: "Intestati",
    nameAfterSelect: "Intestatario selezionato",
    channel: "catasto:immobili:intestatari",
    inputParams: ["id_bene"],
    isSelectable: false,
    details: [
        { title: "Intestatario", description: "", icon: "fa-solid fa-building-columns", field: "_description" },
        { title: "Codice Fiscale", description: "", icon: "fa-regular fa-id-card", field: "codice_fiscale" },
        { title: "Info", description: "Intestatario", icon: "fa-regular fa-calendar-days", field: "_extra" },
        { title: "Diritti", description: "", icon: "fa-solid fa-shield", field: "diritti" },
        { title: "Quote", description: "", icon: "fa-solid fa-chart-pie", field: "quote" }]
}

export const catasto_immibili_details = [
    { title: "Ubicazione", description: "", icon: "fa-solid fa-location-dot", field: "ubicazione", colspan: 2 },
    { title: "Classamento", description: "", icon: "fa-solid fa-tag", field: "_classamento" },
    { title: "Consistenza", description: "", icon: "fa-solid fa-ruler", field: "_consistenza" },
    { title: "Rendita", description: "", icon: "fa-solid fa-coins", field: "_rendita", suffix: "€" },
    { title: "Titolarità", description: "", icon: "fa-solid fa-chart-pie", field: "titolarita" },
    { title: "Tipo catasto", description: "", icon: "fa-solid fa-tree-city", field: "tipo_catasto" },
    { title: "Identificazione", description: "Foglio/Particella/Subalterno", icon: "fa-regular fa-building", field: ['foglio', 'particella', 'subalterno'], separator: "/" }]


const catasto_navigation_beni: NavigationConfig = {
    name: "Beni",
    nameAfterSelect: "Bene selezionato",
    channel: "catasto:soggetti:uffici:immobili",
    inputParams: ["codice_ufficio"],
    outputParamName: "id_bene",
    isSelectable: true,
    disabledWarning: 'Immobile appartenente ad unità immobiliare soppressa',
    details: catasto_immibili_details,
    actions: [
        {
            title: 'Scheda catastale', slug: "scheda-catastale-immobile", type: 'request',
            params: [
                { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
                { userParam: "foglio", requestParam: "foglio" },
                { userParam: "particella", requestParam: "particella" },
                { userParam: "particella_secondaria", requestParam: "particella_secondaria" },
                { userParam: "sezione_urbana", requestParam: "sezione_urbana" },
                { userParam: "subalterno", requestParam: "subalterno" },
                { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
                { userParam: "territorio", requestParam: "territorio" }
            ]
        },
        {
            title: "Visura catastale", slug: "visura-catastale-immobile", type: 'request', availlableForDisabledItems: true,
            params: [
                { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
                { userParam: "foglio", requestParam: "foglio" },
                { userParam: "particella", requestParam: "particella" },
                { userParam: "particella_secondaria", requestParam: "particella_secondaria" },
                { userParam: "sezione_urbana", requestParam: "sezione_urbana" },
                { userParam: "subalterno", requestParam: "subalterno" },
                { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
                { userParam: "territorio", requestParam: "territorio" },
                { value: "VISURA_IMMOBILE_COMPLETA", requestParam: "codice_tipo_visura" }
            ]
        },
        {
            title: "Visura catastale STORICA", slug: "visura-catastale-immobile", type: 'request', availlableForDisabledItems: true,
            params: [
                { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
                { userParam: "foglio", requestParam: "foglio" },
                { userParam: "particella", requestParam: "particella" },
                { userParam: "particella_secondaria", requestParam: "particella_secondaria" },
                { userParam: "sezione_urbana", requestParam: "sezione_urbana" },
                { userParam: "subalterno", requestParam: "subalterno" },
                { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
                { userParam: "territorio", requestParam: "territorio" },
                { value: "VISURA_IMMOBILE_STORICA", requestParam: "codice_tipo_visura" }
            ]
        },
        {
            title: "Inserisci in Monitoraggio", slug: "monitoraggio-immobili", type: 'request',
            params: [
                { userParam: "codice_comune_catastale", requestParam: "codice_comune_catastale" },
                { userParam: "foglio", requestParam: "foglio" },
                { userParam: "particella", requestParam: "particella" },
                { userParam: "particella_secondaria", requestParam: "particella_secondaria" },
                { userParam: "sezione_urbana", requestParam: "sezione_urbana" },
                { userParam: "subalterno", requestParam: "subalterno" },
                { userParam: "tipo_catasto", requestParam: "tipo_catasto" },
                { userParam: "nome_comune", requestParam: "nome_comune" },
                { userParam: "territorio", requestParam: "territorio" }
            ]
        }
    ]
}
const catasto_soggetti_navigations: NavigationConfig[] = [
    {
        name: "Soggetti",
        nameAfterSelect: "Soggetto selezionato",
        channel: "catasto:soggetti",
        outputParamName: "id_soggetto",
        extraParamNames: [{ from: '_description', to: "denominazione" }],
        isSelectable: true,
        details: [
            { title: "Denominazione", description: "", icon: "fa-regular fa-user", field: '_description', colspan: 2 },
            { title: "Codice Fiscale", description: "", icon: "fa-regular fa-id-card", field: "codice_fiscale" }
        ],
        actions: [
            {
                title: "Inserisci in Monitoraggio", slug: "monitoraggio-soggetti", type: 'request',
                params: [
                    { userParam: 'codice_fiscale', requestParam: 'codice_fiscale' },
                    { userParam: 'nome', requestParam: 'nome' },
                    { userParam: 'cognome', requestParam: 'cognome' }
                ]
            }
        ]
    }, {
        name: "Uffici Catastali",
        nameAfterSelect: "Ufficio selezionato",
        channel: "catasto:soggetti:uffici",
        inputParams: ["id_soggetto"],
        outputParamName: "codice_ufficio",
        isSelectable: true,
        details: [
            { title: "Ufficio Catastale", description: "", icon: "fa-solid fa-building-columns", field: "descrizione_ufficio" },
            { title: "Aggiornamento", description: "", icon: "fa-regular fa-calendar-check", field: "_updated" },
            { title: "Fabbricati", description: "", icon: "fa-regular fa-building", field: "numero_fabbricati" },
            { title: "Terreni", description: "", icon: "fa-solid fa-tractor", field: "numero_terreni" }],
        actions: [
            { title: 'Scheda catastale', slug: "scheda-catastale-persone-fisiche", type: 'request' },
            { title: "Visura catastale", slug: "visura-catastale-persone-fisiche", type: 'request' },
        ]
    }, {
        ...catasto_navigation_beni,
        channel: "catasto:soggetti:uffici:immobili",
    },
    catasto_navigation_intestati
]
export const catastoConfig: EntryPoint[] = [
    {
        name: "Ricerca per persona fisica",
        isActive: true,
        notes: [{
            title: 'Info',
            content: 'Dati minimi per la ricerca: <span class="font-medium">Codice Fiscale</span> oppure <span class="font-medium">Cognome + Nome</span>'
        }],
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "CF",
        }, {
            name: "cognome",
            description: "Cognome",
        }, {
            name: "nome",
            description: "Nome",
        }, {
            name: "territorio",
            description: "Comune",
            type: "autocomplete",
            inputChannel: "catasto:ricerca:comuni",
            extra: {
                valueField: 'codice_ufficio_catastale',
            },
            defaultValue: 'IT'

        }, {
            name: "_tipo_soggetto",
            description: "tipo soggetto",
            type: "hidden",
            defaultValue: "personafisica"

        }],
        navigations: catasto_soggetti_navigations
    }, {
        name: "Ricerca per persona giuridica",
        isActive: true,
        notes: [{
            title: 'Info',
            content: 'Dati minimi per la ricerca: <span class="font-medium">Codice Fiscale</span> oppure <span class="font-medium">Denominazione</span>'
        }],
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            pattern: "COMPANY_CF",
        }, {
            name: "denominazione",
            description: "Denominazione",
        }, {
            name: "territorio",
            description: "Comune",
            type: "autocomplete",
            inputChannel: "catasto:ricerca:comuni",
            defaultValue: 'IT',
            extra: {
                valueField: 'codice_ufficio_catastale',
            }
        }, {
            name: "_tipo_soggetto",
            description: "tipo soggetto",
            type: "hidden",
            defaultValue: "personagiuridica"
        }],
        navigations: catasto_soggetti_navigations
    }, {
        name: "Ricerca per immobile",
        isActive: true,
        notes: [{
            title: 'Info',
            content: '* = dati minimi necessari per la ricerca'
        }],
        fields: [
            {
                name: "tipo_catasto",
                description: "Tipo catasto",
                type: "radio",
                required: true,
                items: [{
                    text: "Fabbricati",
                    value: "FABBRICATI"
                }, {
                    text: "Terreni",
                    value: "TERRENI"
                }],
                defaultValue: "FABBRICATI"

            },
            {
                name: "foglio",
                description: "Foglio",
                required: true
            }, {
                name: "particella",
                description: "Particella",
                required: true
            }, {
                name: "particella_secondaria",
                description: "Particella secondaria"
            }, {
                name: "subalterno",
                description: "Subalterno"
            }, {
                name: "codice_comune_catastale",
                description: "Comune",
                required: true,
                type: "autocomplete",
                inputChannel: "catasto:ricerca:comuni"

            }, {
                name: "codice_sezione_catastale",
                description: "Sezione",
                required: false,
                type: "select",
                dependencies: ['codice_comune_catastale'],
                extra: {
                    requiredParams: [{
                        input: 'codice_comune_catastale',
                        output: 'codice'
                    },
                    {
                        input: 'tipo_catasto',
                        output: 'tipo_catasto'
                    }]
                },
                inputChannel: "catasto:ricerca:sezioni",

            }],
        navigations: [
            {
                ...catasto_navigation_beni,
                channel: "catasto:immobili",
            },
            catasto_navigation_intestati
        ]
    }, {
        name: "Ricerca per indirizzo",
        isActive: true,
        notes: [{
            title: 'Info',
            content: '* = dati minimi necessari per la ricerca'
        }],
        fields: [
            {
                name: "codice_comune_catastale",
                description: "Comune",
                type: "autocomplete",
                inputChannel: "catasto:ricerca:comuni",
                required: true
            }, {
                name: "toponimo",
                description: "Toponimo",
                type: "autocomplete",
                inputChannel: 'catasto:lista:toponimi'
            },
            {
                name: "indirizzo",
                description: "Indirizzo",
                required: true
            },
            {
                name: "numero_da",
                description: "Dal numero"
            },
            {
                name: "numero_a",
                description: "Al numero"
            }
        ],
        navigations: [
            {
                name: "Indirizzi",
                nameAfterSelect: "Indirizzo selezionato",
                channel: "catasto:indirizzi",
                outputParamName: "id_indirizzo",
                isSelectable: true,
                details: [
                    { title: "Indirizzo", description: "", icon: "user", field: 'indirizzo' }
                ]
            },
            {
                ...catasto_navigation_beni,
                details: [
                    { title: "Ubicazione", description: "", icon: "fa-solid fa-location-dot", field: "ubicazione" },
                    { title: "Classamento", description: "", icon: "fa-solid fa-tag", field: "codice_categoria" },
                    { title: "Fabbricati", description: "Foglio/Particella/Subalterno", icon: "fa-regular fa-building", field: ['foglio', 'particella', 'subalterno'], separator: "/" }
                ],
                inputParams: ['id_indirizzo', 'numero_da', 'numero_a'],
                channel: "catasto:indirizzi:civici"
            },
            catasto_navigation_intestati
        ]
    }]
