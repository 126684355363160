<div class="py-3 relative flex flex-col gap-2">
    <div class="flex items-center justify-end gap-2 " *ngIf="params$ | async as params;">
        <app-input (input)="setSearchString.next($event)" placeholder="Ricerca libera"></app-input>
        <app-select title="Filtra servizio" [items]="params.services"
            (selected)="selectedService.next($event)"></app-select>
        <app-date-select title="Data (da)" min="2022-01-01" [max]="maxFrom"
            (selected)="selectedStartDate.next($event)"></app-date-select>
        <app-date-select title="Data (a)" max="2023-12-31" (selected)="selectedEndDate.next($event)"
            [min]="minTo"></app-date-select>
        <div class="px-3 py-1">
            <button (click)="toggleShowArchived()"
                class="shadow-sm rounded-full w-10 h-10 p-2 bg-white  hover:shadow-lg transition"
                title="Mostra/Nascondi richieste archiviate"
                [ngClass]="[showArchived? 'text-primary': 'text-gray-600']">
                <i class="fa fa-solid fa-eye w-6 h-6 "></i>
            </button>
        </div>
    </div>
    <ng-container *ngIf="showArchived">
        <div class="px-3 py-3 even:bg-slate-100 odd:bg-slate-200 rounded shadow">
            <h2 class="text-xl font-semibold dark:text-white">Richieste archiviate</h2>
            <div class="flex gap-4 my-4 overflow-x-auto p-4" *ngIf="archivedItems$ | async as items">
                <app-base-card *ngFor="let item of items; trackBy:trackFn" [attr.data-cy]="item.id" [@inOutAnimation]
                    class="w-1/5">
                    <div class="text-xl font-bold relative w-60 h-12" header>
                        <div class="truncate" [title]="item.title">{{item.title}}</div>
                        <div class="text-xl leading-6 font-normal uppercase">{{item.subtitle}}</div>
                    </div>
                    <div body class="w-60">
                        <div class="text-secondary leading-6 text-lg font-semibold min-h-10">{{item.body}}</div>
                        <!-- <div *ngFor="let param of item.extra.notes | keyvalue" class="text-xs">{{
                        param.key}}: {{param.value}}</div>
                   -->
                        <div *ngIf="item.extra?.error" class="flex gap-1"><i
                                class="fa fa-solid fa-triangle-exclamation w-10 min-h-10 text-red-600"></i>
                            <div class="text-red-600 text-sm font-semibold h-10">{{item.extra.error}}</div>
                        </div>

                    </div>
                    <p footer>
                        <!-- <i class="fa fa-solid fa-circle-info w-4 h-4 text-primary" [title]="'ID:'+item.id"></i> -->
                        <span *ngIf="item.id">ID: {{item.id}} - </span>{{item.footer |
                        date:'dd/MM/yyyy HH:mm'}}
                    </p>
                    <ng-container ngProjectAs="[actions]">
                        <button title="Ripristina" (click)="deleteRequest(item.id, true)"
                            class="px-6 py-2 hover:drop-shadow-md rounded">
                            <i class="h-6 w-6 fa fa-solid fa-eye"></i>
                        </button>

                    </ng-container>
                </app-base-card>
            </div>
        </div>
    </ng-container>
    <div class="px-3 py-3 even:bg-slate-100 odd:bg-slate-200 rounded shadow">
        <h2 class="text-xl font-semibold dark:text-white">In lavorazione</h2>
        <div class="flex gap-4 my-4 overflow-x-auto p-4" *ngIf="pendingItems$ | async as items">
            <app-base-card *ngFor="let item of items; trackBy:trackFn" [attr.data-cy]="item.id" [@inOutAnimation]
                class="w-1/5">
                <div class="text-xl font-bold relative max-w-[200px] h-12" header>
                    <div class="truncate" [title]="item.title">{{item.title}}</div>
                    <div class="text-xl leading-6 font-normal uppercase">{{item.subtitle}}</div>
                </div>
                <div body class="w-48">
                    <div class="text-secondary text-lg leading-6 font-semibold min-h-10">{{item.body}}</div>
                    <!-- <div *ngFor="let param of item.extra.notes | keyvalue" class="text-xs">{{
                        param.key}}: {{param.value}}</div>
                   -->

                </div>
                <div class="relative w-full" footer>
                    <p class="">
                        <!-- <i class="fa fa-solid fa-circle-info w-4 h-4 text-primary" [title]="'ID:'+item.id"></i> -->
                        <span *ngIf="item.id">ID: {{item.id}} - </span>{{item.footer |
                        date:'dd/MM/yyyy HH:mm'}}
                    </p>
                </div>
                <ng-container ngProjectAs="[actions]">
                    <div class="relative">
                        <i
                            class="absolute fa fa-solid fa-circle-notch w-8 h-8 text-primary group-hover:animate-spin"></i>
                        <i class="transition-transform duration-500 m-2 fa fa-solid w-4 h-4 text-primary "
                            [ngClass]="iconStatusMap[item.extra.status]"></i>
                    </div>
                    <button title="Archivia" (click)="deleteRequest(item.id)"
                        class="px-6 py-2 hover:drop-shadow-md rounded ">
                        <i class="h-6 w-6 fa fa-solid fa-eye-slash"></i>
                    </button>

                </ng-container>
            </app-base-card>
        </div>
    </div>
    <div class="px-3 py-3 even:bg-slate-100 odd:bg-slate-200 rounded shadow">
        <h2 class="text-xl font-semibold dark:text-white">Disponibili per la consultazione</h2>
        <div class="flex gap-4 my-4 overflow-x-auto p-4" *ngIf="items$ | async as items">
            <app-base-card *ngFor="let item of items; trackBy:trackFn" [attr.data-cy]="item.extra.requestId"
                [@inOutAnimation] class="w-1/5">
                <ng-container ngProjectAs="[actions]">
                    <div class="flex justify-center items-center">
                        <div *ngIf="!item.extra.lastOpened" class="rounded-full w-4 h-4 bg-secondary animate-ping">
                        </div>
                    </div>
                    <div>
                        <button (click)="delete(item.id)" class="px-6 py-2 hover:drop-shadow-md rounded">
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                        <button (click)="open(item)" class="px-6 py-2 hover:drop-shadow-md rounded cursor-pointer">
                            <i class="h-6 w-6 fa fa-solid fa-cloud-arrow-down"></i>
                        </button>
                    </div>

                </ng-container>
                <div class="text-xl font-bold relative max-w-full" header>
                    <div class="truncate" [title]="item.title">{{item.title}}</div>
                    <div class="text-xl leading-6 font-normal uppercase">{{item.subtitle}}</div>
                </div>
                <div body class="text-secondary leading-6 font-semibold text-lg">
                    {{item.body}}
                    <!--   <div *ngFor="let param of item.request?.notes | keyvalue" class="text-xs">{{
                    param.key}}: {{param.value}}</div>
-->
                </div>
                <p footer>
                    <!--<i class="fa fa-solid fa-circle-info w-4 h-4 text-primary" [title]="'ID:'+item.extra.requestId"></i> -->
                    <span *ngIf="item.extra.requestId">ID: {{item.extra.requestId}} - </span>{{item.footer |
                    date:'dd/MM/yyyy HH:mm'}}
                </p>
            </app-base-card>
        </div>
    </div>
    <div class="px-3 py-3 even:bg-slate-100 odd:bg-slate-200 rounded shadow">
        <h2 class="text-xl font-semibold dark:text-white">Richieste non evase</h2>
        <div class="flex gap-4 my-4 overflow-x-auto p-4" *ngIf="failedItems$ | async as items">
            <app-base-card *ngFor="let item of items; trackBy:trackFn" [attr.data-cy]="item.id" [@inOutAnimation]
                class="w-1/5">
                <div class="text-xl font-bold relative w-48 h-12" header>
                    <div class="truncate" [title]="item.title">{{item.title}}</div>
                    <div class="text-xl leading-6 font-normal uppercase">{{item.subtitle}}</div>
                </div>
                <div body class="w-48">
                    <div class="text-secondary leading-6 text-lg font-semibold min-h-10">{{item.body}}</div>
                    <!-- <div *ngFor="let param of item.extra.notes | keyvalue" class="text-xs">{{
                        param.key}}: {{param.value}}</div>
                   -->
                    <div *ngIf="item.extra?.error"
                        class="flex gap-1 overflow-hidden hover:overflow-visible bg-white hover:shadow-md  h-10 hover:h-auto hover:absolute p-1 rounded-sm">
                        <i class="fa fa-solid fa-triangle-exclamation w-10 min-h-10 text-red-600"></i>
                        <div class=" text-red-600 text-sm font-semibold bg-white">
                            {{item.extra.error}}</div>
                    </div>

                </div>
                <p footer>
                    <!-- <i class="fa fa-solid fa-circle-info w-4 h-4 text-primary" [title]="'ID:'+item.id"></i> -->
                    <span *ngIf="item.id">ID: {{item.id}} - </span>{{item.footer |
                    date:'dd/MM/yyyy HH:mm'}}
                </p> <ng-container ngProjectAs="[actions]">
                    <button title="Archivia" (click)="deleteRequest(item.id)"
                        class="px-6 py-2 hover:drop-shadow-md rounded">
                        <i class="h-6 w-6 fa fa-solid fa-eye-slash"></i>
                    </button>

                </ng-container>
            </app-base-card>
        </div>
    </div>
</div>