import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-light',
  templateUrl: './light.component.html',
})
export class LightComponent {
  @Input() colorName!: string
  @Input() ping: boolean = false

  colorMap(value: string) {
    let theMap: any = {
      YELLOW: 'orange',
      GIALLO: 'orange',
      GREEN: 'green',
      VERDE: 'green',
      ROSSO: 'red',
      RED: 'red',
    }
    return theMap[value.toUpperCase()] || 'gray'
  }
}
