<div class="h-full bg-white transition-all px-4 pt-2 pb-2 gap-2 w-full">
    <button (click)="onDismiss.emit()" class="z-10 absolute top-3 right-4">
        <svg class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
    </button>
    <div class="text-xl p-2 bg-yellow-100 text-blue-900">Catasto TERRENI</div>

    <div class="flex w-full items-center justify-between gap-2 mt-2 ">
        <div><span class="font-semibold">Lat.: </span> {{selectedCoord?.lat | number:'3.4-5'}}</div>
        <div><span class="font-semibold">Long.: </span> {{selectedCoord?.lng | number:'3.4-5'}}</div>
    </div>
    <div>
        <app-navigazione-card *ngIf="terreni?.[0]"
            [details]="populateDetails(navigation.details|| [], terreni?.[0].raw)"
            [actions]="populateActions(navigation.actions || [])" (selectedAction)="launchAction($event)">
        </app-navigazione-card>
    </div>
    <div class="flex-grow"></div>
    <button (click)="onToggleLower.emit()" *ngIf="showToggleLowerButton"
        class="mt-2 text-center text-xl px-4 py-2 rounded w-full text-white bg-primary transition-colors">
        Mostra fabbricati
    </button>
</div>
<app-submission-confirm [showPanel]="showSubmissionConfirm" (onStay)="showSubmissionConfirm=false">
</app-submission-confirm>