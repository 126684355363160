<div class="w-full">
    <div class="-mx-2 sm:-mx-4 px-4 sm:px-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <thead>
                    <tr>
                        <th *ngFor="let det of details" scope="col" [attr.colspan]="det.colspan || 1"
                            class="bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal"
                            [ngClass]="[compact ? 'px-2 py-1.5' : 'px-4 py-3' ]">
                            {{det.title}}
                            <div class="text-xs normal-case" *ngIf="det.description">
                                {{det.description}}</div>
                        </th>

                        <th scope="col" colspan="1"
                            class="bg-white  border-b border-gray-200 text-primary text-left text-sm uppercase font-normal"
                            [ngClass]="[compact ? 'px-2 py-1.5' : 'px-4 py-3' ]">

                        </th>
                    </tr>
                </thead>
                <tbody class="" *ngIf="rows?.length else nodata;">
                    <ng-container *ngFor="let item of rows">
                        <tr *ngIf="!item.raw._disabled || !hideDisabled"
                            class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">
                            <td *ngFor="let detail of populateDetails(details|| [], item.raw || {})"
                                [attr.colspan]="detail.colspan || 1" class="border-b border-gray-200 text-sm"
                                [ngClass]="[compact ? 'px-2 py-1.5' : 'px-4 py-3' ]">
                                <app-light *ngIf="detail.type == 'light'; else text" [colorName]="detail.value"
                                    [ping]="true"></app-light>
                                <ng-template #text>
                                    <span class="group">{{detail.value}} {{detail.suffix}}<button
                                            *ngIf="detail.copiable"
                                            class="group-hover:opacity-100 opacity-0 transition-opacity text-black"
                                            (click)="copyText(detail.value)"><i
                                                class="fa-solid fa-clipboard w-5 h-5"></i></button></span>
                                </ng-template>
                            </td>
                            <td class="border-b border-gray-200 text-sm" colspan="1"
                                [ngClass]="[compact ? 'px-2 py-1.5' : 'px-4 py-3' ]">

                                <div class="flex items-center">
                                    <div class="flex-shrink flex flex-wrap">
                                        <ng-container *ngFor="let action of actions">

                                            <button
                                                *ngIf="action.subscription?.service?.status =='COMINGSOON'; else act"
                                                disabled [attr.data-cy]="action.slug" title="Presto Disponibile"
                                                class="mx-2 my-1 px-2 py-1 cursor-not-allowed rounded hover:ring-secondary border-secondary hover:ring-1 border flex items-center text-secondary bg-white hover:text-white hover:bg-gray-300 transition-colors"
                                                [ngClass]="[(item.raw._disabled && !action.availlableForDisabledItems) ? 'disabled hidden': '']">
                                                {{action.title}}
                                                <span *ngIf="!action.isFree"
                                                    class="px-1.5 py-0.5 text-center text-xs rounded-full text-yellow-600  bg-yellow-200 ">€</span>
                                            </button>
                                            <ng-template #act>
                                                <button (click)="onAction.emit({action, item})"
                                                    [attr.data-cy]="action.slug"
                                                    class="mx-2 my-1 px-2 py-1 rounded border-secondary hover:border-primary hover:ring-primary hover:ring-1 border flex items-center text-black bg-white hover:text-white hover:bg-black transition-colors"
                                                    [ngClass]="[(item.raw._disabled && !action.availlableForDisabledItems) ? 'disabled hidden': '']">
                                                    {{action.title}}
                                                    <span *ngIf="!action.isFree"
                                                        class="px-1.5 py-0.5 text-center text-xs rounded-full text-yellow-600  bg-yellow-200 ">€</span>
                                                </button>
                                            </ng-template>
                                        </ng-container>
                                        <button *ngIf="!item.raw._disabled && selectable" data-cy="list"
                                            class="mx-2 my-1 px-2 py-1 rounded  border-primary border text-primary bg-white hover:text-white hover:bg-primary transition-colors"
                                            (click)="onNextNavigation.emit(item)">Lista
                                            {{nextButton}} </button>
                                    </div>
                                </div>
                            </td>
                            <div *ngIf="item.raw._disabled" data-cy="disabled-item"
                                class="absolute font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                {{disabledWarning}}</div>
                        </tr>
                    </ng-container>
                </tbody>
                <ng-template #nodata>
                    <tbody data-cy="no-results">
                        <tr class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">

                            <td [attr.colspan]="details.length+1"
                                class="font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                Nessun risultato per i dati inseriti</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>