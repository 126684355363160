import { Component, OnInit } from '@angular/core';
import { gql, QueryRef } from 'apollo-angular';
import { tap, map, Subject, Observable } from 'rxjs';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { MyDepositsGQL, MyDepositsQuery } from 'src/graphql/generated';

const DEPOSITS = gql`
query MyDeposits( $order_by: [ledger_order_by!] = { createdAt: desc }) {
  ledger(where: { amount: {_neq: 0}, userId: {_is_null:true}}, order_by: $order_by) {
    createdAt
    amount
    description
    pending
    wallet { name }
  }
}`

@Component({
  selector: 'app-deposits',
  templateUrl: './deposits.component.html',
})
export class DepositsComponent implements OnInit {


  constructor(private getRequests: MyDepositsGQL) {

    this.orderCondition.pipe(
      tap(x => {
        this.itemsWatch = this.getRequests.watch({ order_by: x })
        this.items$ = this.itemsWatch.valueChanges.pipe(
          map(y => y.data.ledger),
        )
      })
    ).subscribe()

  }

  p: number = 1;
  orderCondition = new Subject<any>()
  items$!: Observable<MyDepositsQuery['ledger'] | any>
  itemsWatch!: QueryRef<MyDepositsQuery, any>;
  details: { title: string, description?: string, order: number, sortable?: boolean, asc?: any, desc?: any }[] = [{
    description: "",
    title: "Data",
    sortable: true,
    order: 0,
    asc: { createdAt: 'asc' },
    desc: { createdAt: 'desc' },
  },
  {
    description: "",
    title: "Importo",
    order: 0
  }]

  ngOnInit(): void {
    this.orderCondition.next({})
  }

  sortBy(idx: number) {
    this.details.forEach((item, idy) => {
      if (idx == idy) {
        item.order = item.order <= 0 ? 1 : -1
      } else {
        item.order = 0
      }
    }
    )
    let item = this.details[idx]
    this.orderCondition.next(item?.order == -1 ? item.asc : item?.order == 1 ? item.desc : {})

  }
}
