import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError, map } from 'rxjs';
import { SSOService } from 'src/app/services/sso.service';

@Component({
  selector: 'app-securesuite',
  templateUrl: './securesuite.component.html',
  styles: [`
    :host{
        height: 100%;
        position: relative;
    }`]
})
export class SecuresuiteComponent implements OnInit {

  constructor(
    private ssoService: SSOService,
    private sanitizer: DomSanitizer
  ) { }
  ready = false
  loginUrl: SafeResourceUrl = ''
  error = ''
  browserName = this.detectBrowserName()
  supportedBrowserIFrame = ['firefox']
  ngOnInit(): void {
    this.ssoService.login('securesuite').pipe(
      map((res) => {
        this.loginUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.redirectTo || '')
        this.ready = !!this.loginUrl

      }),
      catchError((error, caught) => {
        this.error = error.error.message
        console.log(error, caught)
        return ''
      })
    ).subscribe()
  }
  openInNewWindow() {
    this.ssoService.login('securesuite').pipe(
      map((res) => {
        let url = this.sanitizer.bypassSecurityTrustResourceUrl(res.redirectTo || '')
        window.open(res.redirectTo, 'securesuite')
      }),
      catchError((error, caught) => {
        this.error = error.error.message
        console.log(error, caught)
        return ''
      })
    ).subscribe()
  }
  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
