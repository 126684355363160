import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnInit {

  @Output() input = new EventEmitter()
  @Input() placeholder?: string = 'Inserisci testo'
  @Input() initialValue?: string | null
  @Input() clearable?: boolean = true
  inputString?: string | null

  constructor() { }

  ngOnInit(): void {
    this.inputString = this.initialValue
  }

  InputEvent(text: any) {
    this.inputString = text
    this.input.emit(text.target.value)
    console.log(text.target.value)
  }

}
