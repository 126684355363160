import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryItem } from 'src/app/models/query.model';
import { QueriesService } from 'src/app/pages/vv/services/queries.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: []
})
export class MessagesComponent implements OnInit {

  constructor(
    private queryService: QueriesService
  ) { }
  eventiMonitoraggio$ = new Observable<QueryItem[]>()
  ngOnInit(): void {
    this.getEvents()
  }
  getEvents() {
    this.eventiMonitoraggio$ = this.queryService.post({
      channel: 'monitoraggio:eventi',
      data: {}
    })
  }
  markAsRead(evento: QueryItem) {
    this.queryService.post({
      channel: 'monitoraggio:eventi:status',
      data: {
        "identificativo_evento": evento.value,
        "stato_lettura": true
      }
    })
  }

}
