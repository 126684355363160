import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LatLng } from 'leaflet';
import { tap, map, catchError, of, delay, filter, switchMap, forkJoin, combineLatest, Observable } from 'rxjs';
import { DatiCatastali } from 'src/app/models/catasto.model';
import { NavigationActionsConfig, NavigationDetailsConfig } from 'src/app/models/navigation.shared.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { MyScreenersQuery, MySubscriptionsQuery } from 'src/graphql/generated';
import { QueriesService } from '../../services/queries.service';
import { RequestService } from '../../services/request.service';
import { SubscriptionService } from '../../services/subscription.service';
import { terreniConfig } from '../mappe.config';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
})
export class SidePanelComponent implements OnInit, OnChanges {

  constructor(
    private queriesService: QueriesService,
    private requestService: RequestService,
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService) {
    this.subscriptionService.subscriptions$.subscribe((s: MySubscriptionsQuery) => this.availableServices = s.subscription)
  }
  navigation = terreniConfig
  confirmActionPanel: boolean = false
  confirmActionData?: ActionComponentData
  showSubmissionConfirm: boolean = false
  // selectedPointData?: { coords: any, data: any, catastoData: any }

  @Input() selectedCoord?: LatLng
  @Input() selectedCatastoInfo?: DatiCatastali
  @Input() showToggleLowerButton: boolean = true
  @Output() onDismiss = new EventEmitter()
  @Output() onToggleLower = new EventEmitter()
  availableServices: any[] = []
  terreni?: any[]
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCatastoInfo']) {
      if (changes['selectedCatastoInfo'].currentValue) {
        let info = changes['selectedCatastoInfo'].currentValue
        this.queriesService.post({
          channel: 'catasto:immobili',
          data: info
        }).subscribe((x) => {
          this.terreni = x.items
        })
      }
    }
  }


  populateActions(actions: NavigationActionsConfig[]): NavigationActionsConfig[] {
    return actions.map(
      a => ({ ...a, subscription: this.availableServices.find(s => s.service?.slug == a.slug) })
    ).filter(a => a.subscription || a.type == 'query')
      .map(a => ({ ...a, isFree: !a.subscription || !a.subscription.price }))
  }

  populateDetails(details: NavigationDetailsConfig[], item: any) {
    let populated = details.map(d => ({ ...d, value: "" }))
    populated.forEach(p => p.value = Array.isArray(p.field) ? p.field.map(f => item[f] || '').join(p.separator || " ") : item[p.field])
    return populated
  }

  launchAction(data: NavigationActionsConfig) {
    debugger
    this.confirmActionData = {
      title: data.title,
      description: data.subscription?.service?.description || '',
      subscriptionId: data.subscription?.id || 0,
      price: data.subscription?.price || 0,
      type: data.type,
      channel: data.slug,
      params: data.params
    }

    // ask for price information
    if (data.subscription?.service?.priceCalculation?.channel) {
      this.sharedService.loadingMsg$ = 'Caricamento costi...'
      // query service for price
      this.queriesService.post({
        channel: data.subscription?.service?.priceCalculation?.channel,
        data: {}  // TODO COLLECT DATA
      })
        .subscribe(s => {
          console.log("PREVENTIVO", s)
          this.sharedService.loadingMsg$ = ''
          // populate data
          this.confirmActionData = { ...(this.confirmActionData || {}), price: 10 }
          // open popup
          this.confirmActionPanel = true
        })
    } else {
      if (data.subscription?.price) {
        this.confirmActionPanel = true
      } else {
        this.confirmSubmission()
      }
    }
  }

  makeRequest(data: ActionComponentData) {
    // collect params 
    let params: any = {}
    let notes: any = {}
    this.sharedService.hideActionPopup()
    this.confirmActionData?.params?.forEach(p => {
      if (p.userParam) {
        params[p.requestParam] = this.terreni?.[0]?.raw?.[p.userParam]
      } else if (p.value) {
        params[p.requestParam] = p.value
      }
    })

    this.sharedService.submitRequestWithParamsAndNotes(
      params,
      {},
      data,
      "Caricamento..."
    ).pipe(
      map((res: any) => {
        this.sharedService.loadingMsg$ = ""
        if (res?.status == 'queued' || res?.status == 'completed') {
          this.showSubmissionConfirm = true
        }
      })).subscribe()
  }

  confirmSubmission() {
    this.confirmActionPanel = false
    this.makeRequest(this.confirmActionData || {})
  }

}
