<div class="flex flex-col">
    <app-admin-feature>
        <section class="w-full p-6 py-20">
            <div class="text-center text-xl font-bold text-primary py-3 ">Conosci il codice fiscale / partita IVA?<br>
                <span class="text-black text-sm">Esegui una ricerca rapida qui!</span>
            </div>
            <form class="w-full flex flex-col items-center justify-center">
                <div class="w-96 relative flex items-center">
                    <input (input)="precog($event)" #search
                        class="shadow-md focus:shadow-xl rounded-full flex-1 appearance-none border border-gray-300 w-full py-4 px-4 bg-white dark:bg-dark text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
                        placeholder="Inserisci cf/piva">
                    <div class="absolute right-2 h-5 text-primary flex">
                        <button *ngIf="foundData?.length" type="button" (click)="isOpen =!isOpen" data-cy="open-btn">
                            <svg class="h-5 w-5 transition-transform" [class.rotate-90]="isOpen" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <polyline points="6 9 12 15 18 9" />
                            </svg>
                        </button>
                        <button type="button" *ngIf="!!search.value" (click)="clear(search)" data-cy="clear-btn">
                            <svg class="h-5 w-5 " width=" 24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" fill="none" stroke-linecap="round" stroke-linseleejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="relative flex p-2 gap-2 " *ngIf="showSubmitButtons">
                    <span class="text-xs text-primary text-center">Sembra che tu stia inserendo un nome o una
                        denominazione,
                        <br>specifica in quale
                        ambito vuoi
                        ricercare</span>
                    <button
                        class="px-2 py-1 border border-secondary rounded-md bg-white hover:border-primary hover:text-primary text-secondary"
                        (click)="advancedLookup(search.value, 'people')">Cerca persona</button>
                    <button
                        class="px-2 py-1 border border-secondary rounded-md bg-white hover:border-primary hover:text-primary text-secondary"
                        (click)="advancedLookup(search.value, 'company')">Cerca azienda</button>
                </div>
                <div class="relative w-96 mt-2">
                    <ul *ngIf="foundData?.length && isOpen"
                        class="shadow-md absolute z-20 border border-gray-400 bg-white dark:bg-dark rounded-lg w-full flex-1 max-h-60 overflow-y-auto list-none appearance-none text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent">
                        <li *ngFor="let item of foundData"
                            class="w-full px-2 flex items-center justify-center hover:bg-gray-100 transition-colors">
                            <div class="flex-grow">
                                <div class="text-left w-full min-h-10 rounded-lg py-1 px-2  text-primary">
                                    {{item.title}}
                                </div>
                                <div
                                    class="text-left text-ellipsis w-full min-h-10 text-xs px-2 pb-2 border-b border-b-secondary ">
                                    {{item.subtitle}}
                                </div>
                            </div>
                            <button
                                class="px-2 py-1 border border-secondary rounded-md bg-white hover:border-primary hover:text-primary text-secondary"
                                (click)="selectServices(item)">Servizi</button>
                        </li>
                    </ul>
                </div>

                <div class="text-primary py-4 h-5"><span *ngIf="parsedData && false">Complimenti, hai inserito un
                        {{extractedMessages[parsedData.type]}}, procediamo con la
                        ricerca...</span></div>
            </form>
            <app-service-selection-popup [services]="route.parent?.routeConfig?.children"
                (onCancel)="showSelection=false" [showPanel]="showSelection" [title]="selectedItem?.title || ''"
                [data]="selectedItem?.raw"></app-service-selection-popup>

        </section>
    </app-admin-feature>
    <app-workarea></app-workarea>
</div>