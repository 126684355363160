<div class="flex items-center justify-between">
    <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white">Servizi Richiesti </div>
    <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white flex gap-2" *ngIf="params$ | async as params;">
        <app-date-select title="Data (da)" min="2022-01-01" [max]="maxFrom"
            (selected)="selectedStartDate.next($event)"></app-date-select>
        <app-date-select title="Data  (a)" max="2023-12-31" (selected)="selectedEndDate.next($event)"
            [min]="minTo"></app-date-select>
        <app-select title="Filtra stato richiesta" [items]="params.statuses"
            (selected)="selectedStatus.next($event)"></app-select>
        <app-select title="Filtra servizio" [items]="params.services"
            (selected)="selectedService.next($event)"></app-select>
    </div>
</div>
<div class="w-full">
    <div class="-mx-4 sm:-mx-8 px-4 sm:px-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <thead>
                    <tr>
                        <th *ngFor="let det of details; let i = index;" scope="col"
                            class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                            {{det.title}}
                            <div class="text-xs normal-case" *ngIf="det.description">
                                {{det.description}}</div>
                            <button *ngIf="det.sortable" (click)="sortBy(i)" class="p-1"><i class="w-3 h-3 fa fa-solid"
                                    [ngClass]="{
                            'text-gray-800 fa-sort-up': det.order == -1, 
                            'text-gray-800 fa-sort-down': det.order == 1, 
                            'text-gray-400 fa-sort': !det.order}"></i>
                            </button>
                        </th>

                    </tr>
                </thead>
                <tbody class="" *ngIf="items$ | async as rows">
                    <ng-container *ngIf="rows.length; else nodata;">
                        <tr *ngFor="let item of rows | paginate: {itemsPerPage: 12 , currentPage: p}"
                            class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">
                            <td class="px-4 py-3 border-b border-gray-200 text-sm">
                                {{item.id}}
                            </td>
                            <td class="px-4 py-3 border-b border-gray-200 text-sm">
                                {{item.createdAt | date: 'dd.MM.yyyy HH:mm'}}
                            </td>
                            <td class="px-4 py-3 border-b border-gray-200 text-sm">
                                {{item.service?.name}}
                            </td>
                            <td class="px-4 py-3 border-b border-gray-200 text-sm group relative">
                                <div class="uppercase" *ngIf="item.service">
                                    {{userService._getParamByFieldPosition(item.service.fields,
                                    item,
                                    'title') || '...'}}
                                </div>
                                <ng-container
                                    *ngIf="userService._getParamFromFields(item.service.fields, item) as notes">
                                    <div *ngIf="notes.length"
                                        class="group-hover:block hidden absolute w-80 p-4 bg-white shadow-lg z-50">
                                        <div *ngFor="let note of notes"> <span *ngIf="note.title"
                                                class="font-bold">{{note.title}}: </span>{{note.value}}
                                        </div>
                                    </div>
                                </ng-container>
                            </td>
                            <td class="px-4 py-3 border-b border-gray-200 text-sm">
                                {{stateMap[item.status] || ''}}
                            </td>
                            <td class="px-4 py-3 border-b border-gray-200 text-sm">
                                <div *ngFor="let transaction of item.transactions"
                                    [ngClass]="[transaction.pending ? 'text-orange-400': transaction.amount > 0 ? 'text-green-800': 'text-red-700']">
                                    {{transaction.amount
                                    | number }}
                                    €</div>
                                <div *ngIf="!item.transactions?.length">-</div>
                            </td>

                        </tr>

                        <button title="Esporta" (click)="xmlExport(rows)"
                            class="text-white fixed bottom-4 right-4 p-0 w-12 h-12 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
                            <i class="w-6 h-6 fa-solid fa-file-circle-check"></i>
                        </button>
                    </ng-container>
                    <ng-template #nodata>
                        <tr data-cy="no-results" class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">

                            <td colspan="5"
                                class="font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                Nessun risultato per i dati inseriti</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>

            <pagination-template #q="paginationApi" *ngIf="details && details.length"
                class="flex justify-center text-black dark:text-white gap-2 mt-2 border-t-secondary py-2 border-t "
                (pageChange)="p = $event" data-cy="pagination">

                <button data-cy="pagination-first"
                    class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                    *ngIf="!q.isFirstPage()" (click)="q.previous()" [class.disabled]="q.isFirstPage()">
                    < </button>

                        <div data-cy="pagination-item" class="flex" *ngFor="let page of q.pages">
                            <button class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                                (click)="q.setCurrent(page.value)" *ngIf="q.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </button>
                            <div data-cy="pagination-current" class="flex" *ngIf="q.getCurrent() === page.value">
                                <span class="px-4 py-2 rounded-md bg-secondary text-white">{{
                                    page.label }}</span>
                            </div>
                        </div>

                        <button data-cy="pagination-last" *ngIf="!q.isLastPage()" (click)="q.next()"
                            class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                            [class.disabled]="q.isLastPage()"> > </button>
            </pagination-template>

        </div>
    </div>
</div>