export const environment = {
  production: false,
  enableSentry: false,
  graphql_endpoint: 'https://hasura.staging.reopera.it/v1/graphql',
  ws_graphql: 'wss://hasura.staging.reopera.it/v1/graphql',
  admin_endpoint: 'https://admin.staging.reopera.it/',
  auth: {
    domain: "reopera-staging.eu.auth0.com",
    clientId: "ZVn0nebr3HYq18MLCSSYtk8smPbNH4IC",
    audience: 'https://api.staging.reopera.it', // <-- will come back to this
    redirectUrl: window.location.origin,
    httpInterceptor: {
      allowedList: [
        // Attach access tokens to any calls that start with '/api/'
        'https://api.staging.reopera.it/*',
        'https://admin.staging.reopera.it/*',
        'https://staging.reopera.it/*',
        'https://hasura.staging.reopera.it/*',
        '/api/*',
      ],
    }
  }
};
