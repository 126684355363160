<div *ngIf="request$| async as detailData" class="flex flex-col h-full w-full">
    <div class="text-2xl font-semibold">Lista formalità</div>
    <!--
        <div class="flex">
            <div *ngFor="let p  of detailData | keyvalue">{{p.key}}: {{p.value}}</div>
        </div> 
    -->

    <app-report-preview [request]="detailData" class="w-full h-full flex-grow overflow-scroll bg-white py-2">
    </app-report-preview>
</div>