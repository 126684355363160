<div class="relative flex items-center rounded-lg bg-white dark:bg-dark ">
    <input
        class="w-40 rounded-lg flex-1 appearance-none border px-4 py-2 border-gray-300  bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
        [placeholder]="title" [(ngModel)]="selectedItem" (change)="onChange($event)" data-cy="date-input" type="text"
        [min]="min" [max]="max" onfocus="(this.type='date')" onchange="(this.type='text')" (emptied)="clear()"
        (select)="onChange($event)">
    <div class="absolute right-2 h-5 text-primary flex">
        <button type="button" *ngIf="selectedItem" (click)="clear()" data-cy="clear-btn">
            <svg class="h-5 w-5 " width=" 24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
        </button>
    </div>
</div>