<li class="text-base" *ngIf="name$ | async as name">
    <ng-container *ngIf="realChildren$ | async as realChildren">
        <div class="relative flex items-center transition-colors duration-500 hover:!border-primary hover:text-primary justify-start border-l-4 border-transparent   hover:bg-white hover:bg-opacity-10"
            routerLinkActive="!border-gray-200" (isActiveChange)="open=$event">
            <button *ngIf="realChildren.length" (click)="open=!open" class="ml-3 p-1 py-3">
                <svg class="w-3 h-3 transition-transform" role="img" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512" [ngClass]="[!open?'-rotate-90':'rotate-0']">
                    <path fill="currentColor"
                        d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                    </path>
                </svg>
            </button>
            <a *ngIf="name; else noUrl" class="flex p-2 overflow-hidden" [routerLink]=" [basePath, data.path]"
                [ngClass]="realChildren.length? '':'ml-8'">
                <span class="text-left mt-0.5">
                    <i class="w-5 h-5 fa-solid mr-1.5" *ngIf="data.data?.['highlightMenu'] && data.data?.['icon']"
                        [ngClass]="[data.data?.['icon']]"></i>
                    <!--<svg class="h-5 w-5" fill="currentColor" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path
                    d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
                <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
                <circle cx="15" cy="9" r="1" />
            </svg> -->
                </span>
                <span [title]="name" class="font-normal max-w-full truncate">
                    {{name}}
                    <span class="inline-block rounded-full bg-accent text-xs w-6 h-6 animate-pulse" *ngIf="badge">
                        <p class="!text-white p-1 text-center align-middle">{{badge}}</p>
                    </span>
                </span>
            </a>
            <ng-template #noUrl>
                <button class="w-full flex m-2 cursor-not-allowed" (click)="!data.data?.['name'] && open=!open"
                    [ngClass]="[realChildren.length? 'ml-2':'ml-10',!name && !realChildren.length? 'cursor-not-allowed': '']">
                    <i class="w-4 h-4 fa-solid mr-1.5" *ngIf="showIcon && data.data?.['icon']"
                        [ngClass]="[data.data?.['icon']]"></i>
                    {{name}}
                    <span class="inline-block rounded-full bg-accent text-xs w-6 h-6 animate-pulse" *ngIf="badge">
                        <p class="!text-white p-1 text-center align-middle">{{badge}}</p>
                    </span>
                </button>
            </ng-template>
        </div>
        <ul *ngIf="realChildren.length" class="relative pl-6 -ml-3" [ngClass]="[open? 'block':'hidden']">
            <app-nav-bar-item *ngFor="let item of realChildren" [data]="item"
                [basePath]="data.path ? basePath + '/' + data.path: basePath">
            </app-nav-bar-item>
        </ul>

    </ng-container>
</li>