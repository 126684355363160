import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-eventi',
  templateUrl: './eventi.component.html',
  styleUrls: []
})
export class EventiComponent implements OnInit {

  constructor() { }
  @Input() list!: any[]
  @Output() onRegisterAsRead = new EventEmitter()
  ngOnInit(): void {
  }
  markRead(item: any) {
    this.onRegisterAsRead.emit(item.id)
  }
}
