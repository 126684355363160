import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { gql, Query, QueryRef } from 'apollo-angular';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { AddDossierGQL, DeleteDocGQL, DeleteDossierGQL, Document, DossiersGQL, DossiersQuery, RenameDossierGQL, SetDocumentParentDossierGQL, SetFolderParentDossierGQL } from 'src/graphql/generated';
import { DocumentService } from '../../vv/services/document.service';

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class DossierComponent implements OnInit {
  data$?: Observable<DossiersQuery>
  parent?: string | null
  query?: QueryRef<DossiersQuery, any>
  screenerTypeIconMap: any = {
    'I': { icons: [['fa-chart-line']], title: 'Monitoraggio ' }
  }
  screenerSubTypeIconMap: any = {
    'S': { icons: [], title: "soggetti" },
    'I': { icons: [], title: "immobili" }
  }
  confirmMove: boolean = false
  showNewDossierWindow: boolean = false
  showRenameDossierWindow: boolean = false
  toBeRenamed: string = ''
  folderName: string = ''
  moveData: { from: string | number, to: string, title: string, description: string, fromType: string } = { from: '', to: '', title: 'Spostamento elementi', description: 'Vuoi davvero procedere?', fromType: '' }


  showRootDocuments = new Subject<boolean>()
  selectedOrder = new Subject<any>()
  minTo: string = ''
  maxFrom: string = ''
  selectedStartDate = new Subject<any>()
  selectedEndDate = new Subject<any>()
  availableOrderModes = [{
    text: 'Data decrescente',
    value: { dossier: { createdAt: 'desc' }, document: { createdAt: 'desc' } }
  }, {
    text: 'Data crescente',
    value: { dossier: { createdAt: 'asc' }, document: { createdAt: 'asc' } }
  }, {
    text: 'Nome crescente',
    value: { dossier: { title: 'asc' }, document: { name: 'asc' } }
  }, {
    text: 'Nome decrescente',
    value: { dossier: { title: 'desc' }, document: { name: 'desc' } }
  }]

  constructor(
    private getDossiers: DossiersGQL,
    public route: ActivatedRoute,
    private router: Router,
    private documentService: DocumentService,
    private deleteDoc: DeleteDocGQL,
    private updateFolder: SetFolderParentDossierGQL,
    private updateDocument: SetDocumentParentDossierGQL,
    private addDossier: AddDossierGQL,
    private renameDossier: RenameDossierGQL,
    private deleteDossier: DeleteDossierGQL,
  ) {
    combineLatest([
      this.selectedOrder,
      this.showRootDocuments,
      this.selectedStartDate,
      this.selectedEndDate,
      this.route.paramMap]).subscribe(a => {
        this.parent = a[4].get('parent')
        this.query = this.getDossiers.watch({
          parentId: this.parent ? { _eq: this.parent } : { _is_null: true },
          allowOrphanedDocs: !this.parent && a[1],
          from: a[2]?.text || undefined,
          to: a[3]?.text || undefined,
          order_by_doc: a[0]?.document || undefined,
          order_by_dossier: a[0]?.dossier || undefined,
        })
        this.data$ = this.query.valueChanges.pipe(map(d => d.data))
        //this.query.refetch()
      })
    this.selectedStartDate.subscribe(x => {
      this.minTo = x?.text || ''
    })
    this.selectedEndDate.subscribe(x => {
      this.maxFrom = x?.text || ''
    })
    this.selectedOrder.next(this.availableOrderModes[0])
    this.showRootDocuments.next(false)
    this.selectedEndDate.next(null)
    this.selectedStartDate.next(null)
  }

  ngOnInit(): void {
  }

  open(file: Partial<Document>) {
    this.documentService.open(file.id || 0).pipe(
      map(res => {
        // create anchor, populate link, click and download
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = file.name?.split("/").pop() || '';
        link.click();
      }),
    ).subscribe()
  }

  remove(file: Partial<Document>) {
    this.deleteDoc.mutate({ id: file.id }).subscribe(() => {
      this.data$ = this.query?.valueChanges.pipe(map(d => d.data))
      this.query?.refetch()
    })
  }

  onDragEnd(ev: any) {
    console.log("DRAG END")
    ev.target.classList.remove("sepia")
    ev.preventDefault()
  }
  onDragStart(item: string | number, ev: any, type: string = 'FOLDER') {
    ev.currentTarget?.classList?.add("sepia");
    // Clear the drag data cache (for all formats/types)
    ev.dataTransfer.clearData();
    this.moveData.fromType = type
    this.moveData.from = item
    this.moveData.to = ''
    // Set the drag's format and data.
    // Use the event target's id for the data
    ev.dataTransfer.setData("text/plain", item);
  }

  onDrop(item: string, ev: any) {
    ev.currentTarget?.classList?.remove("animate-wiggle");
    ev.preventDefault();
    const data = ev.dataTransfer.getData("text");
    this.moveData.to = item
    console.log("DROP", data, "OVER", item)
    this.confirmMove = true
  }
  onDragLeave(ev: any) {
    console.log("DRAG LEAVE", ev.dataTransfer.getData("text"), ev.currentTarget?.classList)
    ev.currentTarget?.classList?.remove("animate-wiggle");
    ev.dataTransfer.clearData();
  }
  onDragEnter(item: any, ev: any) {
    if (item !== ev.dataTransfer.getData("text")) {
      console.log("DRAG ENTER", ev.dataTransfer.getData("text"), ev.currentTarget?.classList)
      //   ev.currentTarget?.classList?.add("animate-wiggle");
    }
  }
  onDragOver(item: string, ev: any) {
    if (item !== ev.dataTransfer.getData("text")) {
      ev.currentTarget?.classList?.add("animate-wiggle");
      ev.preventDefault()
    }
  }

  doMove() {
    this.confirmMove = false
    if (this.moveData.fromType == 'FOLDER') {
      this.updateFolder.mutate({
        id: this.moveData.from,
        parentId: this.moveData.to
      }).subscribe(() => {
        this.data$ = this.query?.valueChanges.pipe(map(d => d.data))
        this.query?.refetch()
      })
    }
    if (this.moveData.fromType == 'DOC' && !!this.moveData.to) {
      this.updateDocument.mutate({
        id: +this.moveData.from,
        dossierId: this.moveData.to
      }).subscribe(() => {
        this.data$ = this.query?.valueChanges.pipe(map(d => d.data))
        this.query?.refetch()
      })
    }
  }

  createDossier() {
    this.addDossier.mutate({
      title: this.folderName,
      parentId: this.parent
    }).subscribe(x => {
      this.query?.refetch()
      this.folderName = ''
    })
    this.showNewDossierWindow = false
  }

  clearNewDossier() {
    this.showNewDossierWindow = false
    this.folderName = ''
  }

  prepareDossierRename(item: any) {
    this.toBeRenamed = item.id;
    this.folderName = item.title
    this.showRenameDossierWindow = true;
  }
  renameExistingDossier() {
    this.renameDossier.mutate({ id: this.toBeRenamed, title: this.folderName }).subscribe(x => {
      this.query?.refetch()
      this.folderName = ''
      this.toBeRenamed = ''
    })
    this.showRenameDossierWindow = false
  }

  removeFolder(id: string) {
    this.deleteDossier.mutate({ id }).subscribe(() => {
      this.query?.refetch()
    })
  }

  onSelectedOrder(ev: any) {
    console.log("SELECTED ORDER", ev)
    this.selectedOrder.next(ev)
  }

  goto(id: string) {
    this.router.navigate(['/', 'user', 'consolle-immobiliare', 'dossier', id])
  }

}
