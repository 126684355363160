import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';

import { pregiudizievoliConfig } from './ricerche.ui.config';
import { GetRichiestePerSlugGQL } from 'src/graphql/generated';

@Component({
  selector: 'app-ricerche',
  templateUrl: './ricerche.component.html',
  styleUrls: []
})

export class RicercheComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getRichiestePregiudizievoli: GetRichiestePerSlugGQL
  ) { }
  visuraSubscription?: SubscriptionModel
  entrypoints: EntryPoint[] = pregiudizievoliConfig
  shortcuts$?: Observable<{ title: string, subtitle: string, uuid: string, date: string | Date }[]>
  serviceSlug = 'pregiudizievoli-immobiliari-dettaglio'
  showPrevious: boolean = false
  showSubmissionConfirm: boolean = false

  ngOnInit(): void {

    this.shortcuts$ = this.getRichiestePregiudizievoli.watch({ _eq: this.serviceSlug }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(s => s.data.request),
      map(s => s.map(
        r => ({
          title: [r.params.nome, r.params.cognome].join(' '),
          subtitle: r.params.codice_fiscale,
          uuid: r.queryResult?.uuid,
          date: r.createdAt || ''
        })
      ))
    )


  }

  onFormRequestSubmit(res: any) {
    this.showSubmissionConfirm = true
  }



}
