import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { User, AuthService } from '@auth0/auth0-angular';
import { MyActivationsGQL } from 'src/graphql/generated';
import { routes } from '../app-routing.module';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  host: {
    class: 'h-full'
  }
})
export class UserDashboardComponent implements OnInit {

  profileJson: User | null | undefined = null;
  profileObj: string = '';
  claims: string = '';
  menuitems: Route[] = []
  constructor(
    public auth: AuthService,
    public activationService: MyActivationsGQL) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(
      (profile) => {
        this.profileJson = profile;
        this.profileObj = JSON.stringify(profile, null, 2);
        // console.log("PROFILE", profile)
      }
    );
    this.auth.idTokenClaims$.subscribe(
      claims => {
        this.claims = JSON.stringify(claims, null, 2)
        // console.log("CLAIMS", claims)
      }
    )
    this.activationService.watch().valueChanges.subscribe(waa => {
      let slugs = waa.data.workarea_activation.map(a => a.workarea.slug)
      this.menuitems = routes[0].children?.[1]?.children?.filter(
        c => c.data?.['name'] && (!c.data?.['workareaSlug'] || slugs.includes(c.data?.['workareaSlug']))
      ) || []
    })

  }

}
