import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDashboardComponent } from './user-dashboard.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { UserService } from './user.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxPaginationModule } from 'ngx-pagination';
import { PagesModule } from '../pages/pages.module';
import { BalanceComponent } from './balance/balance.component';
import { RequestsComponent } from './requests/requests.component';
import { UsageComponent } from './usage/usage.component';
import { DepositsComponent } from './deposits/deposits.component';


@NgModule({
  declarations: [
    UsageComponent,
    UserDashboardComponent,
    BalanceComponent,
    RequestsComponent,
    DepositsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    PagesModule
  ],
  exports: [UserDashboardComponent],
  providers: [UserService]
})
export class UserModule { }
