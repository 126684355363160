import { Component, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {

  profileJson: User | null | undefined = null;
  isNavBarOpen: boolean = true
  constructor(public auth: AuthService) { }
  ngOnInit(): void {
    this.auth.user$.subscribe(
      (profile) => (this.profileJson = profile),
    );
    this.auth.isAuthenticated$.subscribe(a => {
      if (!a) {
        this.auth.loginWithRedirect({

        })
      }
    })

  }

}
