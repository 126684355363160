
export enum EventType {
    REQUEST = "REQUEST",
    DOCUMENT = "DOCUMENT",
    WARNING = "WARNING",
    ERROR = "ERROR",
    NOTICE = 'NOTICE',
    EVENT = 'EVENT',
    WALLET = 'WALLET',
    HEARTBEAT = 'HEARTBEAT'
}
export interface EventModel {
    type: EventType
    id?: string | number
    status?: string
    userId?: number
    companyId?: number
    raw?: any
}
