import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-date-select',
  templateUrl: './dateselector.component.html',
  styles: [
  ]
})
export class DateSelectorComponent implements OnInit {
  @ViewChild('theInput') theInput!: ElementRef<HTMLInputElement>
  @Input() title: string = ''
  @Input() min: string = ''
  @Input() max: string = ''
  @Output() selected = new EventEmitter()
  @Input() initialValue?: string = ''
  selectedItem?: any

  constructor() { }

  ngOnInit(): void {
    this.selectedItem = this.initialValue
  }

  onChange(event: any) {
    let data = {
      text: event.target.value,
      date: event.target.valueAsDate,
      ts: event.target.valueAsNumber,
      raw: event.target
    }
    this.selected.emit(data)
    this.selectedItem = data.text
    event.target.type = 'text'
  }
  clear() {
    this.selected.emit({})
    this.selectedItem = null
  }

}
