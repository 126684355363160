<div [@inOutAnimation] class="flex justify-center items-center fixed inset-0 z-[1100] bg-black  bg-opacity-60"
    *ngIf="showPanel">
    <div
        class="flex flex-col rounded-lg shadow-md px-4 py-6 w-1/2 md:w-3/4 sm:w-4/5 xs:w-5/6 dark:bg-black bg-white border-primary border-t-2 border-b-2 text-black dark:text-white max-h-screen">
        <div class="flex items-center justify-between space-x-2 mb-4">
            <div>
                <div class="text-2xl text-secondary font-bold px-10">{{title}}</div>
            </div>
            <button (click)="onCancel.emit()"><svg
                    class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg></button>
        </div>
        <app-macroservice [items]="services || []" (onServiceSelected)="onServiceSelected($event)" [preventRoute]="true"
            [columns]="3"></app-macroservice>
    </div>

</div>