import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ROLE } from './role.enum';
import { DocumentsComponent } from './pages/vv/documents/document.component';
import { RequestformComponent } from './pages/vv/components/requestform/requestform.component';
import { ConservatoriaComponent } from './pages/vv/conservatoria/conservatoria.component';
import { MessagesComponent } from './user/messages/messages.component';
import { UserDashboardComponent } from './user/user-dashboard.component';
import { ConsultazioneCatastoComponent } from './pages/vv/catasto/consultazione.component';
import { WorkInProgressComponent } from './shared/components/work-in-progress/work-in-progress.component';
import { SecuresuiteComponent } from './pages/ss/securesuite/securesuite.component';
import { PregiudizievoliComponent } from './pages/vv/pregiudizievoli/pregiudizievoli.component';
import { WorkareaComponent } from './shared/components/workarea/workarea.component';
import { HelpdeskComponent } from './pages/helpdesk/helpdesk.component';
import { BalanceComponent } from './user/balance/balance.component';
import { ListaFormalitaComponent } from './pages/vv/conservatoria/lista-formalita/lista-formalita.component';
import { ListaPregiudizievoliComponent } from './pages/vv/pregiudizievoli/lista-pregiudizievoli/lista-pregiudizievoli.component';
import { MonitoraggioReoperaComponent } from './pages/vv/monitoraggio_reopera/monitoraggio.component';
import { RequestsComponent } from './user/requests/requests.component';
import { UsageComponent } from './user/usage/usage.component';
import { DossierComponent } from './pages/dossier/dossier/dossier.component';
import { DepositsComponent } from './user/deposits/deposits.component';
import { BilanciComponent } from './pages/vv/bilanci/bilanci.component';
import { GeoCatastoComponent } from './pages/vv/geocatasto/geocatasto.component';
import { RicercheComponent } from './pages/vv/ricerche/ricerche.component';
import { ReoperaPlusComponent } from './pages/reopera-plus/reopera-plus.component';
import { UserIsactiveGuard } from './user/user.isactive.guard';
import { ForbiddenComponent } from './forbidden/forbidden.component';

export const routes: Routes = [{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: '',
      pathMatch: 'prefix',
      redirectTo: 'user',
    },
    {
      path: 'user',
      canActivate: [AuthGuard],
      data: { expectedRole: ROLE.USER, name: 'Home' },
      children: [{
        path: '',
        canActivate: [UserIsactiveGuard],
        component: UserDashboardComponent,
      }, {
        path: 'work-in-progress',
        component: WorkInProgressComponent
      }, {
        path: 'assistenza',
        component: HelpdeskComponent
      }, {
        path: 'balance',
        component: BalanceComponent
      }, {
        path: 'deposits',
        component: DepositsComponent
      }, {
        path: 'usage',
        component: UsageComponent
      }, {
        path: 'requests',
        component: RequestsComponent
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'service/:id',
        component: RequestformComponent
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'consolle-immobiliare',
        data: {
          id: 'consolle-immobiliare',
          name: 'Consolle Immobiliare',
          workareaSlug: 'consolle-immobiliare',
          imgUrl: "/assets/console_immobiliare.png",
          icon: "fa-house-laptop",
          class: 'bg-white',
          layout: "base",
          noCard: true,
          highlightMenu: true,
        },
        children: [
          {
            path: "",
            component: WorkareaComponent
          },
          {
            path: 'plus',
            data: {
              name: "ReOpera PLUS",
              icon: "fa-list-check",
              layout: "vertical",
              id: 'plus',
              intro: "Verifiche e valutazioni su persone, imprese ed immobili",
              columns: 4
            },
            children: [
              {
                path: '',
                component: ReoperaPlusComponent,
              },
              {
                path: 'report-persone',
                data: {
                  name: "Check ReOpera",
                  layout: "service",
                  serviceSlug: "report-persone"
                },
                component: RequestformComponent
              }, {
                path: 'adeguata-verifica',
                data: {
                  name: "Verifica per antiriciclaggio",
                  layout: "service",
                  serviceSlug: "adeguata-verifica"
                },
                component: RequestformComponent
              }, {
                path: 'stima-immobiliare',
                data: {
                  name: "Stima immobili",
                  layout: "service",
                  serviceSlug: "stima-immobiliare"
                },
                component: RequestformComponent
              },
              {
                path: 'atti-immobiliari-pregiudizievoli',
                data: {
                  layout: "service",
                  serviceSlug: "pregiudizievoli-immobiliari-dettaglio",
                  name: "Atti immobiliari pregiudizievoli",
                },
                component: PregiudizievoliComponent
              },
              {
                path: 'atti-immobiliari-pregiudizievoli/:uuid',
                component: ListaPregiudizievoliComponent
              },
              {
                path: 'scheda-impresa',
                data: {
                  name: "Scheda Impresa",
                  layout: "service",
                  serviceSlug: "scheda-impresa"
                },
                component: RequestformComponent
              },
              {
                path: 'report-impresa',
                data: {
                  name: "Report Impresa",
                  layout: "service",
                  serviceSlug: "report-impresa",
                  beta: true
                },
                component: RequestformComponent
              },
              {
                path: 'report-trend-finanziario',
                data: {
                  // name: "Report Trend Finanziario",
                  layout: "service",
                  serviceSlug: "report-trend-finanziario",
                  beta: true
                },
                component: RequestformComponent
              },
              {
                path: 'report-inquilino',
                data: {
                  name: "Report Inquilino",
                  layout: "service",
                  serviceSlug: "report-inquilino"
                },
                component: RequestformComponent
              },
            ]
          },
          {
            canActivate: [UserIsactiveGuard],
            path: 'catasto',
            data: {
              name: "Catasto",
              icon: "fa-house-user",
              layout: "vertical",
              id: 'catasto',
              intro: "Ricerche, schede, elenchi, visure, planimetrie, mappe",
              columns: 4
            },
            children: [
              {
                path: '',
                component: WorkareaComponent,
              },
              {
                path: 'consultazione',
                component: ConsultazioneCatastoComponent,
                data: {
                  name: "Ricerche su Catasto",
                  layout: "service",
                  serviceSlug: "catasto-ricerche",
                  buttonText: "ENTRA"
                },
              },
              {
                path: 'planimetrie',
                data: {
                  layout: "service",
                  name: "Planimetrie Catastali",
                  serviceSlug: "planimetria"
                },
                component: RequestformComponent
              },
              {
                path: 'mappa',
                data: {
                  layout: "service",
                  name: "Mappe Catastali",
                  serviceSlug: "mappa"
                },
                component: RequestformComponent
              },
              {
                path: 'monitoraggio',
                component: MonitoraggioReoperaComponent,
                data: {
                  icon: "fa-chart-line",
                  layout: "service",
                  serviceSlug: "monitoraggio-soggetti",
                  name: "Monitoraggio",
                  buttonText: "ENTRA",
                  screenerType: "I"
                }
              },

            ]
          },
          {
            canActivate: [UserIsactiveGuard],
            path: 'mappe',
            data: {
              icon: "fa-globe",
              name: 'Geo Catasto',
              layout: "vertical",
              intro: 'Accesso alle info catastali a partire dalla mappa geografica',
            },
            component: GeoCatastoComponent
          },
          {
            canActivate: [UserIsactiveGuard],
            path: 'conservatoria',
            data: {
              icon: "fa-box-archive",
              name: "Conservatoria",
              layout: "vertical",
              intro: "Ispezioni ipotecarie, visure, elenchi formalità, dettagli note",
              columns: 4
            },
            children: [
              { path: "", component: WorkareaComponent },
              {
                path: "consultazione",
                component: ConservatoriaComponent,
                data: {
                  name: "Visura ipotecaria",
                  layout: "service",
                  serviceSlug: "visura-ipotecaria"
                }
              },
              {
                path: 'consultazione/:uuid',
                component: ListaFormalitaComponent
              },
              {
                path: 'nota',
                data: {
                  name: "Stacco nota conservatoria",
                  layout: "service",
                  serviceSlug: "conservatoria-nota"
                },
                component: RequestformComponent
              },
              {
                path: 'titolo',
                data: {
                  name: "Titolo Telematico",
                  layout: "service",
                  serviceSlug: "conservatoria-titolo"
                },
                component: RequestformComponent
              }]
          },
          {
            canActivate: [UserIsactiveGuard],
            path: 'cciaa',
            data: {
              name: "Camera di Commercio",
              icon: "fa-landmark",
              layout: "vertical",
              id: 'cciaa',
              intro: "Visure camerali, cariche e partecipazioni, bilanci",
              columns: 4
            },
            children: [
              {
                path: '',
                component: WorkareaComponent,
              },
              {
                path: 'visura-camerale-ordinaria',
                data: {
                  name: "Vis. Ordinaria",
                  layout: "service",
                  serviceSlug: "visura-camerale-ordinaria"
                },
                component: RequestformComponent
              },
              {
                path: 'visura-camerale-storica',
                data: {
                  name: "Vis. Storica",
                  layout: "service",
                  serviceSlug: "visura-camerale-storica"
                },
                component: RequestformComponent
              }, {
                path: 'cariche-partecipazioni',
                data: {
                  name: "Cariche e Partecipazioni",
                  layout: "service",
                  serviceSlug: "cariche-partecipazioni"
                },
                component: RequestformComponent
              }, {
                path: 'bilanci',
                data: {
                  name: "Bilanci",
                  layout: "service",
                  serviceSlug: "cciaa-ricerca-bilanci"
                },
                component: BilanciComponent
              },

            ]
          }, {
            canActivate: [UserIsactiveGuard],
            path: 'ricerche',
            data: {
              icon: "fa-magnifying-glass",
              name: 'Ricerche',
              layout: "vertical",
              intro: 'Non conosci il Codice Fiscale? Aiutati con una ricerca sulle nostre Banche Dati!',
            },
            component: RicercheComponent
          },
          {
            path: 'documents',
            component: DocumentsComponent,
            data: {
              name: "Archivio documenti",
              icon: 'fa-file-lines',
              layout: 'vertical',
              highlightBox: 'true',
              highlightContainerClass: 'border-primary shadow-primary rounded-3xl ',
              highlightIconClass: 'text-primary opacity-80 group-hover:opacity-100'

            }
          }, {
            canActivate: [UserIsactiveGuard],
            path: 'dossier',
            children: [{
              path: '',
              component: DossierComponent
            }, {
              path: ':parent',
              component: DossierComponent
            }],
            // data: {
            //   name: "Pratiche",
            //   icon: 'fa-folder-open',
            //   layout: 'vertical',
            //   highlightBox: 'true'
            // }
          },
        ]
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'securesuite',
        component: SecuresuiteComponent,
        data: {
          name: "Secure Suite",
          workareaSlug: 'secure-suite',
          layout: "base",
          imgUrl: "/assets/gestionale_antiriciclaggio.png",
          icon: 'fa-vault',
          class: 'bg-white',
          noCard: true,
          highlightMenu: true
        }
      },
      {
        path: 'rezona',
        redirectTo: '/user/work-in-progress',
        data: {
          name: "ReZona",
          workareaSlug: 'rezona',
          layout: "base",
          imgUrl: "/assets/rezona.png",
          noCard: true,
          highlightMenu: true
        }
      },
      {
        canActivate: [UserIsactiveGuard],
        path: 'assicurare',
        data: {
          name: "Assicura.Re",
          workareaSlug: 'assicurare',
          icon: "fa-shield",
          layout: "base",
          imgUrl: "/assets/assicurare.png",
          noCard: true,
          highlightMenu: true
        },
        children: [
          {
            path: "",
            component: WorkareaComponent
          },
          {
            path: 'globale-abitazione',
            data: {
              icon: "fa-house-chimney-crack",
              name: "Globale Assicurazione",
              layout: "service",
              serviceSlug: "globale-abitazione"
            },
            component: RequestformComponent
          },
          {
            path: 'l210',
            data: {
              icon: "fa-building-shield",
              name: "Globale Assicurazione",
              layout: "service",
              serviceSlug: "l210-garanzia-fidejussoria-per-diritti-patrimoniali-dellacquirente"
            },
            component: RequestformComponent
          },
          {
            path: 'permute',
            data: {
              icon: "fa-tree-city",
              name: "Globale Assicurazione",
              layout: "service",
              serviceSlug: "permute-immobiliari-garanzie-tra-privati"
            },
            component: RequestformComponent
          },
          {
            path: 'locazione',
            data: {
              icon: "fa-house-circle-exclamation",
              name: "Globale Assicurazione",
              layout: "service",
              serviceSlug: "locazione-e-garanzia-fidejussoria"
            },
            component: RequestformComponent
          },
          {
            path: 'rc-professionale',
            data: {
              icon: "fa-user-shield",
              name: "Globale Assicurazione",
              layout: "service",
              serviceSlug: "rc-professionale-agenti-immobiliari"
            },
            component: RequestformComponent
          },
        ]
      },
      {
        path: 'messages',
        component: MessagesComponent
      },
      { path: 'pending', component: ForbiddenComponent },
      ]
    },
  ]
},
{ path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
