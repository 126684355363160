<app-navigazione-container [entrypoints]="entrypoints" (onFormRequestSubmit)="onFormRequestSubmit($event)">
    <div class="-mt-10 rounded-xl border-t-4 border-primary flex flex-col mb-4 min-h-40 shadow hover:shadow-xl"
        *ngIf="showPrevious">
        <div class="text-xl font-bold text-center p-2">
            Richiama precedenti ricerche
        </div>
        <div *ngIf="shortcuts$ | async as shortcuts; else empty" class="p-3 flex flex-col">
            <a *ngFor="let shortcut of shortcuts" class="hover:bg-primary/5 p-2 rounded "
                [routerLink]="[shortcut.uuid]">
                <div class="flex justify-between">
                    <span class="font-bold">{{shortcut.title}}</span>
                    <span>{{shortcut.date | date:'dd.MM.yyyy HH:mm'}}</span>
                </div>
            </a>

        </div>
        <ng-template #empty>
            <div class="text-center p-2">Spiacenti, non ci sono ricerche effettuate</div>
        </ng-template>
    </div>
</app-navigazione-container>
<app-submission-confirm [showPanel]="showSubmissionConfirm" (onStay)="showSubmissionConfirm=false">
</app-submission-confirm>