<div class="mx-auto max-w-md p-6 text-lg font-bold text-center w-full border border-secondary rounded-md shadow-xl">
    <div class="text-primary mb-6 text-2xl">
        Attenzione!
    </div>
    <div class="mb-6">
        La tua utenza è attualmente sospesa, puoi visualizzare i
        documenti generati ma non avrai accesso ai servizi.</div>
    <div>Contatta l'<a [routerLink]="['/','user','assistenza']"
            class="text-primary hover:text-secondary transition-colors">assistenza</a> per riattivare l'account!</div>
</div>
<app-documents></app-documents>