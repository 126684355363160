import { Component } from '@angular/core';
import { SharedService } from '../../shared.service';

@Component({
  selector: 'app-admin-feature',
  templateUrl: './admin-feature.component.html',
})
export class AdminFeatureComponent {
  constructor(public sharedService: SharedService) { }
}
