<div class="h-full bg-white transition-all px-3 py-2 gap-2 w-full relative">
    <button (click)="onDismiss.emit()" class="z-10 absolute top-1 right-1">
        <svg class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
        </svg>
    </button>

    <div>
        <div class="text-xl">Fabbricati</div>
        <app-navigazione-results *ngIf="fabbricati" [currentNavigation]="0" [items]="fabbricati"
            [navigation]="navigation" [listLayout]="true" [nextButtonTitle]="navigation.name || ''"
            [compact]="true"></app-navigazione-results>
    </div>
</div>
<app-submission-confirm [showPanel]="showSubmissionConfirm" (onStay)="showSubmissionConfirm=false">
</app-submission-confirm>