<table class="min-w-full leading-normal" *ngIf="list?.length">
    <thead>
        <tr>
            <th scope="col"
                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                Data evento
            </th>
            <th scope="col"
                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                Dettaglio evento
            </th>

            <th scope="col"
                class="px-4 py-3 bg-white  border-b border-gray-200 text-primary text-left text-sm uppercase font-normal">

            </th>
        </tr>
    </thead>
    <tbody class="">
        <tr *ngFor="let item of list" class="bg-white hover:bg-gray-100 even:bg-gray-50">
            <td class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                <span>{{item.createdAt | date: "dd.MM.y"}}</span>
            </td>
            <td class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                <span *ngFor="let dettaglio of item.dettaglio">
                    {{dettaglio.descrizione_evento}}
                </span>
            </td>
            <td class="px-4 py-2 border-b border-gray-200 text-sm">

                <div class="flex items-center">
                    <div class="flex-shrink flex flex-wrap">
                        <!--
                        <button
                            class="mx-2 my-1 px-2 py-1 rounded border-black border flex items-center text-black bg-white hover:text-white hover:bg-black transition-colors">
                            bla</button> -->
                        <button (click)="markRead(item)" [disabled]="item.userAck"
                            class="disabled:hover:opacity-30 disabled:cursor-not-allowed disabled:opacity-50 mx-2 my-1 px-2 py-1 rounded  border-primary border text-primary bg-white hover:text-white hover:bg-primary transition-colors">
                            Segna come letto</button>

                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>