import { Injectable } from '@angular/core';
import { tap, Observable, map, shareReplay, of, switchMap } from 'rxjs';
import { MyActivationsGQL, MyActivationsQuery, MySubscriptionsGQL, MySubscriptionsQuery } from 'src/graphql/generated';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private mySubscriptions: MySubscriptionsGQL,
    private myActivations: MyActivationsGQL) {
    this.subscriptions$ = this.mySubscriptions.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(x => x.data),
      shareReplay(1),
    )
    this.availableServices$ = this.subscriptions$.pipe(
      map(d => d.subscription.map(s => s.service?.slug)
      ))
    this.activations$ = this.myActivations.watch({}, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(x => x.data),
      shareReplay(1)
    )
  }
  public subscriptions$: Observable<MySubscriptionsQuery>
  public availableServices$: Observable<string[]>
  public activations$: Observable<MyActivationsQuery>

  getByServiceSlug(slug: string): Observable<any> {
    return this.subscriptions$.pipe(
      switchMap((s) => of(s.subscription.find((p) => p.service?.slug == slug)))
    )
  }
}
