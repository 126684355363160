import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SSOResponseModel } from '../models/sso-response.model';

@Injectable({
  providedIn: 'root'
})
export class SSOService {
  static URL = '/api/v1/sso/';

  constructor(public http: HttpClient) { }

  login(platform: string) {
    return this.http.post<SSOResponseModel>(SSOService.URL + platform, {});
  }



}
