<div class="flex justify-center items-center fixed inset-0 z-[1100] bg-black  bg-opacity-60" *ngIf="config.showPanel">
    <div
        class="flex flex-col rounded-lg shadow-md px-4 py-6 w-1/3 md:w-1/2 sm:w-4/5 xs:w-5/6 dark:bg-black bg-white border-primary border-t-2 border-b-2 text-black dark:text-white max-h-screen">
        <div class="flex items-center justify-between space-x-2 mb-4">
            <div>
                <div class="text-xl">{{config.data?.title}}</div>
            </div>
            <button (click)="onCancel()"><svg class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg></button>
        </div>
        <div *ngIf="config.data?.description; else content"
            class="flex flex-col flex-grow pb-2 mb-2 text-sm sm:space-x-12 justify-between overflow-y-auto max-h-[400px] "
            [innerHTML]="config.data?.description">
        </div>
        <ng-template #content>
            <div class="overflow-y-auto max-h-fit w-full flex flex-col">
                <ng-content></ng-content>
            </div>
        </ng-template>
        <div class="text-sm w-full font-semibold flex justify-between items-center transition-opacity duration-300">
            <button type="button" (click)="onCancel()"
                class="py-2 px-4 border-primary border hover:bg-primary hover:text-white text-primary hover:bg-white-700 focus:ring-light-100 focus:ring-offset-light-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring focus:ring-offset-2  rounded-lg ">
                Annulla</button>
            <div *ngIf="config.data?.price || config.data?.showZeroPrice"
                class="text-xl rounded-full text-yellow-600  bg-yellow-200 flex px-4 py-2">
                {{config.priceLabel}}: {{config.data?.price || 0}}€
                {{config.data?.priceOnFail? '('+config.data?.priceOnFail+'€ in caso di irreperibilità del dato)': ''}}
            </div>
            <button type="button" (click)="onConfirm()" [disabled]="config.disabled"
                class=" py-2 px-4 bg-primary disabled:opacity-30 hover:bg-white text-white hover:text-primary focus:ring-light-100 focus:ring-offset-light-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring focus:ring-offset-2  rounded-lg ">
                {{config.buttonLabel}}</button>
        </div>
    </div>
</div>