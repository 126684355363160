import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PaginatedModel } from 'src/app/models/dto/PaginatedModel';
import { DocumentModel } from '../../../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  static URL = '/api/v1/document';

  constructor(public http: HttpClient) { }

  open(id: number, inline: boolean = true) {
    return this.http.get(`${DocumentService.URL}/${id}/open`, {
      params: { inline },
      responseType: 'blob'
    })
  }
}
