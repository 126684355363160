import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LatLng } from 'leaflet';
import { tap, map, catchError, of, delay, filter, switchMap, forkJoin, combineLatest, Observable } from 'rxjs';
import { DatiCatastali } from 'src/app/models/catasto.model';
import { NavigationActionsConfig, NavigationDetailsConfig } from 'src/app/models/navigation.shared.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { MySubscriptionsQuery } from 'src/graphql/generated';
import { QueriesService } from '../../services/queries.service';
import { RequestService } from '../../services/request.service';
import { SubscriptionService } from '../../services/subscription.service';
import { fabbricatiConfig } from '../mappe.config';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-lower-panel',
  templateUrl: './lower-panel.component.html',
})
export class LowerPanelComponent implements OnInit, OnChanges {

  constructor(
    private queriesService: QueriesService,
    private requestService: RequestService,
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService) {
    this.subscriptionService.subscriptions$.subscribe((s: MySubscriptionsQuery) => this.availableServices = s.subscription)
  }
  navigation = fabbricatiConfig
  confirmActionData?: ActionComponentData
  showSubmissionConfirm: boolean = false
  // selectedPointData?: { coords: any, data: any, catastoData: any }

  @Input() selectedCatastoInfo?: DatiCatastali
  @Output() onDismiss = new EventEmitter()
  availableServices: any[] = []
  fabbricati?: any[]
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedCatastoInfo']) {
      if (changes['selectedCatastoInfo'].currentValue) {
        let info = changes['selectedCatastoInfo'].currentValue
        this.queriesService.post({
          channel: 'catasto:immobili',
          data: {
            ...info,
            tipo_catasto: 'FABBRICATI'
          }
        }).subscribe((y) => {
          this.fabbricati = y.items
        })
      }
    }
  }


  populateActions(actions: NavigationActionsConfig[]): NavigationActionsConfig[] {
    return actions.map(
      a => ({ ...a, subscription: this.availableServices.find(s => s.service?.slug == a.slug) })
    ).filter(a => a.subscription || a.type == 'query')
      .map(a => ({ ...a, isFree: !a.subscription || !a.subscription.price }))
  }

  populateDetails(details: NavigationDetailsConfig[], item: any) {
    let populated = details.map(d => ({ ...d, value: "" }))
    populated.forEach(p => p.value = Array.isArray(p.field) ? p.field.map(f => item[f] || '').join(p.separator || " ") : item[p.field])
    return populated
  }

}
