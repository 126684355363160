import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { bounds, latLng, MapOptions, Proj, tileLayer, Map, LeafletMouseEvent, Layer, latLngBounds, LatLngBounds, LatLng, marker, icon, Icon } from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { delay, map, of } from 'rxjs';
@Component({
  selector: 'app-mappa',
  templateUrl: './mappa.component.html',
  styles: [`
    :host{
        height: 100%;
        position: relative;
    }`]
})
export class MappaComponent implements OnInit, OnChanges {

  constructor() { }
  @Output() mapClick = new EventEmitter()
  @Input() markers: { latlng: LatLng, popupText: string, isPopupOpen: boolean }[] = []
  mapMarkers: Layer[] = []
  CRS_6706 = new Proj.CRS(
    "EPSG:6706",
    "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs",
    {
      resolutions: new Array(22).map((i, idx) => ((18.99 - 5.93) / 1024) / Math.pow(2, idx)),
      origin: [0, 0],
      bounds: bounds([5.93, 34.76], [18.99, 47.1])
    }
  );
  layers = {
    "Confini Particelle": tileLayer.wms('https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php', {
      layers: ["CP.CadastralZoning", "CP.CadastralParcel", "fabbricati", "strade", "acque", "vestizioni"].join(','),
      transparent: true,
      format: 'image/png',
      styles: 'default',
      zIndex: 100,
      minZoom: 13,
      maxZoom: 16,
      attribution: '© <a href="https://creativecommons.org/licenses/by-nc-nd/2.0/it/">Agenzia delle Entrate</a>',
      tileSize: 1024,
      crs: this.CRS_6706
    }),
    "Aree Particelle": tileLayer.wms('https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php', {
      layers: ["CP.CadastralZoning", "CP.CadastralParcel", "strade", "acque", "vestizioni"].join(','),
      transparent: true,
      format: 'image/png',
      styles: 'default',
      zIndex: 100,
      opacity: 0.4,
      minZoom: 16,
      maxZoom: 21,
      attribution: '© <a href="https://creativecommons.org/licenses/by-nc-nd/2.0/it/">Agenzia delle Entrate</a>',
      tileSize: 1024,
      crs: this.CRS_6706
    }),
    "Fabbricati": tileLayer.wms('https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php', {
      layers: ["fabbricati"].join(','),
      transparent: true,
      format: 'image/png',
      styles: 'default',
      zIndex: 100,
      opacity: 1,
      minZoom: 16,
      maxZoom: 21,
      attribution: '© <a href="https://creativecommons.org/licenses/by-nc-nd/2.0/it/">Agenzia delle Entrate</a>',
      tileSize: 1024,
      crs: this.CRS_6706
    })
  }
  CRS_25832 = new Proj.CRS("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs")
  options: MapOptions = {
    zoom: 6,
    minZoom: 4,
    maxZoom: 21,
    center: latLng(43.0, 12.0),
    layers: Object.values(this.layers)
  }
  tiles = {
    "Classic": tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }),
    "Bright": tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }),
    "Civici": tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }),
    "Topografica": tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    }),
    "Satellite": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    })
  }
  userPosition: any = {}
  _mapRef?: Map

  ngOnInit(): void {
    if (!navigator.geolocation) {
      console.log("Your browser doesn't support geolocation feature!");
    } else {
      this.getPosition()
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['markers'].currentValue) {
      if (changes['markers'].currentValue.length) {
        let m = changes['markers'].currentValue[0]
        this.mapMarkers = changes['markers'].currentValue.map((m: any) => {
          let mark = marker(m.latlng, {
            icon: icon({
              ...Icon.Default.prototype.options,
              iconUrl: 'assets/marker-icon.png',
              iconRetinaUrl: 'assets/marker-icon-2x.png',
              shadowUrl: 'assets/marker-shadow.png'
            })
          })
          if (m.popupText) {
            mark.bindPopup(m.popupText)
            // mark.bindPopup(layer => {
            //   const popupEl: NgElement & WithProperties<PopupComponent> = document.createElement('popup-element') as any;
            //   popupEl.selectedCoord = m.selectedPos;
            //   popupEl.selectedCatastoInfo = m.selectedCatastoInfo;
            //   return popupEl;
            // });
            if (m.isPopupOpen) {
              of(true).pipe(
                delay(1000)
              ).subscribe(() => {
                mark.openPopup()
              })
            }
          }

          return mark
        })
        of(true).pipe(
          delay(1000)
        ).subscribe(() => {
          this._mapRef?.invalidateSize()
          this._mapRef?.flyTo(m.latlng, 20, { animate: true, duration: 2 })
        })
      }
    }
  }
  onMapReady(map: Map) {
    this._mapRef = map
    const provider = new OpenStreetMapProvider({
      params: {
        "accept-language": 'it', // render results in Dutch
        countrycodes: 'it', // limit search results to the Netherlands
        addressdetails: 1, // include additional address detail parts
      }
    });
    const searchControl = GeoSearchControl({ provider: provider, style: "bar" });

    map.addControl(searchControl);
    map.on('resize', (value) => {
      map.invalidateSize();
    })
    map.invalidateSize()
  }
  getPosition() {
    navigator.geolocation.getCurrentPosition((position) => {
      let { latitude, longitude, accuracy } = position.coords;
      this.userPosition = { latitude, longitude, accuracy }
      this._mapRef?.flyTo(latLng(latitude, longitude), 16, { animate: true, duration: 2 }).invalidateSize()

    })
  }
  onClick($event: LeafletMouseEvent) {
    this.mapClick.emit($event)
  }
  refreshMap() {
    this._mapRef?.invalidateSize()
  }

}
