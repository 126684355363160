import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { SharedService } from '../../shared.service';

export class ActionComponentData {
  subscriptionId?: number
  title?: string
  description?: string
  price?: number
  priceOnFail?: number
  showZeroPrice?: boolean
  type?: string
  channel?: string
  params?: { userParam?: string, requestParam: string, value?: string }[]
  extra?: { params: any, notes: any }
  submissionButtons?: any
}

export class ActionPopupData {
  showPanel!: boolean
  data?: ActionComponentData | undefined
  priceLabel?: string
  buttonLabel?: string
  disabled?: boolean
  ev?: EventEmitter<any>
}

@Component({
  selector: 'global-actionpopup',
  templateUrl: './actionpopup.component.html',
  styles: [
  ]
})
export class GlobalActionpopupComponent implements OnInit {
  @Output()
  confirm$?= new EventEmitter();

  config: ActionPopupData = {
    showPanel: false,
    priceLabel: 'Costo',
    buttonLabel: "RICHIEDI",
    disabled: false
  }
  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.actionPopupDataObservable$.subscribe((data) => {
      this.confirm$ = data.ev
      this.config = data
    })
  }
  onCancel() {
    this.sharedService.hideActionPopup()
  }
  onConfirm() {
    this.confirm$?.emit()
    this.sharedService.hideActionPopup()
  }
}
