import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  request_status_enum: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "alert" */
export type Alert = {
  __typename?: 'alert';
  activeFrom?: Maybe<Scalars['timestamp']>;
  activeTo?: Maybe<Scalars['timestamp']>;
  createdAt: Scalars['timestamp'];
  description: Scalars['String'];
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  /** An object relationship */
  service: Service;
  serviceId: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "alert" */
export type Alert_Aggregate = {
  __typename?: 'alert_aggregate';
  aggregate?: Maybe<Alert_Aggregate_Fields>;
  nodes: Array<Alert>;
};

export type Alert_Aggregate_Bool_Exp = {
  count?: InputMaybe<Alert_Aggregate_Bool_Exp_Count>;
};

export type Alert_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Alert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "alert" */
export type Alert_Aggregate_Fields = {
  __typename?: 'alert_aggregate_fields';
  avg?: Maybe<Alert_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Alert_Max_Fields>;
  min?: Maybe<Alert_Min_Fields>;
  stddev?: Maybe<Alert_Stddev_Fields>;
  stddev_pop?: Maybe<Alert_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Alert_Stddev_Samp_Fields>;
  sum?: Maybe<Alert_Sum_Fields>;
  var_pop?: Maybe<Alert_Var_Pop_Fields>;
  var_samp?: Maybe<Alert_Var_Samp_Fields>;
  variance?: Maybe<Alert_Variance_Fields>;
};


/** aggregate fields of "alert" */
export type Alert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alert" */
export type Alert_Aggregate_Order_By = {
  avg?: InputMaybe<Alert_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Alert_Max_Order_By>;
  min?: InputMaybe<Alert_Min_Order_By>;
  stddev?: InputMaybe<Alert_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Alert_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Alert_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Alert_Sum_Order_By>;
  var_pop?: InputMaybe<Alert_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Alert_Var_Samp_Order_By>;
  variance?: InputMaybe<Alert_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "alert" */
export type Alert_Arr_Rel_Insert_Input = {
  data: Array<Alert_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};

/** aggregate avg on columns */
export type Alert_Avg_Fields = {
  __typename?: 'alert_avg_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "alert" */
export type Alert_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "alert". All fields are combined with a logical 'AND'. */
export type Alert_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Bool_Exp>>;
  _not?: InputMaybe<Alert_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Bool_Exp>>;
  activeFrom?: InputMaybe<Timestamp_Comparison_Exp>;
  activeTo?: InputMaybe<Timestamp_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert" */
export enum Alert_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkAd91cad659a3536465d564a4b2f = 'PK_ad91cad659a3536465d564a4b2f'
}

/** input type for incrementing numeric columns in table "alert" */
export type Alert_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "alert" */
export type Alert_Insert_Input = {
  activeFrom?: InputMaybe<Scalars['timestamp']>;
  activeTo?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alert_Max_Fields = {
  __typename?: 'alert_max_fields';
  activeFrom?: Maybe<Scalars['timestamp']>;
  activeTo?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  serviceId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "alert" */
export type Alert_Max_Order_By = {
  activeFrom?: InputMaybe<Order_By>;
  activeTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Alert_Min_Fields = {
  __typename?: 'alert_min_fields';
  activeFrom?: Maybe<Scalars['timestamp']>;
  activeTo?: Maybe<Scalars['timestamp']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  serviceId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "alert" */
export type Alert_Min_Order_By = {
  activeFrom?: InputMaybe<Order_By>;
  activeTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "alert" */
export type Alert_Mutation_Response = {
  __typename?: 'alert_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert>;
};

/** on_conflict condition type for table "alert" */
export type Alert_On_Conflict = {
  constraint: Alert_Constraint;
  update_columns?: Array<Alert_Update_Column>;
  where?: InputMaybe<Alert_Bool_Exp>;
};

/** Ordering options when selecting data from "alert". */
export type Alert_Order_By = {
  activeFrom?: InputMaybe<Order_By>;
  activeTo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert */
export type Alert_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "alert" */
export enum Alert_Select_Column {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  ActiveTo = 'activeTo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "alert" */
export type Alert_Set_Input = {
  activeFrom?: InputMaybe<Scalars['timestamp']>;
  activeTo?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Alert_Stddev_Fields = {
  __typename?: 'alert_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "alert" */
export type Alert_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Alert_Stddev_Pop_Fields = {
  __typename?: 'alert_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "alert" */
export type Alert_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Alert_Stddev_Samp_Fields = {
  __typename?: 'alert_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "alert" */
export type Alert_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "alert" */
export type Alert_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Stream_Cursor_Value_Input = {
  activeFrom?: InputMaybe<Scalars['timestamp']>;
  activeTo?: InputMaybe<Scalars['timestamp']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Alert_Sum_Fields = {
  __typename?: 'alert_sum_fields';
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "alert" */
export type Alert_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** update columns of table "alert" */
export enum Alert_Update_Column {
  /** column name */
  ActiveFrom = 'activeFrom',
  /** column name */
  ActiveTo = 'activeTo',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export type Alert_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Alert_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Alert_Var_Pop_Fields = {
  __typename?: 'alert_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "alert" */
export type Alert_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Alert_Var_Samp_Fields = {
  __typename?: 'alert_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "alert" */
export type Alert_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Alert_Variance_Fields = {
  __typename?: 'alert_variance_fields';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "alert" */
export type Alert_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** columns and relationships of "attachments" */
export type Attachments = {
  __typename?: 'attachments';
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  description: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['Int'];
  lastModified: Scalars['timestamptz'];
  reportId?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  storedFilename: Scalars['String'];
  url: Scalars['String'];
};

/** aggregated selection of "attachments" */
export type Attachments_Aggregate = {
  __typename?: 'attachments_aggregate';
  aggregate?: Maybe<Attachments_Aggregate_Fields>;
  nodes: Array<Attachments>;
};

export type Attachments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Attachments_Aggregate_Bool_Exp_Count>;
};

export type Attachments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Attachments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "attachments" */
export type Attachments_Aggregate_Fields = {
  __typename?: 'attachments_aggregate_fields';
  avg?: Maybe<Attachments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachments_Max_Fields>;
  min?: Maybe<Attachments_Min_Fields>;
  stddev?: Maybe<Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Attachments_Sum_Fields>;
  var_pop?: Maybe<Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Attachments_Var_Samp_Fields>;
  variance?: Maybe<Attachments_Variance_Fields>;
};


/** aggregate fields of "attachments" */
export type Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "attachments" */
export type Attachments_Aggregate_Order_By = {
  avg?: InputMaybe<Attachments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Attachments_Max_Order_By>;
  min?: InputMaybe<Attachments_Min_Order_By>;
  stddev?: InputMaybe<Attachments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Attachments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Attachments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Attachments_Sum_Order_By>;
  var_pop?: InputMaybe<Attachments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Attachments_Var_Samp_Order_By>;
  variance?: InputMaybe<Attachments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachments" */
export type Attachments_Arr_Rel_Insert_Input = {
  data: Array<Attachments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};

/** aggregate avg on columns */
export type Attachments_Avg_Fields = {
  __typename?: 'attachments_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "attachments" */
export type Attachments_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachments". All fields are combined with a logical 'AND'. */
export type Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Attachments_Bool_Exp>>;
  _not?: InputMaybe<Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Attachments_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  filename?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamptz_Comparison_Exp>;
  reportId?: InputMaybe<Int_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  storedFilename?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachments" */
export enum Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  AttachmentsPkey = 'attachments_pkey'
}

/** input type for incrementing numeric columns in table "attachments" */
export type Attachments_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  reportId?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "attachments" */
export type Attachments_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamptz']>;
  reportId?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  storedFilename?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Attachments_Max_Fields = {
  __typename?: 'attachments_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamptz']>;
  reportId?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  storedFilename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "attachments" */
export type Attachments_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  storedFilename?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attachments_Min_Fields = {
  __typename?: 'attachments_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamptz']>;
  reportId?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  storedFilename?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "attachments" */
export type Attachments_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  storedFilename?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "attachments" */
export type Attachments_Mutation_Response = {
  __typename?: 'attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachments>;
};

/** on_conflict condition type for table "attachments" */
export type Attachments_On_Conflict = {
  constraint: Attachments_Constraint;
  update_columns?: Array<Attachments_Update_Column>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "attachments". */
export type Attachments_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  filename?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  storedFilename?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: attachments */
export type Attachments_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "attachments" */
export enum Attachments_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  ReportId = 'reportId',
  /** column name */
  Size = 'size',
  /** column name */
  StoredFilename = 'storedFilename',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "attachments" */
export type Attachments_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamptz']>;
  reportId?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  storedFilename?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Attachments_Stddev_Fields = {
  __typename?: 'attachments_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "attachments" */
export type Attachments_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attachments_Stddev_Pop_Fields = {
  __typename?: 'attachments_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "attachments" */
export type Attachments_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attachments_Stddev_Samp_Fields = {
  __typename?: 'attachments_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "attachments" */
export type Attachments_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "attachments" */
export type Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachments_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamptz']>;
  reportId?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
  storedFilename?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Attachments_Sum_Fields = {
  __typename?: 'attachments_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  reportId?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "attachments" */
export type Attachments_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** update columns of table "attachments" */
export enum Attachments_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  ReportId = 'reportId',
  /** column name */
  Size = 'size',
  /** column name */
  StoredFilename = 'storedFilename',
  /** column name */
  Url = 'url'
}

export type Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachments_Var_Pop_Fields = {
  __typename?: 'attachments_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "attachments" */
export type Attachments_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attachments_Var_Samp_Fields = {
  __typename?: 'attachments_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "attachments" */
export type Attachments_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Attachments_Variance_Fields = {
  __typename?: 'attachments_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reportId?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "attachments" */
export type Attachments_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reportId?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "company" */
export type Company = {
  __typename?: 'company';
  active: Scalars['Boolean'];
  /** An array relationship */
  attachments: Array<Attachments>;
  /** An aggregate relationship */
  attachments_aggregate: Attachments_Aggregate;
  clientType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamp']>;
  hqAddress?: Maybe<Scalars['String']>;
  hqCounty?: Maybe<Scalars['String']>;
  hqTown?: Maybe<Scalars['String']>;
  hqZip?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  parent?: Maybe<Company>;
  parentId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  reports: Array<Report>;
  /** An aggregate relationship */
  reports_aggregate: Report_Aggregate;
  /** An array relationship */
  requests: Array<Request>;
  /** An aggregate relationship */
  requests_aggregate: Request_Aggregate;
  /** An array relationship */
  screeners: Array<Screeners>;
  /** An aggregate relationship */
  screeners_aggregate: Screeners_Aggregate;
  /** An array relationship */
  subcompanies: Array<Company>;
  /** An aggregate relationship */
  subcompanies_aggregate: Company_Aggregate;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  taxCode: Scalars['String'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
  /** An object relationship */
  wallet?: Maybe<Wallet>;
  walletId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  workarea_activations: Array<Workarea_Activation>;
  /** An aggregate relationship */
  workarea_activations_aggregate: Workarea_Activation_Aggregate;
};


/** columns and relationships of "company" */
export type CompanyAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyReportsArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyRequestsArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyScreenersArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyScreeners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySubcompaniesArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySubcompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanySubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyWorkarea_ActivationsArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


/** columns and relationships of "company" */
export type CompanyWorkarea_Activations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};

/** aggregated selection of "company" */
export type Company_Aggregate = {
  __typename?: 'company_aggregate';
  aggregate?: Maybe<Company_Aggregate_Fields>;
  nodes: Array<Company>;
};

export type Company_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Company_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Company_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Company_Aggregate_Bool_Exp_Count>;
};

export type Company_Aggregate_Bool_Exp_Bool_And = {
  arguments: Company_Select_Column_Company_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Company_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Company_Select_Column_Company_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Company_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Company_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "company" */
export type Company_Aggregate_Fields = {
  __typename?: 'company_aggregate_fields';
  avg?: Maybe<Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Max_Fields>;
  min?: Maybe<Company_Min_Fields>;
  stddev?: Maybe<Company_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Sum_Fields>;
  var_pop?: Maybe<Company_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Var_Samp_Fields>;
  variance?: Maybe<Company_Variance_Fields>;
};


/** aggregate fields of "company" */
export type Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company" */
export type Company_Aggregate_Order_By = {
  avg?: InputMaybe<Company_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Company_Max_Order_By>;
  min?: InputMaybe<Company_Min_Order_By>;
  stddev?: InputMaybe<Company_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Company_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Company_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Company_Sum_Order_By>;
  var_pop?: InputMaybe<Company_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Company_Var_Samp_Order_By>;
  variance?: InputMaybe<Company_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "company" */
export type Company_Arr_Rel_Insert_Input = {
  data: Array<Company_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** aggregate avg on columns */
export type Company_Avg_Fields = {
  __typename?: 'company_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "company" */
export type Company_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** columns and relationships of "company_balance" */
export type Company_Balance = {
  __typename?: 'company_balance';
  accountBalance?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  /** An object relationship */
  company?: Maybe<Company>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "company_balance" */
export type Company_Balance_Aggregate = {
  __typename?: 'company_balance_aggregate';
  aggregate?: Maybe<Company_Balance_Aggregate_Fields>;
  nodes: Array<Company_Balance>;
};

/** aggregate fields of "company_balance" */
export type Company_Balance_Aggregate_Fields = {
  __typename?: 'company_balance_aggregate_fields';
  avg?: Maybe<Company_Balance_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Company_Balance_Max_Fields>;
  min?: Maybe<Company_Balance_Min_Fields>;
  stddev?: Maybe<Company_Balance_Stddev_Fields>;
  stddev_pop?: Maybe<Company_Balance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Company_Balance_Stddev_Samp_Fields>;
  sum?: Maybe<Company_Balance_Sum_Fields>;
  var_pop?: Maybe<Company_Balance_Var_Pop_Fields>;
  var_samp?: Maybe<Company_Balance_Var_Samp_Fields>;
  variance?: Maybe<Company_Balance_Variance_Fields>;
};


/** aggregate fields of "company_balance" */
export type Company_Balance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Company_Balance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Company_Balance_Avg_Fields = {
  __typename?: 'company_balance_avg_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company_balance". All fields are combined with a logical 'AND'. */
export type Company_Balance_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Balance_Bool_Exp>>;
  _not?: InputMaybe<Company_Balance_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Balance_Bool_Exp>>;
  accountBalance?: InputMaybe<Float8_Comparison_Exp>;
  balance?: InputMaybe<Float8_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Company_Balance_Max_Fields = {
  __typename?: 'company_balance_max_fields';
  accountBalance?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Company_Balance_Min_Fields = {
  __typename?: 'company_balance_min_fields';
  accountBalance?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "company_balance". */
export type Company_Balance_Order_By = {
  accountBalance?: InputMaybe<Order_By>;
  balance?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "company_balance" */
export enum Company_Balance_Select_Column {
  /** column name */
  AccountBalance = 'accountBalance',
  /** column name */
  Balance = 'balance',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type Company_Balance_Stddev_Fields = {
  __typename?: 'company_balance_stddev_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Company_Balance_Stddev_Pop_Fields = {
  __typename?: 'company_balance_stddev_pop_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Company_Balance_Stddev_Samp_Fields = {
  __typename?: 'company_balance_stddev_samp_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "company_balance" */
export type Company_Balance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Balance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Balance_Stream_Cursor_Value_Input = {
  accountBalance?: InputMaybe<Scalars['float8']>;
  balance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Balance_Sum_Fields = {
  __typename?: 'company_balance_sum_fields';
  accountBalance?: Maybe<Scalars['float8']>;
  balance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Company_Balance_Var_Pop_Fields = {
  __typename?: 'company_balance_var_pop_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Company_Balance_Var_Samp_Fields = {
  __typename?: 'company_balance_var_samp_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Company_Balance_Variance_Fields = {
  __typename?: 'company_balance_variance_fields';
  accountBalance?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "company". All fields are combined with a logical 'AND'. */
export type Company_Bool_Exp = {
  _and?: InputMaybe<Array<Company_Bool_Exp>>;
  _not?: InputMaybe<Company_Bool_Exp>;
  _or?: InputMaybe<Array<Company_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  attachments?: InputMaybe<Attachments_Bool_Exp>;
  attachments_aggregate?: InputMaybe<Attachments_Aggregate_Bool_Exp>;
  clientType?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expiration?: InputMaybe<Timestamp_Comparison_Exp>;
  hqAddress?: InputMaybe<String_Comparison_Exp>;
  hqCounty?: InputMaybe<String_Comparison_Exp>;
  hqTown?: InputMaybe<String_Comparison_Exp>;
  hqZip?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Company_Bool_Exp>;
  parentId?: InputMaybe<Int_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  reports?: InputMaybe<Report_Bool_Exp>;
  reports_aggregate?: InputMaybe<Report_Aggregate_Bool_Exp>;
  requests?: InputMaybe<Request_Bool_Exp>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Bool_Exp>;
  screeners?: InputMaybe<Screeners_Bool_Exp>;
  screeners_aggregate?: InputMaybe<Screeners_Aggregate_Bool_Exp>;
  subcompanies?: InputMaybe<Company_Bool_Exp>;
  subcompanies_aggregate?: InputMaybe<Company_Aggregate_Bool_Exp>;
  subscriptions?: InputMaybe<Subscription_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Bool_Exp>;
  taxCode?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
  wallet?: InputMaybe<Wallet_Bool_Exp>;
  walletId?: InputMaybe<Int_Comparison_Exp>;
  workarea_activations?: InputMaybe<Workarea_Activation_Bool_Exp>;
  workarea_activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "company" */
export enum Company_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_056f7854a7afdba7cbd6d45fc20 = 'PK_056f7854a7afdba7cbd6d45fc20'
}

/** input type for incrementing numeric columns in table "company" */
export type Company_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "company" */
export type Company_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  attachments?: InputMaybe<Attachments_Arr_Rel_Insert_Input>;
  clientType?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamp']>;
  hqAddress?: InputMaybe<Scalars['String']>;
  hqCounty?: InputMaybe<Scalars['String']>;
  hqTown?: InputMaybe<Scalars['String']>;
  hqZip?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  reports?: InputMaybe<Report_Arr_Rel_Insert_Input>;
  requests?: InputMaybe<Request_Arr_Rel_Insert_Input>;
  screeners?: InputMaybe<Screeners_Arr_Rel_Insert_Input>;
  subcompanies?: InputMaybe<Company_Arr_Rel_Insert_Input>;
  subscriptions?: InputMaybe<Subscription_Arr_Rel_Insert_Input>;
  taxCode?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
  wallet?: InputMaybe<Wallet_Obj_Rel_Insert_Input>;
  walletId?: InputMaybe<Scalars['Int']>;
  workarea_activations?: InputMaybe<Workarea_Activation_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Company_Max_Fields = {
  __typename?: 'company_max_fields';
  clientType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamp']>;
  hqAddress?: Maybe<Scalars['String']>;
  hqCounty?: Maybe<Scalars['String']>;
  hqTown?: Maybe<Scalars['String']>;
  hqZip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "company" */
export type Company_Max_Order_By = {
  clientType?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  hqAddress?: InputMaybe<Order_By>;
  hqCounty?: InputMaybe<Order_By>;
  hqTown?: InputMaybe<Order_By>;
  hqZip?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  taxCode?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Min_Fields = {
  __typename?: 'company_min_fields';
  clientType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['timestamp']>;
  hqAddress?: Maybe<Scalars['String']>;
  hqCounty?: Maybe<Scalars['String']>;
  hqTown?: Maybe<Scalars['String']>;
  hqZip?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "company" */
export type Company_Min_Order_By = {
  clientType?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  hqAddress?: InputMaybe<Order_By>;
  hqCounty?: InputMaybe<Order_By>;
  hqTown?: InputMaybe<Order_By>;
  hqZip?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  taxCode?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "company" */
export type Company_Mutation_Response = {
  __typename?: 'company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Company>;
};

/** input type for inserting object relation for remote table "company" */
export type Company_Obj_Rel_Insert_Input = {
  data: Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Company_On_Conflict>;
};

/** on_conflict condition type for table "company" */
export type Company_On_Conflict = {
  constraint: Company_Constraint;
  update_columns?: Array<Company_Update_Column>;
  where?: InputMaybe<Company_Bool_Exp>;
};

/** Ordering options when selecting data from "company". */
export type Company_Order_By = {
  active?: InputMaybe<Order_By>;
  attachments_aggregate?: InputMaybe<Attachments_Aggregate_Order_By>;
  clientType?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiration?: InputMaybe<Order_By>;
  hqAddress?: InputMaybe<Order_By>;
  hqCounty?: InputMaybe<Order_By>;
  hqTown?: InputMaybe<Order_By>;
  hqZip?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Company_Order_By>;
  parentId?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  reports_aggregate?: InputMaybe<Report_Aggregate_Order_By>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Order_By>;
  screeners_aggregate?: InputMaybe<Screeners_Aggregate_Order_By>;
  subcompanies_aggregate?: InputMaybe<Company_Aggregate_Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Order_By>;
  taxCode?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
  wallet?: InputMaybe<Wallet_Order_By>;
  walletId?: InputMaybe<Order_By>;
  workarea_activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Order_By>;
};

/** primary key columns input for table: company */
export type Company_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "company" */
export enum Company_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClientType = 'clientType',
  /** column name */
  Email = 'email',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  HqAddress = 'hqAddress',
  /** column name */
  HqCounty = 'hqCounty',
  /** column name */
  HqTown = 'hqTown',
  /** column name */
  HqZip = 'hqZip',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Phone = 'phone',
  /** column name */
  TaxCode = 'taxCode',
  /** column name */
  WalletId = 'walletId'
}

/** select "company_aggregate_bool_exp_bool_and_arguments_columns" columns of table "company" */
export enum Company_Select_Column_Company_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "company_aggregate_bool_exp_bool_or_arguments_columns" columns of table "company" */
export enum Company_Select_Column_Company_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "company" */
export type Company_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  clientType?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamp']>;
  hqAddress?: InputMaybe<Scalars['String']>;
  hqCounty?: InputMaybe<Scalars['String']>;
  hqTown?: InputMaybe<Scalars['String']>;
  hqZip?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Company_Stddev_Fields = {
  __typename?: 'company_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "company" */
export type Company_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Company_Stddev_Pop_Fields = {
  __typename?: 'company_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "company" */
export type Company_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Company_Stddev_Samp_Fields = {
  __typename?: 'company_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "company" */
export type Company_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "company" */
export type Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Company_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  clientType?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['timestamp']>;
  hqAddress?: InputMaybe<Scalars['String']>;
  hqCounty?: InputMaybe<Scalars['String']>;
  hqTown?: InputMaybe<Scalars['String']>;
  hqZip?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  taxCode?: InputMaybe<Scalars['String']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Company_Sum_Fields = {
  __typename?: 'company_sum_fields';
  id?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "company" */
export type Company_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** update columns of table "company" */
export enum Company_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ClientType = 'clientType',
  /** column name */
  Email = 'email',
  /** column name */
  Expiration = 'expiration',
  /** column name */
  HqAddress = 'hqAddress',
  /** column name */
  HqCounty = 'hqCounty',
  /** column name */
  HqTown = 'hqTown',
  /** column name */
  HqZip = 'hqZip',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Phone = 'phone',
  /** column name */
  TaxCode = 'taxCode',
  /** column name */
  WalletId = 'walletId'
}

export type Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Company_Var_Pop_Fields = {
  __typename?: 'company_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "company" */
export type Company_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Company_Var_Samp_Fields = {
  __typename?: 'company_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "company" */
export type Company_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Company_Variance_Fields = {
  __typename?: 'company_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "company" */
export type Company_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "document" */
export type Document = {
  __typename?: 'document';
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamp'];
  /** An object relationship */
  dossier?: Maybe<Dossier>;
  dossierId?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  lastOpened?: Maybe<Scalars['timestamp']>;
  name: Scalars['String'];
  /** An object relationship */
  request?: Maybe<Request>;
  requestId?: Maybe<Scalars['Int']>;
  userId: Scalars['Int'];
};

/** aggregated selection of "document" */
export type Document_Aggregate = {
  __typename?: 'document_aggregate';
  aggregate?: Maybe<Document_Aggregate_Fields>;
  nodes: Array<Document>;
};

export type Document_Aggregate_Bool_Exp = {
  count?: InputMaybe<Document_Aggregate_Bool_Exp_Count>;
};

export type Document_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Document_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "document" */
export type Document_Aggregate_Fields = {
  __typename?: 'document_aggregate_fields';
  avg?: Maybe<Document_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Document_Max_Fields>;
  min?: Maybe<Document_Min_Fields>;
  stddev?: Maybe<Document_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Sum_Fields>;
  var_pop?: Maybe<Document_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Var_Samp_Fields>;
  variance?: Maybe<Document_Variance_Fields>;
};


/** aggregate fields of "document" */
export type Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document" */
export type Document_Aggregate_Order_By = {
  avg?: InputMaybe<Document_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
  stddev?: InputMaybe<Document_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "document" */
export type Document_Arr_Rel_Insert_Input = {
  data: Array<Document_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Document_On_Conflict>;
};

/** aggregate avg on columns */
export type Document_Avg_Fields = {
  __typename?: 'document_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "document" */
export type Document_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  _and?: InputMaybe<Array<Document_Bool_Exp>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<Document_Bool_Exp>>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  dossier?: InputMaybe<Dossier_Bool_Exp>;
  dossierId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastOpened?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  request?: InputMaybe<Request_Bool_Exp>;
  requestId?: InputMaybe<Int_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "document" */
export enum Document_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkE57d3357f83f3cdc0acffc3d777 = 'PK_e57d3357f83f3cdc0acffc3d777'
}

/** input type for incrementing numeric columns in table "document" */
export type Document_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  requestId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "document" */
export type Document_Insert_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dossier?: InputMaybe<Dossier_Obj_Rel_Insert_Input>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  lastOpened?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Request_Obj_Rel_Insert_Input>;
  requestId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Document_Max_Fields = {
  __typename?: 'document_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dossierId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  lastOpened?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "document" */
export type Document_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dossierId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastOpened?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Min_Fields = {
  __typename?: 'document_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dossierId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  lastOpened?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "document" */
export type Document_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dossierId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastOpened?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "document" */
export type Document_Mutation_Response = {
  __typename?: 'document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** on_conflict condition type for table "document" */
export type Document_On_Conflict = {
  constraint: Document_Constraint;
  update_columns?: Array<Document_Update_Column>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** Ordering options when selecting data from "document". */
export type Document_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dossier?: InputMaybe<Dossier_Order_By>;
  dossierId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastOpened?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  request?: InputMaybe<Request_Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: document */
export type Document_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "document" */
export enum Document_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DossierId = 'dossierId',
  /** column name */
  Id = 'id',
  /** column name */
  LastOpened = 'lastOpened',
  /** column name */
  Name = 'name',
  /** column name */
  RequestId = 'requestId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "document" */
export type Document_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  lastOpened?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  requestId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Document_Stddev_Fields = {
  __typename?: 'document_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "document" */
export type Document_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Document_Stddev_Pop_Fields = {
  __typename?: 'document_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "document" */
export type Document_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Document_Stddev_Samp_Fields = {
  __typename?: 'document_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "document" */
export type Document_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "document" */
export type Document_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Document_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Document_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  lastOpened?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  requestId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Document_Sum_Fields = {
  __typename?: 'document_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "document" */
export type Document_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "document" */
export enum Document_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DossierId = 'dossierId',
  /** column name */
  Id = 'id',
  /** column name */
  LastOpened = 'lastOpened',
  /** column name */
  Name = 'name',
  /** column name */
  RequestId = 'requestId',
  /** column name */
  UserId = 'userId'
}

export type Document_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Document_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Document_Set_Input>;
  /** filter the rows which have to be updated */
  where: Document_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Document_Var_Pop_Fields = {
  __typename?: 'document_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "document" */
export type Document_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Document_Var_Samp_Fields = {
  __typename?: 'document_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "document" */
export type Document_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Document_Variance_Fields = {
  __typename?: 'document_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "document" */
export type Document_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "dossier" */
export type Dossier = {
  __typename?: 'dossier';
  /** An array relationship */
  children: Array<Dossier>;
  /** An aggregate relationship */
  children_aggregate: Dossier_Aggregate;
  createdAt: Scalars['timestamp'];
  createdBy: Scalars['Int'];
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documents_aggregate: Document_Aggregate;
  id: Scalars['uuid'];
  /** An object relationship */
  parent?: Maybe<Dossier>;
  parentId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  screener?: Maybe<Screeners>;
  title: Scalars['String'];
};


/** columns and relationships of "dossier" */
export type DossierChildrenArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


/** columns and relationships of "dossier" */
export type DossierChildren_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


/** columns and relationships of "dossier" */
export type DossierDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "dossier" */
export type DossierDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};

/** aggregated selection of "dossier" */
export type Dossier_Aggregate = {
  __typename?: 'dossier_aggregate';
  aggregate?: Maybe<Dossier_Aggregate_Fields>;
  nodes: Array<Dossier>;
};

export type Dossier_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dossier_Aggregate_Bool_Exp_Count>;
};

export type Dossier_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dossier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dossier_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dossier" */
export type Dossier_Aggregate_Fields = {
  __typename?: 'dossier_aggregate_fields';
  avg?: Maybe<Dossier_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dossier_Max_Fields>;
  min?: Maybe<Dossier_Min_Fields>;
  stddev?: Maybe<Dossier_Stddev_Fields>;
  stddev_pop?: Maybe<Dossier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dossier_Stddev_Samp_Fields>;
  sum?: Maybe<Dossier_Sum_Fields>;
  var_pop?: Maybe<Dossier_Var_Pop_Fields>;
  var_samp?: Maybe<Dossier_Var_Samp_Fields>;
  variance?: Maybe<Dossier_Variance_Fields>;
};


/** aggregate fields of "dossier" */
export type Dossier_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dossier_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dossier" */
export type Dossier_Aggregate_Order_By = {
  avg?: InputMaybe<Dossier_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dossier_Max_Order_By>;
  min?: InputMaybe<Dossier_Min_Order_By>;
  stddev?: InputMaybe<Dossier_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dossier_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dossier_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dossier_Sum_Order_By>;
  var_pop?: InputMaybe<Dossier_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dossier_Var_Samp_Order_By>;
  variance?: InputMaybe<Dossier_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dossier" */
export type Dossier_Arr_Rel_Insert_Input = {
  data: Array<Dossier_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dossier_On_Conflict>;
};

/** aggregate avg on columns */
export type Dossier_Avg_Fields = {
  __typename?: 'dossier_avg_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dossier" */
export type Dossier_Avg_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dossier". All fields are combined with a logical 'AND'. */
export type Dossier_Bool_Exp = {
  _and?: InputMaybe<Array<Dossier_Bool_Exp>>;
  _not?: InputMaybe<Dossier_Bool_Exp>;
  _or?: InputMaybe<Array<Dossier_Bool_Exp>>;
  children?: InputMaybe<Dossier_Bool_Exp>;
  children_aggregate?: InputMaybe<Dossier_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  createdBy?: InputMaybe<Int_Comparison_Exp>;
  documents?: InputMaybe<Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  parent?: InputMaybe<Dossier_Bool_Exp>;
  parentId?: InputMaybe<Uuid_Comparison_Exp>;
  screener?: InputMaybe<Screeners_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "dossier" */
export enum Dossier_Constraint {
  /** unique or primary key constraint on columns "id" */
  DossierPkey = 'dossier_pkey'
}

/** input type for incrementing numeric columns in table "dossier" */
export type Dossier_Inc_Input = {
  createdBy?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dossier" */
export type Dossier_Insert_Input = {
  children?: InputMaybe<Dossier_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  documents?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  parent?: InputMaybe<Dossier_Obj_Rel_Insert_Input>;
  parentId?: InputMaybe<Scalars['uuid']>;
  screener?: InputMaybe<Screeners_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dossier_Max_Fields = {
  __typename?: 'dossier_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  parentId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "dossier" */
export type Dossier_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dossier_Min_Fields = {
  __typename?: 'dossier_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  createdBy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  parentId?: Maybe<Scalars['uuid']>;
  title?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "dossier" */
export type Dossier_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  parentId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dossier" */
export type Dossier_Mutation_Response = {
  __typename?: 'dossier_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dossier>;
};

/** input type for inserting object relation for remote table "dossier" */
export type Dossier_Obj_Rel_Insert_Input = {
  data: Dossier_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dossier_On_Conflict>;
};

/** on_conflict condition type for table "dossier" */
export type Dossier_On_Conflict = {
  constraint: Dossier_Constraint;
  update_columns?: Array<Dossier_Update_Column>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};

/** Ordering options when selecting data from "dossier". */
export type Dossier_Order_By = {
  children_aggregate?: InputMaybe<Dossier_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  parent?: InputMaybe<Dossier_Order_By>;
  parentId?: InputMaybe<Order_By>;
  screener?: InputMaybe<Screeners_Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dossier */
export type Dossier_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dossier" */
export enum Dossier_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "dossier" */
export type Dossier_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dossier_Stddev_Fields = {
  __typename?: 'dossier_stddev_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dossier" */
export type Dossier_Stddev_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dossier_Stddev_Pop_Fields = {
  __typename?: 'dossier_stddev_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dossier" */
export type Dossier_Stddev_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dossier_Stddev_Samp_Fields = {
  __typename?: 'dossier_stddev_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dossier" */
export type Dossier_Stddev_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dossier" */
export type Dossier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dossier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dossier_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Dossier_Sum_Fields = {
  __typename?: 'dossier_sum_fields';
  createdBy?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dossier" */
export type Dossier_Sum_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** update columns of table "dossier" */
export enum Dossier_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  Id = 'id',
  /** column name */
  ParentId = 'parentId',
  /** column name */
  Title = 'title'
}

export type Dossier_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dossier_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dossier_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dossier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dossier_Var_Pop_Fields = {
  __typename?: 'dossier_var_pop_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dossier" */
export type Dossier_Var_Pop_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dossier_Var_Samp_Fields = {
  __typename?: 'dossier_var_samp_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dossier" */
export type Dossier_Var_Samp_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dossier_Variance_Fields = {
  __typename?: 'dossier_variance_fields';
  createdBy?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dossier" */
export type Dossier_Variance_Order_By = {
  createdBy?: InputMaybe<Order_By>;
};

/** columns and relationships of "extended_request" */
export type Extended_Request = {
  __typename?: 'extended_request';
  airflowData?: Maybe<Scalars['json']>;
  archived?: Maybe<Scalars['Boolean']>;
  codiceComune?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  coupon?: Maybe<Subscription_Coupon>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documents_aggregate: Document_Aggregate;
  errorDescription?: Maybe<Scalars['String']>;
  foglio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  nome?: Maybe<Scalars['String']>;
  nomeComune?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['json']>;
  params?: Maybe<Scalars['jsonb']>;
  particella?: Maybe<Scalars['String']>;
  providerData?: Maybe<Scalars['json']>;
  queryResult?: Maybe<Scalars['json']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['request_status_enum']>;
  subalterno?: Maybe<Scalars['String']>;
  /** An object relationship */
  subscription?: Maybe<Subscription>;
  subscriptionId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  transactions: Array<Ledger>;
  /** An aggregate relationship */
  transactions_aggregate: Ledger_Aggregate;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestAirflowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestNotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestProviderDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestQueryResultArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "extended_request" */
export type Extended_RequestTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};

/** aggregated selection of "extended_request" */
export type Extended_Request_Aggregate = {
  __typename?: 'extended_request_aggregate';
  aggregate?: Maybe<Extended_Request_Aggregate_Fields>;
  nodes: Array<Extended_Request>;
};

/** aggregate fields of "extended_request" */
export type Extended_Request_Aggregate_Fields = {
  __typename?: 'extended_request_aggregate_fields';
  avg?: Maybe<Extended_Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Extended_Request_Max_Fields>;
  min?: Maybe<Extended_Request_Min_Fields>;
  stddev?: Maybe<Extended_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Extended_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Extended_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Extended_Request_Sum_Fields>;
  var_pop?: Maybe<Extended_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Extended_Request_Var_Samp_Fields>;
  variance?: Maybe<Extended_Request_Variance_Fields>;
};


/** aggregate fields of "extended_request" */
export type Extended_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Extended_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Extended_Request_Append_Input = {
  params?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Extended_Request_Avg_Fields = {
  __typename?: 'extended_request_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "extended_request". All fields are combined with a logical 'AND'. */
export type Extended_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Extended_Request_Bool_Exp>>;
  _not?: InputMaybe<Extended_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Extended_Request_Bool_Exp>>;
  airflowData?: InputMaybe<Json_Comparison_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  codiceComune?: InputMaybe<String_Comparison_Exp>;
  codiceFiscale?: InputMaybe<String_Comparison_Exp>;
  cognome?: InputMaybe<String_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  coupon?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  couponId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  documents?: InputMaybe<Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  errorDescription?: InputMaybe<String_Comparison_Exp>;
  foglio?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  nome?: InputMaybe<String_Comparison_Exp>;
  nomeComune?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Json_Comparison_Exp>;
  params?: InputMaybe<Jsonb_Comparison_Exp>;
  particella?: InputMaybe<String_Comparison_Exp>;
  providerData?: InputMaybe<Json_Comparison_Exp>;
  queryResult?: InputMaybe<Json_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Request_Status_Enum_Comparison_Exp>;
  subalterno?: InputMaybe<String_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  transactions?: InputMaybe<Ledger_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Extended_Request_Delete_At_Path_Input = {
  params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Extended_Request_Delete_Elem_Input = {
  params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Extended_Request_Delete_Key_Input = {
  params?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "extended_request" */
export type Extended_Request_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "extended_request" */
export type Extended_Request_Insert_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  codiceComune?: InputMaybe<Scalars['String']>;
  codiceFiscale?: InputMaybe<Scalars['String']>;
  cognome?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  coupon?: InputMaybe<Subscription_Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  documents?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  errorDescription?: InputMaybe<Scalars['String']>;
  foglio?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  nome?: InputMaybe<Scalars['String']>;
  nomeComune?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  particella?: InputMaybe<Scalars['String']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subalterno?: InputMaybe<Scalars['String']>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  transactions?: InputMaybe<Ledger_Arr_Rel_Insert_Input>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Extended_Request_Max_Fields = {
  __typename?: 'extended_request_max_fields';
  codiceComune?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  errorDescription?: Maybe<Scalars['String']>;
  foglio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  nome?: Maybe<Scalars['String']>;
  nomeComune?: Maybe<Scalars['String']>;
  particella?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['request_status_enum']>;
  subalterno?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Extended_Request_Min_Fields = {
  __typename?: 'extended_request_min_fields';
  codiceComune?: Maybe<Scalars['String']>;
  codiceFiscale?: Maybe<Scalars['String']>;
  cognome?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  errorDescription?: Maybe<Scalars['String']>;
  foglio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  nome?: Maybe<Scalars['String']>;
  nomeComune?: Maybe<Scalars['String']>;
  particella?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['request_status_enum']>;
  subalterno?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "extended_request" */
export type Extended_Request_Mutation_Response = {
  __typename?: 'extended_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Extended_Request>;
};

/** Ordering options when selecting data from "extended_request". */
export type Extended_Request_Order_By = {
  airflowData?: InputMaybe<Order_By>;
  archived?: InputMaybe<Order_By>;
  codiceComune?: InputMaybe<Order_By>;
  codiceFiscale?: InputMaybe<Order_By>;
  cognome?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Subscription_Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  errorDescription?: InputMaybe<Order_By>;
  foglio?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  nome?: InputMaybe<Order_By>;
  nomeComune?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  params?: InputMaybe<Order_By>;
  particella?: InputMaybe<Order_By>;
  providerData?: InputMaybe<Order_By>;
  queryResult?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subalterno?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Extended_Request_Prepend_Input = {
  params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "extended_request" */
export enum Extended_Request_Select_Column {
  /** column name */
  AirflowData = 'airflowData',
  /** column name */
  Archived = 'archived',
  /** column name */
  CodiceComune = 'codiceComune',
  /** column name */
  CodiceFiscale = 'codiceFiscale',
  /** column name */
  Cognome = 'cognome',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorDescription = 'errorDescription',
  /** column name */
  Foglio = 'foglio',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Nome = 'nome',
  /** column name */
  NomeComune = 'nomeComune',
  /** column name */
  Notes = 'notes',
  /** column name */
  Params = 'params',
  /** column name */
  Particella = 'particella',
  /** column name */
  ProviderData = 'providerData',
  /** column name */
  QueryResult = 'queryResult',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Status = 'status',
  /** column name */
  Subalterno = 'subalterno',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "extended_request" */
export type Extended_Request_Set_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  codiceComune?: InputMaybe<Scalars['String']>;
  codiceFiscale?: InputMaybe<Scalars['String']>;
  cognome?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  errorDescription?: InputMaybe<Scalars['String']>;
  foglio?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  nome?: InputMaybe<Scalars['String']>;
  nomeComune?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  particella?: InputMaybe<Scalars['String']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subalterno?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Extended_Request_Stddev_Fields = {
  __typename?: 'extended_request_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Extended_Request_Stddev_Pop_Fields = {
  __typename?: 'extended_request_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Extended_Request_Stddev_Samp_Fields = {
  __typename?: 'extended_request_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "extended_request" */
export type Extended_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Extended_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Extended_Request_Stream_Cursor_Value_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  codiceComune?: InputMaybe<Scalars['String']>;
  codiceFiscale?: InputMaybe<Scalars['String']>;
  cognome?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  errorDescription?: InputMaybe<Scalars['String']>;
  foglio?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  nome?: InputMaybe<Scalars['String']>;
  nomeComune?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  particella?: InputMaybe<Scalars['String']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subalterno?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Extended_Request_Sum_Fields = {
  __typename?: 'extended_request_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export type Extended_Request_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Extended_Request_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Extended_Request_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Extended_Request_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Extended_Request_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Extended_Request_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Extended_Request_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Extended_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Extended_Request_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Extended_Request_Var_Pop_Fields = {
  __typename?: 'extended_request_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Extended_Request_Var_Samp_Fields = {
  __typename?: 'extended_request_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Extended_Request_Variance_Fields = {
  __typename?: 'extended_request_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "ledger" */
export type Ledger = {
  __typename?: 'ledger';
  amount: Scalars['float8'];
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamp'];
  description: Scalars['String'];
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  operatorId?: Maybe<Scalars['Int']>;
  pending: Scalars['Boolean'];
  /** An object relationship */
  request?: Maybe<Request>;
  requestId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  subscription?: Maybe<Subscription>;
  subscriptionId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  wallet: Wallet;
  walletId: Scalars['Int'];
};

/** aggregated selection of "ledger" */
export type Ledger_Aggregate = {
  __typename?: 'ledger_aggregate';
  aggregate?: Maybe<Ledger_Aggregate_Fields>;
  nodes: Array<Ledger>;
};

export type Ledger_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Ledger_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Ledger_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ledger_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Ledger_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Ledger_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Ledger_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Ledger_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Ledger_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Ledger_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Ledger_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Ledger_Aggregate_Bool_Exp_Var_Samp>;
};

export type Ledger_Aggregate_Bool_Exp_Avg = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Corr = {
  arguments: Ledger_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Ledger_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ledger_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Ledger_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Ledger_Aggregate_Bool_Exp_Max = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Min = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Sum = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ledger_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ledger_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "ledger" */
export type Ledger_Aggregate_Fields = {
  __typename?: 'ledger_aggregate_fields';
  avg?: Maybe<Ledger_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ledger_Max_Fields>;
  min?: Maybe<Ledger_Min_Fields>;
  stddev?: Maybe<Ledger_Stddev_Fields>;
  stddev_pop?: Maybe<Ledger_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ledger_Stddev_Samp_Fields>;
  sum?: Maybe<Ledger_Sum_Fields>;
  var_pop?: Maybe<Ledger_Var_Pop_Fields>;
  var_samp?: Maybe<Ledger_Var_Samp_Fields>;
  variance?: Maybe<Ledger_Variance_Fields>;
};


/** aggregate fields of "ledger" */
export type Ledger_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ledger_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ledger" */
export type Ledger_Aggregate_Order_By = {
  avg?: InputMaybe<Ledger_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ledger_Max_Order_By>;
  min?: InputMaybe<Ledger_Min_Order_By>;
  stddev?: InputMaybe<Ledger_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ledger_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ledger_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ledger_Sum_Order_By>;
  var_pop?: InputMaybe<Ledger_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ledger_Var_Samp_Order_By>;
  variance?: InputMaybe<Ledger_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ledger" */
export type Ledger_Arr_Rel_Insert_Input = {
  data: Array<Ledger_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ledger_On_Conflict>;
};

/** aggregate avg on columns */
export type Ledger_Avg_Fields = {
  __typename?: 'ledger_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ledger" */
export type Ledger_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ledger". All fields are combined with a logical 'AND'. */
export type Ledger_Bool_Exp = {
  _and?: InputMaybe<Array<Ledger_Bool_Exp>>;
  _not?: InputMaybe<Ledger_Bool_Exp>;
  _or?: InputMaybe<Array<Ledger_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  operatorId?: InputMaybe<Int_Comparison_Exp>;
  pending?: InputMaybe<Boolean_Comparison_Exp>;
  request?: InputMaybe<Request_Bool_Exp>;
  requestId?: InputMaybe<Int_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  wallet?: InputMaybe<Wallet_Bool_Exp>;
  walletId?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "ledger_by_company_and_day" */
export type Ledger_By_Company_And_Day = {
  __typename?: 'ledger_by_company_and_day';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  total?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "ledger_by_company_and_day" */
export type Ledger_By_Company_And_Day_Aggregate = {
  __typename?: 'ledger_by_company_and_day_aggregate';
  aggregate?: Maybe<Ledger_By_Company_And_Day_Aggregate_Fields>;
  nodes: Array<Ledger_By_Company_And_Day>;
};

/** aggregate fields of "ledger_by_company_and_day" */
export type Ledger_By_Company_And_Day_Aggregate_Fields = {
  __typename?: 'ledger_by_company_and_day_aggregate_fields';
  avg?: Maybe<Ledger_By_Company_And_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ledger_By_Company_And_Day_Max_Fields>;
  min?: Maybe<Ledger_By_Company_And_Day_Min_Fields>;
  stddev?: Maybe<Ledger_By_Company_And_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Ledger_By_Company_And_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ledger_By_Company_And_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Ledger_By_Company_And_Day_Sum_Fields>;
  var_pop?: Maybe<Ledger_By_Company_And_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Ledger_By_Company_And_Day_Var_Samp_Fields>;
  variance?: Maybe<Ledger_By_Company_And_Day_Variance_Fields>;
};


/** aggregate fields of "ledger_by_company_and_day" */
export type Ledger_By_Company_And_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ledger_By_Company_And_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ledger_By_Company_And_Day_Avg_Fields = {
  __typename?: 'ledger_by_company_and_day_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ledger_by_company_and_day". All fields are combined with a logical 'AND'. */
export type Ledger_By_Company_And_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Ledger_By_Company_And_Day_Bool_Exp>>;
  _not?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Ledger_By_Company_And_Day_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  total?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ledger_By_Company_And_Day_Max_Fields = {
  __typename?: 'ledger_by_company_and_day_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  total?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Ledger_By_Company_And_Day_Min_Fields = {
  __typename?: 'ledger_by_company_and_day_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  total?: Maybe<Scalars['float8']>;
};

/** Ordering options when selecting data from "ledger_by_company_and_day". */
export type Ledger_By_Company_And_Day_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
};

/** select columns of table "ledger_by_company_and_day" */
export enum Ledger_By_Company_And_Day_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  Total = 'total'
}

/** aggregate stddev on columns */
export type Ledger_By_Company_And_Day_Stddev_Fields = {
  __typename?: 'ledger_by_company_and_day_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ledger_By_Company_And_Day_Stddev_Pop_Fields = {
  __typename?: 'ledger_by_company_and_day_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ledger_By_Company_And_Day_Stddev_Samp_Fields = {
  __typename?: 'ledger_by_company_and_day_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ledger_by_company_and_day" */
export type Ledger_By_Company_And_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ledger_By_Company_And_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ledger_By_Company_And_Day_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['timestamp']>;
  total?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Ledger_By_Company_And_Day_Sum_Fields = {
  __typename?: 'ledger_by_company_and_day_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  total?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Ledger_By_Company_And_Day_Var_Pop_Fields = {
  __typename?: 'ledger_by_company_and_day_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ledger_By_Company_And_Day_Var_Samp_Fields = {
  __typename?: 'ledger_by_company_and_day_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ledger_By_Company_And_Day_Variance_Fields = {
  __typename?: 'ledger_by_company_and_day_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "ledger" */
export enum Ledger_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_7a322e9157e5f42a16750ba2a20 = 'PK_7a322e9157e5f42a16750ba2a20'
}

/** input type for incrementing numeric columns in table "ledger" */
export type Ledger_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  operatorId?: InputMaybe<Scalars['Int']>;
  requestId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ledger" */
export type Ledger_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  operatorId?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  request?: InputMaybe<Request_Obj_Rel_Insert_Input>;
  requestId?: InputMaybe<Scalars['Int']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
  wallet?: InputMaybe<Wallet_Obj_Rel_Insert_Input>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ledger_Max_Fields = {
  __typename?: 'ledger_max_fields';
  amount?: Maybe<Scalars['float8']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  operatorId?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ledger" */
export type Ledger_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ledger_Min_Fields = {
  __typename?: 'ledger_min_fields';
  amount?: Maybe<Scalars['float8']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  operatorId?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ledger" */
export type Ledger_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ledger" */
export type Ledger_Mutation_Response = {
  __typename?: 'ledger_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ledger>;
};

/** on_conflict condition type for table "ledger" */
export type Ledger_On_Conflict = {
  constraint: Ledger_Constraint;
  update_columns?: Array<Ledger_Update_Column>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};

/** Ordering options when selecting data from "ledger". */
export type Ledger_Order_By = {
  amount?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  pending?: InputMaybe<Order_By>;
  request?: InputMaybe<Request_Order_By>;
  requestId?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Wallet_Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ledger */
export type Ledger_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ledger" */
export enum Ledger_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  OperatorId = 'operatorId',
  /** column name */
  Pending = 'pending',
  /** column name */
  RequestId = 'requestId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId',
  /** column name */
  WalletId = 'walletId'
}

/** select "ledger_aggregate_bool_exp_avg_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Pending = 'pending'
}

/** select "ledger_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Pending = 'pending'
}

/** select "ledger_aggregate_bool_exp_corr_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_max_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_min_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_sum_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** select "ledger_aggregate_bool_exp_var_samp_arguments_columns" columns of table "ledger" */
export enum Ledger_Select_Column_Ledger_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount'
}

/** input type for updating data in table "ledger" */
export type Ledger_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  operatorId?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  requestId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ledger_Stddev_Fields = {
  __typename?: 'ledger_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ledger" */
export type Ledger_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ledger_Stddev_Pop_Fields = {
  __typename?: 'ledger_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ledger" */
export type Ledger_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ledger_Stddev_Samp_Fields = {
  __typename?: 'ledger_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ledger" */
export type Ledger_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ledger" */
export type Ledger_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ledger_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ledger_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  operatorId?: InputMaybe<Scalars['Int']>;
  pending?: InputMaybe<Scalars['Boolean']>;
  requestId?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
  walletId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ledger_Sum_Fields = {
  __typename?: 'ledger_sum_fields';
  amount?: Maybe<Scalars['float8']>;
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  operatorId?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  walletId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ledger" */
export type Ledger_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** update columns of table "ledger" */
export enum Ledger_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  OperatorId = 'operatorId',
  /** column name */
  Pending = 'pending',
  /** column name */
  RequestId = 'requestId',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId',
  /** column name */
  WalletId = 'walletId'
}

export type Ledger_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ledger_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ledger_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ledger_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ledger_Var_Pop_Fields = {
  __typename?: 'ledger_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ledger" */
export type Ledger_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ledger_Var_Samp_Fields = {
  __typename?: 'ledger_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ledger" */
export type Ledger_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ledger_Variance_Fields = {
  __typename?: 'ledger_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['Float']>;
  requestId?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  walletId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ledger" */
export type Ledger_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operatorId?: InputMaybe<Order_By>;
  requestId?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  walletId?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "alert" */
  delete_alert?: Maybe<Alert_Mutation_Response>;
  /** delete single row from the table: "alert" */
  delete_alert_by_pk?: Maybe<Alert>;
  /** delete data from the table: "attachments" */
  delete_attachments?: Maybe<Attachments_Mutation_Response>;
  /** delete single row from the table: "attachments" */
  delete_attachments_by_pk?: Maybe<Attachments>;
  /** delete data from the table: "company" */
  delete_company?: Maybe<Company_Mutation_Response>;
  /** delete single row from the table: "company" */
  delete_company_by_pk?: Maybe<Company>;
  /** delete data from the table: "document" */
  delete_document?: Maybe<Document_Mutation_Response>;
  /** delete single row from the table: "document" */
  delete_document_by_pk?: Maybe<Document>;
  /** delete data from the table: "dossier" */
  delete_dossier?: Maybe<Dossier_Mutation_Response>;
  /** delete single row from the table: "dossier" */
  delete_dossier_by_pk?: Maybe<Dossier>;
  /** delete data from the table: "extended_request" */
  delete_extended_request?: Maybe<Extended_Request_Mutation_Response>;
  /** delete data from the table: "ledger" */
  delete_ledger?: Maybe<Ledger_Mutation_Response>;
  /** delete single row from the table: "ledger" */
  delete_ledger_by_pk?: Maybe<Ledger>;
  /** delete data from the table: "report" */
  delete_report?: Maybe<Report_Mutation_Response>;
  /** delete single row from the table: "report" */
  delete_report_by_pk?: Maybe<Report>;
  /** delete data from the table: "request" */
  delete_request?: Maybe<Request_Mutation_Response>;
  /** delete single row from the table: "request" */
  delete_request_by_pk?: Maybe<Request>;
  /** delete data from the table: "screener_check" */
  delete_screener_check?: Maybe<Screener_Check_Mutation_Response>;
  /** delete single row from the table: "screener_check" */
  delete_screener_check_by_pk?: Maybe<Screener_Check>;
  /** delete data from the table: "screeners" */
  delete_screeners?: Maybe<Screeners_Mutation_Response>;
  /** delete single row from the table: "screeners" */
  delete_screeners_by_pk?: Maybe<Screeners>;
  /** delete data from the table: "service" */
  delete_service?: Maybe<Service_Mutation_Response>;
  /** delete single row from the table: "service" */
  delete_service_by_pk?: Maybe<Service>;
  /** delete data from the table: "subscription" */
  delete_subscription?: Maybe<Subscription_Mutation_Response>;
  /** delete single row from the table: "subscription" */
  delete_subscription_by_pk?: Maybe<Subscription>;
  /** delete data from the table: "subscription_coupon" */
  delete_subscription_coupon?: Maybe<Subscription_Coupon_Mutation_Response>;
  /** delete single row from the table: "subscription_coupon" */
  delete_subscription_coupon_by_pk?: Maybe<Subscription_Coupon>;
  /** delete data from the table: "subscription_history" */
  delete_subscription_history?: Maybe<Subscription_History_Mutation_Response>;
  /** delete single row from the table: "subscription_history" */
  delete_subscription_history_by_pk?: Maybe<Subscription_History>;
  /** delete data from the table: "track_data_model" */
  delete_track_data_model?: Maybe<Track_Data_Model_Mutation_Response>;
  /** delete single row from the table: "track_data_model" */
  delete_track_data_model_by_pk?: Maybe<Track_Data_Model>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  /** delete data from the table: "wallet" */
  delete_wallet?: Maybe<Wallet_Mutation_Response>;
  /** delete single row from the table: "wallet" */
  delete_wallet_by_pk?: Maybe<Wallet>;
  /** delete data from the table: "workarea" */
  delete_workarea?: Maybe<Workarea_Mutation_Response>;
  /** delete data from the table: "workarea_activation" */
  delete_workarea_activation?: Maybe<Workarea_Activation_Mutation_Response>;
  /** delete single row from the table: "workarea_activation" */
  delete_workarea_activation_by_pk?: Maybe<Workarea_Activation>;
  /** delete data from the table: "workarea_activation_history" */
  delete_workarea_activation_history?: Maybe<Workarea_Activation_History_Mutation_Response>;
  /** delete single row from the table: "workarea_activation_history" */
  delete_workarea_activation_history_by_pk?: Maybe<Workarea_Activation_History>;
  /** delete data from the table: "workarea_bundle" */
  delete_workarea_bundle?: Maybe<Workarea_Bundle_Mutation_Response>;
  /** delete single row from the table: "workarea_bundle" */
  delete_workarea_bundle_by_pk?: Maybe<Workarea_Bundle>;
  /** delete single row from the table: "workarea" */
  delete_workarea_by_pk?: Maybe<Workarea>;
  /** insert data into the table: "alert" */
  insert_alert?: Maybe<Alert_Mutation_Response>;
  /** insert a single row into the table: "alert" */
  insert_alert_one?: Maybe<Alert>;
  /** insert data into the table: "attachments" */
  insert_attachments?: Maybe<Attachments_Mutation_Response>;
  /** insert a single row into the table: "attachments" */
  insert_attachments_one?: Maybe<Attachments>;
  /** insert data into the table: "company" */
  insert_company?: Maybe<Company_Mutation_Response>;
  /** insert a single row into the table: "company" */
  insert_company_one?: Maybe<Company>;
  /** insert data into the table: "document" */
  insert_document?: Maybe<Document_Mutation_Response>;
  /** insert a single row into the table: "document" */
  insert_document_one?: Maybe<Document>;
  /** insert data into the table: "dossier" */
  insert_dossier?: Maybe<Dossier_Mutation_Response>;
  /** insert a single row into the table: "dossier" */
  insert_dossier_one?: Maybe<Dossier>;
  /** insert data into the table: "extended_request" */
  insert_extended_request?: Maybe<Extended_Request_Mutation_Response>;
  /** insert a single row into the table: "extended_request" */
  insert_extended_request_one?: Maybe<Extended_Request>;
  /** insert data into the table: "ledger" */
  insert_ledger?: Maybe<Ledger_Mutation_Response>;
  /** insert a single row into the table: "ledger" */
  insert_ledger_one?: Maybe<Ledger>;
  /** insert data into the table: "report" */
  insert_report?: Maybe<Report_Mutation_Response>;
  /** insert a single row into the table: "report" */
  insert_report_one?: Maybe<Report>;
  /** insert data into the table: "request" */
  insert_request?: Maybe<Request_Mutation_Response>;
  /** insert a single row into the table: "request" */
  insert_request_one?: Maybe<Request>;
  /** insert data into the table: "screener_check" */
  insert_screener_check?: Maybe<Screener_Check_Mutation_Response>;
  /** insert a single row into the table: "screener_check" */
  insert_screener_check_one?: Maybe<Screener_Check>;
  /** insert data into the table: "screeners" */
  insert_screeners?: Maybe<Screeners_Mutation_Response>;
  /** insert a single row into the table: "screeners" */
  insert_screeners_one?: Maybe<Screeners>;
  /** insert data into the table: "service" */
  insert_service?: Maybe<Service_Mutation_Response>;
  /** insert a single row into the table: "service" */
  insert_service_one?: Maybe<Service>;
  /** insert data into the table: "subscription" */
  insert_subscription?: Maybe<Subscription_Mutation_Response>;
  /** insert data into the table: "subscription_coupon" */
  insert_subscription_coupon?: Maybe<Subscription_Coupon_Mutation_Response>;
  /** insert a single row into the table: "subscription_coupon" */
  insert_subscription_coupon_one?: Maybe<Subscription_Coupon>;
  /** insert data into the table: "subscription_history" */
  insert_subscription_history?: Maybe<Subscription_History_Mutation_Response>;
  /** insert a single row into the table: "subscription_history" */
  insert_subscription_history_one?: Maybe<Subscription_History>;
  /** insert a single row into the table: "subscription" */
  insert_subscription_one?: Maybe<Subscription>;
  /** insert data into the table: "track_data_model" */
  insert_track_data_model?: Maybe<Track_Data_Model_Mutation_Response>;
  /** insert a single row into the table: "track_data_model" */
  insert_track_data_model_one?: Maybe<Track_Data_Model>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  /** insert data into the table: "wallet" */
  insert_wallet?: Maybe<Wallet_Mutation_Response>;
  /** insert a single row into the table: "wallet" */
  insert_wallet_one?: Maybe<Wallet>;
  /** insert data into the table: "workarea" */
  insert_workarea?: Maybe<Workarea_Mutation_Response>;
  /** insert data into the table: "workarea_activation" */
  insert_workarea_activation?: Maybe<Workarea_Activation_Mutation_Response>;
  /** insert data into the table: "workarea_activation_history" */
  insert_workarea_activation_history?: Maybe<Workarea_Activation_History_Mutation_Response>;
  /** insert a single row into the table: "workarea_activation_history" */
  insert_workarea_activation_history_one?: Maybe<Workarea_Activation_History>;
  /** insert a single row into the table: "workarea_activation" */
  insert_workarea_activation_one?: Maybe<Workarea_Activation>;
  /** insert data into the table: "workarea_bundle" */
  insert_workarea_bundle?: Maybe<Workarea_Bundle_Mutation_Response>;
  /** insert a single row into the table: "workarea_bundle" */
  insert_workarea_bundle_one?: Maybe<Workarea_Bundle>;
  /** insert a single row into the table: "workarea" */
  insert_workarea_one?: Maybe<Workarea>;
  /** update data of the table: "alert" */
  update_alert?: Maybe<Alert_Mutation_Response>;
  /** update single row of the table: "alert" */
  update_alert_by_pk?: Maybe<Alert>;
  /** update multiples rows of table: "alert" */
  update_alert_many?: Maybe<Array<Maybe<Alert_Mutation_Response>>>;
  /** update data of the table: "attachments" */
  update_attachments?: Maybe<Attachments_Mutation_Response>;
  /** update single row of the table: "attachments" */
  update_attachments_by_pk?: Maybe<Attachments>;
  /** update multiples rows of table: "attachments" */
  update_attachments_many?: Maybe<Array<Maybe<Attachments_Mutation_Response>>>;
  /** update data of the table: "company" */
  update_company?: Maybe<Company_Mutation_Response>;
  /** update single row of the table: "company" */
  update_company_by_pk?: Maybe<Company>;
  /** update multiples rows of table: "company" */
  update_company_many?: Maybe<Array<Maybe<Company_Mutation_Response>>>;
  /** update data of the table: "document" */
  update_document?: Maybe<Document_Mutation_Response>;
  /** update single row of the table: "document" */
  update_document_by_pk?: Maybe<Document>;
  /** update multiples rows of table: "document" */
  update_document_many?: Maybe<Array<Maybe<Document_Mutation_Response>>>;
  /** update data of the table: "dossier" */
  update_dossier?: Maybe<Dossier_Mutation_Response>;
  /** update single row of the table: "dossier" */
  update_dossier_by_pk?: Maybe<Dossier>;
  /** update multiples rows of table: "dossier" */
  update_dossier_many?: Maybe<Array<Maybe<Dossier_Mutation_Response>>>;
  /** update data of the table: "extended_request" */
  update_extended_request?: Maybe<Extended_Request_Mutation_Response>;
  /** update multiples rows of table: "extended_request" */
  update_extended_request_many?: Maybe<Array<Maybe<Extended_Request_Mutation_Response>>>;
  /** update data of the table: "ledger" */
  update_ledger?: Maybe<Ledger_Mutation_Response>;
  /** update single row of the table: "ledger" */
  update_ledger_by_pk?: Maybe<Ledger>;
  /** update multiples rows of table: "ledger" */
  update_ledger_many?: Maybe<Array<Maybe<Ledger_Mutation_Response>>>;
  /** update data of the table: "report" */
  update_report?: Maybe<Report_Mutation_Response>;
  /** update single row of the table: "report" */
  update_report_by_pk?: Maybe<Report>;
  /** update multiples rows of table: "report" */
  update_report_many?: Maybe<Array<Maybe<Report_Mutation_Response>>>;
  /** update data of the table: "request" */
  update_request?: Maybe<Request_Mutation_Response>;
  /** update single row of the table: "request" */
  update_request_by_pk?: Maybe<Request>;
  /** update multiples rows of table: "request" */
  update_request_many?: Maybe<Array<Maybe<Request_Mutation_Response>>>;
  /** update data of the table: "screener_check" */
  update_screener_check?: Maybe<Screener_Check_Mutation_Response>;
  /** update single row of the table: "screener_check" */
  update_screener_check_by_pk?: Maybe<Screener_Check>;
  /** update multiples rows of table: "screener_check" */
  update_screener_check_many?: Maybe<Array<Maybe<Screener_Check_Mutation_Response>>>;
  /** update data of the table: "screeners" */
  update_screeners?: Maybe<Screeners_Mutation_Response>;
  /** update single row of the table: "screeners" */
  update_screeners_by_pk?: Maybe<Screeners>;
  /** update multiples rows of table: "screeners" */
  update_screeners_many?: Maybe<Array<Maybe<Screeners_Mutation_Response>>>;
  /** update data of the table: "service" */
  update_service?: Maybe<Service_Mutation_Response>;
  /** update single row of the table: "service" */
  update_service_by_pk?: Maybe<Service>;
  /** update multiples rows of table: "service" */
  update_service_many?: Maybe<Array<Maybe<Service_Mutation_Response>>>;
  /** update data of the table: "subscription" */
  update_subscription?: Maybe<Subscription_Mutation_Response>;
  /** update single row of the table: "subscription" */
  update_subscription_by_pk?: Maybe<Subscription>;
  /** update data of the table: "subscription_coupon" */
  update_subscription_coupon?: Maybe<Subscription_Coupon_Mutation_Response>;
  /** update single row of the table: "subscription_coupon" */
  update_subscription_coupon_by_pk?: Maybe<Subscription_Coupon>;
  /** update multiples rows of table: "subscription_coupon" */
  update_subscription_coupon_many?: Maybe<Array<Maybe<Subscription_Coupon_Mutation_Response>>>;
  /** update data of the table: "subscription_history" */
  update_subscription_history?: Maybe<Subscription_History_Mutation_Response>;
  /** update single row of the table: "subscription_history" */
  update_subscription_history_by_pk?: Maybe<Subscription_History>;
  /** update multiples rows of table: "subscription_history" */
  update_subscription_history_many?: Maybe<Array<Maybe<Subscription_History_Mutation_Response>>>;
  /** update multiples rows of table: "subscription" */
  update_subscription_many?: Maybe<Array<Maybe<Subscription_Mutation_Response>>>;
  /** update data of the table: "track_data_model" */
  update_track_data_model?: Maybe<Track_Data_Model_Mutation_Response>;
  /** update single row of the table: "track_data_model" */
  update_track_data_model_by_pk?: Maybe<Track_Data_Model>;
  /** update multiples rows of table: "track_data_model" */
  update_track_data_model_many?: Maybe<Array<Maybe<Track_Data_Model_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
  /** update data of the table: "wallet" */
  update_wallet?: Maybe<Wallet_Mutation_Response>;
  /** update single row of the table: "wallet" */
  update_wallet_by_pk?: Maybe<Wallet>;
  /** update multiples rows of table: "wallet" */
  update_wallet_many?: Maybe<Array<Maybe<Wallet_Mutation_Response>>>;
  /** update data of the table: "workarea" */
  update_workarea?: Maybe<Workarea_Mutation_Response>;
  /** update data of the table: "workarea_activation" */
  update_workarea_activation?: Maybe<Workarea_Activation_Mutation_Response>;
  /** update single row of the table: "workarea_activation" */
  update_workarea_activation_by_pk?: Maybe<Workarea_Activation>;
  /** update data of the table: "workarea_activation_history" */
  update_workarea_activation_history?: Maybe<Workarea_Activation_History_Mutation_Response>;
  /** update single row of the table: "workarea_activation_history" */
  update_workarea_activation_history_by_pk?: Maybe<Workarea_Activation_History>;
  /** update multiples rows of table: "workarea_activation_history" */
  update_workarea_activation_history_many?: Maybe<Array<Maybe<Workarea_Activation_History_Mutation_Response>>>;
  /** update multiples rows of table: "workarea_activation" */
  update_workarea_activation_many?: Maybe<Array<Maybe<Workarea_Activation_Mutation_Response>>>;
  /** update data of the table: "workarea_bundle" */
  update_workarea_bundle?: Maybe<Workarea_Bundle_Mutation_Response>;
  /** update single row of the table: "workarea_bundle" */
  update_workarea_bundle_by_pk?: Maybe<Workarea_Bundle>;
  /** update multiples rows of table: "workarea_bundle" */
  update_workarea_bundle_many?: Maybe<Array<Maybe<Workarea_Bundle_Mutation_Response>>>;
  /** update single row of the table: "workarea" */
  update_workarea_by_pk?: Maybe<Workarea>;
  /** update multiples rows of table: "workarea" */
  update_workarea_many?: Maybe<Array<Maybe<Workarea_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AlertArgs = {
  where: Alert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Alert_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AttachmentsArgs = {
  where: Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachments_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CompanyArgs = {
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DocumentArgs = {
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Document_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_DossierArgs = {
  where: Dossier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dossier_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Extended_RequestArgs = {
  where: Extended_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LedgerArgs = {
  where: Ledger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ledger_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ReportArgs = {
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Report_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RequestArgs = {
  where: Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Request_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Screener_CheckArgs = {
  where: Screener_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Screener_Check_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ScreenersArgs = {
  where: Screeners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Screeners_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServiceArgs = {
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_SubscriptionArgs = {
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_CouponArgs = {
  where: Subscription_Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_Coupon_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Subscription_HistoryArgs = {
  where: Subscription_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Subscription_History_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Track_Data_ModelArgs = {
  where: Track_Data_Model_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Track_Data_Model_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WalletArgs = {
  where: Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallet_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WorkareaArgs = {
  where: Workarea_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workarea_ActivationArgs = {
  where: Workarea_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workarea_Activation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Workarea_Activation_HistoryArgs = {
  where: Workarea_Activation_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workarea_Activation_History_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Workarea_BundleArgs = {
  where: Workarea_Bundle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Workarea_Bundle_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Workarea_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_AlertArgs = {
  objects: Array<Alert_Insert_Input>;
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Alert_OneArgs = {
  object: Alert_Insert_Input;
  on_conflict?: InputMaybe<Alert_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttachmentsArgs = {
  objects: Array<Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachments_OneArgs = {
  object: Attachments_Insert_Input;
  on_conflict?: InputMaybe<Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompanyArgs = {
  objects: Array<Company_Insert_Input>;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_OneArgs = {
  object: Company_Insert_Input;
  on_conflict?: InputMaybe<Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DocumentArgs = {
  objects: Array<Document_Insert_Input>;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Document_OneArgs = {
  object: Document_Insert_Input;
  on_conflict?: InputMaybe<Document_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DossierArgs = {
  objects: Array<Dossier_Insert_Input>;
  on_conflict?: InputMaybe<Dossier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dossier_OneArgs = {
  object: Dossier_Insert_Input;
  on_conflict?: InputMaybe<Dossier_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Extended_RequestArgs = {
  objects: Array<Extended_Request_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Extended_Request_OneArgs = {
  object: Extended_Request_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_LedgerArgs = {
  objects: Array<Ledger_Insert_Input>;
  on_conflict?: InputMaybe<Ledger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ledger_OneArgs = {
  object: Ledger_Insert_Input;
  on_conflict?: InputMaybe<Ledger_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportArgs = {
  objects: Array<Report_Insert_Input>;
  on_conflict?: InputMaybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Report_OneArgs = {
  object: Report_Insert_Input;
  on_conflict?: InputMaybe<Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RequestArgs = {
  objects: Array<Request_Insert_Input>;
  on_conflict?: InputMaybe<Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Request_OneArgs = {
  object: Request_Insert_Input;
  on_conflict?: InputMaybe<Request_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screener_CheckArgs = {
  objects: Array<Screener_Check_Insert_Input>;
  on_conflict?: InputMaybe<Screener_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screener_Check_OneArgs = {
  object: Screener_Check_Insert_Input;
  on_conflict?: InputMaybe<Screener_Check_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ScreenersArgs = {
  objects: Array<Screeners_Insert_Input>;
  on_conflict?: InputMaybe<Screeners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Screeners_OneArgs = {
  object: Screeners_Insert_Input;
  on_conflict?: InputMaybe<Screeners_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServiceArgs = {
  objects: Array<Service_Insert_Input>;
  on_conflict?: InputMaybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_OneArgs = {
  object: Service_Insert_Input;
  on_conflict?: InputMaybe<Service_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SubscriptionArgs = {
  objects: Array<Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_CouponArgs = {
  objects: Array<Subscription_Coupon_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_Coupon_OneArgs = {
  object: Subscription_Coupon_Insert_Input;
  on_conflict?: InputMaybe<Subscription_Coupon_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_HistoryArgs = {
  objects: Array<Subscription_History_Insert_Input>;
  on_conflict?: InputMaybe<Subscription_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_History_OneArgs = {
  object: Subscription_History_Insert_Input;
  on_conflict?: InputMaybe<Subscription_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Subscription_OneArgs = {
  object: Subscription_Insert_Input;
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_Data_ModelArgs = {
  objects: Array<Track_Data_Model_Insert_Input>;
  on_conflict?: InputMaybe<Track_Data_Model_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Track_Data_Model_OneArgs = {
  object: Track_Data_Model_Insert_Input;
  on_conflict?: InputMaybe<Track_Data_Model_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WalletArgs = {
  objects: Array<Wallet_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallet_OneArgs = {
  object: Wallet_Insert_Input;
  on_conflict?: InputMaybe<Wallet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WorkareaArgs = {
  objects: Array<Workarea_Insert_Input>;
  on_conflict?: InputMaybe<Workarea_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_ActivationArgs = {
  objects: Array<Workarea_Activation_Insert_Input>;
  on_conflict?: InputMaybe<Workarea_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_Activation_HistoryArgs = {
  objects: Array<Workarea_Activation_History_Insert_Input>;
  on_conflict?: InputMaybe<Workarea_Activation_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_Activation_History_OneArgs = {
  object: Workarea_Activation_History_Insert_Input;
  on_conflict?: InputMaybe<Workarea_Activation_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_Activation_OneArgs = {
  object: Workarea_Activation_Insert_Input;
  on_conflict?: InputMaybe<Workarea_Activation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_BundleArgs = {
  objects: Array<Workarea_Bundle_Insert_Input>;
  on_conflict?: InputMaybe<Workarea_Bundle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_Bundle_OneArgs = {
  object: Workarea_Bundle_Insert_Input;
  on_conflict?: InputMaybe<Workarea_Bundle_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Workarea_OneArgs = {
  object: Workarea_Insert_Input;
  on_conflict?: InputMaybe<Workarea_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AlertArgs = {
  _inc?: InputMaybe<Alert_Inc_Input>;
  _set?: InputMaybe<Alert_Set_Input>;
  where: Alert_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Alert_By_PkArgs = {
  _inc?: InputMaybe<Alert_Inc_Input>;
  _set?: InputMaybe<Alert_Set_Input>;
  pk_columns: Alert_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Alert_ManyArgs = {
  updates: Array<Alert_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AttachmentsArgs = {
  _inc?: InputMaybe<Attachments_Inc_Input>;
  _set?: InputMaybe<Attachments_Set_Input>;
  where: Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachments_By_PkArgs = {
  _inc?: InputMaybe<Attachments_Inc_Input>;
  _set?: InputMaybe<Attachments_Set_Input>;
  pk_columns: Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachments_ManyArgs = {
  updates: Array<Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompanyArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  where: Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_By_PkArgs = {
  _inc?: InputMaybe<Company_Inc_Input>;
  _set?: InputMaybe<Company_Set_Input>;
  pk_columns: Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_ManyArgs = {
  updates: Array<Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DocumentArgs = {
  _inc?: InputMaybe<Document_Inc_Input>;
  _set?: InputMaybe<Document_Set_Input>;
  where: Document_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Document_By_PkArgs = {
  _inc?: InputMaybe<Document_Inc_Input>;
  _set?: InputMaybe<Document_Set_Input>;
  pk_columns: Document_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Document_ManyArgs = {
  updates: Array<Document_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DossierArgs = {
  _inc?: InputMaybe<Dossier_Inc_Input>;
  _set?: InputMaybe<Dossier_Set_Input>;
  where: Dossier_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dossier_By_PkArgs = {
  _inc?: InputMaybe<Dossier_Inc_Input>;
  _set?: InputMaybe<Dossier_Set_Input>;
  pk_columns: Dossier_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dossier_ManyArgs = {
  updates: Array<Dossier_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Extended_RequestArgs = {
  _append?: InputMaybe<Extended_Request_Append_Input>;
  _delete_at_path?: InputMaybe<Extended_Request_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Extended_Request_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Extended_Request_Delete_Key_Input>;
  _inc?: InputMaybe<Extended_Request_Inc_Input>;
  _prepend?: InputMaybe<Extended_Request_Prepend_Input>;
  _set?: InputMaybe<Extended_Request_Set_Input>;
  where: Extended_Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Extended_Request_ManyArgs = {
  updates: Array<Extended_Request_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LedgerArgs = {
  _inc?: InputMaybe<Ledger_Inc_Input>;
  _set?: InputMaybe<Ledger_Set_Input>;
  where: Ledger_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ledger_By_PkArgs = {
  _inc?: InputMaybe<Ledger_Inc_Input>;
  _set?: InputMaybe<Ledger_Set_Input>;
  pk_columns: Ledger_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ledger_ManyArgs = {
  updates: Array<Ledger_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportArgs = {
  _inc?: InputMaybe<Report_Inc_Input>;
  _set?: InputMaybe<Report_Set_Input>;
  where: Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Report_By_PkArgs = {
  _inc?: InputMaybe<Report_Inc_Input>;
  _set?: InputMaybe<Report_Set_Input>;
  pk_columns: Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Report_ManyArgs = {
  updates: Array<Report_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RequestArgs = {
  _append?: InputMaybe<Request_Append_Input>;
  _delete_at_path?: InputMaybe<Request_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Request_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Request_Delete_Key_Input>;
  _inc?: InputMaybe<Request_Inc_Input>;
  _prepend?: InputMaybe<Request_Prepend_Input>;
  _set?: InputMaybe<Request_Set_Input>;
  where: Request_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Request_By_PkArgs = {
  _append?: InputMaybe<Request_Append_Input>;
  _delete_at_path?: InputMaybe<Request_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Request_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Request_Delete_Key_Input>;
  _inc?: InputMaybe<Request_Inc_Input>;
  _prepend?: InputMaybe<Request_Prepend_Input>;
  _set?: InputMaybe<Request_Set_Input>;
  pk_columns: Request_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Request_ManyArgs = {
  updates: Array<Request_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Screener_CheckArgs = {
  _inc?: InputMaybe<Screener_Check_Inc_Input>;
  _set?: InputMaybe<Screener_Check_Set_Input>;
  where: Screener_Check_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Screener_Check_By_PkArgs = {
  _inc?: InputMaybe<Screener_Check_Inc_Input>;
  _set?: InputMaybe<Screener_Check_Set_Input>;
  pk_columns: Screener_Check_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Screener_Check_ManyArgs = {
  updates: Array<Screener_Check_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ScreenersArgs = {
  _inc?: InputMaybe<Screeners_Inc_Input>;
  _set?: InputMaybe<Screeners_Set_Input>;
  where: Screeners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Screeners_By_PkArgs = {
  _inc?: InputMaybe<Screeners_Inc_Input>;
  _set?: InputMaybe<Screeners_Set_Input>;
  pk_columns: Screeners_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Screeners_ManyArgs = {
  updates: Array<Screeners_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ServiceArgs = {
  _append?: InputMaybe<Service_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Inc_Input>;
  _prepend?: InputMaybe<Service_Prepend_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  where: Service_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_By_PkArgs = {
  _append?: InputMaybe<Service_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Inc_Input>;
  _prepend?: InputMaybe<Service_Prepend_Input>;
  _set?: InputMaybe<Service_Set_Input>;
  pk_columns: Service_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_ManyArgs = {
  updates: Array<Service_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SubscriptionArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  where: Subscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Inc_Input>;
  _set?: InputMaybe<Subscription_Set_Input>;
  pk_columns: Subscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_CouponArgs = {
  _inc?: InputMaybe<Subscription_Coupon_Inc_Input>;
  _set?: InputMaybe<Subscription_Coupon_Set_Input>;
  where: Subscription_Coupon_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_Coupon_By_PkArgs = {
  _inc?: InputMaybe<Subscription_Coupon_Inc_Input>;
  _set?: InputMaybe<Subscription_Coupon_Set_Input>;
  pk_columns: Subscription_Coupon_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_Coupon_ManyArgs = {
  updates: Array<Subscription_Coupon_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_HistoryArgs = {
  _inc?: InputMaybe<Subscription_History_Inc_Input>;
  _set?: InputMaybe<Subscription_History_Set_Input>;
  where: Subscription_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_History_By_PkArgs = {
  _inc?: InputMaybe<Subscription_History_Inc_Input>;
  _set?: InputMaybe<Subscription_History_Set_Input>;
  pk_columns: Subscription_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_History_ManyArgs = {
  updates: Array<Subscription_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Subscription_ManyArgs = {
  updates: Array<Subscription_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Track_Data_ModelArgs = {
  _inc?: InputMaybe<Track_Data_Model_Inc_Input>;
  _set?: InputMaybe<Track_Data_Model_Set_Input>;
  where: Track_Data_Model_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Track_Data_Model_By_PkArgs = {
  _inc?: InputMaybe<Track_Data_Model_Inc_Input>;
  _set?: InputMaybe<Track_Data_Model_Set_Input>;
  pk_columns: Track_Data_Model_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Track_Data_Model_ManyArgs = {
  updates: Array<Track_Data_Model_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: InputMaybe<User_Inc_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WalletArgs = {
  _inc?: InputMaybe<Wallet_Inc_Input>;
  _set?: InputMaybe<Wallet_Set_Input>;
  where: Wallet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallet_By_PkArgs = {
  _inc?: InputMaybe<Wallet_Inc_Input>;
  _set?: InputMaybe<Wallet_Set_Input>;
  pk_columns: Wallet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wallet_ManyArgs = {
  updates: Array<Wallet_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WorkareaArgs = {
  _inc?: InputMaybe<Workarea_Inc_Input>;
  _set?: InputMaybe<Workarea_Set_Input>;
  where: Workarea_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_ActivationArgs = {
  _inc?: InputMaybe<Workarea_Activation_Inc_Input>;
  _set?: InputMaybe<Workarea_Activation_Set_Input>;
  where: Workarea_Activation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Activation_By_PkArgs = {
  _inc?: InputMaybe<Workarea_Activation_Inc_Input>;
  _set?: InputMaybe<Workarea_Activation_Set_Input>;
  pk_columns: Workarea_Activation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Activation_HistoryArgs = {
  _inc?: InputMaybe<Workarea_Activation_History_Inc_Input>;
  _set?: InputMaybe<Workarea_Activation_History_Set_Input>;
  where: Workarea_Activation_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Activation_History_By_PkArgs = {
  _inc?: InputMaybe<Workarea_Activation_History_Inc_Input>;
  _set?: InputMaybe<Workarea_Activation_History_Set_Input>;
  pk_columns: Workarea_Activation_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Activation_History_ManyArgs = {
  updates: Array<Workarea_Activation_History_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Activation_ManyArgs = {
  updates: Array<Workarea_Activation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_BundleArgs = {
  _inc?: InputMaybe<Workarea_Bundle_Inc_Input>;
  _set?: InputMaybe<Workarea_Bundle_Set_Input>;
  where: Workarea_Bundle_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Bundle_By_PkArgs = {
  _inc?: InputMaybe<Workarea_Bundle_Inc_Input>;
  _set?: InputMaybe<Workarea_Bundle_Set_Input>;
  pk_columns: Workarea_Bundle_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_Bundle_ManyArgs = {
  updates: Array<Workarea_Bundle_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_By_PkArgs = {
  _inc?: InputMaybe<Workarea_Inc_Input>;
  _set?: InputMaybe<Workarea_Set_Input>;
  pk_columns: Workarea_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Workarea_ManyArgs = {
  updates: Array<Workarea_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "alert" */
  alert: Array<Alert>;
  /** fetch aggregated fields from the table: "alert" */
  alert_aggregate: Alert_Aggregate;
  /** fetch data from the table: "alert" using primary key columns */
  alert_by_pk?: Maybe<Alert>;
  /** An array relationship */
  attachments: Array<Attachments>;
  /** An aggregate relationship */
  attachments_aggregate: Attachments_Aggregate;
  /** fetch data from the table: "attachments" using primary key columns */
  attachments_by_pk?: Maybe<Attachments>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_balance" */
  company_balance: Array<Company_Balance>;
  /** fetch aggregated fields from the table: "company_balance" */
  company_balance_aggregate: Company_Balance_Aggregate;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "dossier" */
  dossier: Array<Dossier>;
  /** fetch aggregated fields from the table: "dossier" */
  dossier_aggregate: Dossier_Aggregate;
  /** fetch data from the table: "dossier" using primary key columns */
  dossier_by_pk?: Maybe<Dossier>;
  /** fetch data from the table: "extended_request" */
  extended_request: Array<Extended_Request>;
  /** fetch aggregated fields from the table: "extended_request" */
  extended_request_aggregate: Extended_Request_Aggregate;
  /** fetch data from the table: "ledger" */
  ledger: Array<Ledger>;
  /** fetch aggregated fields from the table: "ledger" */
  ledger_aggregate: Ledger_Aggregate;
  /** fetch data from the table: "ledger_by_company_and_day" */
  ledger_by_company_and_day: Array<Ledger_By_Company_And_Day>;
  /** fetch aggregated fields from the table: "ledger_by_company_and_day" */
  ledger_by_company_and_day_aggregate: Ledger_By_Company_And_Day_Aggregate;
  /** fetch data from the table: "ledger" using primary key columns */
  ledger_by_pk?: Maybe<Ledger>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table: "request" */
  request: Array<Request>;
  /** fetch aggregated fields from the table: "request" */
  request_aggregate: Request_Aggregate;
  /** fetch data from the table: "request" using primary key columns */
  request_by_pk?: Maybe<Request>;
  /** fetch data from the table: "screener_check" */
  screener_check: Array<Screener_Check>;
  /** fetch aggregated fields from the table: "screener_check" */
  screener_check_aggregate: Screener_Check_Aggregate;
  /** fetch data from the table: "screener_check" using primary key columns */
  screener_check_by_pk?: Maybe<Screener_Check>;
  /** An array relationship */
  screeners: Array<Screeners>;
  /** An aggregate relationship */
  screeners_aggregate: Screeners_Aggregate;
  /** fetch data from the table: "screeners" using primary key columns */
  screeners_by_pk?: Maybe<Screeners>;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table: "service_track_by_company_and_day" */
  service_track_by_company_and_day: Array<Service_Track_By_Company_And_Day>;
  /** fetch aggregated fields from the table: "service_track_by_company_and_day" */
  service_track_by_company_and_day_aggregate: Service_Track_By_Company_And_Day_Aggregate;
  /** fetch data from the table: "service_track_by_day" */
  service_track_by_day: Array<Service_Track_By_Day>;
  /** fetch aggregated fields from the table: "service_track_by_day" */
  service_track_by_day_aggregate: Service_Track_By_Day_Aggregate;
  /** fetch data from the table: "service_usage_by_company" */
  service_usage_by_company: Array<Service_Usage_By_Company>;
  /** fetch aggregated fields from the table: "service_usage_by_company" */
  service_usage_by_company_aggregate: Service_Usage_By_Company_Aggregate;
  /** fetch data from the table: "service_usage_by_user" */
  service_usage_by_user: Array<Service_Usage_By_User>;
  /** fetch aggregated fields from the table: "service_usage_by_user" */
  service_usage_by_user_aggregate: Service_Usage_By_User_Aggregate;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "subscription_coupon" */
  subscription_coupon: Array<Subscription_Coupon>;
  /** fetch aggregated fields from the table: "subscription_coupon" */
  subscription_coupon_aggregate: Subscription_Coupon_Aggregate;
  /** fetch data from the table: "subscription_coupon" using primary key columns */
  subscription_coupon_by_pk?: Maybe<Subscription_Coupon>;
  /** fetch data from the table: "subscription_history" */
  subscription_history: Array<Subscription_History>;
  /** fetch aggregated fields from the table: "subscription_history" */
  subscription_history_aggregate: Subscription_History_Aggregate;
  /** fetch data from the table: "subscription_history" using primary key columns */
  subscription_history_by_pk?: Maybe<Subscription_History>;
  /** fetch data from the table: "track_data_model" */
  track_data_model: Array<Track_Data_Model>;
  /** fetch aggregated fields from the table: "track_data_model" */
  track_data_model_aggregate: Track_Data_Model_Aggregate;
  /** fetch data from the table: "track_data_model" using primary key columns */
  track_data_model_by_pk?: Maybe<Track_Data_Model>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "wallet" */
  wallet: Array<Wallet>;
  /** fetch aggregated fields from the table: "wallet" */
  wallet_aggregate: Wallet_Aggregate;
  /** fetch data from the table: "wallet" using primary key columns */
  wallet_by_pk?: Maybe<Wallet>;
  /** fetch data from the table: "workarea" */
  workarea: Array<Workarea>;
  /** fetch data from the table: "workarea_activation" */
  workarea_activation: Array<Workarea_Activation>;
  /** fetch aggregated fields from the table: "workarea_activation" */
  workarea_activation_aggregate: Workarea_Activation_Aggregate;
  /** fetch data from the table: "workarea_activation" using primary key columns */
  workarea_activation_by_pk?: Maybe<Workarea_Activation>;
  /** fetch data from the table: "workarea_activation_history" */
  workarea_activation_history: Array<Workarea_Activation_History>;
  /** fetch aggregated fields from the table: "workarea_activation_history" */
  workarea_activation_history_aggregate: Workarea_Activation_History_Aggregate;
  /** fetch data from the table: "workarea_activation_history" using primary key columns */
  workarea_activation_history_by_pk?: Maybe<Workarea_Activation_History>;
  /** fetch aggregated fields from the table: "workarea" */
  workarea_aggregate: Workarea_Aggregate;
  /** fetch data from the table: "workarea_bundle" */
  workarea_bundle: Array<Workarea_Bundle>;
  /** fetch aggregated fields from the table: "workarea_bundle" */
  workarea_bundle_aggregate: Workarea_Bundle_Aggregate;
  /** fetch data from the table: "workarea_bundle" using primary key columns */
  workarea_bundle_by_pk?: Maybe<Workarea_Bundle>;
  /** fetch data from the table: "workarea" using primary key columns */
  workarea_by_pk?: Maybe<Workarea>;
};


export type Query_RootAlertArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type Query_RootAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type Query_RootAlert_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Query_RootAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Query_RootAttachments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Query_RootCompany_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Company_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Balance_Order_By>>;
  where?: InputMaybe<Company_Balance_Bool_Exp>;
};


export type Query_RootCompany_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Balance_Order_By>>;
  where?: InputMaybe<Company_Balance_Bool_Exp>;
};


export type Query_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Query_RootDocument_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDossierArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


export type Query_RootDossier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


export type Query_RootDossier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootExtended_RequestArgs = {
  distinct_on?: InputMaybe<Array<Extended_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Extended_Request_Order_By>>;
  where?: InputMaybe<Extended_Request_Bool_Exp>;
};


export type Query_RootExtended_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Extended_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Extended_Request_Order_By>>;
  where?: InputMaybe<Extended_Request_Bool_Exp>;
};


export type Query_RootLedgerArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


export type Query_RootLedger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


export type Query_RootLedger_By_Company_And_DayArgs = {
  distinct_on?: InputMaybe<Array<Ledger_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
};


export type Query_RootLedger_By_Company_And_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
};


export type Query_RootLedger_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Query_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Query_RootReport_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootRequestArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


export type Query_RootRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


export type Query_RootRequest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootScreener_CheckArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


export type Query_RootScreener_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


export type Query_RootScreener_Check_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootScreenersArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


export type Query_RootScreeners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


export type Query_RootScreeners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServiceArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Query_RootService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Query_RootService_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootService_Track_By_Company_And_DayArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
};


export type Query_RootService_Track_By_Company_And_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
};


export type Query_RootService_Track_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
};


export type Query_RootService_Track_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
};


export type Query_RootService_Usage_By_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_Company_Order_By>>;
  where?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
};


export type Query_RootService_Usage_By_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_Company_Order_By>>;
  where?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
};


export type Query_RootService_Usage_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_User_Order_By>>;
  where?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
};


export type Query_RootService_Usage_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_User_Order_By>>;
  where?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
};


export type Query_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Query_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubscription_CouponArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


export type Query_RootSubscription_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


export type Query_RootSubscription_Coupon_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootSubscription_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Subscription_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_History_Order_By>>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};


export type Query_RootSubscription_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_History_Order_By>>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};


export type Query_RootSubscription_History_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootTrack_Data_ModelArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


export type Query_RootTrack_Data_Model_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


export type Query_RootTrack_Data_Model_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWalletArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Order_By>>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};


export type Query_RootWallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Order_By>>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};


export type Query_RootWallet_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWorkareaArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Order_By>>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};


export type Query_RootWorkarea_ActivationArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


export type Query_RootWorkarea_Activation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


export type Query_RootWorkarea_Activation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWorkarea_Activation_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_History_Order_By>>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};


export type Query_RootWorkarea_Activation_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_History_Order_By>>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};


export type Query_RootWorkarea_Activation_History_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWorkarea_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Order_By>>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};


export type Query_RootWorkarea_BundleArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


export type Query_RootWorkarea_Bundle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


export type Query_RootWorkarea_Bundle_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWorkarea_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "report" */
export type Report = {
  __typename?: 'report';
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  date: Scalars['timestamp'];
  id: Scalars['Int'];
  note: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "report" */
export type Report_Aggregate = {
  __typename?: 'report_aggregate';
  aggregate?: Maybe<Report_Aggregate_Fields>;
  nodes: Array<Report>;
};

export type Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Report_Aggregate_Bool_Exp_Count>;
};

export type Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Report_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "report" */
export type Report_Aggregate_Fields = {
  __typename?: 'report_aggregate_fields';
  avg?: Maybe<Report_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Report_Max_Fields>;
  min?: Maybe<Report_Min_Fields>;
  stddev?: Maybe<Report_Stddev_Fields>;
  stddev_pop?: Maybe<Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Report_Stddev_Samp_Fields>;
  sum?: Maybe<Report_Sum_Fields>;
  var_pop?: Maybe<Report_Var_Pop_Fields>;
  var_samp?: Maybe<Report_Var_Samp_Fields>;
  variance?: Maybe<Report_Variance_Fields>;
};


/** aggregate fields of "report" */
export type Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "report" */
export type Report_Aggregate_Order_By = {
  avg?: InputMaybe<Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Report_Max_Order_By>;
  min?: InputMaybe<Report_Min_Order_By>;
  stddev?: InputMaybe<Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Report_Sum_Order_By>;
  var_pop?: InputMaybe<Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Report_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "report" */
export type Report_Arr_Rel_Insert_Input = {
  data: Array<Report_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Report_On_Conflict>;
};

/** aggregate avg on columns */
export type Report_Avg_Fields = {
  __typename?: 'report_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "report" */
export type Report_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "report". All fields are combined with a logical 'AND'. */
export type Report_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Bool_Exp>>;
  _not?: InputMaybe<Report_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "report" */
export enum Report_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_99e4d0bea58cba73c57f935a546 = 'PK_99e4d0bea58cba73c57f935a546'
}

/** input type for incrementing numeric columns in table "report" */
export type Report_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "report" */
export type Report_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Report_Max_Fields = {
  __typename?: 'report_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "report" */
export type Report_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Report_Min_Fields = {
  __typename?: 'report_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "report" */
export type Report_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "report" */
export type Report_Mutation_Response = {
  __typename?: 'report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Report>;
};

/** on_conflict condition type for table "report" */
export type Report_On_Conflict = {
  constraint: Report_Constraint;
  update_columns?: Array<Report_Update_Column>;
  where?: InputMaybe<Report_Bool_Exp>;
};

/** Ordering options when selecting data from "report". */
export type Report_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: report */
export type Report_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "report" */
export enum Report_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "report" */
export type Report_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Report_Stddev_Fields = {
  __typename?: 'report_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "report" */
export type Report_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Report_Stddev_Pop_Fields = {
  __typename?: 'report_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "report" */
export type Report_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Report_Stddev_Samp_Fields = {
  __typename?: 'report_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "report" */
export type Report_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "report" */
export type Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Report_Sum_Fields = {
  __typename?: 'report_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "report" */
export type Report_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "report" */
export enum Report_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type'
}

export type Report_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Report_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Report_Var_Pop_Fields = {
  __typename?: 'report_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "report" */
export type Report_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Report_Var_Samp_Fields = {
  __typename?: 'report_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "report" */
export type Report_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Report_Variance_Fields = {
  __typename?: 'report_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "report" */
export type Report_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "request" */
export type Request = {
  __typename?: 'request';
  airflowData: Scalars['json'];
  archived: Scalars['Boolean'];
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  /** An object relationship */
  coupon?: Maybe<Subscription_Coupon>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamp'];
  /** An array relationship */
  documents: Array<Document>;
  /** An aggregate relationship */
  documents_aggregate: Document_Aggregate;
  errorDescription?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  notes: Scalars['json'];
  params: Scalars['jsonb'];
  providerData: Scalars['json'];
  queryResult?: Maybe<Scalars['json']>;
  /** An object relationship */
  service: Service;
  serviceId: Scalars['Int'];
  status: Scalars['request_status_enum'];
  /** An object relationship */
  subscription?: Maybe<Subscription>;
  subscriptionId?: Maybe<Scalars['Int']>;
  /** An array relationship */
  transactions: Array<Ledger>;
  /** An aggregate relationship */
  transactions_aggregate: Ledger_Aggregate;
  /** An object relationship */
  user: User;
  userId: Scalars['Int'];
};


/** columns and relationships of "request" */
export type RequestAirflowDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "request" */
export type RequestDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "request" */
export type RequestDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** columns and relationships of "request" */
export type RequestNotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "request" */
export type RequestParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "request" */
export type RequestProviderDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "request" */
export type RequestQueryResultArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "request" */
export type RequestTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "request" */
export type RequestTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};

/** aggregated selection of "request" */
export type Request_Aggregate = {
  __typename?: 'request_aggregate';
  aggregate?: Maybe<Request_Aggregate_Fields>;
  nodes: Array<Request>;
};

export type Request_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Request_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Request_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Request_Aggregate_Bool_Exp_Count>;
};

export type Request_Aggregate_Bool_Exp_Bool_And = {
  arguments: Request_Select_Column_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Request_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Request_Select_Column_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Request_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Request_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Request_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "request" */
export type Request_Aggregate_Fields = {
  __typename?: 'request_aggregate_fields';
  avg?: Maybe<Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Request_Max_Fields>;
  min?: Maybe<Request_Min_Fields>;
  stddev?: Maybe<Request_Stddev_Fields>;
  stddev_pop?: Maybe<Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Request_Stddev_Samp_Fields>;
  sum?: Maybe<Request_Sum_Fields>;
  var_pop?: Maybe<Request_Var_Pop_Fields>;
  var_samp?: Maybe<Request_Var_Samp_Fields>;
  variance?: Maybe<Request_Variance_Fields>;
};


/** aggregate fields of "request" */
export type Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "request" */
export type Request_Aggregate_Order_By = {
  avg?: InputMaybe<Request_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Request_Max_Order_By>;
  min?: InputMaybe<Request_Min_Order_By>;
  stddev?: InputMaybe<Request_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Request_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Request_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Request_Sum_Order_By>;
  var_pop?: InputMaybe<Request_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Request_Var_Samp_Order_By>;
  variance?: InputMaybe<Request_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Request_Append_Input = {
  params?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "request" */
export type Request_Arr_Rel_Insert_Input = {
  data: Array<Request_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Request_On_Conflict>;
};

/** aggregate avg on columns */
export type Request_Avg_Fields = {
  __typename?: 'request_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "request" */
export type Request_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "request". All fields are combined with a logical 'AND'. */
export type Request_Bool_Exp = {
  _and?: InputMaybe<Array<Request_Bool_Exp>>;
  _not?: InputMaybe<Request_Bool_Exp>;
  _or?: InputMaybe<Array<Request_Bool_Exp>>;
  airflowData?: InputMaybe<Json_Comparison_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  coupon?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  couponId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  documents?: InputMaybe<Document_Bool_Exp>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Bool_Exp>;
  errorDescription?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  notes?: InputMaybe<Json_Comparison_Exp>;
  params?: InputMaybe<Jsonb_Comparison_Exp>;
  providerData?: InputMaybe<Json_Comparison_Exp>;
  queryResult?: InputMaybe<Json_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Request_Status_Enum_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  transactions?: InputMaybe<Ledger_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Bool_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "request" */
export enum Request_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_167d324701e6867f189aed52e18 = 'PK_167d324701e6867f189aed52e18'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Request_Delete_At_Path_Input = {
  params?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Request_Delete_Elem_Input = {
  params?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Request_Delete_Key_Input = {
  params?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "request" */
export type Request_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "request" */
export type Request_Insert_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  coupon?: InputMaybe<Subscription_Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  documents?: InputMaybe<Document_Arr_Rel_Insert_Input>;
  errorDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  transactions?: InputMaybe<Ledger_Arr_Rel_Insert_Input>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Request_Max_Fields = {
  __typename?: 'request_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  errorDescription?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['request_status_enum']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "request" */
export type Request_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorDescription?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Request_Min_Fields = {
  __typename?: 'request_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  errorDescription?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['request_status_enum']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "request" */
export type Request_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  errorDescription?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "request" */
export type Request_Mutation_Response = {
  __typename?: 'request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Request>;
};

/** input type for inserting object relation for remote table "request" */
export type Request_Obj_Rel_Insert_Input = {
  data: Request_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Request_On_Conflict>;
};

/** on_conflict condition type for table "request" */
export type Request_On_Conflict = {
  constraint: Request_Constraint;
  update_columns?: Array<Request_Update_Column>;
  where?: InputMaybe<Request_Bool_Exp>;
};

/** Ordering options when selecting data from "request". */
export type Request_Order_By = {
  airflowData?: InputMaybe<Order_By>;
  archived?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Subscription_Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Document_Aggregate_Order_By>;
  errorDescription?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  params?: InputMaybe<Order_By>;
  providerData?: InputMaybe<Order_By>;
  queryResult?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: request */
export type Request_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Request_Prepend_Input = {
  params?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "request" */
export enum Request_Select_Column {
  /** column name */
  AirflowData = 'airflowData',
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorDescription = 'errorDescription',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Notes = 'notes',
  /** column name */
  Params = 'params',
  /** column name */
  ProviderData = 'providerData',
  /** column name */
  QueryResult = 'queryResult',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId'
}

/** select "request_aggregate_bool_exp_bool_and_arguments_columns" columns of table "request" */
export enum Request_Select_Column_Request_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** select "request_aggregate_bool_exp_bool_or_arguments_columns" columns of table "request" */
export enum Request_Select_Column_Request_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived'
}

/** input type for updating data in table "request" */
export type Request_Set_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  errorDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "request_status_enum". All fields are combined with logical 'AND'. */
export type Request_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['request_status_enum']>;
  _gt?: InputMaybe<Scalars['request_status_enum']>;
  _gte?: InputMaybe<Scalars['request_status_enum']>;
  _in?: InputMaybe<Array<Scalars['request_status_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['request_status_enum']>;
  _lte?: InputMaybe<Scalars['request_status_enum']>;
  _neq?: InputMaybe<Scalars['request_status_enum']>;
  _nin?: InputMaybe<Array<Scalars['request_status_enum']>>;
};

/** aggregate stddev on columns */
export type Request_Stddev_Fields = {
  __typename?: 'request_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "request" */
export type Request_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Request_Stddev_Pop_Fields = {
  __typename?: 'request_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "request" */
export type Request_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Request_Stddev_Samp_Fields = {
  __typename?: 'request_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "request" */
export type Request_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "request" */
export type Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Request_Stream_Cursor_Value_Input = {
  airflowData?: InputMaybe<Scalars['json']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  errorDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  notes?: InputMaybe<Scalars['json']>;
  params?: InputMaybe<Scalars['jsonb']>;
  providerData?: InputMaybe<Scalars['json']>;
  queryResult?: InputMaybe<Scalars['json']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['request_status_enum']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Request_Sum_Fields = {
  __typename?: 'request_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "request" */
export type Request_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "request" */
export enum Request_Update_Column {
  /** column name */
  AirflowData = 'airflowData',
  /** column name */
  Archived = 'archived',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ErrorDescription = 'errorDescription',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Notes = 'notes',
  /** column name */
  Params = 'params',
  /** column name */
  ProviderData = 'providerData',
  /** column name */
  QueryResult = 'queryResult',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UserId = 'userId'
}

export type Request_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Request_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Request_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Request_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Request_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Request_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Request_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Request_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Request_Var_Pop_Fields = {
  __typename?: 'request_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "request" */
export type Request_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Request_Var_Samp_Fields = {
  __typename?: 'request_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "request" */
export type Request_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Request_Variance_Fields = {
  __typename?: 'request_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "request" */
export type Request_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "screener_check" */
export type Screener_Check = {
  __typename?: 'screener_check';
  createdAt: Scalars['timestamptz'];
  detectedChanges: Scalars['json'];
  documentPath?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  previousValue?: Maybe<Scalars['json']>;
  previusValue?: Maybe<Scalars['json']>;
  public: Scalars['Boolean'];
  response: Scalars['json'];
  /** An object relationship */
  screener: Screeners;
  screenerId: Scalars['uuid'];
  userAck: Scalars['Boolean'];
};


/** columns and relationships of "screener_check" */
export type Screener_CheckDetectedChangesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "screener_check" */
export type Screener_CheckPreviousValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "screener_check" */
export type Screener_CheckPreviusValueArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "screener_check" */
export type Screener_CheckResponseArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "screener_check" */
export type Screener_Check_Aggregate = {
  __typename?: 'screener_check_aggregate';
  aggregate?: Maybe<Screener_Check_Aggregate_Fields>;
  nodes: Array<Screener_Check>;
};

export type Screener_Check_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Screener_Check_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Screener_Check_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Screener_Check_Aggregate_Bool_Exp_Count>;
};

export type Screener_Check_Aggregate_Bool_Exp_Bool_And = {
  arguments: Screener_Check_Select_Column_Screener_Check_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screener_Check_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screener_Check_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Screener_Check_Select_Column_Screener_Check_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screener_Check_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screener_Check_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Screener_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screener_Check_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "screener_check" */
export type Screener_Check_Aggregate_Fields = {
  __typename?: 'screener_check_aggregate_fields';
  avg?: Maybe<Screener_Check_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Screener_Check_Max_Fields>;
  min?: Maybe<Screener_Check_Min_Fields>;
  stddev?: Maybe<Screener_Check_Stddev_Fields>;
  stddev_pop?: Maybe<Screener_Check_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Screener_Check_Stddev_Samp_Fields>;
  sum?: Maybe<Screener_Check_Sum_Fields>;
  var_pop?: Maybe<Screener_Check_Var_Pop_Fields>;
  var_samp?: Maybe<Screener_Check_Var_Samp_Fields>;
  variance?: Maybe<Screener_Check_Variance_Fields>;
};


/** aggregate fields of "screener_check" */
export type Screener_Check_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screener_Check_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "screener_check" */
export type Screener_Check_Aggregate_Order_By = {
  avg?: InputMaybe<Screener_Check_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Screener_Check_Max_Order_By>;
  min?: InputMaybe<Screener_Check_Min_Order_By>;
  stddev?: InputMaybe<Screener_Check_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Screener_Check_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Screener_Check_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Screener_Check_Sum_Order_By>;
  var_pop?: InputMaybe<Screener_Check_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Screener_Check_Var_Samp_Order_By>;
  variance?: InputMaybe<Screener_Check_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "screener_check" */
export type Screener_Check_Arr_Rel_Insert_Input = {
  data: Array<Screener_Check_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Screener_Check_On_Conflict>;
};

/** aggregate avg on columns */
export type Screener_Check_Avg_Fields = {
  __typename?: 'screener_check_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "screener_check" */
export type Screener_Check_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "screener_check". All fields are combined with a logical 'AND'. */
export type Screener_Check_Bool_Exp = {
  _and?: InputMaybe<Array<Screener_Check_Bool_Exp>>;
  _not?: InputMaybe<Screener_Check_Bool_Exp>;
  _or?: InputMaybe<Array<Screener_Check_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  detectedChanges?: InputMaybe<Json_Comparison_Exp>;
  documentPath?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  previousValue?: InputMaybe<Json_Comparison_Exp>;
  previusValue?: InputMaybe<Json_Comparison_Exp>;
  public?: InputMaybe<Boolean_Comparison_Exp>;
  response?: InputMaybe<Json_Comparison_Exp>;
  screener?: InputMaybe<Screeners_Bool_Exp>;
  screenerId?: InputMaybe<Uuid_Comparison_Exp>;
  userAck?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "screener_check" */
export enum Screener_Check_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScreenerCheckPkey = 'screener_check_pkey'
}

/** input type for incrementing numeric columns in table "screener_check" */
export type Screener_Check_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "screener_check" */
export type Screener_Check_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectedChanges?: InputMaybe<Scalars['json']>;
  documentPath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  previousValue?: InputMaybe<Scalars['json']>;
  previusValue?: InputMaybe<Scalars['json']>;
  public?: InputMaybe<Scalars['Boolean']>;
  response?: InputMaybe<Scalars['json']>;
  screener?: InputMaybe<Screeners_Obj_Rel_Insert_Input>;
  screenerId?: InputMaybe<Scalars['uuid']>;
  userAck?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Screener_Check_Max_Fields = {
  __typename?: 'screener_check_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  screenerId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "screener_check" */
export type Screener_Check_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  documentPath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  screenerId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Screener_Check_Min_Fields = {
  __typename?: 'screener_check_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  documentPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  screenerId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "screener_check" */
export type Screener_Check_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  documentPath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  screenerId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "screener_check" */
export type Screener_Check_Mutation_Response = {
  __typename?: 'screener_check_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screener_Check>;
};

/** on_conflict condition type for table "screener_check" */
export type Screener_Check_On_Conflict = {
  constraint: Screener_Check_Constraint;
  update_columns?: Array<Screener_Check_Update_Column>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "screener_check". */
export type Screener_Check_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  detectedChanges?: InputMaybe<Order_By>;
  documentPath?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  previousValue?: InputMaybe<Order_By>;
  previusValue?: InputMaybe<Order_By>;
  public?: InputMaybe<Order_By>;
  response?: InputMaybe<Order_By>;
  screener?: InputMaybe<Screeners_Order_By>;
  screenerId?: InputMaybe<Order_By>;
  userAck?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screener_check */
export type Screener_Check_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "screener_check" */
export enum Screener_Check_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DetectedChanges = 'detectedChanges',
  /** column name */
  DocumentPath = 'documentPath',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  PreviusValue = 'previusValue',
  /** column name */
  Public = 'public',
  /** column name */
  Response = 'response',
  /** column name */
  ScreenerId = 'screenerId',
  /** column name */
  UserAck = 'userAck'
}

/** select "screener_check_aggregate_bool_exp_bool_and_arguments_columns" columns of table "screener_check" */
export enum Screener_Check_Select_Column_Screener_Check_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Public = 'public',
  /** column name */
  UserAck = 'userAck'
}

/** select "screener_check_aggregate_bool_exp_bool_or_arguments_columns" columns of table "screener_check" */
export enum Screener_Check_Select_Column_Screener_Check_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Public = 'public',
  /** column name */
  UserAck = 'userAck'
}

/** input type for updating data in table "screener_check" */
export type Screener_Check_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectedChanges?: InputMaybe<Scalars['json']>;
  documentPath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  previousValue?: InputMaybe<Scalars['json']>;
  previusValue?: InputMaybe<Scalars['json']>;
  public?: InputMaybe<Scalars['Boolean']>;
  response?: InputMaybe<Scalars['json']>;
  screenerId?: InputMaybe<Scalars['uuid']>;
  userAck?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Screener_Check_Stddev_Fields = {
  __typename?: 'screener_check_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "screener_check" */
export type Screener_Check_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Screener_Check_Stddev_Pop_Fields = {
  __typename?: 'screener_check_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "screener_check" */
export type Screener_Check_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Screener_Check_Stddev_Samp_Fields = {
  __typename?: 'screener_check_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "screener_check" */
export type Screener_Check_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "screener_check" */
export type Screener_Check_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screener_Check_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screener_Check_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectedChanges?: InputMaybe<Scalars['json']>;
  documentPath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  previousValue?: InputMaybe<Scalars['json']>;
  previusValue?: InputMaybe<Scalars['json']>;
  public?: InputMaybe<Scalars['Boolean']>;
  response?: InputMaybe<Scalars['json']>;
  screenerId?: InputMaybe<Scalars['uuid']>;
  userAck?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Screener_Check_Sum_Fields = {
  __typename?: 'screener_check_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "screener_check" */
export type Screener_Check_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "screener_check" */
export enum Screener_Check_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DetectedChanges = 'detectedChanges',
  /** column name */
  DocumentPath = 'documentPath',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousValue = 'previousValue',
  /** column name */
  PreviusValue = 'previusValue',
  /** column name */
  Public = 'public',
  /** column name */
  Response = 'response',
  /** column name */
  ScreenerId = 'screenerId',
  /** column name */
  UserAck = 'userAck'
}

export type Screener_Check_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Screener_Check_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screener_Check_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screener_Check_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Screener_Check_Var_Pop_Fields = {
  __typename?: 'screener_check_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "screener_check" */
export type Screener_Check_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Screener_Check_Var_Samp_Fields = {
  __typename?: 'screener_check_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "screener_check" */
export type Screener_Check_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Screener_Check_Variance_Fields = {
  __typename?: 'screener_check_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "screener_check" */
export type Screener_Check_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "screeners" */
export type Screeners = {
  __typename?: 'screeners';
  active: Scalars['Boolean'];
  /** An array relationship */
  checks: Array<Screener_Check>;
  /** An aggregate relationship */
  checks_aggregate: Screener_Check_Aggregate;
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  configuration: Scalars['json'];
  createdAt: Scalars['timestamptz'];
  createdBy: Scalars['Int'];
  /** An object relationship */
  dossier: Dossier;
  dossierId: Scalars['uuid'];
  expiresOn: Scalars['timestamptz'];
  id: Scalars['uuid'];
  subType?: Maybe<Scalars['bpchar']>;
  type: Scalars['bpchar'];
  /** An object relationship */
  user: User;
  userId: Scalars['Int'];
};


/** columns and relationships of "screeners" */
export type ScreenersChecksArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


/** columns and relationships of "screeners" */
export type ScreenersChecks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


/** columns and relationships of "screeners" */
export type ScreenersConfigurationArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "screeners" */
export type Screeners_Aggregate = {
  __typename?: 'screeners_aggregate';
  aggregate?: Maybe<Screeners_Aggregate_Fields>;
  nodes: Array<Screeners>;
};

export type Screeners_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Screeners_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Screeners_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Screeners_Aggregate_Bool_Exp_Count>;
};

export type Screeners_Aggregate_Bool_Exp_Bool_And = {
  arguments: Screeners_Select_Column_Screeners_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screeners_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screeners_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Screeners_Select_Column_Screeners_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screeners_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Screeners_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Screeners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Screeners_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "screeners" */
export type Screeners_Aggregate_Fields = {
  __typename?: 'screeners_aggregate_fields';
  avg?: Maybe<Screeners_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Screeners_Max_Fields>;
  min?: Maybe<Screeners_Min_Fields>;
  stddev?: Maybe<Screeners_Stddev_Fields>;
  stddev_pop?: Maybe<Screeners_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Screeners_Stddev_Samp_Fields>;
  sum?: Maybe<Screeners_Sum_Fields>;
  var_pop?: Maybe<Screeners_Var_Pop_Fields>;
  var_samp?: Maybe<Screeners_Var_Samp_Fields>;
  variance?: Maybe<Screeners_Variance_Fields>;
};


/** aggregate fields of "screeners" */
export type Screeners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Screeners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "screeners" */
export type Screeners_Aggregate_Order_By = {
  avg?: InputMaybe<Screeners_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Screeners_Max_Order_By>;
  min?: InputMaybe<Screeners_Min_Order_By>;
  stddev?: InputMaybe<Screeners_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Screeners_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Screeners_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Screeners_Sum_Order_By>;
  var_pop?: InputMaybe<Screeners_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Screeners_Var_Samp_Order_By>;
  variance?: InputMaybe<Screeners_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "screeners" */
export type Screeners_Arr_Rel_Insert_Input = {
  data: Array<Screeners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Screeners_On_Conflict>;
};

/** aggregate avg on columns */
export type Screeners_Avg_Fields = {
  __typename?: 'screeners_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "screeners" */
export type Screeners_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "screeners". All fields are combined with a logical 'AND'. */
export type Screeners_Bool_Exp = {
  _and?: InputMaybe<Array<Screeners_Bool_Exp>>;
  _not?: InputMaybe<Screeners_Bool_Exp>;
  _or?: InputMaybe<Array<Screeners_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  checks?: InputMaybe<Screener_Check_Bool_Exp>;
  checks_aggregate?: InputMaybe<Screener_Check_Aggregate_Bool_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  configuration?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdBy?: InputMaybe<Int_Comparison_Exp>;
  dossier?: InputMaybe<Dossier_Bool_Exp>;
  dossierId?: InputMaybe<Uuid_Comparison_Exp>;
  expiresOn?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subType?: InputMaybe<Bpchar_Comparison_Exp>;
  type?: InputMaybe<Bpchar_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "screeners" */
export enum Screeners_Constraint {
  /** unique or primary key constraint on columns "id" */
  ScreenersPkey = 'screeners_pkey'
}

/** input type for incrementing numeric columns in table "screeners" */
export type Screeners_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "screeners" */
export type Screeners_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  checks?: InputMaybe<Screener_Check_Arr_Rel_Insert_Input>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  configuration?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  dossier?: InputMaybe<Dossier_Obj_Rel_Insert_Input>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  expiresOn?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  subType?: InputMaybe<Scalars['bpchar']>;
  type?: InputMaybe<Scalars['bpchar']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Screeners_Max_Fields = {
  __typename?: 'screeners_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['Int']>;
  dossierId?: Maybe<Scalars['uuid']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subType?: Maybe<Scalars['bpchar']>;
  type?: Maybe<Scalars['bpchar']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "screeners" */
export type Screeners_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dossierId?: InputMaybe<Order_By>;
  expiresOn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subType?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Screeners_Min_Fields = {
  __typename?: 'screeners_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  createdBy?: Maybe<Scalars['Int']>;
  dossierId?: Maybe<Scalars['uuid']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  subType?: Maybe<Scalars['bpchar']>;
  type?: Maybe<Scalars['bpchar']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "screeners" */
export type Screeners_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dossierId?: InputMaybe<Order_By>;
  expiresOn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subType?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "screeners" */
export type Screeners_Mutation_Response = {
  __typename?: 'screeners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Screeners>;
};

/** input type for inserting object relation for remote table "screeners" */
export type Screeners_Obj_Rel_Insert_Input = {
  data: Screeners_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Screeners_On_Conflict>;
};

/** on_conflict condition type for table "screeners" */
export type Screeners_On_Conflict = {
  constraint: Screeners_Constraint;
  update_columns?: Array<Screeners_Update_Column>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};

/** Ordering options when selecting data from "screeners". */
export type Screeners_Order_By = {
  active?: InputMaybe<Order_By>;
  checks_aggregate?: InputMaybe<Screener_Check_Aggregate_Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  configuration?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  dossier?: InputMaybe<Dossier_Order_By>;
  dossierId?: InputMaybe<Order_By>;
  expiresOn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subType?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: screeners */
export type Screeners_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "screeners" */
export enum Screeners_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DossierId = 'dossierId',
  /** column name */
  ExpiresOn = 'expiresOn',
  /** column name */
  Id = 'id',
  /** column name */
  SubType = 'subType',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** select "screeners_aggregate_bool_exp_bool_and_arguments_columns" columns of table "screeners" */
export enum Screeners_Select_Column_Screeners_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "screeners_aggregate_bool_exp_bool_or_arguments_columns" columns of table "screeners" */
export enum Screeners_Select_Column_Screeners_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "screeners" */
export type Screeners_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['Int']>;
  configuration?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  expiresOn?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  subType?: InputMaybe<Scalars['bpchar']>;
  type?: InputMaybe<Scalars['bpchar']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Screeners_Stddev_Fields = {
  __typename?: 'screeners_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "screeners" */
export type Screeners_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Screeners_Stddev_Pop_Fields = {
  __typename?: 'screeners_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "screeners" */
export type Screeners_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Screeners_Stddev_Samp_Fields = {
  __typename?: 'screeners_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "screeners" */
export type Screeners_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "screeners" */
export type Screeners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Screeners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Screeners_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  companyId?: InputMaybe<Scalars['Int']>;
  configuration?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  createdBy?: InputMaybe<Scalars['Int']>;
  dossierId?: InputMaybe<Scalars['uuid']>;
  expiresOn?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  subType?: InputMaybe<Scalars['bpchar']>;
  type?: InputMaybe<Scalars['bpchar']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Screeners_Sum_Fields = {
  __typename?: 'screeners_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "screeners" */
export type Screeners_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "screeners" */
export enum Screeners_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Configuration = 'configuration',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreatedBy = 'createdBy',
  /** column name */
  DossierId = 'dossierId',
  /** column name */
  ExpiresOn = 'expiresOn',
  /** column name */
  Id = 'id',
  /** column name */
  SubType = 'subType',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type Screeners_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Screeners_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Screeners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Screeners_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Screeners_Var_Pop_Fields = {
  __typename?: 'screeners_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "screeners" */
export type Screeners_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Screeners_Var_Samp_Fields = {
  __typename?: 'screeners_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "screeners" */
export type Screeners_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Screeners_Variance_Fields = {
  __typename?: 'screeners_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  createdBy?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "screeners" */
export type Screeners_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  createdBy?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "service" */
export type Service = {
  __typename?: 'service';
  /** An array relationship */
  alerts: Array<Alert>;
  /** An aggregate relationship */
  alerts_aggregate: Alert_Aggregate;
  category: Scalars['String'];
  channel: Scalars['String'];
  createdAt: Scalars['timestamp'];
  dataChannel?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  executionPolicy: Scalars['String'];
  fields: Scalars['jsonb'];
  id: Scalars['Int'];
  internalDescription?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastModified: Scalars['timestamp'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['json']>;
  postConfirmButtons?: Maybe<Scalars['json']>;
  priceCalculation: Scalars['json'];
  priceDescription?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  reportTemplate: Scalars['String'];
  /** An array relationship */
  requests: Array<Request>;
  /** An aggregate relationship */
  requests_aggregate: Request_Aggregate;
  revision: Scalars['Int'];
  showReportPreview: Scalars['Boolean'];
  slug: Scalars['String'];
  status: Scalars['String'];
  submitText?: Maybe<Scalars['String']>;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  targets: Scalars['json'];
  /** An array relationship */
  transactions: Array<Ledger>;
  /** An aggregate relationship */
  transactions_aggregate: Ledger_Aggregate;
  /** An array relationship */
  usages: Array<Track_Data_Model>;
  /** An aggregate relationship */
  usages_aggregate: Track_Data_Model_Aggregate;
  /** An object relationship */
  workarea?: Maybe<Workarea>;
  workareaId?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "service" */
export type ServiceAlertsArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceAlerts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceFieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServiceNotesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServicePostConfirmButtonsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServicePriceCalculationArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServiceRequestsArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceTargetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "service" */
export type ServiceTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceUsagesArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


/** columns and relationships of "service" */
export type ServiceUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};

/** aggregated selection of "service" */
export type Service_Aggregate = {
  __typename?: 'service_aggregate';
  aggregate?: Maybe<Service_Aggregate_Fields>;
  nodes: Array<Service>;
};

export type Service_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Service_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Service_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Service_Aggregate_Bool_Exp_Count>;
};

export type Service_Aggregate_Bool_Exp_Bool_And = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service" */
export type Service_Aggregate_Fields = {
  __typename?: 'service_aggregate_fields';
  avg?: Maybe<Service_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Max_Fields>;
  min?: Maybe<Service_Min_Fields>;
  stddev?: Maybe<Service_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Sum_Fields>;
  var_pop?: Maybe<Service_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Var_Samp_Fields>;
  variance?: Maybe<Service_Variance_Fields>;
};


/** aggregate fields of "service" */
export type Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "service" */
export type Service_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Max_Order_By>;
  min?: InputMaybe<Service_Min_Order_By>;
  stddev?: InputMaybe<Service_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Service_Append_Input = {
  fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "service" */
export type Service_Arr_Rel_Insert_Input = {
  data: Array<Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Avg_Fields = {
  __typename?: 'service_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "service" */
export type Service_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service". All fields are combined with a logical 'AND'. */
export type Service_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Bool_Exp>>;
  _not?: InputMaybe<Service_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Bool_Exp>>;
  alerts?: InputMaybe<Alert_Bool_Exp>;
  alerts_aggregate?: InputMaybe<Alert_Aggregate_Bool_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  dataChannel?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  executionPolicy?: InputMaybe<String_Comparison_Exp>;
  fields?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  internalDescription?: InputMaybe<String_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<Json_Comparison_Exp>;
  postConfirmButtons?: InputMaybe<Json_Comparison_Exp>;
  priceCalculation?: InputMaybe<Json_Comparison_Exp>;
  priceDescription?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  reportTemplate?: InputMaybe<String_Comparison_Exp>;
  requests?: InputMaybe<Request_Bool_Exp>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Bool_Exp>;
  revision?: InputMaybe<Int_Comparison_Exp>;
  showReportPreview?: InputMaybe<Boolean_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  submitText?: InputMaybe<String_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscription_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Bool_Exp>;
  targets?: InputMaybe<Json_Comparison_Exp>;
  transactions?: InputMaybe<Ledger_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Bool_Exp>;
  usages?: InputMaybe<Track_Data_Model_Bool_Exp>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp>;
  workarea?: InputMaybe<Workarea_Bool_Exp>;
  workareaId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "service" */
export enum Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_85a21558c006647cd76fdce044b = 'PK_85a21558c006647cd76fdce044b',
  /** unique or primary key constraint on columns "slug" */
  Uq_4df47ef659e04d5be78ddb6b598 = 'UQ_4df47ef659e04d5be78ddb6b598'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Service_Delete_At_Path_Input = {
  fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Service_Delete_Elem_Input = {
  fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Service_Delete_Key_Input = {
  fields?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "service" */
export type Service_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "service" */
export type Service_Insert_Input = {
  alerts?: InputMaybe<Alert_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dataChannel?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  executionPolicy?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  postConfirmButtons?: InputMaybe<Scalars['json']>;
  priceCalculation?: InputMaybe<Scalars['json']>;
  priceDescription?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  reportTemplate?: InputMaybe<Scalars['String']>;
  requests?: InputMaybe<Request_Arr_Rel_Insert_Input>;
  revision?: InputMaybe<Scalars['Int']>;
  showReportPreview?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  submitText?: InputMaybe<Scalars['String']>;
  subscriptions?: InputMaybe<Subscription_Arr_Rel_Insert_Input>;
  targets?: InputMaybe<Scalars['json']>;
  transactions?: InputMaybe<Ledger_Arr_Rel_Insert_Input>;
  usages?: InputMaybe<Track_Data_Model_Arr_Rel_Insert_Input>;
  workarea?: InputMaybe<Workarea_Obj_Rel_Insert_Input>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Service_Max_Fields = {
  __typename?: 'service_max_fields';
  category?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dataChannel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  executionPolicy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  internalDescription?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  priceDescription?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  reportTemplate?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submitText?: Maybe<Scalars['String']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "service" */
export type Service_Max_Order_By = {
  category?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataChannel?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executionPolicy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalDescription?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priceDescription?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  reportTemplate?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submitText?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Min_Fields = {
  __typename?: 'service_min_fields';
  category?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  dataChannel?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  executionPolicy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  internalDescription?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  priceDescription?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  reportTemplate?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submitText?: Maybe<Scalars['String']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "service" */
export type Service_Min_Order_By = {
  category?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataChannel?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executionPolicy?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalDescription?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priceDescription?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  reportTemplate?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submitText?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service" */
export type Service_Mutation_Response = {
  __typename?: 'service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service>;
};

/** input type for inserting object relation for remote table "service" */
export type Service_Obj_Rel_Insert_Input = {
  data: Service_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_On_Conflict>;
};

/** on_conflict condition type for table "service" */
export type Service_On_Conflict = {
  constraint: Service_Constraint;
  update_columns?: Array<Service_Update_Column>;
  where?: InputMaybe<Service_Bool_Exp>;
};

/** Ordering options when selecting data from "service". */
export type Service_Order_By = {
  alerts_aggregate?: InputMaybe<Alert_Aggregate_Order_By>;
  category?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  dataChannel?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  executionPolicy?: InputMaybe<Order_By>;
  fields?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internalDescription?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  postConfirmButtons?: InputMaybe<Order_By>;
  priceCalculation?: InputMaybe<Order_By>;
  priceDescription?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  reportTemplate?: InputMaybe<Order_By>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Order_By>;
  revision?: InputMaybe<Order_By>;
  showReportPreview?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  submitText?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Order_By>;
  targets?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Order_By>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Order_By>;
  workarea?: InputMaybe<Workarea_Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service */
export type Service_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Service_Prepend_Input = {
  fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "service" */
export enum Service_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataChannel = 'dataChannel',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionPolicy = 'executionPolicy',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  InternalDescription = 'internalDescription',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PostConfirmButtons = 'postConfirmButtons',
  /** column name */
  PriceCalculation = 'priceCalculation',
  /** column name */
  PriceDescription = 'priceDescription',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReportTemplate = 'reportTemplate',
  /** column name */
  Revision = 'revision',
  /** column name */
  ShowReportPreview = 'showReportPreview',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitText = 'submitText',
  /** column name */
  Targets = 'targets',
  /** column name */
  WorkareaId = 'workareaId'
}

/** select "service_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  ShowReportPreview = 'showReportPreview'
}

/** select "service_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service" */
export enum Service_Select_Column_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  ShowReportPreview = 'showReportPreview'
}

/** input type for updating data in table "service" */
export type Service_Set_Input = {
  category?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dataChannel?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  executionPolicy?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  postConfirmButtons?: InputMaybe<Scalars['json']>;
  priceCalculation?: InputMaybe<Scalars['json']>;
  priceDescription?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  reportTemplate?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  showReportPreview?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  submitText?: InputMaybe<Scalars['String']>;
  targets?: InputMaybe<Scalars['json']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Service_Stddev_Fields = {
  __typename?: 'service_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "service" */
export type Service_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Stddev_Pop_Fields = {
  __typename?: 'service_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "service" */
export type Service_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Stddev_Samp_Fields = {
  __typename?: 'service_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "service" */
export type Service_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service" */
export type Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  dataChannel?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  executionPolicy?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['Int']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['json']>;
  postConfirmButtons?: InputMaybe<Scalars['json']>;
  priceCalculation?: InputMaybe<Scalars['json']>;
  priceDescription?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  reportTemplate?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  showReportPreview?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  submitText?: InputMaybe<Scalars['String']>;
  targets?: InputMaybe<Scalars['json']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Service_Sum_Fields = {
  __typename?: 'service_sum_fields';
  id?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "service" */
export type Service_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_track_by_company_and_day" */
export type Service_Track_By_Company_And_Day = {
  __typename?: 'service_track_by_company_and_day';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "service_track_by_company_and_day" */
export type Service_Track_By_Company_And_Day_Aggregate = {
  __typename?: 'service_track_by_company_and_day_aggregate';
  aggregate?: Maybe<Service_Track_By_Company_And_Day_Aggregate_Fields>;
  nodes: Array<Service_Track_By_Company_And_Day>;
};

/** aggregate fields of "service_track_by_company_and_day" */
export type Service_Track_By_Company_And_Day_Aggregate_Fields = {
  __typename?: 'service_track_by_company_and_day_aggregate_fields';
  avg?: Maybe<Service_Track_By_Company_And_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Track_By_Company_And_Day_Max_Fields>;
  min?: Maybe<Service_Track_By_Company_And_Day_Min_Fields>;
  stddev?: Maybe<Service_Track_By_Company_And_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Track_By_Company_And_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Track_By_Company_And_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Track_By_Company_And_Day_Sum_Fields>;
  var_pop?: Maybe<Service_Track_By_Company_And_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Track_By_Company_And_Day_Var_Samp_Fields>;
  variance?: Maybe<Service_Track_By_Company_And_Day_Variance_Fields>;
};


/** aggregate fields of "service_track_by_company_and_day" */
export type Service_Track_By_Company_And_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Track_By_Company_And_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Track_By_Company_And_Day_Avg_Fields = {
  __typename?: 'service_track_by_company_and_day_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_track_by_company_and_day". All fields are combined with a logical 'AND'. */
export type Service_Track_By_Company_And_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Track_By_Company_And_Day_Bool_Exp>>;
  _not?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Track_By_Company_And_Day_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  queryChannel?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Service_Track_By_Company_And_Day_Max_Fields = {
  __typename?: 'service_track_by_company_and_day_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Service_Track_By_Company_And_Day_Min_Fields = {
  __typename?: 'service_track_by_company_and_day_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "service_track_by_company_and_day". */
export type Service_Track_By_Company_And_Day_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  queryChannel?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "service_track_by_company_and_day" */
export enum Service_Track_By_Company_And_Day_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  QueryChannel = 'queryChannel',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Service_Track_By_Company_And_Day_Stddev_Fields = {
  __typename?: 'service_track_by_company_and_day_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Track_By_Company_And_Day_Stddev_Pop_Fields = {
  __typename?: 'service_track_by_company_and_day_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Track_By_Company_And_Day_Stddev_Samp_Fields = {
  __typename?: 'service_track_by_company_and_day_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_track_by_company_and_day" */
export type Service_Track_By_Company_And_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Track_By_Company_And_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Track_By_Company_And_Day_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['timestamp']>;
  queryChannel?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Service_Track_By_Company_And_Day_Sum_Fields = {
  __typename?: 'service_track_by_company_and_day_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  serviceId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Service_Track_By_Company_And_Day_Var_Pop_Fields = {
  __typename?: 'service_track_by_company_and_day_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Track_By_Company_And_Day_Var_Samp_Fields = {
  __typename?: 'service_track_by_company_and_day_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Track_By_Company_And_Day_Variance_Fields = {
  __typename?: 'service_track_by_company_and_day_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "service_track_by_day" */
export type Service_Track_By_Day = {
  __typename?: 'service_track_by_day';
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "service_track_by_day" */
export type Service_Track_By_Day_Aggregate = {
  __typename?: 'service_track_by_day_aggregate';
  aggregate?: Maybe<Service_Track_By_Day_Aggregate_Fields>;
  nodes: Array<Service_Track_By_Day>;
};

/** aggregate fields of "service_track_by_day" */
export type Service_Track_By_Day_Aggregate_Fields = {
  __typename?: 'service_track_by_day_aggregate_fields';
  avg?: Maybe<Service_Track_By_Day_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Track_By_Day_Max_Fields>;
  min?: Maybe<Service_Track_By_Day_Min_Fields>;
  stddev?: Maybe<Service_Track_By_Day_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Track_By_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Track_By_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Track_By_Day_Sum_Fields>;
  var_pop?: Maybe<Service_Track_By_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Track_By_Day_Var_Samp_Fields>;
  variance?: Maybe<Service_Track_By_Day_Variance_Fields>;
};


/** aggregate fields of "service_track_by_day" */
export type Service_Track_By_Day_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Track_By_Day_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Track_By_Day_Avg_Fields = {
  __typename?: 'service_track_by_day_avg_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_track_by_day". All fields are combined with a logical 'AND'. */
export type Service_Track_By_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Track_By_Day_Bool_Exp>>;
  _not?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Track_By_Day_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Timestamp_Comparison_Exp>;
  queryChannel?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Service_Track_By_Day_Max_Fields = {
  __typename?: 'service_track_by_day_max_fields';
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Service_Track_By_Day_Min_Fields = {
  __typename?: 'service_track_by_day_min_fields';
  count?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['timestamp']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "service_track_by_day". */
export type Service_Track_By_Day_Order_By = {
  count?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  queryChannel?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "service_track_by_day" */
export enum Service_Track_By_Day_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  QueryChannel = 'queryChannel',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Service_Track_By_Day_Stddev_Fields = {
  __typename?: 'service_track_by_day_stddev_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Track_By_Day_Stddev_Pop_Fields = {
  __typename?: 'service_track_by_day_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Track_By_Day_Stddev_Samp_Fields = {
  __typename?: 'service_track_by_day_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_track_by_day" */
export type Service_Track_By_Day_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Track_By_Day_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Track_By_Day_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['timestamp']>;
  queryChannel?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Service_Track_By_Day_Sum_Fields = {
  __typename?: 'service_track_by_day_sum_fields';
  count?: Maybe<Scalars['bigint']>;
  serviceId?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Service_Track_By_Day_Var_Pop_Fields = {
  __typename?: 'service_track_by_day_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Track_By_Day_Var_Samp_Fields = {
  __typename?: 'service_track_by_day_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Track_By_Day_Variance_Fields = {
  __typename?: 'service_track_by_day_variance_fields';
  count?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** update columns of table "service" */
export enum Service_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DataChannel = 'dataChannel',
  /** column name */
  Description = 'description',
  /** column name */
  ExecutionPolicy = 'executionPolicy',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  InternalDescription = 'internalDescription',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  PostConfirmButtons = 'postConfirmButtons',
  /** column name */
  PriceCalculation = 'priceCalculation',
  /** column name */
  PriceDescription = 'priceDescription',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReportTemplate = 'reportTemplate',
  /** column name */
  Revision = 'revision',
  /** column name */
  ShowReportPreview = 'showReportPreview',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  SubmitText = 'submitText',
  /** column name */
  Targets = 'targets',
  /** column name */
  WorkareaId = 'workareaId'
}

export type Service_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Service_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Service_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Service_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Service_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Service_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Bool_Exp;
};

/** columns and relationships of "service_usage_by_company" */
export type Service_Usage_By_Company = {
  __typename?: 'service_usage_by_company';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "service_usage_by_company" */
export type Service_Usage_By_Company_Aggregate = {
  __typename?: 'service_usage_by_company_aggregate';
  aggregate?: Maybe<Service_Usage_By_Company_Aggregate_Fields>;
  nodes: Array<Service_Usage_By_Company>;
};

/** aggregate fields of "service_usage_by_company" */
export type Service_Usage_By_Company_Aggregate_Fields = {
  __typename?: 'service_usage_by_company_aggregate_fields';
  avg?: Maybe<Service_Usage_By_Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Usage_By_Company_Max_Fields>;
  min?: Maybe<Service_Usage_By_Company_Min_Fields>;
  stddev?: Maybe<Service_Usage_By_Company_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Usage_By_Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Usage_By_Company_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Usage_By_Company_Sum_Fields>;
  var_pop?: Maybe<Service_Usage_By_Company_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Usage_By_Company_Var_Samp_Fields>;
  variance?: Maybe<Service_Usage_By_Company_Variance_Fields>;
};


/** aggregate fields of "service_usage_by_company" */
export type Service_Usage_By_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Usage_By_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Usage_By_Company_Avg_Fields = {
  __typename?: 'service_usage_by_company_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_usage_by_company". All fields are combined with a logical 'AND'. */
export type Service_Usage_By_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Usage_By_Company_Bool_Exp>>;
  _not?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Usage_By_Company_Bool_Exp>>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  limit_type?: InputMaybe<String_Comparison_Exp>;
  limit_value?: InputMaybe<Int_Comparison_Exp>;
  month?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Service_Usage_By_Company_Max_Fields = {
  __typename?: 'service_usage_by_company_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Service_Usage_By_Company_Min_Fields = {
  __typename?: 'service_usage_by_company_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "service_usage_by_company". */
export type Service_Usage_By_Company_Order_By = {
  companyId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "service_usage_by_company" */
export enum Service_Usage_By_Company_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Month = 'month',
  /** column name */
  Name = 'name',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Service_Usage_By_Company_Stddev_Fields = {
  __typename?: 'service_usage_by_company_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Usage_By_Company_Stddev_Pop_Fields = {
  __typename?: 'service_usage_by_company_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Usage_By_Company_Stddev_Samp_Fields = {
  __typename?: 'service_usage_by_company_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_usage_by_company" */
export type Service_Usage_By_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Usage_By_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Usage_By_Company_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Service_Usage_By_Company_Sum_Fields = {
  __typename?: 'service_usage_by_company_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Service_Usage_By_Company_Var_Pop_Fields = {
  __typename?: 'service_usage_by_company_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Usage_By_Company_Var_Samp_Fields = {
  __typename?: 'service_usage_by_company_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Usage_By_Company_Variance_Fields = {
  __typename?: 'service_usage_by_company_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "service_usage_by_user" */
export type Service_Usage_By_User = {
  __typename?: 'service_usage_by_user';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "service_usage_by_user" */
export type Service_Usage_By_User_Aggregate = {
  __typename?: 'service_usage_by_user_aggregate';
  aggregate?: Maybe<Service_Usage_By_User_Aggregate_Fields>;
  nodes: Array<Service_Usage_By_User>;
};

/** aggregate fields of "service_usage_by_user" */
export type Service_Usage_By_User_Aggregate_Fields = {
  __typename?: 'service_usage_by_user_aggregate_fields';
  avg?: Maybe<Service_Usage_By_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Usage_By_User_Max_Fields>;
  min?: Maybe<Service_Usage_By_User_Min_Fields>;
  stddev?: Maybe<Service_Usage_By_User_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Usage_By_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Usage_By_User_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Usage_By_User_Sum_Fields>;
  var_pop?: Maybe<Service_Usage_By_User_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Usage_By_User_Var_Samp_Fields>;
  variance?: Maybe<Service_Usage_By_User_Variance_Fields>;
};


/** aggregate fields of "service_usage_by_user" */
export type Service_Usage_By_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Usage_By_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Usage_By_User_Avg_Fields = {
  __typename?: 'service_usage_by_user_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_usage_by_user". All fields are combined with a logical 'AND'. */
export type Service_Usage_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Usage_By_User_Bool_Exp>>;
  _not?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Usage_By_User_Bool_Exp>>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  limit_type?: InputMaybe<String_Comparison_Exp>;
  limit_value?: InputMaybe<Int_Comparison_Exp>;
  month?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  year?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Service_Usage_By_User_Max_Fields = {
  __typename?: 'service_usage_by_user_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type Service_Usage_By_User_Min_Fields = {
  __typename?: 'service_usage_by_user_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** Ordering options when selecting data from "service_usage_by_user". */
export type Service_Usage_By_User_Order_By = {
  companyId?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** select columns of table "service_usage_by_user" */
export enum Service_Usage_By_User_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Count = 'count',
  /** column name */
  Id = 'id',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Month = 'month',
  /** column name */
  Name = 'name',
  /** column name */
  UserId = 'userId',
  /** column name */
  Year = 'year'
}

/** aggregate stddev on columns */
export type Service_Usage_By_User_Stddev_Fields = {
  __typename?: 'service_usage_by_user_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Usage_By_User_Stddev_Pop_Fields = {
  __typename?: 'service_usage_by_user_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Usage_By_User_Stddev_Samp_Fields = {
  __typename?: 'service_usage_by_user_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_usage_by_user" */
export type Service_Usage_By_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Usage_By_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Usage_By_User_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  count?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['Int']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Service_Usage_By_User_Sum_Fields = {
  __typename?: 'service_usage_by_user_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  limit_value?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['numeric']>;
};

/** aggregate var_pop on columns */
export type Service_Usage_By_User_Var_Pop_Fields = {
  __typename?: 'service_usage_by_user_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Usage_By_User_Var_Samp_Fields = {
  __typename?: 'service_usage_by_user_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Usage_By_User_Variance_Fields = {
  __typename?: 'service_usage_by_user_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Service_Var_Pop_Fields = {
  __typename?: 'service_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "service" */
export type Service_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Var_Samp_Fields = {
  __typename?: 'service_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "service" */
export type Service_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Variance_Fields = {
  __typename?: 'service_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "service" */
export type Service_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription" */
export type Subscription = {
  __typename?: 'subscription';
  /** An object relationship */
  activation?: Maybe<Workarea_Activation>;
  activationId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  bundle?: Maybe<Workarea_Bundle>;
  bundleId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  /** An array relationship */
  coupons: Array<Subscription_Coupon>;
  /** An aggregate relationship */
  coupons_aggregate: Subscription_Coupon_Aggregate;
  createdAt: Scalars['timestamp'];
  endDate: Scalars['timestamp'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isOverridingBundleConfig: Scalars['Boolean'];
  lastModified: Scalars['timestamp'];
  limit_type: Scalars['String'];
  limit_value: Scalars['Int'];
  price: Scalars['float8'];
  priceOnFail: Scalars['float8'];
  /** An array relationship */
  requests: Array<Request>;
  /** An aggregate relationship */
  requests_aggregate: Request_Aggregate;
  /** An object relationship */
  service: Service;
  serviceId: Scalars['Int'];
  startDate: Scalars['timestamp'];
  /** An array relationship */
  transactions: Array<Ledger>;
  /** An aggregate relationship */
  transactions_aggregate: Ledger_Aggregate;
  /** An array relationship */
  usages: Array<Track_Data_Model>;
  /** An aggregate relationship */
  usages_aggregate: Track_Data_Model_Aggregate;
};


/** columns and relationships of "subscription" */
export type SubscriptionCouponsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionCoupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionRequestsArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionUsagesArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


/** columns and relationships of "subscription" */
export type SubscriptionUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};

/** aggregated selection of "subscription" */
export type Subscription_Aggregate = {
  __typename?: 'subscription_aggregate';
  aggregate?: Maybe<Subscription_Aggregate_Fields>;
  nodes: Array<Subscription>;
};

export type Subscription_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Subscription_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Subscription_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Subscription_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Subscription_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Subscription_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Subscription_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Subscription_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Subscription_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Subscription_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Subscription_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Subscription_Aggregate_Bool_Exp_Var_Samp>;
};

export type Subscription_Aggregate_Bool_Exp_Avg = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Bool_And = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Corr = {
  arguments: Subscription_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Subscription_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Subscription_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Subscription_Aggregate_Bool_Exp_Max = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Min = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Sum = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Subscription_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "subscription" */
export type Subscription_Aggregate_Fields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<Subscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_Max_Fields>;
  min?: Maybe<Subscription_Min_Fields>;
  stddev?: Maybe<Subscription_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Sum_Fields>;
  var_pop?: Maybe<Subscription_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Variance_Fields>;
};


/** aggregate fields of "subscription" */
export type Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription" */
export type Subscription_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Max_Order_By>;
  min?: InputMaybe<Subscription_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscription" */
export type Subscription_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Avg_Fields = {
  __typename?: 'subscription_avg_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subscription" */
export type Subscription_Avg_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription". All fields are combined with a logical 'AND'. */
export type Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Bool_Exp>>;
  activation?: InputMaybe<Workarea_Activation_Bool_Exp>;
  activationId?: InputMaybe<Int_Comparison_Exp>;
  bundle?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  bundleId?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  coupons?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  coupons_aggregate?: InputMaybe<Subscription_Coupon_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  endDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isDeleted?: InputMaybe<Boolean_Comparison_Exp>;
  isOverridingBundleConfig?: InputMaybe<Boolean_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  limit_type?: InputMaybe<String_Comparison_Exp>;
  limit_value?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  priceOnFail?: InputMaybe<Float8_Comparison_Exp>;
  requests?: InputMaybe<Request_Bool_Exp>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Bool_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<Timestamp_Comparison_Exp>;
  transactions?: InputMaybe<Ledger_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Bool_Exp>;
  usages?: InputMaybe<Track_Data_Model_Bool_Exp>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "subscription" */
export enum Subscription_Constraint {
  /** unique or primary key constraint on columns "serviceId", "companyId" */
  IdxA7c07e5a47eaa8c0ab474070dd = 'IDX_a7c07e5a47eaa8c0ab474070dd',
  /** unique or primary key constraint on columns "id" */
  Pk_8c3e00ebd02103caa1174cd5d9d = 'PK_8c3e00ebd02103caa1174cd5d9d'
}

/** columns and relationships of "subscription_coupon" */
export type Subscription_Coupon = {
  __typename?: 'subscription_coupon';
  createdAt: Scalars['timestamp'];
  discountAmount?: Maybe<Scalars['numeric']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  name: Scalars['String'];
  /** An array relationship */
  requests: Array<Request>;
  /** An aggregate relationship */
  requests_aggregate: Request_Aggregate;
  /** An object relationship */
  subscription: Subscription;
  subscriptionId: Scalars['Int'];
  usageLimit: Scalars['Int'];
  /** An array relationship */
  usages: Array<Track_Data_Model>;
  /** An aggregate relationship */
  usages_aggregate: Track_Data_Model_Aggregate;
  validFrom: Scalars['timestamp'];
  validUntil: Scalars['timestamp'];
};


/** columns and relationships of "subscription_coupon" */
export type Subscription_CouponRequestsArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "subscription_coupon" */
export type Subscription_CouponRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "subscription_coupon" */
export type Subscription_CouponUsagesArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


/** columns and relationships of "subscription_coupon" */
export type Subscription_CouponUsages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};

/** aggregated selection of "subscription_coupon" */
export type Subscription_Coupon_Aggregate = {
  __typename?: 'subscription_coupon_aggregate';
  aggregate?: Maybe<Subscription_Coupon_Aggregate_Fields>;
  nodes: Array<Subscription_Coupon>;
};

export type Subscription_Coupon_Aggregate_Bool_Exp = {
  count?: InputMaybe<Subscription_Coupon_Aggregate_Bool_Exp_Count>;
};

export type Subscription_Coupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "subscription_coupon" */
export type Subscription_Coupon_Aggregate_Fields = {
  __typename?: 'subscription_coupon_aggregate_fields';
  avg?: Maybe<Subscription_Coupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_Coupon_Max_Fields>;
  min?: Maybe<Subscription_Coupon_Min_Fields>;
  stddev?: Maybe<Subscription_Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_Coupon_Sum_Fields>;
  var_pop?: Maybe<Subscription_Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_Coupon_Var_Samp_Fields>;
  variance?: Maybe<Subscription_Coupon_Variance_Fields>;
};


/** aggregate fields of "subscription_coupon" */
export type Subscription_Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "subscription_coupon" */
export type Subscription_Coupon_Aggregate_Order_By = {
  avg?: InputMaybe<Subscription_Coupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Subscription_Coupon_Max_Order_By>;
  min?: InputMaybe<Subscription_Coupon_Min_Order_By>;
  stddev?: InputMaybe<Subscription_Coupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Subscription_Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Subscription_Coupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Subscription_Coupon_Sum_Order_By>;
  var_pop?: InputMaybe<Subscription_Coupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Subscription_Coupon_Var_Samp_Order_By>;
  variance?: InputMaybe<Subscription_Coupon_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "subscription_coupon" */
export type Subscription_Coupon_Arr_Rel_Insert_Input = {
  data: Array<Subscription_Coupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Coupon_On_Conflict>;
};

/** aggregate avg on columns */
export type Subscription_Coupon_Avg_Fields = {
  __typename?: 'subscription_coupon_avg_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Avg_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "subscription_coupon". All fields are combined with a logical 'AND'. */
export type Subscription_Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_Coupon_Bool_Exp>>;
  _not?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_Coupon_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  discountAmount?: InputMaybe<Numeric_Comparison_Exp>;
  discountPercentage?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  requests?: InputMaybe<Request_Bool_Exp>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Bool_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  usageLimit?: InputMaybe<Int_Comparison_Exp>;
  usages?: InputMaybe<Track_Data_Model_Bool_Exp>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp>;
  validFrom?: InputMaybe<Timestamp_Comparison_Exp>;
  validUntil?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "subscription_coupon" */
export enum Subscription_Coupon_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_9d205b7b3b05e92236796d6c5b9 = 'PK_9d205b7b3b05e92236796d6c5b9'
}

/** input type for incrementing numeric columns in table "subscription_coupon" */
export type Subscription_Coupon_Inc_Input = {
  discountAmount?: InputMaybe<Scalars['numeric']>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  usageLimit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subscription_coupon" */
export type Subscription_Coupon_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  discountAmount?: InputMaybe<Scalars['numeric']>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  requests?: InputMaybe<Request_Arr_Rel_Insert_Input>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  usageLimit?: InputMaybe<Scalars['Int']>;
  usages?: InputMaybe<Track_Data_Model_Arr_Rel_Insert_Input>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Subscription_Coupon_Max_Fields = {
  __typename?: 'subscription_coupon_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  discountAmount?: Maybe<Scalars['numeric']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  usageLimit?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Coupon_Min_Fields = {
  __typename?: 'subscription_coupon_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  discountAmount?: Maybe<Scalars['numeric']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  usageLimit?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription_coupon" */
export type Subscription_Coupon_Mutation_Response = {
  __typename?: 'subscription_coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_Coupon>;
};

/** input type for inserting object relation for remote table "subscription_coupon" */
export type Subscription_Coupon_Obj_Rel_Insert_Input = {
  data: Subscription_Coupon_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_Coupon_On_Conflict>;
};

/** on_conflict condition type for table "subscription_coupon" */
export type Subscription_Coupon_On_Conflict = {
  constraint: Subscription_Coupon_Constraint;
  update_columns?: Array<Subscription_Coupon_Update_Column>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_coupon". */
export type Subscription_Coupon_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
};

/** primary key columns input for table: subscription_coupon */
export type Subscription_Coupon_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "subscription_coupon" */
export enum Subscription_Coupon_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountPercentage = 'discountPercentage',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UsageLimit = 'usageLimit',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil'
}

/** input type for updating data in table "subscription_coupon" */
export type Subscription_Coupon_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  discountAmount?: InputMaybe<Scalars['numeric']>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  usageLimit?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Subscription_Coupon_Stddev_Fields = {
  __typename?: 'subscription_coupon_stddev_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Stddev_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Coupon_Stddev_Pop_Fields = {
  __typename?: 'subscription_coupon_stddev_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Stddev_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Coupon_Stddev_Samp_Fields = {
  __typename?: 'subscription_coupon_stddev_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Stddev_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription_coupon" */
export type Subscription_Coupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Coupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Coupon_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  discountAmount?: InputMaybe<Scalars['numeric']>;
  discountPercentage?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  usageLimit?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Subscription_Coupon_Sum_Fields = {
  __typename?: 'subscription_coupon_sum_fields';
  discountAmount?: Maybe<Scalars['numeric']>;
  discountPercentage?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  usageLimit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Sum_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** update columns of table "subscription_coupon" */
export enum Subscription_Coupon_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DiscountAmount = 'discountAmount',
  /** column name */
  DiscountPercentage = 'discountPercentage',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  UsageLimit = 'usageLimit',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil'
}

export type Subscription_Coupon_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Coupon_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Coupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Coupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Coupon_Var_Pop_Fields = {
  __typename?: 'subscription_coupon_var_pop_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Var_Pop_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Coupon_Var_Samp_Fields = {
  __typename?: 'subscription_coupon_var_samp_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Var_Samp_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Coupon_Variance_Fields = {
  __typename?: 'subscription_coupon_variance_fields';
  discountAmount?: Maybe<Scalars['Float']>;
  discountPercentage?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  usageLimit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subscription_coupon" */
export type Subscription_Coupon_Variance_Order_By = {
  discountAmount?: InputMaybe<Order_By>;
  discountPercentage?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  usageLimit?: InputMaybe<Order_By>;
};

/** columns and relationships of "subscription_history" */
export type Subscription_History = {
  __typename?: 'subscription_history';
  activationId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  bundle?: Maybe<Workarea_Bundle>;
  bundleId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: Maybe<Scalars['Boolean']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  subscription: Subscription;
  subscriptionId: Scalars['Int'];
  validFrom: Scalars['timestamp'];
  validUntil?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  workarea_activation?: Maybe<Workarea_Activation>;
};

/** aggregated selection of "subscription_history" */
export type Subscription_History_Aggregate = {
  __typename?: 'subscription_history_aggregate';
  aggregate?: Maybe<Subscription_History_Aggregate_Fields>;
  nodes: Array<Subscription_History>;
};

/** aggregate fields of "subscription_history" */
export type Subscription_History_Aggregate_Fields = {
  __typename?: 'subscription_history_aggregate_fields';
  avg?: Maybe<Subscription_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Subscription_History_Max_Fields>;
  min?: Maybe<Subscription_History_Min_Fields>;
  stddev?: Maybe<Subscription_History_Stddev_Fields>;
  stddev_pop?: Maybe<Subscription_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Subscription_History_Stddev_Samp_Fields>;
  sum?: Maybe<Subscription_History_Sum_Fields>;
  var_pop?: Maybe<Subscription_History_Var_Pop_Fields>;
  var_samp?: Maybe<Subscription_History_Var_Samp_Fields>;
  variance?: Maybe<Subscription_History_Variance_Fields>;
};


/** aggregate fields of "subscription_history" */
export type Subscription_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Subscription_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Subscription_History_Avg_Fields = {
  __typename?: 'subscription_history_avg_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "subscription_history". All fields are combined with a logical 'AND'. */
export type Subscription_History_Bool_Exp = {
  _and?: InputMaybe<Array<Subscription_History_Bool_Exp>>;
  _not?: InputMaybe<Subscription_History_Bool_Exp>;
  _or?: InputMaybe<Array<Subscription_History_Bool_Exp>>;
  activationId?: InputMaybe<Int_Comparison_Exp>;
  bundle?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  bundleId?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  endDate?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  isOverridingBundleConfig?: InputMaybe<Boolean_Comparison_Exp>;
  limit_type?: InputMaybe<String_Comparison_Exp>;
  limit_value?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  priceOnFail?: InputMaybe<Float8_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<Timestamp_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  validFrom?: InputMaybe<Timestamp_Comparison_Exp>;
  validUntil?: InputMaybe<Timestamp_Comparison_Exp>;
  workarea_activation?: InputMaybe<Workarea_Activation_Bool_Exp>;
};

/** unique or primary key constraints on table "subscription_history" */
export enum Subscription_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubscriptionHistoryPkey = 'subscription_history_pkey',
  /** unique or primary key constraint on columns "subscriptionId", "validUntil" */
  SubscriptionHistorySubscriptionIdValidUntilKey = 'subscription_history_subscriptionId_validUntil_key'
}

/** input type for incrementing numeric columns in table "subscription_history" */
export type Subscription_History_Inc_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subscription_history" */
export type Subscription_History_Insert_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundle?: InputMaybe<Workarea_Bundle_Obj_Rel_Insert_Input>;
  bundleId?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
  workarea_activation?: InputMaybe<Workarea_Activation_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Subscription_History_Max_Fields = {
  __typename?: 'subscription_history_max_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamp']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Subscription_History_Min_Fields = {
  __typename?: 'subscription_history_min_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamp']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "subscription_history" */
export type Subscription_History_Mutation_Response = {
  __typename?: 'subscription_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription_History>;
};

/** on_conflict condition type for table "subscription_history" */
export type Subscription_History_On_Conflict = {
  constraint: Subscription_History_Constraint;
  update_columns?: Array<Subscription_History_Update_Column>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription_history". */
export type Subscription_History_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundle?: InputMaybe<Workarea_Bundle_Order_By>;
  bundleId?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isOverridingBundleConfig?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workarea_activation?: InputMaybe<Workarea_Activation_Order_By>;
};

/** primary key columns input for table: subscription_history */
export type Subscription_History_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "subscription_history" */
export enum Subscription_History_Select_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil'
}

/** input type for updating data in table "subscription_history" */
export type Subscription_History_Set_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Subscription_History_Stddev_Fields = {
  __typename?: 'subscription_history_stddev_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Subscription_History_Stddev_Pop_Fields = {
  __typename?: 'subscription_history_stddev_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Subscription_History_Stddev_Samp_Fields = {
  __typename?: 'subscription_history_stddev_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "subscription_history" */
export type Subscription_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_History_Stream_Cursor_Value_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Subscription_History_Sum_Fields = {
  __typename?: 'subscription_history_sum_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
};

/** update columns of table "subscription_history" */
export enum Subscription_History_Update_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil'
}

export type Subscription_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_History_Var_Pop_Fields = {
  __typename?: 'subscription_history_var_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Subscription_History_Var_Samp_Fields = {
  __typename?: 'subscription_history_var_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Subscription_History_Variance_Fields = {
  __typename?: 'subscription_history_variance_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "subscription" */
export type Subscription_Inc_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "subscription" */
export type Subscription_Insert_Input = {
  activation?: InputMaybe<Workarea_Activation_Obj_Rel_Insert_Input>;
  activationId?: InputMaybe<Scalars['Int']>;
  bundle?: InputMaybe<Workarea_Bundle_Obj_Rel_Insert_Input>;
  bundleId?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  coupons?: InputMaybe<Subscription_Coupon_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  requests?: InputMaybe<Request_Arr_Rel_Insert_Input>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
  transactions?: InputMaybe<Ledger_Arr_Rel_Insert_Input>;
  usages?: InputMaybe<Track_Data_Model_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Subscription_Max_Fields = {
  __typename?: 'subscription_max_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "subscription" */
export type Subscription_Max_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Subscription_Min_Fields = {
  __typename?: 'subscription_min_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  endDate?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  limit_type?: Maybe<Scalars['String']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "subscription" */
export type Subscription_Min_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "subscription" */
export type Subscription_Mutation_Response = {
  __typename?: 'subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Subscription>;
};

/** input type for inserting object relation for remote table "subscription" */
export type Subscription_Obj_Rel_Insert_Input = {
  data: Subscription_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Subscription_On_Conflict>;
};

/** on_conflict condition type for table "subscription" */
export type Subscription_On_Conflict = {
  constraint: Subscription_Constraint;
  update_columns?: Array<Subscription_Update_Column>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "subscription". */
export type Subscription_Order_By = {
  activation?: InputMaybe<Workarea_Activation_Order_By>;
  activationId?: InputMaybe<Order_By>;
  bundle?: InputMaybe<Workarea_Bundle_Order_By>;
  bundleId?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  coupons_aggregate?: InputMaybe<Subscription_Coupon_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  isDeleted?: InputMaybe<Order_By>;
  isOverridingBundleConfig?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  limit_type?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Order_By>;
  usages_aggregate?: InputMaybe<Track_Data_Model_Aggregate_Order_By>;
};

/** primary key columns input for table: subscription */
export type Subscription_Pk_Columns_Input = {
  id: Scalars['Int'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "alert" */
  alert: Array<Alert>;
  /** fetch aggregated fields from the table: "alert" */
  alert_aggregate: Alert_Aggregate;
  /** fetch data from the table: "alert" using primary key columns */
  alert_by_pk?: Maybe<Alert>;
  /** fetch data from the table in a streaming manner: "alert" */
  alert_stream: Array<Alert>;
  /** An array relationship */
  attachments: Array<Attachments>;
  /** An aggregate relationship */
  attachments_aggregate: Attachments_Aggregate;
  /** fetch data from the table: "attachments" using primary key columns */
  attachments_by_pk?: Maybe<Attachments>;
  /** fetch data from the table in a streaming manner: "attachments" */
  attachments_stream: Array<Attachments>;
  /** fetch data from the table: "company" */
  company: Array<Company>;
  /** fetch aggregated fields from the table: "company" */
  company_aggregate: Company_Aggregate;
  /** fetch data from the table: "company_balance" */
  company_balance: Array<Company_Balance>;
  /** fetch aggregated fields from the table: "company_balance" */
  company_balance_aggregate: Company_Balance_Aggregate;
  /** fetch data from the table in a streaming manner: "company_balance" */
  company_balance_stream: Array<Company_Balance>;
  /** fetch data from the table: "company" using primary key columns */
  company_by_pk?: Maybe<Company>;
  /** fetch data from the table in a streaming manner: "company" */
  company_stream: Array<Company>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table in a streaming manner: "document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "dossier" */
  dossier: Array<Dossier>;
  /** fetch aggregated fields from the table: "dossier" */
  dossier_aggregate: Dossier_Aggregate;
  /** fetch data from the table: "dossier" using primary key columns */
  dossier_by_pk?: Maybe<Dossier>;
  /** fetch data from the table in a streaming manner: "dossier" */
  dossier_stream: Array<Dossier>;
  /** fetch data from the table: "extended_request" */
  extended_request: Array<Extended_Request>;
  /** fetch aggregated fields from the table: "extended_request" */
  extended_request_aggregate: Extended_Request_Aggregate;
  /** fetch data from the table in a streaming manner: "extended_request" */
  extended_request_stream: Array<Extended_Request>;
  /** fetch data from the table: "ledger" */
  ledger: Array<Ledger>;
  /** fetch aggregated fields from the table: "ledger" */
  ledger_aggregate: Ledger_Aggregate;
  /** fetch data from the table: "ledger_by_company_and_day" */
  ledger_by_company_and_day: Array<Ledger_By_Company_And_Day>;
  /** fetch aggregated fields from the table: "ledger_by_company_and_day" */
  ledger_by_company_and_day_aggregate: Ledger_By_Company_And_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "ledger_by_company_and_day" */
  ledger_by_company_and_day_stream: Array<Ledger_By_Company_And_Day>;
  /** fetch data from the table: "ledger" using primary key columns */
  ledger_by_pk?: Maybe<Ledger>;
  /** fetch data from the table in a streaming manner: "ledger" */
  ledger_stream: Array<Ledger>;
  /** fetch data from the table: "report" */
  report: Array<Report>;
  /** fetch aggregated fields from the table: "report" */
  report_aggregate: Report_Aggregate;
  /** fetch data from the table: "report" using primary key columns */
  report_by_pk?: Maybe<Report>;
  /** fetch data from the table in a streaming manner: "report" */
  report_stream: Array<Report>;
  /** fetch data from the table: "request" */
  request: Array<Request>;
  /** fetch aggregated fields from the table: "request" */
  request_aggregate: Request_Aggregate;
  /** fetch data from the table: "request" using primary key columns */
  request_by_pk?: Maybe<Request>;
  /** fetch data from the table in a streaming manner: "request" */
  request_stream: Array<Request>;
  /** fetch data from the table: "screener_check" */
  screener_check: Array<Screener_Check>;
  /** fetch aggregated fields from the table: "screener_check" */
  screener_check_aggregate: Screener_Check_Aggregate;
  /** fetch data from the table: "screener_check" using primary key columns */
  screener_check_by_pk?: Maybe<Screener_Check>;
  /** fetch data from the table in a streaming manner: "screener_check" */
  screener_check_stream: Array<Screener_Check>;
  /** An array relationship */
  screeners: Array<Screeners>;
  /** An aggregate relationship */
  screeners_aggregate: Screeners_Aggregate;
  /** fetch data from the table: "screeners" using primary key columns */
  screeners_by_pk?: Maybe<Screeners>;
  /** fetch data from the table in a streaming manner: "screeners" */
  screeners_stream: Array<Screeners>;
  /** fetch data from the table: "service" */
  service: Array<Service>;
  /** fetch aggregated fields from the table: "service" */
  service_aggregate: Service_Aggregate;
  /** fetch data from the table: "service" using primary key columns */
  service_by_pk?: Maybe<Service>;
  /** fetch data from the table in a streaming manner: "service" */
  service_stream: Array<Service>;
  /** fetch data from the table: "service_track_by_company_and_day" */
  service_track_by_company_and_day: Array<Service_Track_By_Company_And_Day>;
  /** fetch aggregated fields from the table: "service_track_by_company_and_day" */
  service_track_by_company_and_day_aggregate: Service_Track_By_Company_And_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "service_track_by_company_and_day" */
  service_track_by_company_and_day_stream: Array<Service_Track_By_Company_And_Day>;
  /** fetch data from the table: "service_track_by_day" */
  service_track_by_day: Array<Service_Track_By_Day>;
  /** fetch aggregated fields from the table: "service_track_by_day" */
  service_track_by_day_aggregate: Service_Track_By_Day_Aggregate;
  /** fetch data from the table in a streaming manner: "service_track_by_day" */
  service_track_by_day_stream: Array<Service_Track_By_Day>;
  /** fetch data from the table: "service_usage_by_company" */
  service_usage_by_company: Array<Service_Usage_By_Company>;
  /** fetch aggregated fields from the table: "service_usage_by_company" */
  service_usage_by_company_aggregate: Service_Usage_By_Company_Aggregate;
  /** fetch data from the table in a streaming manner: "service_usage_by_company" */
  service_usage_by_company_stream: Array<Service_Usage_By_Company>;
  /** fetch data from the table: "service_usage_by_user" */
  service_usage_by_user: Array<Service_Usage_By_User>;
  /** fetch aggregated fields from the table: "service_usage_by_user" */
  service_usage_by_user_aggregate: Service_Usage_By_User_Aggregate;
  /** fetch data from the table in a streaming manner: "service_usage_by_user" */
  service_usage_by_user_stream: Array<Service_Usage_By_User>;
  /** fetch data from the table: "subscription" */
  subscription: Array<Subscription>;
  /** fetch aggregated fields from the table: "subscription" */
  subscription_aggregate: Subscription_Aggregate;
  /** fetch data from the table: "subscription" using primary key columns */
  subscription_by_pk?: Maybe<Subscription>;
  /** fetch data from the table: "subscription_coupon" */
  subscription_coupon: Array<Subscription_Coupon>;
  /** fetch aggregated fields from the table: "subscription_coupon" */
  subscription_coupon_aggregate: Subscription_Coupon_Aggregate;
  /** fetch data from the table: "subscription_coupon" using primary key columns */
  subscription_coupon_by_pk?: Maybe<Subscription_Coupon>;
  /** fetch data from the table in a streaming manner: "subscription_coupon" */
  subscription_coupon_stream: Array<Subscription_Coupon>;
  /** fetch data from the table: "subscription_history" */
  subscription_history: Array<Subscription_History>;
  /** fetch aggregated fields from the table: "subscription_history" */
  subscription_history_aggregate: Subscription_History_Aggregate;
  /** fetch data from the table: "subscription_history" using primary key columns */
  subscription_history_by_pk?: Maybe<Subscription_History>;
  /** fetch data from the table in a streaming manner: "subscription_history" */
  subscription_history_stream: Array<Subscription_History>;
  /** fetch data from the table in a streaming manner: "subscription" */
  subscription_stream: Array<Subscription>;
  /** fetch data from the table: "track_data_model" */
  track_data_model: Array<Track_Data_Model>;
  /** fetch aggregated fields from the table: "track_data_model" */
  track_data_model_aggregate: Track_Data_Model_Aggregate;
  /** fetch data from the table: "track_data_model" using primary key columns */
  track_data_model_by_pk?: Maybe<Track_Data_Model>;
  /** fetch data from the table in a streaming manner: "track_data_model" */
  track_data_model_stream: Array<Track_Data_Model>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
  /** fetch data from the table: "wallet" */
  wallet: Array<Wallet>;
  /** fetch aggregated fields from the table: "wallet" */
  wallet_aggregate: Wallet_Aggregate;
  /** fetch data from the table: "wallet" using primary key columns */
  wallet_by_pk?: Maybe<Wallet>;
  /** fetch data from the table in a streaming manner: "wallet" */
  wallet_stream: Array<Wallet>;
  /** fetch data from the table: "workarea" */
  workarea: Array<Workarea>;
  /** fetch data from the table: "workarea_activation" */
  workarea_activation: Array<Workarea_Activation>;
  /** fetch aggregated fields from the table: "workarea_activation" */
  workarea_activation_aggregate: Workarea_Activation_Aggregate;
  /** fetch data from the table: "workarea_activation" using primary key columns */
  workarea_activation_by_pk?: Maybe<Workarea_Activation>;
  /** fetch data from the table: "workarea_activation_history" */
  workarea_activation_history: Array<Workarea_Activation_History>;
  /** fetch aggregated fields from the table: "workarea_activation_history" */
  workarea_activation_history_aggregate: Workarea_Activation_History_Aggregate;
  /** fetch data from the table: "workarea_activation_history" using primary key columns */
  workarea_activation_history_by_pk?: Maybe<Workarea_Activation_History>;
  /** fetch data from the table in a streaming manner: "workarea_activation_history" */
  workarea_activation_history_stream: Array<Workarea_Activation_History>;
  /** fetch data from the table in a streaming manner: "workarea_activation" */
  workarea_activation_stream: Array<Workarea_Activation>;
  /** fetch aggregated fields from the table: "workarea" */
  workarea_aggregate: Workarea_Aggregate;
  /** fetch data from the table: "workarea_bundle" */
  workarea_bundle: Array<Workarea_Bundle>;
  /** fetch aggregated fields from the table: "workarea_bundle" */
  workarea_bundle_aggregate: Workarea_Bundle_Aggregate;
  /** fetch data from the table: "workarea_bundle" using primary key columns */
  workarea_bundle_by_pk?: Maybe<Workarea_Bundle>;
  /** fetch data from the table in a streaming manner: "workarea_bundle" */
  workarea_bundle_stream: Array<Workarea_Bundle>;
  /** fetch data from the table: "workarea" using primary key columns */
  workarea_by_pk?: Maybe<Workarea>;
  /** fetch data from the table in a streaming manner: "workarea" */
  workarea_stream: Array<Workarea>;
};


export type Subscription_RootAlertArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type Subscription_RootAlert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Order_By>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type Subscription_RootAlert_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAlert_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Bool_Exp>;
};


export type Subscription_RootAttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootAttachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Attachments_Order_By>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootAttachments_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAttachments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Attachments_Bool_Exp>;
};


export type Subscription_RootCompanyArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootCompany_BalanceArgs = {
  distinct_on?: InputMaybe<Array<Company_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Balance_Order_By>>;
  where?: InputMaybe<Company_Balance_Bool_Exp>;
};


export type Subscription_RootCompany_Balance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Balance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Balance_Order_By>>;
  where?: InputMaybe<Company_Balance_Bool_Exp>;
};


export type Subscription_RootCompany_Balance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Balance_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Balance_Bool_Exp>;
};


export type Subscription_RootCompany_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCompany_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


export type Subscription_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDocument_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDocument_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Document_Stream_Cursor_Input>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


export type Subscription_RootDossierArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


export type Subscription_RootDossier_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dossier_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dossier_Order_By>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


export type Subscription_RootDossier_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDossier_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dossier_Stream_Cursor_Input>>;
  where?: InputMaybe<Dossier_Bool_Exp>;
};


export type Subscription_RootExtended_RequestArgs = {
  distinct_on?: InputMaybe<Array<Extended_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Extended_Request_Order_By>>;
  where?: InputMaybe<Extended_Request_Bool_Exp>;
};


export type Subscription_RootExtended_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Extended_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Extended_Request_Order_By>>;
  where?: InputMaybe<Extended_Request_Bool_Exp>;
};


export type Subscription_RootExtended_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Extended_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Extended_Request_Bool_Exp>;
};


export type Subscription_RootLedgerArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


export type Subscription_RootLedger_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


export type Subscription_RootLedger_By_Company_And_DayArgs = {
  distinct_on?: InputMaybe<Array<Ledger_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootLedger_By_Company_And_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootLedger_By_Company_And_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ledger_By_Company_And_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Ledger_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootLedger_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLedger_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ledger_Stream_Cursor_Input>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


export type Subscription_RootReportArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootReport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Report_Order_By>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootReport_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootReport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Bool_Exp>;
};


export type Subscription_RootRequestArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


export type Subscription_RootRequest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


export type Subscription_RootRequest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootRequest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


export type Subscription_RootScreener_CheckArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


export type Subscription_RootScreener_Check_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screener_Check_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screener_Check_Order_By>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


export type Subscription_RootScreener_Check_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootScreener_Check_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Screener_Check_Stream_Cursor_Input>>;
  where?: InputMaybe<Screener_Check_Bool_Exp>;
};


export type Subscription_RootScreenersArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


export type Subscription_RootScreeners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Screeners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Screeners_Order_By>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


export type Subscription_RootScreeners_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootScreeners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Screeners_Stream_Cursor_Input>>;
  where?: InputMaybe<Screeners_Bool_Exp>;
};


export type Subscription_RootServiceArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootService_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootService_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootService_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


export type Subscription_RootService_Track_By_Company_And_DayArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootService_Track_By_Company_And_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Company_And_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Company_And_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootService_Track_By_Company_And_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Track_By_Company_And_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Track_By_Company_And_Day_Bool_Exp>;
};


export type Subscription_RootService_Track_By_DayArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
};


export type Subscription_RootService_Track_By_Day_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Track_By_Day_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Track_By_Day_Order_By>>;
  where?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
};


export type Subscription_RootService_Track_By_Day_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Track_By_Day_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Track_By_Day_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_Company_Order_By>>;
  where?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_Company_Order_By>>;
  where?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Usage_By_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Usage_By_Company_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_User_Order_By>>;
  where?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Usage_By_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Usage_By_User_Order_By>>;
  where?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
};


export type Subscription_RootService_Usage_By_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Service_Usage_By_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Usage_By_User_Bool_Exp>;
};


export type Subscription_RootSubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootSubscription_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscription_CouponArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


export type Subscription_RootSubscription_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Coupon_Order_By>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


export type Subscription_RootSubscription_Coupon_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscription_Coupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Subscription_Coupon_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Coupon_Bool_Exp>;
};


export type Subscription_RootSubscription_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Subscription_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_History_Order_By>>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};


export type Subscription_RootSubscription_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_History_Order_By>>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};


export type Subscription_RootSubscription_History_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootSubscription_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Subscription_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_History_Bool_Exp>;
};


export type Subscription_RootSubscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


export type Subscription_RootTrack_Data_ModelArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


export type Subscription_RootTrack_Data_Model_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Track_Data_Model_Order_By>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


export type Subscription_RootTrack_Data_Model_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootTrack_Data_Model_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Track_Data_Model_Stream_Cursor_Input>>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootWalletArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Order_By>>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};


export type Subscription_RootWallet_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Order_By>>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};


export type Subscription_RootWallet_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWallet_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallet_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};


export type Subscription_RootWorkareaArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Order_By>>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};


export type Subscription_RootWorkarea_ActivationArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


export type Subscription_RootWorkarea_Activation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


export type Subscription_RootWorkarea_Activation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWorkarea_Activation_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_History_Order_By>>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};


export type Subscription_RootWorkarea_Activation_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_History_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_History_Order_By>>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};


export type Subscription_RootWorkarea_Activation_History_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWorkarea_Activation_History_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workarea_Activation_History_Stream_Cursor_Input>>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};


export type Subscription_RootWorkarea_Activation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workarea_Activation_Stream_Cursor_Input>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


export type Subscription_RootWorkarea_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Order_By>>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};


export type Subscription_RootWorkarea_BundleArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


export type Subscription_RootWorkarea_Bundle_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


export type Subscription_RootWorkarea_Bundle_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWorkarea_Bundle_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workarea_Bundle_Stream_Cursor_Input>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


export type Subscription_RootWorkarea_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWorkarea_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Workarea_Stream_Cursor_Input>>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};

/** select columns of table "subscription" */
export enum Subscription_Select_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate'
}

/** select "subscription_aggregate_bool_exp_avg_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_bool_and_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig'
}

/** select "subscription_aggregate_bool_exp_bool_or_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig'
}

/** select "subscription_aggregate_bool_exp_corr_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_max_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_min_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_sum_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** select "subscription_aggregate_bool_exp_var_samp_arguments_columns" columns of table "subscription" */
export enum Subscription_Select_Column_Subscription_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail'
}

/** input type for updating data in table "subscription" */
export type Subscription_Set_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Subscription_Stddev_Fields = {
  __typename?: 'subscription_stddev_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "subscription" */
export type Subscription_Stddev_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Subscription_Stddev_Pop_Fields = {
  __typename?: 'subscription_stddev_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "subscription" */
export type Subscription_Stddev_Pop_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Subscription_Stddev_Samp_Fields = {
  __typename?: 'subscription_stddev_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "subscription" */
export type Subscription_Stddev_Samp_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "subscription" */
export type Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Subscription_Stream_Cursor_Value_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isOverridingBundleConfig?: InputMaybe<Scalars['Boolean']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  limit_type?: InputMaybe<Scalars['String']>;
  limit_value?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['float8']>;
  priceOnFail?: InputMaybe<Scalars['float8']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Subscription_Sum_Fields = {
  __typename?: 'subscription_sum_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  limit_value?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['float8']>;
  priceOnFail?: Maybe<Scalars['float8']>;
  serviceId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "subscription" */
export type Subscription_Sum_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** update columns of table "subscription" */
export enum Subscription_Update_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  IsDeleted = 'isDeleted',
  /** column name */
  IsOverridingBundleConfig = 'isOverridingBundleConfig',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  LimitType = 'limit_type',
  /** column name */
  LimitValue = 'limit_value',
  /** column name */
  Price = 'price',
  /** column name */
  PriceOnFail = 'priceOnFail',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  StartDate = 'startDate'
}

export type Subscription_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Subscription_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Subscription_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Subscription_Var_Pop_Fields = {
  __typename?: 'subscription_var_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "subscription" */
export type Subscription_Var_Pop_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Subscription_Var_Samp_Fields = {
  __typename?: 'subscription_var_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "subscription" */
export type Subscription_Var_Samp_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Subscription_Variance_Fields = {
  __typename?: 'subscription_variance_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  limit_value?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceOnFail?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "subscription" */
export type Subscription_Variance_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  limit_value?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  priceOnFail?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "track_data_model" */
export type Track_Data_Model = {
  __typename?: 'track_data_model';
  companyId: Scalars['Int'];
  controller: Scalars['String'];
  /** An object relationship */
  coupon?: Maybe<Subscription_Coupon>;
  couponId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  ip: Scalars['String'];
  method: Scalars['String'];
  mock: Scalars['Boolean'];
  queryChannel?: Maybe<Scalars['String']>;
  /** An object relationship */
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  subscription?: Maybe<Subscription>;
  subscriptionId?: Maybe<Scalars['Int']>;
  timestamp: Scalars['timestamp'];
  type: Scalars['String'];
  userId: Scalars['Int'];
};

/** aggregated selection of "track_data_model" */
export type Track_Data_Model_Aggregate = {
  __typename?: 'track_data_model_aggregate';
  aggregate?: Maybe<Track_Data_Model_Aggregate_Fields>;
  nodes: Array<Track_Data_Model>;
};

export type Track_Data_Model_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Track_Data_Model_Aggregate_Bool_Exp_Count>;
};

export type Track_Data_Model_Aggregate_Bool_Exp_Bool_And = {
  arguments: Track_Data_Model_Select_Column_Track_Data_Model_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Track_Data_Model_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Track_Data_Model_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Track_Data_Model_Select_Column_Track_Data_Model_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Track_Data_Model_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Track_Data_Model_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Track_Data_Model_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "track_data_model" */
export type Track_Data_Model_Aggregate_Fields = {
  __typename?: 'track_data_model_aggregate_fields';
  avg?: Maybe<Track_Data_Model_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Track_Data_Model_Max_Fields>;
  min?: Maybe<Track_Data_Model_Min_Fields>;
  stddev?: Maybe<Track_Data_Model_Stddev_Fields>;
  stddev_pop?: Maybe<Track_Data_Model_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Track_Data_Model_Stddev_Samp_Fields>;
  sum?: Maybe<Track_Data_Model_Sum_Fields>;
  var_pop?: Maybe<Track_Data_Model_Var_Pop_Fields>;
  var_samp?: Maybe<Track_Data_Model_Var_Samp_Fields>;
  variance?: Maybe<Track_Data_Model_Variance_Fields>;
};


/** aggregate fields of "track_data_model" */
export type Track_Data_Model_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Track_Data_Model_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "track_data_model" */
export type Track_Data_Model_Aggregate_Order_By = {
  avg?: InputMaybe<Track_Data_Model_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Track_Data_Model_Max_Order_By>;
  min?: InputMaybe<Track_Data_Model_Min_Order_By>;
  stddev?: InputMaybe<Track_Data_Model_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Track_Data_Model_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Track_Data_Model_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Track_Data_Model_Sum_Order_By>;
  var_pop?: InputMaybe<Track_Data_Model_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Track_Data_Model_Var_Samp_Order_By>;
  variance?: InputMaybe<Track_Data_Model_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "track_data_model" */
export type Track_Data_Model_Arr_Rel_Insert_Input = {
  data: Array<Track_Data_Model_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Track_Data_Model_On_Conflict>;
};

/** aggregate avg on columns */
export type Track_Data_Model_Avg_Fields = {
  __typename?: 'track_data_model_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "track_data_model" */
export type Track_Data_Model_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "track_data_model". All fields are combined with a logical 'AND'. */
export type Track_Data_Model_Bool_Exp = {
  _and?: InputMaybe<Array<Track_Data_Model_Bool_Exp>>;
  _not?: InputMaybe<Track_Data_Model_Bool_Exp>;
  _or?: InputMaybe<Array<Track_Data_Model_Bool_Exp>>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  controller?: InputMaybe<String_Comparison_Exp>;
  coupon?: InputMaybe<Subscription_Coupon_Bool_Exp>;
  couponId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  mock?: InputMaybe<Boolean_Comparison_Exp>;
  queryChannel?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<Service_Bool_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  subscription?: InputMaybe<Subscription_Bool_Exp>;
  subscriptionId?: InputMaybe<Int_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "track_data_model" */
export enum Track_Data_Model_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_944460af678fa6cd9e4b757a23e = 'PK_944460af678fa6cd9e4b757a23e'
}

/** input type for incrementing numeric columns in table "track_data_model" */
export type Track_Data_Model_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "track_data_model" */
export type Track_Data_Model_Insert_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  controller?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Subscription_Coupon_Obj_Rel_Insert_Input>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  mock?: InputMaybe<Scalars['Boolean']>;
  queryChannel?: InputMaybe<Scalars['String']>;
  service?: InputMaybe<Service_Obj_Rel_Insert_Input>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<Subscription_Obj_Rel_Insert_Input>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Track_Data_Model_Max_Fields = {
  __typename?: 'track_data_model_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  controller?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "track_data_model" */
export type Track_Data_Model_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  controller?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  queryChannel?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Track_Data_Model_Min_Fields = {
  __typename?: 'track_data_model_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  controller?: Maybe<Scalars['String']>;
  couponId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  ip?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  queryChannel?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "track_data_model" */
export type Track_Data_Model_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  controller?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  queryChannel?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "track_data_model" */
export type Track_Data_Model_Mutation_Response = {
  __typename?: 'track_data_model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Track_Data_Model>;
};

/** on_conflict condition type for table "track_data_model" */
export type Track_Data_Model_On_Conflict = {
  constraint: Track_Data_Model_Constraint;
  update_columns?: Array<Track_Data_Model_Update_Column>;
  where?: InputMaybe<Track_Data_Model_Bool_Exp>;
};

/** Ordering options when selecting data from "track_data_model". */
export type Track_Data_Model_Order_By = {
  companyId?: InputMaybe<Order_By>;
  controller?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Subscription_Coupon_Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  mock?: InputMaybe<Order_By>;
  queryChannel?: InputMaybe<Order_By>;
  service?: InputMaybe<Service_Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Subscription_Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: track_data_model */
export type Track_Data_Model_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "track_data_model" */
export enum Track_Data_Model_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Controller = 'controller',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Method = 'method',
  /** column name */
  Mock = 'mock',
  /** column name */
  QueryChannel = 'queryChannel',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

/** select "track_data_model_aggregate_bool_exp_bool_and_arguments_columns" columns of table "track_data_model" */
export enum Track_Data_Model_Select_Column_Track_Data_Model_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Mock = 'mock'
}

/** select "track_data_model_aggregate_bool_exp_bool_or_arguments_columns" columns of table "track_data_model" */
export enum Track_Data_Model_Select_Column_Track_Data_Model_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Mock = 'mock'
}

/** input type for updating data in table "track_data_model" */
export type Track_Data_Model_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  controller?: InputMaybe<Scalars['String']>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  mock?: InputMaybe<Scalars['Boolean']>;
  queryChannel?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Track_Data_Model_Stddev_Fields = {
  __typename?: 'track_data_model_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "track_data_model" */
export type Track_Data_Model_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Track_Data_Model_Stddev_Pop_Fields = {
  __typename?: 'track_data_model_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "track_data_model" */
export type Track_Data_Model_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Track_Data_Model_Stddev_Samp_Fields = {
  __typename?: 'track_data_model_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "track_data_model" */
export type Track_Data_Model_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "track_data_model" */
export type Track_Data_Model_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Track_Data_Model_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Track_Data_Model_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  controller?: InputMaybe<Scalars['String']>;
  couponId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ip?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<Scalars['String']>;
  mock?: InputMaybe<Scalars['Boolean']>;
  queryChannel?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  subscriptionId?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['timestamp']>;
  type?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Track_Data_Model_Sum_Fields = {
  __typename?: 'track_data_model_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  couponId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "track_data_model" */
export type Track_Data_Model_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "track_data_model" */
export enum Track_Data_Model_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Controller = 'controller',
  /** column name */
  CouponId = 'couponId',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Method = 'method',
  /** column name */
  Mock = 'mock',
  /** column name */
  QueryChannel = 'queryChannel',
  /** column name */
  ServiceId = 'serviceId',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}

export type Track_Data_Model_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Track_Data_Model_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Track_Data_Model_Set_Input>;
  /** filter the rows which have to be updated */
  where: Track_Data_Model_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Track_Data_Model_Var_Pop_Fields = {
  __typename?: 'track_data_model_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "track_data_model" */
export type Track_Data_Model_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Track_Data_Model_Var_Samp_Fields = {
  __typename?: 'track_data_model_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "track_data_model" */
export type Track_Data_Model_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Track_Data_Model_Variance_Fields = {
  __typename?: 'track_data_model_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  couponId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "track_data_model" */
export type Track_Data_Model_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  couponId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serviceId?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user';
  /** An object relationship */
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['Int']>;
  config: Scalars['json'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  /** An array relationship */
  requests: Array<Request>;
  /** An aggregate relationship */
  requests_aggregate: Request_Aggregate;
  role: Scalars['String'];
  sub?: Maybe<Scalars['String']>;
  thirdPartyCredentials: Scalars['json'];
};


/** columns and relationships of "user" */
export type UserConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "user" */
export type UserRequestsArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserRequests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Request_Order_By>>;
  where?: InputMaybe<Request_Bool_Exp>;
};


/** columns and relationships of "user" */
export type UserThirdPartyCredentialsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: InputMaybe<User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
  stddev?: InputMaybe<User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Sum_Order_By>;
  var_pop?: InputMaybe<User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  config?: InputMaybe<Json_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  lastLogin?: InputMaybe<Timestamp_Comparison_Exp>;
  lastName?: InputMaybe<String_Comparison_Exp>;
  requests?: InputMaybe<Request_Bool_Exp>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  sub?: InputMaybe<String_Comparison_Exp>;
  thirdPartyCredentials?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "sub" */
  Idx_3641ff83ff7c23b2760b3df56d = 'IDX_3641ff83ff7c23b2760b3df56d',
  /** unique or primary key constraint on columns "email" */
  IdxE12875dfb3b1d92d7d7c5377e2 = 'IDX_e12875dfb3b1d92d7d7c5377e2',
  /** unique or primary key constraint on columns "id" */
  PkCace4a159ff9f2512dd42373760 = 'PK_cace4a159ff9f2512dd42373760'
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  config?: InputMaybe<Scalars['json']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  requests?: InputMaybe<Request_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<Scalars['String']>;
  thirdPartyCredentials?: InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  companyId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  companyId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLogin?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sub?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  companyId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  lastLogin?: Maybe<Scalars['timestamp']>;
  lastName?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  companyId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastLogin?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  sub?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  lastLogin?: InputMaybe<Order_By>;
  lastName?: InputMaybe<Order_By>;
  requests_aggregate?: InputMaybe<Request_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  sub?: InputMaybe<Order_By>;
  thirdPartyCredentials?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Config = 'config',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastLogin = 'lastLogin',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  Sub = 'sub',
  /** column name */
  ThirdPartyCredentials = 'thirdPartyCredentials'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  config?: InputMaybe<Scalars['json']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<Scalars['String']>;
  thirdPartyCredentials?: InputMaybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  companyId?: InputMaybe<Scalars['Int']>;
  config?: InputMaybe<Scalars['json']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastLogin?: InputMaybe<Scalars['timestamp']>;
  lastName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<Scalars['String']>;
  thirdPartyCredentials?: InputMaybe<Scalars['json']>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields';
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Config = 'config',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastLogin = 'lastLogin',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Role = 'role',
  /** column name */
  Sub = 'sub',
  /** column name */
  ThirdPartyCredentials = 'thirdPartyCredentials'
}

export type User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields';
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "wallet" */
export type Wallet = {
  __typename?: 'wallet';
  /** An array relationship */
  companies: Array<Company>;
  /** An aggregate relationship */
  companies_aggregate: Company_Aggregate;
  createdAt: Scalars['timestamp'];
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  name: Scalars['String'];
  /** An array relationship */
  transactions: Array<Ledger>;
  /** An aggregate relationship */
  transactions_aggregate: Ledger_Aggregate;
};


/** columns and relationships of "wallet" */
export type WalletCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


/** columns and relationships of "wallet" */
export type WalletCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Company_Order_By>>;
  where?: InputMaybe<Company_Bool_Exp>;
};


/** columns and relationships of "wallet" */
export type WalletTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};


/** columns and relationships of "wallet" */
export type WalletTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ledger_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ledger_Order_By>>;
  where?: InputMaybe<Ledger_Bool_Exp>;
};

/** aggregated selection of "wallet" */
export type Wallet_Aggregate = {
  __typename?: 'wallet_aggregate';
  aggregate?: Maybe<Wallet_Aggregate_Fields>;
  nodes: Array<Wallet>;
};

/** aggregate fields of "wallet" */
export type Wallet_Aggregate_Fields = {
  __typename?: 'wallet_aggregate_fields';
  avg?: Maybe<Wallet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wallet_Max_Fields>;
  min?: Maybe<Wallet_Min_Fields>;
  stddev?: Maybe<Wallet_Stddev_Fields>;
  stddev_pop?: Maybe<Wallet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wallet_Stddev_Samp_Fields>;
  sum?: Maybe<Wallet_Sum_Fields>;
  var_pop?: Maybe<Wallet_Var_Pop_Fields>;
  var_samp?: Maybe<Wallet_Var_Samp_Fields>;
  variance?: Maybe<Wallet_Variance_Fields>;
};


/** aggregate fields of "wallet" */
export type Wallet_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wallet_Avg_Fields = {
  __typename?: 'wallet_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wallet". All fields are combined with a logical 'AND'. */
export type Wallet_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Bool_Exp>>;
  companies?: InputMaybe<Company_Bool_Exp>;
  companies_aggregate?: InputMaybe<Company_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  transactions?: InputMaybe<Ledger_Bool_Exp>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "wallet" */
export enum Wallet_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkBec464dd8d54c39c54fd32e2334 = 'PK_bec464dd8d54c39c54fd32e2334'
}

/** input type for incrementing numeric columns in table "wallet" */
export type Wallet_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "wallet" */
export type Wallet_Insert_Input = {
  companies?: InputMaybe<Company_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<Ledger_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wallet_Max_Fields = {
  __typename?: 'wallet_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wallet_Min_Fields = {
  __typename?: 'wallet_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wallet" */
export type Wallet_Mutation_Response = {
  __typename?: 'wallet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet>;
};

/** input type for inserting object relation for remote table "wallet" */
export type Wallet_Obj_Rel_Insert_Input = {
  data: Wallet_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_On_Conflict>;
};

/** on_conflict condition type for table "wallet" */
export type Wallet_On_Conflict = {
  constraint: Wallet_Constraint;
  update_columns?: Array<Wallet_Update_Column>;
  where?: InputMaybe<Wallet_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet". */
export type Wallet_Order_By = {
  companies_aggregate?: InputMaybe<Company_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  transactions_aggregate?: InputMaybe<Ledger_Aggregate_Order_By>;
};

/** primary key columns input for table: wallet */
export type Wallet_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "wallet" */
export enum Wallet_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "wallet" */
export type Wallet_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wallet_Stddev_Fields = {
  __typename?: 'wallet_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wallet_Stddev_Pop_Fields = {
  __typename?: 'wallet_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wallet_Stddev_Samp_Fields = {
  __typename?: 'wallet_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wallet" */
export type Wallet_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallet_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallet_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wallet_Sum_Fields = {
  __typename?: 'wallet_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "wallet" */
export enum Wallet_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name'
}

export type Wallet_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Wallet_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallet_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wallet_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wallet_Var_Pop_Fields = {
  __typename?: 'wallet_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wallet_Var_Samp_Fields = {
  __typename?: 'wallet_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wallet_Variance_Fields = {
  __typename?: 'wallet_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "workarea" */
export type Workarea = {
  __typename?: 'workarea';
  /** An array relationship */
  activations: Array<Workarea_Activation>;
  /** An aggregate relationship */
  activations_aggregate: Workarea_Activation_Aggregate;
  /** An array relationship */
  bundles: Array<Workarea_Bundle>;
  /** An aggregate relationship */
  bundles_aggregate: Workarea_Bundle_Aggregate;
  config: Scalars['json'];
  createdAt: Scalars['timestamp'];
  id: Scalars['Int'];
  introText?: Maybe<Scalars['String']>;
  lastModified: Scalars['timestamp'];
  name: Scalars['String'];
  /** An array relationship */
  services: Array<Service>;
  /** An aggregate relationship */
  services_aggregate: Service_Aggregate;
  slug: Scalars['String'];
};


/** columns and relationships of "workarea" */
export type WorkareaActivationsArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


/** columns and relationships of "workarea" */
export type WorkareaActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


/** columns and relationships of "workarea" */
export type WorkareaBundlesArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


/** columns and relationships of "workarea" */
export type WorkareaBundles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Bundle_Order_By>>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};


/** columns and relationships of "workarea" */
export type WorkareaConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workarea" */
export type WorkareaServicesArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};


/** columns and relationships of "workarea" */
export type WorkareaServices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Service_Order_By>>;
  where?: InputMaybe<Service_Bool_Exp>;
};

/** columns and relationships of "workarea_activation" */
export type Workarea_Activation = {
  __typename?: 'workarea_activation';
  /** An object relationship */
  bundle?: Maybe<Workarea_Bundle>;
  bundleId?: Maybe<Scalars['Int']>;
  /** An object relationship */
  company: Company;
  companyId: Scalars['Int'];
  createdAt: Scalars['timestamp'];
  endDate?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  lastAppliedBundleRevision: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  startDate?: Maybe<Scalars['date']>;
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  /** An object relationship */
  workarea: Workarea;
  workareaId: Scalars['Int'];
};


/** columns and relationships of "workarea_activation" */
export type Workarea_ActivationSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "workarea_activation" */
export type Workarea_ActivationSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** aggregated selection of "workarea_activation" */
export type Workarea_Activation_Aggregate = {
  __typename?: 'workarea_activation_aggregate';
  aggregate?: Maybe<Workarea_Activation_Aggregate_Fields>;
  nodes: Array<Workarea_Activation>;
};

export type Workarea_Activation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp_Count>;
};

export type Workarea_Activation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Workarea_Activation_Select_Column_Workarea_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workarea_Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workarea_Activation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Workarea_Activation_Select_Column_Workarea_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workarea_Activation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Workarea_Activation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workarea_Activation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workarea_activation" */
export type Workarea_Activation_Aggregate_Fields = {
  __typename?: 'workarea_activation_aggregate_fields';
  avg?: Maybe<Workarea_Activation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workarea_Activation_Max_Fields>;
  min?: Maybe<Workarea_Activation_Min_Fields>;
  stddev?: Maybe<Workarea_Activation_Stddev_Fields>;
  stddev_pop?: Maybe<Workarea_Activation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workarea_Activation_Stddev_Samp_Fields>;
  sum?: Maybe<Workarea_Activation_Sum_Fields>;
  var_pop?: Maybe<Workarea_Activation_Var_Pop_Fields>;
  var_samp?: Maybe<Workarea_Activation_Var_Samp_Fields>;
  variance?: Maybe<Workarea_Activation_Variance_Fields>;
};


/** aggregate fields of "workarea_activation" */
export type Workarea_Activation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workarea_activation" */
export type Workarea_Activation_Aggregate_Order_By = {
  avg?: InputMaybe<Workarea_Activation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workarea_Activation_Max_Order_By>;
  min?: InputMaybe<Workarea_Activation_Min_Order_By>;
  stddev?: InputMaybe<Workarea_Activation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workarea_Activation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workarea_Activation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workarea_Activation_Sum_Order_By>;
  var_pop?: InputMaybe<Workarea_Activation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workarea_Activation_Var_Samp_Order_By>;
  variance?: InputMaybe<Workarea_Activation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workarea_activation" */
export type Workarea_Activation_Arr_Rel_Insert_Input = {
  data: Array<Workarea_Activation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workarea_Activation_On_Conflict>;
};

/** aggregate avg on columns */
export type Workarea_Activation_Avg_Fields = {
  __typename?: 'workarea_activation_avg_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workarea_activation" */
export type Workarea_Activation_Avg_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workarea_activation". All fields are combined with a logical 'AND'. */
export type Workarea_Activation_Bool_Exp = {
  _and?: InputMaybe<Array<Workarea_Activation_Bool_Exp>>;
  _not?: InputMaybe<Workarea_Activation_Bool_Exp>;
  _or?: InputMaybe<Array<Workarea_Activation_Bool_Exp>>;
  bundle?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  bundleId?: InputMaybe<Int_Comparison_Exp>;
  company?: InputMaybe<Company_Bool_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  endDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  lastAppliedBundleRevision?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscription_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Bool_Exp>;
  workarea?: InputMaybe<Workarea_Bool_Exp>;
  workareaId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "workarea_activation" */
export enum Workarea_Activation_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_9da61991407f1d570bfe2021a67 = 'PK_9da61991407f1d570bfe2021a67',
  /** unique or primary key constraint on columns "workareaId", "companyId" */
  WorkareaActivationWorkareaIdCompanyIdKey = 'workarea_activation_workareaId_companyId_key'
}

/** columns and relationships of "workarea_activation_history" */
export type Workarea_Activation_History = {
  __typename?: 'workarea_activation_history';
  activationId: Scalars['Int'];
  /** An object relationship */
  bundle?: Maybe<Workarea_Bundle>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  isActive?: Maybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  validFrom: Scalars['timestamp'];
  validUntil?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  workarea: Workarea_Activation;
  workareaId?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "workarea_activation_history" */
export type Workarea_Activation_History_Aggregate = {
  __typename?: 'workarea_activation_history_aggregate';
  aggregate?: Maybe<Workarea_Activation_History_Aggregate_Fields>;
  nodes: Array<Workarea_Activation_History>;
};

/** aggregate fields of "workarea_activation_history" */
export type Workarea_Activation_History_Aggregate_Fields = {
  __typename?: 'workarea_activation_history_aggregate_fields';
  avg?: Maybe<Workarea_Activation_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workarea_Activation_History_Max_Fields>;
  min?: Maybe<Workarea_Activation_History_Min_Fields>;
  stddev?: Maybe<Workarea_Activation_History_Stddev_Fields>;
  stddev_pop?: Maybe<Workarea_Activation_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workarea_Activation_History_Stddev_Samp_Fields>;
  sum?: Maybe<Workarea_Activation_History_Sum_Fields>;
  var_pop?: Maybe<Workarea_Activation_History_Var_Pop_Fields>;
  var_samp?: Maybe<Workarea_Activation_History_Var_Samp_Fields>;
  variance?: Maybe<Workarea_Activation_History_Variance_Fields>;
};


/** aggregate fields of "workarea_activation_history" */
export type Workarea_Activation_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workarea_Activation_History_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Workarea_Activation_History_Avg_Fields = {
  __typename?: 'workarea_activation_history_avg_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "workarea_activation_history". All fields are combined with a logical 'AND'. */
export type Workarea_Activation_History_Bool_Exp = {
  _and?: InputMaybe<Array<Workarea_Activation_History_Bool_Exp>>;
  _not?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
  _or?: InputMaybe<Array<Workarea_Activation_History_Bool_Exp>>;
  activationId?: InputMaybe<Int_Comparison_Exp>;
  bundle?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  bundleId?: InputMaybe<Int_Comparison_Exp>;
  companyId?: InputMaybe<Int_Comparison_Exp>;
  endDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isActive?: InputMaybe<Boolean_Comparison_Exp>;
  lastAppliedBundleRevision?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<Date_Comparison_Exp>;
  validFrom?: InputMaybe<Timestamp_Comparison_Exp>;
  validUntil?: InputMaybe<Timestamp_Comparison_Exp>;
  workarea?: InputMaybe<Workarea_Activation_Bool_Exp>;
  workareaId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "workarea_activation_history" */
export enum Workarea_Activation_History_Constraint {
  /** unique or primary key constraint on columns "activationId", "validUntil" */
  WorkareaActivationHistoryActivationIdValidUntilKey = 'workarea_activation_history_activationId_validUntil_key',
  /** unique or primary key constraint on columns "id" */
  WorkareaActivationHistoryPkey = 'workarea_activation_history_pkey'
}

/** input type for incrementing numeric columns in table "workarea_activation_history" */
export type Workarea_Activation_History_Inc_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workarea_activation_history" */
export type Workarea_Activation_History_Insert_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundle?: InputMaybe<Workarea_Bundle_Obj_Rel_Insert_Input>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
  workarea?: InputMaybe<Workarea_Activation_Obj_Rel_Insert_Input>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Workarea_Activation_History_Max_Fields = {
  __typename?: 'workarea_activation_history_max_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Workarea_Activation_History_Min_Fields = {
  __typename?: 'workarea_activation_history_min_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  validFrom?: Maybe<Scalars['timestamp']>;
  validUntil?: Maybe<Scalars['timestamp']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "workarea_activation_history" */
export type Workarea_Activation_History_Mutation_Response = {
  __typename?: 'workarea_activation_history_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workarea_Activation_History>;
};

/** on_conflict condition type for table "workarea_activation_history" */
export type Workarea_Activation_History_On_Conflict = {
  constraint: Workarea_Activation_History_Constraint;
  update_columns?: Array<Workarea_Activation_History_Update_Column>;
  where?: InputMaybe<Workarea_Activation_History_Bool_Exp>;
};

/** Ordering options when selecting data from "workarea_activation_history". */
export type Workarea_Activation_History_Order_By = {
  activationId?: InputMaybe<Order_By>;
  bundle?: InputMaybe<Workarea_Bundle_Order_By>;
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  validFrom?: InputMaybe<Order_By>;
  validUntil?: InputMaybe<Order_By>;
  workarea?: InputMaybe<Workarea_Activation_Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workarea_activation_history */
export type Workarea_Activation_History_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "workarea_activation_history" */
export enum Workarea_Activation_History_Select_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastAppliedBundleRevision = 'lastAppliedBundleRevision',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkareaId = 'workareaId'
}

/** input type for updating data in table "workarea_activation_history" */
export type Workarea_Activation_History_Set_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Workarea_Activation_History_Stddev_Fields = {
  __typename?: 'workarea_activation_history_stddev_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Workarea_Activation_History_Stddev_Pop_Fields = {
  __typename?: 'workarea_activation_history_stddev_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Workarea_Activation_History_Stddev_Samp_Fields = {
  __typename?: 'workarea_activation_history_stddev_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "workarea_activation_history" */
export type Workarea_Activation_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workarea_Activation_History_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workarea_Activation_History_Stream_Cursor_Value_Input = {
  activationId?: InputMaybe<Scalars['Int']>;
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['date']>;
  validFrom?: InputMaybe<Scalars['timestamp']>;
  validUntil?: InputMaybe<Scalars['timestamp']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Workarea_Activation_History_Sum_Fields = {
  __typename?: 'workarea_activation_history_sum_fields';
  activationId?: Maybe<Scalars['Int']>;
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** update columns of table "workarea_activation_history" */
export enum Workarea_Activation_History_Update_Column {
  /** column name */
  ActivationId = 'activationId',
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastAppliedBundleRevision = 'lastAppliedBundleRevision',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  ValidFrom = 'validFrom',
  /** column name */
  ValidUntil = 'validUntil',
  /** column name */
  WorkareaId = 'workareaId'
}

export type Workarea_Activation_History_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workarea_Activation_History_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workarea_Activation_History_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workarea_Activation_History_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workarea_Activation_History_Var_Pop_Fields = {
  __typename?: 'workarea_activation_history_var_pop_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Workarea_Activation_History_Var_Samp_Fields = {
  __typename?: 'workarea_activation_history_var_samp_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Workarea_Activation_History_Variance_Fields = {
  __typename?: 'workarea_activation_history_variance_fields';
  activationId?: Maybe<Scalars['Float']>;
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "workarea_activation" */
export type Workarea_Activation_Inc_Input = {
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workarea_activation" */
export type Workarea_Activation_Insert_Input = {
  bundle?: InputMaybe<Workarea_Bundle_Obj_Rel_Insert_Input>;
  bundleId?: InputMaybe<Scalars['Int']>;
  company?: InputMaybe<Company_Obj_Rel_Insert_Input>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  startDate?: InputMaybe<Scalars['date']>;
  subscriptions?: InputMaybe<Subscription_Arr_Rel_Insert_Input>;
  workarea?: InputMaybe<Workarea_Obj_Rel_Insert_Input>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Workarea_Activation_Max_Fields = {
  __typename?: 'workarea_activation_max_fields';
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  startDate?: Maybe<Scalars['date']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "workarea_activation" */
export type Workarea_Activation_Max_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workarea_Activation_Min_Fields = {
  __typename?: 'workarea_activation_min_fields';
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  startDate?: Maybe<Scalars['date']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "workarea_activation" */
export type Workarea_Activation_Min_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workarea_activation" */
export type Workarea_Activation_Mutation_Response = {
  __typename?: 'workarea_activation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workarea_Activation>;
};

/** input type for inserting object relation for remote table "workarea_activation" */
export type Workarea_Activation_Obj_Rel_Insert_Input = {
  data: Workarea_Activation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workarea_Activation_On_Conflict>;
};

/** on_conflict condition type for table "workarea_activation" */
export type Workarea_Activation_On_Conflict = {
  constraint: Workarea_Activation_Constraint;
  update_columns?: Array<Workarea_Activation_Update_Column>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};

/** Ordering options when selecting data from "workarea_activation". */
export type Workarea_Activation_Order_By = {
  bundle?: InputMaybe<Workarea_Bundle_Order_By>;
  bundleId?: InputMaybe<Order_By>;
  company?: InputMaybe<Company_Order_By>;
  companyId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isActive?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Order_By>;
  workarea?: InputMaybe<Workarea_Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workarea_activation */
export type Workarea_Activation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "workarea_activation" */
export enum Workarea_Activation_Select_Column {
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastAppliedBundleRevision = 'lastAppliedBundleRevision',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  WorkareaId = 'workareaId'
}

/** select "workarea_activation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "workarea_activation" */
export enum Workarea_Activation_Select_Column_Workarea_Activation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** select "workarea_activation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "workarea_activation" */
export enum Workarea_Activation_Select_Column_Workarea_Activation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'isActive'
}

/** input type for updating data in table "workarea_activation" */
export type Workarea_Activation_Set_Input = {
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  startDate?: InputMaybe<Scalars['date']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Workarea_Activation_Stddev_Fields = {
  __typename?: 'workarea_activation_stddev_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workarea_activation" */
export type Workarea_Activation_Stddev_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workarea_Activation_Stddev_Pop_Fields = {
  __typename?: 'workarea_activation_stddev_pop_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workarea_activation" */
export type Workarea_Activation_Stddev_Pop_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workarea_Activation_Stddev_Samp_Fields = {
  __typename?: 'workarea_activation_stddev_samp_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workarea_activation" */
export type Workarea_Activation_Stddev_Samp_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workarea_activation" */
export type Workarea_Activation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workarea_Activation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workarea_Activation_Stream_Cursor_Value_Input = {
  bundleId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  endDate?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  lastAppliedBundleRevision?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  startDate?: InputMaybe<Scalars['date']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Workarea_Activation_Sum_Fields = {
  __typename?: 'workarea_activation_sum_fields';
  bundleId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "workarea_activation" */
export type Workarea_Activation_Sum_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** update columns of table "workarea_activation" */
export enum Workarea_Activation_Update_Column {
  /** column name */
  BundleId = 'bundleId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  LastAppliedBundleRevision = 'lastAppliedBundleRevision',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  WorkareaId = 'workareaId'
}

export type Workarea_Activation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workarea_Activation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workarea_Activation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workarea_Activation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workarea_Activation_Var_Pop_Fields = {
  __typename?: 'workarea_activation_var_pop_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workarea_activation" */
export type Workarea_Activation_Var_Pop_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workarea_Activation_Var_Samp_Fields = {
  __typename?: 'workarea_activation_var_samp_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workarea_activation" */
export type Workarea_Activation_Var_Samp_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workarea_Activation_Variance_Fields = {
  __typename?: 'workarea_activation_variance_fields';
  bundleId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lastAppliedBundleRevision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workarea_activation" */
export type Workarea_Activation_Variance_Order_By = {
  bundleId?: InputMaybe<Order_By>;
  companyId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastAppliedBundleRevision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregated selection of "workarea" */
export type Workarea_Aggregate = {
  __typename?: 'workarea_aggregate';
  aggregate?: Maybe<Workarea_Aggregate_Fields>;
  nodes: Array<Workarea>;
};

/** aggregate fields of "workarea" */
export type Workarea_Aggregate_Fields = {
  __typename?: 'workarea_aggregate_fields';
  avg?: Maybe<Workarea_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workarea_Max_Fields>;
  min?: Maybe<Workarea_Min_Fields>;
  stddev?: Maybe<Workarea_Stddev_Fields>;
  stddev_pop?: Maybe<Workarea_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workarea_Stddev_Samp_Fields>;
  sum?: Maybe<Workarea_Sum_Fields>;
  var_pop?: Maybe<Workarea_Var_Pop_Fields>;
  var_samp?: Maybe<Workarea_Var_Samp_Fields>;
  variance?: Maybe<Workarea_Variance_Fields>;
};


/** aggregate fields of "workarea" */
export type Workarea_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workarea_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Workarea_Avg_Fields = {
  __typename?: 'workarea_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "workarea". All fields are combined with a logical 'AND'. */
export type Workarea_Bool_Exp = {
  _and?: InputMaybe<Array<Workarea_Bool_Exp>>;
  _not?: InputMaybe<Workarea_Bool_Exp>;
  _or?: InputMaybe<Array<Workarea_Bool_Exp>>;
  activations?: InputMaybe<Workarea_Activation_Bool_Exp>;
  activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp>;
  bundles?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  bundles_aggregate?: InputMaybe<Workarea_Bundle_Aggregate_Bool_Exp>;
  config?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  introText?: InputMaybe<String_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  services?: InputMaybe<Service_Bool_Exp>;
  services_aggregate?: InputMaybe<Service_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "workarea_bundle" */
export type Workarea_Bundle = {
  __typename?: 'workarea_bundle';
  /** An array relationship */
  activations: Array<Workarea_Activation>;
  /** An aggregate relationship */
  activations_aggregate: Workarea_Activation_Aggregate;
  config: Scalars['json'];
  createdAt: Scalars['timestamp'];
  id: Scalars['Int'];
  lastModified: Scalars['timestamp'];
  name: Scalars['String'];
  revision: Scalars['Int'];
  /** An array relationship */
  subscriptions: Array<Subscription>;
  /** An aggregate relationship */
  subscriptions_aggregate: Subscription_Aggregate;
  /** An object relationship */
  workarea: Workarea;
  workareaId: Scalars['Int'];
};


/** columns and relationships of "workarea_bundle" */
export type Workarea_BundleActivationsArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


/** columns and relationships of "workarea_bundle" */
export type Workarea_BundleActivations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workarea_Activation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Workarea_Activation_Order_By>>;
  where?: InputMaybe<Workarea_Activation_Bool_Exp>;
};


/** columns and relationships of "workarea_bundle" */
export type Workarea_BundleConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "workarea_bundle" */
export type Workarea_BundleSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};


/** columns and relationships of "workarea_bundle" */
export type Workarea_BundleSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Subscription_Order_By>>;
  where?: InputMaybe<Subscription_Bool_Exp>;
};

/** aggregated selection of "workarea_bundle" */
export type Workarea_Bundle_Aggregate = {
  __typename?: 'workarea_bundle_aggregate';
  aggregate?: Maybe<Workarea_Bundle_Aggregate_Fields>;
  nodes: Array<Workarea_Bundle>;
};

export type Workarea_Bundle_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workarea_Bundle_Aggregate_Bool_Exp_Count>;
};

export type Workarea_Bundle_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workarea_bundle" */
export type Workarea_Bundle_Aggregate_Fields = {
  __typename?: 'workarea_bundle_aggregate_fields';
  avg?: Maybe<Workarea_Bundle_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Workarea_Bundle_Max_Fields>;
  min?: Maybe<Workarea_Bundle_Min_Fields>;
  stddev?: Maybe<Workarea_Bundle_Stddev_Fields>;
  stddev_pop?: Maybe<Workarea_Bundle_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Workarea_Bundle_Stddev_Samp_Fields>;
  sum?: Maybe<Workarea_Bundle_Sum_Fields>;
  var_pop?: Maybe<Workarea_Bundle_Var_Pop_Fields>;
  var_samp?: Maybe<Workarea_Bundle_Var_Samp_Fields>;
  variance?: Maybe<Workarea_Bundle_Variance_Fields>;
};


/** aggregate fields of "workarea_bundle" */
export type Workarea_Bundle_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workarea_Bundle_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "workarea_bundle" */
export type Workarea_Bundle_Aggregate_Order_By = {
  avg?: InputMaybe<Workarea_Bundle_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workarea_Bundle_Max_Order_By>;
  min?: InputMaybe<Workarea_Bundle_Min_Order_By>;
  stddev?: InputMaybe<Workarea_Bundle_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Workarea_Bundle_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Workarea_Bundle_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Workarea_Bundle_Sum_Order_By>;
  var_pop?: InputMaybe<Workarea_Bundle_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Workarea_Bundle_Var_Samp_Order_By>;
  variance?: InputMaybe<Workarea_Bundle_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "workarea_bundle" */
export type Workarea_Bundle_Arr_Rel_Insert_Input = {
  data: Array<Workarea_Bundle_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workarea_Bundle_On_Conflict>;
};

/** aggregate avg on columns */
export type Workarea_Bundle_Avg_Fields = {
  __typename?: 'workarea_bundle_avg_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "workarea_bundle". All fields are combined with a logical 'AND'. */
export type Workarea_Bundle_Bool_Exp = {
  _and?: InputMaybe<Array<Workarea_Bundle_Bool_Exp>>;
  _not?: InputMaybe<Workarea_Bundle_Bool_Exp>;
  _or?: InputMaybe<Array<Workarea_Bundle_Bool_Exp>>;
  activations?: InputMaybe<Workarea_Activation_Bool_Exp>;
  activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Bool_Exp>;
  config?: InputMaybe<Json_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastModified?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  revision?: InputMaybe<Int_Comparison_Exp>;
  subscriptions?: InputMaybe<Subscription_Bool_Exp>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Bool_Exp>;
  workarea?: InputMaybe<Workarea_Bool_Exp>;
  workareaId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "workarea_bundle" */
export enum Workarea_Bundle_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkD27f504f3aaab90648760c9b37c = 'PK_d27f504f3aaab90648760c9b37c'
}

/** input type for incrementing numeric columns in table "workarea_bundle" */
export type Workarea_Bundle_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  revision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workarea_bundle" */
export type Workarea_Bundle_Insert_Input = {
  activations?: InputMaybe<Workarea_Activation_Arr_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  subscriptions?: InputMaybe<Subscription_Arr_Rel_Insert_Input>;
  workarea?: InputMaybe<Workarea_Obj_Rel_Insert_Input>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Workarea_Bundle_Max_Fields = {
  __typename?: 'workarea_bundle_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workarea_Bundle_Min_Fields = {
  __typename?: 'workarea_bundle_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workarea_bundle" */
export type Workarea_Bundle_Mutation_Response = {
  __typename?: 'workarea_bundle_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workarea_Bundle>;
};

/** input type for inserting object relation for remote table "workarea_bundle" */
export type Workarea_Bundle_Obj_Rel_Insert_Input = {
  data: Workarea_Bundle_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workarea_Bundle_On_Conflict>;
};

/** on_conflict condition type for table "workarea_bundle" */
export type Workarea_Bundle_On_Conflict = {
  constraint: Workarea_Bundle_Constraint;
  update_columns?: Array<Workarea_Bundle_Update_Column>;
  where?: InputMaybe<Workarea_Bundle_Bool_Exp>;
};

/** Ordering options when selecting data from "workarea_bundle". */
export type Workarea_Bundle_Order_By = {
  activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Order_By>;
  config?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  subscriptions_aggregate?: InputMaybe<Subscription_Aggregate_Order_By>;
  workarea?: InputMaybe<Workarea_Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workarea_bundle */
export type Workarea_Bundle_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "workarea_bundle" */
export enum Workarea_Bundle_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  WorkareaId = 'workareaId'
}

/** input type for updating data in table "workarea_bundle" */
export type Workarea_Bundle_Set_Input = {
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Workarea_Bundle_Stddev_Fields = {
  __typename?: 'workarea_bundle_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Workarea_Bundle_Stddev_Pop_Fields = {
  __typename?: 'workarea_bundle_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Workarea_Bundle_Stddev_Samp_Fields = {
  __typename?: 'workarea_bundle_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "workarea_bundle" */
export type Workarea_Bundle_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workarea_Bundle_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workarea_Bundle_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  revision?: InputMaybe<Scalars['Int']>;
  workareaId?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Workarea_Bundle_Sum_Fields = {
  __typename?: 'workarea_bundle_sum_fields';
  id?: Maybe<Scalars['Int']>;
  revision?: Maybe<Scalars['Int']>;
  workareaId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** update columns of table "workarea_bundle" */
export enum Workarea_Bundle_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Revision = 'revision',
  /** column name */
  WorkareaId = 'workareaId'
}

export type Workarea_Bundle_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workarea_Bundle_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workarea_Bundle_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workarea_Bundle_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workarea_Bundle_Var_Pop_Fields = {
  __typename?: 'workarea_bundle_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Workarea_Bundle_Var_Samp_Fields = {
  __typename?: 'workarea_bundle_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Workarea_Bundle_Variance_Fields = {
  __typename?: 'workarea_bundle_variance_fields';
  id?: Maybe<Scalars['Float']>;
  revision?: Maybe<Scalars['Float']>;
  workareaId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "workarea_bundle" */
export type Workarea_Bundle_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  workareaId?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "workarea" */
export enum Workarea_Constraint {
  /** unique or primary key constraint on columns "id" */
  Pk_09f04bef2ae4e47ed70f8d79089 = 'PK_09f04bef2ae4e47ed70f8d79089',
  /** unique or primary key constraint on columns "slug" */
  Slug = 'slug'
}

/** input type for incrementing numeric columns in table "workarea" */
export type Workarea_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "workarea" */
export type Workarea_Insert_Input = {
  activations?: InputMaybe<Workarea_Activation_Arr_Rel_Insert_Input>;
  bundles?: InputMaybe<Workarea_Bundle_Arr_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  introText?: InputMaybe<Scalars['String']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Service_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Workarea_Max_Fields = {
  __typename?: 'workarea_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  introText?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Workarea_Min_Fields = {
  __typename?: 'workarea_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  introText?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "workarea" */
export type Workarea_Mutation_Response = {
  __typename?: 'workarea_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Workarea>;
};

/** input type for inserting object relation for remote table "workarea" */
export type Workarea_Obj_Rel_Insert_Input = {
  data: Workarea_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workarea_On_Conflict>;
};

/** on_conflict condition type for table "workarea" */
export type Workarea_On_Conflict = {
  constraint: Workarea_Constraint;
  update_columns?: Array<Workarea_Update_Column>;
  where?: InputMaybe<Workarea_Bool_Exp>;
};

/** Ordering options when selecting data from "workarea". */
export type Workarea_Order_By = {
  activations_aggregate?: InputMaybe<Workarea_Activation_Aggregate_Order_By>;
  bundles_aggregate?: InputMaybe<Workarea_Bundle_Aggregate_Order_By>;
  config?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  introText?: InputMaybe<Order_By>;
  lastModified?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  services_aggregate?: InputMaybe<Service_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workarea */
export type Workarea_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "workarea" */
export enum Workarea_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IntroText = 'introText',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

/** input type for updating data in table "workarea" */
export type Workarea_Set_Input = {
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  introText?: InputMaybe<Scalars['String']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Workarea_Stddev_Fields = {
  __typename?: 'workarea_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Workarea_Stddev_Pop_Fields = {
  __typename?: 'workarea_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Workarea_Stddev_Samp_Fields = {
  __typename?: 'workarea_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "workarea" */
export type Workarea_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workarea_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workarea_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['json']>;
  createdAt?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['Int']>;
  introText?: InputMaybe<Scalars['String']>;
  lastModified?: InputMaybe<Scalars['timestamp']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Workarea_Sum_Fields = {
  __typename?: 'workarea_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "workarea" */
export enum Workarea_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IntroText = 'introText',
  /** column name */
  LastModified = 'lastModified',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug'
}

export type Workarea_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Workarea_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workarea_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workarea_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Workarea_Var_Pop_Fields = {
  __typename?: 'workarea_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Workarea_Var_Samp_Fields = {
  __typename?: 'workarea_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Workarea_Variance_Fields = {
  __typename?: 'workarea_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type DossiersQueryVariables = Exact<{
  parentId?: InputMaybe<Uuid_Comparison_Exp>;
  allowOrphanedDocs?: InputMaybe<Scalars['Boolean']>;
  order_by_doc?: InputMaybe<Array<Document_Order_By> | Document_Order_By>;
  order_by_dossier?: InputMaybe<Array<Dossier_Order_By> | Dossier_Order_By>;
  from?: InputMaybe<Scalars['timestamp']>;
  to?: InputMaybe<Scalars['timestamp']>;
}>;


export type DossiersQuery = { __typename?: 'query_root', children: Array<{ __typename?: 'dossier', id: any, title: string, createdAt: any, createdBy: number, parent?: { __typename?: 'dossier', id: any, title: string } | null, screener?: { __typename?: 'screeners', type: any, subType?: any | null } | null, children_aggregate: { __typename?: 'dossier_aggregate', aggregate?: { __typename?: 'dossier_aggregate_fields', count: number } | null }, documents_aggregate: { __typename?: 'document_aggregate', aggregate?: { __typename?: 'document_aggregate_fields', count: number } | null } }>, document: Array<{ __typename?: 'document', name: string, id: number, createdAt: any }>, current: Array<{ __typename?: 'dossier', title: string, id: any, screener?: { __typename?: 'screeners', type: any, subType?: any | null } | null, parent?: { __typename?: 'dossier', id: any, title: string, parent?: { __typename?: 'dossier', id: any, title: string, parent?: { __typename?: 'dossier', id: any, title: string, parent?: { __typename?: 'dossier', id: any, title: string, parent?: { __typename?: 'dossier', id: any, title: string } | null } | null } | null } | null } | null }> };

export type DeleteDocMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type DeleteDocMutation = { __typename?: 'mutation_root', delete_document_by_pk?: { __typename?: 'document', id: number } | null };

export type SetFolderParentDossierMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  parentId?: InputMaybe<Scalars['uuid']>;
}>;


export type SetFolderParentDossierMutation = { __typename?: 'mutation_root', update_dossier_by_pk?: { __typename?: 'dossier', id: any, parentId?: any | null } | null };

export type SetDocumentParentDossierMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  dossierId?: InputMaybe<Scalars['uuid']>;
}>;


export type SetDocumentParentDossierMutation = { __typename?: 'mutation_root', update_document_by_pk?: { __typename?: 'document', id: number, dossierId?: any | null } | null };

export type AddDossierMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['uuid']>;
}>;


export type AddDossierMutation = { __typename?: 'mutation_root', insert_dossier_one?: { __typename?: 'dossier', id: any } | null };

export type RenameDossierMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type RenameDossierMutation = { __typename?: 'mutation_root', update_dossier_by_pk?: { __typename?: 'dossier', id: any } | null };

export type DeleteDossierMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteDossierMutation = { __typename?: 'mutation_root', delete_dossier_by_pk?: { __typename?: 'dossier', id: any } | null };

export type GetVisuraIpotecariaQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
  _contains?: InputMaybe<Scalars['String']>;
}>;


export type GetVisuraIpotecariaQuery = { __typename?: 'query_root', request: Array<{ __typename?: 'request', id: number, queryResult?: any | null, createdAt: any, params: any }> };

export type GetRichiestePerSlugQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
}>;


export type GetRichiestePerSlugQuery = { __typename?: 'query_root', request: Array<{ __typename?: 'request', id: number, params: any, createdAt: any, queryResult?: any | null }> };

export type GetRequestsSubscriptionVariables = Exact<{
  status?: InputMaybe<Request_Status_Enum_Comparison_Exp>;
  archived?: InputMaybe<Boolean_Comparison_Exp>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;


export type GetRequestsSubscription = { __typename?: 'subscription_root', request: Array<{ __typename?: 'request', errorDescription?: string | null, params: any, notes: any, status: any, archived: boolean, id: number, createdAt: any, service: { __typename?: 'service', name: string, fields: any } }> };

export type MyDocumentsSubscriptionVariables = Exact<{
  _gte?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  _ilike?: InputMaybe<Scalars['String']>;
}>;


export type MyDocumentsSubscription = { __typename?: 'subscription_root', document: Array<{ __typename?: 'document', id: number, name: string, lastOpened?: any | null, requestId?: number | null, request?: { __typename?: 'request', createdAt: any, params: any, notes: any, service: { __typename?: 'service', name: string, fields: any } } | null }> };

export type HideRequestMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  archived?: InputMaybe<Scalars['Boolean']>;
}>;


export type HideRequestMutation = { __typename?: 'mutation_root', update_request_by_pk?: { __typename?: 'request', id: number } | null };

export type MyDocParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyDocParamsQuery = { __typename?: 'query_root', service: Array<{ __typename?: 'service', id: number, name: string }> };

export type MyScreenersQueryVariables = Exact<{
  type?: InputMaybe<Bpchar_Comparison_Exp>;
  subType?: InputMaybe<Bpchar_Comparison_Exp>;
}>;


export type MyScreenersQuery = { __typename?: 'query_root', screeners: Array<{ __typename?: 'screeners', id: any, active: boolean, configuration: any, createdAt: any, expiresOn: any, subType?: any | null, type: any, dossierId: any, checks_aggregate: { __typename?: 'screener_check_aggregate', aggregate?: { __typename?: 'screener_check_aggregate_fields', count: number } | null, nodes: Array<{ __typename?: 'screener_check', createdAt: any, detectedChanges: any, id: number, response: any, userAck: boolean }> } }> };

export type AckCheckMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
  userAck?: InputMaybe<Scalars['Boolean']>;
}>;


export type AckCheckMutation = { __typename?: 'mutation_root', update_screener_check_by_pk?: { __typename?: 'screener_check', userAck: boolean, id: number } | null };

export type SwitchActiveMutationVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type SwitchActiveMutation = { __typename?: 'mutation_root', update_screeners_by_pk?: { __typename?: 'screeners', id: any, active: boolean } | null };

export type MyActivationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyActivationsQuery = { __typename?: 'query_root', workarea_activation: Array<{ __typename?: 'workarea_activation', createdAt: any, startDate?: any | null, endDate?: any | null, bundle?: { __typename?: 'workarea_bundle', name: string } | null, workarea: { __typename?: 'workarea', slug: string, introText?: string | null } }> };

export type MySubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type MySubscriptionsQuery = { __typename?: 'query_root', subscription: Array<{ __typename?: 'subscription', id: number, isActive: boolean, limit_type: string, limit_value: number, price: any, activationId?: number | null, startDate: any, endDate: any, service: { __typename?: 'service', name: string, fields: any, id: number, isActive: boolean, channel: string, description: string, priceDescription?: string | null, internalDescription?: string | null, slug: string, priceCalculation: any, executionPolicy: string, postConfirmButtons?: any | null, targets: any, dataChannel?: string | null, submitText?: string | null, status: string, notes?: any | null }, activation?: { __typename?: 'workarea_activation', bundle?: { __typename?: 'workarea_bundle', name: string } | null } | null }> };

export type MyTransactionsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Ledger_Order_By> | Ledger_Order_By>;
  pending?: InputMaybe<Boolean_Comparison_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
}>;


export type MyTransactionsQuery = { __typename?: 'query_root', ledger: Array<{ __typename?: 'ledger', createdAt: any, amount: any, description: string, pending: boolean, userId?: number | null, requestId?: number | null, companyId?: number | null, walletId: number, service?: { __typename?: 'service', name: string, fields: any } | null, request?: { __typename?: 'request', params: any, notes: any, status: any } | null }> };

export type MyTransactionParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTransactionParamsQuery = { __typename?: 'query_root', ledger: Array<{ __typename?: 'ledger', pending: boolean }>, service: Array<{ __typename?: 'service', id: number, name: string }> };

export type MyDepositsQueryVariables = Exact<{
  order_by?: InputMaybe<Array<Ledger_Order_By> | Ledger_Order_By>;
}>;


export type MyDepositsQuery = { __typename?: 'query_root', ledger: Array<{ __typename?: 'ledger', createdAt: any, amount: any, description: string, pending: boolean, wallet: { __typename?: 'wallet', name: string } }> };

export type MyRequestsQueryVariables = Exact<{
  status?: InputMaybe<Request_Status_Enum_Comparison_Exp>;
  serviceId?: InputMaybe<Int_Comparison_Exp>;
  order_by?: InputMaybe<Array<Request_Order_By> | Request_Order_By>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
}>;


export type MyRequestsQuery = { __typename?: 'query_root', request: Array<{ __typename?: 'request', id: number, queryResult?: any | null, createdAt: any, params: any, status: any, notes: any, service: { __typename?: 'service', name: string, fields: any }, transactions: Array<{ __typename?: 'ledger', amount: any, pending: boolean }> }> };

export type MyRequestParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRequestParamsQuery = { __typename?: 'query_root', request: Array<{ __typename?: 'request', status: any }>, service: Array<{ __typename?: 'service', id: number, name: string }> };

export type MyUsageQueryVariables = Exact<{
  month?: InputMaybe<Scalars['numeric']>;
  year?: InputMaybe<Scalars['numeric']>;
  companyId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type MyUsageQuery = { __typename?: 'query_root', service_usage_by_company: Array<{ __typename?: 'service_usage_by_company', companyId?: number | null, id?: number | null, month?: any | null, year?: any | null, count?: any | null, name?: string | null, limit_type?: string | null, limit_value?: number | null }>, service_usage_by_user: Array<{ __typename?: 'service_usage_by_user', companyId?: number | null, userId?: number | null, id?: number | null, month?: any | null, year?: any | null, count?: any | null, name?: string | null, limit_type?: string | null, limit_value?: number | null }>, companyScreeners: { __typename?: 'screeners_aggregate', aggregate?: { __typename?: 'screeners_aggregate_fields', count: number } | null }, myScreeners: { __typename?: 'screeners_aggregate', aggregate?: { __typename?: 'screeners_aggregate_fields', count: number } | null } };

export type UsageFieldParamsQueryVariables = Exact<{ [key: string]: never; }>;


export type UsageFieldParamsQuery = { __typename?: 'query_root', service_usage_by_company: Array<{ __typename?: 'service_usage_by_company', month?: any | null, year?: any | null }> };

export type MyCompleteBalanceSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MyCompleteBalanceSubscription = { __typename?: 'subscription_root', company_balance: Array<{ __typename?: 'company_balance', balance?: any | null, accountBalance?: any | null }> };

export type MyUserQueryVariables = Exact<{ [key: string]: never; }>;


export type MyUserQuery = { __typename?: 'query_root', user: Array<{ __typename?: 'user', id: number, firstName?: string | null, lastName?: string | null, email?: string | null, isActive: boolean, company?: { __typename?: 'company', name: string, active: boolean, clientType?: string | null } | null }> };

export const DossiersDocument = gql`
    query Dossiers($parentId: uuid_comparison_exp = {_is_null: true}, $allowOrphanedDocs: Boolean = false, $order_by_doc: [document_order_by!] = {}, $order_by_dossier: [dossier_order_by!] = {}, $from: timestamp = "2022/01/01", $to: timestamp = "2099/01/01") {
  children: dossier(
    where: {parentId: $parentId, createdAt: {_gte: $from, _lte: $to}}
    order_by: $order_by_dossier
  ) {
    parent {
      id
      title
    }
    id
    title
    createdAt
    createdBy
    screener {
      type
      subType
    }
    children_aggregate {
      aggregate {
        count
      }
    }
    documents_aggregate {
      aggregate {
        count
      }
    }
  }
  document(
    where: {_and: [{dossierId: $parentId}, {dossierId: {_is_null: $allowOrphanedDocs}}], createdAt: {_gte: $from, _lte: $to}}
    order_by: $order_by_doc
  ) {
    name
    id
    createdAt
  }
  current: dossier(where: {id: $parentId}) {
    title
    id
    screener {
      type
      subType
    }
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          parent {
            id
            title
            parent {
              id
              title
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DossiersGQL extends Apollo.Query<DossiersQuery, DossiersQueryVariables> {
    override document = DossiersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDocDocument = gql`
    mutation DeleteDoc($id: Int = 10) {
  delete_document_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDocGQL extends Apollo.Mutation<DeleteDocMutation, DeleteDocMutationVariables> {
    override document = DeleteDocDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetFolderParentDossierDocument = gql`
    mutation SetFolderParentDossier($id: uuid = "", $parentId: uuid = "") {
  update_dossier_by_pk(pk_columns: {id: $id}, _set: {parentId: $parentId}) {
    id
    parentId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFolderParentDossierGQL extends Apollo.Mutation<SetFolderParentDossierMutation, SetFolderParentDossierMutationVariables> {
    override document = SetFolderParentDossierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDocumentParentDossierDocument = gql`
    mutation SetDocumentParentDossier($id: Int = 0, $dossierId: uuid = "") {
  update_document_by_pk(pk_columns: {id: $id}, _set: {dossierId: $dossierId}) {
    id
    dossierId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDocumentParentDossierGQL extends Apollo.Mutation<SetDocumentParentDossierMutation, SetDocumentParentDossierMutationVariables> {
    override document = SetDocumentParentDossierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddDossierDocument = gql`
    mutation AddDossier($title: String = "", $parentId: uuid = "") {
  insert_dossier_one(object: {parentId: $parentId, title: $title}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddDossierGQL extends Apollo.Mutation<AddDossierMutation, AddDossierMutationVariables> {
    override document = AddDossierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RenameDossierDocument = gql`
    mutation RenameDossier($title: String = "", $id: uuid = "") {
  update_dossier_by_pk(pk_columns: {id: $id}, _set: {title: $title}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RenameDossierGQL extends Apollo.Mutation<RenameDossierMutation, RenameDossierMutationVariables> {
    override document = RenameDossierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDossierDocument = gql`
    mutation DeleteDossier($id: uuid = "") {
  delete_dossier_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDossierGQL extends Apollo.Mutation<DeleteDossierMutation, DeleteDossierMutationVariables> {
    override document = DeleteDossierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetVisuraIpotecariaDocument = gql`
    query GetVisuraIpotecaria($_eq: String = "", $_contains: String = "") {
  request(
    where: {params: {_cast: {String: {_ilike: $_contains}}}, service: {slug: {_eq: $_eq}}}
  ) {
    id
    queryResult
    createdAt
    params
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetVisuraIpotecariaGQL extends Apollo.Query<GetVisuraIpotecariaQuery, GetVisuraIpotecariaQueryVariables> {
    override document = GetVisuraIpotecariaDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRichiestePerSlugDocument = gql`
    query GetRichiestePerSlug($_eq: String = "") {
  request(where: {service: {slug: {_eq: $_eq}}}, order_by: {createdAt: desc}) {
    id
    params
    createdAt
    queryResult
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRichiestePerSlugGQL extends Apollo.Query<GetRichiestePerSlugQuery, GetRichiestePerSlugQueryVariables> {
    override document = GetRichiestePerSlugDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRequestsDocument = gql`
    subscription GetRequests($status: request_status_enum_comparison_exp = {}, $archived: Boolean_comparison_exp = {}, $_gte: timestamp = "2022/01/01", $_lte: timestamp = "2099/01/01", $serviceId: Int_comparison_exp = {}, $_ilike: String = "%") {
  request(
    where: {status: $status, archived: $archived, createdAt: {_gte: $_gte, _lte: $_lte}, serviceId: $serviceId, params: {_cast: {String: {_ilike: $_ilike}}}}
    order_by: {createdAt: desc}
  ) {
    service {
      name
      fields
    }
    errorDescription
    params
    notes
    status
    archived
    id
    createdAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRequestsGQL extends Apollo.Subscription<GetRequestsSubscription, GetRequestsSubscriptionVariables> {
    override document = GetRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyDocumentsDocument = gql`
    subscription MyDocuments($_gte: timestamp = "2022/01/01", $_lte: timestamp = "2099/01/01", $serviceId: Int_comparison_exp = {}, $_ilike: String = "%") {
  document(
    where: {createdAt: {_gte: $_gte, _lte: $_lte}, dossierId: {_is_null: true}, request: {serviceId: $serviceId, params: {_cast: {String: {_ilike: $_ilike}}}}}
    order_by: {createdAt: desc}
  ) {
    id
    name
    lastOpened
    requestId
    request {
      createdAt
      params
      notes
      service {
        name
        fields
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyDocumentsGQL extends Apollo.Subscription<MyDocumentsSubscription, MyDocumentsSubscriptionVariables> {
    override document = MyDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HideRequestDocument = gql`
    mutation HideRequest($id: Int = 10, $archived: Boolean = false) {
  update_request_by_pk(pk_columns: {id: $id}, _set: {archived: $archived}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HideRequestGQL extends Apollo.Mutation<HideRequestMutation, HideRequestMutationVariables> {
    override document = HideRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyDocParamsDocument = gql`
    query MyDocParams {
  service(
    distinct_on: name
    where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyDocParamsGQL extends Apollo.Query<MyDocParamsQuery, MyDocParamsQueryVariables> {
    override document = MyDocParamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyScreenersDocument = gql`
    query MyScreeners($type: bpchar_comparison_exp = {}, $subType: bpchar_comparison_exp = {}) {
  screeners(where: {type: $type, subType: $subType}) {
    id
    checks_aggregate {
      aggregate {
        count
      }
      nodes {
        createdAt
        detectedChanges
        id
        response
        userAck
      }
    }
    active
    configuration
    createdAt
    expiresOn
    subType
    type
    dossierId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyScreenersGQL extends Apollo.Query<MyScreenersQuery, MyScreenersQueryVariables> {
    override document = MyScreenersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AckCheckDocument = gql`
    mutation AckCheck($id: Int = 10, $userAck: Boolean = false) {
  update_screener_check_by_pk(pk_columns: {id: $id}, _set: {userAck: $userAck}) {
    userAck
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AckCheckGQL extends Apollo.Mutation<AckCheckMutation, AckCheckMutationVariables> {
    override document = AckCheckDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SwitchActiveDocument = gql`
    mutation SwitchActive($active: Boolean = false, $id: uuid = "") {
  update_screeners_by_pk(_set: {active: $active}, pk_columns: {id: $id}) {
    id
    active
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SwitchActiveGQL extends Apollo.Mutation<SwitchActiveMutation, SwitchActiveMutationVariables> {
    override document = SwitchActiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyActivationsDocument = gql`
    query MyActivations {
  workarea_activation {
    createdAt
    startDate
    endDate
    bundle {
      name
    }
    workarea {
      slug
      introText
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyActivationsGQL extends Apollo.Query<MyActivationsQuery, MyActivationsQueryVariables> {
    override document = MyActivationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MySubscriptionsDocument = gql`
    query MySubscriptions {
  subscription {
    service {
      name
      fields
      id
      isActive
      channel
      description
      priceDescription
      internalDescription
      slug
      priceCalculation
      executionPolicy
      postConfirmButtons
      targets
      dataChannel
      submitText
      status
      notes
    }
    id
    isActive
    limit_type
    limit_value
    price
    activationId
    startDate
    endDate
    activation {
      bundle {
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MySubscriptionsGQL extends Apollo.Query<MySubscriptionsQuery, MySubscriptionsQueryVariables> {
    override document = MySubscriptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTransactionsDocument = gql`
    query MyTransactions($order_by: [ledger_order_by!] = {createdAt: desc}, $pending: Boolean_comparison_exp = {}, $serviceId: Int_comparison_exp = {}, $_gte: timestamp = "2022/01/01", $_lte: timestamp = "2099/01/01") {
  ledger(
    where: {userId: {_is_null: false}, amount: {_neq: 0}, pending: $pending, serviceId: $serviceId, createdAt: {_gte: $_gte, _lte: $_lte}}
    order_by: $order_by
  ) {
    createdAt
    service {
      name
      fields
    }
    amount
    description
    request {
      params
      notes
      status
    }
    pending
    userId
    requestId
    companyId
    walletId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTransactionsGQL extends Apollo.Query<MyTransactionsQuery, MyTransactionsQueryVariables> {
    override document = MyTransactionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTransactionParamsDocument = gql`
    query MyTransactionParams {
  ledger(distinct_on: [pending]) {
    pending
  }
  service(
    distinct_on: name
    where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTransactionParamsGQL extends Apollo.Query<MyTransactionParamsQuery, MyTransactionParamsQueryVariables> {
    override document = MyTransactionParamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyDepositsDocument = gql`
    query MyDeposits($order_by: [ledger_order_by!] = {createdAt: desc}) {
  ledger(
    where: {amount: {_neq: 0}, userId: {_is_null: true}}
    order_by: $order_by
  ) {
    createdAt
    amount
    description
    pending
    wallet {
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyDepositsGQL extends Apollo.Query<MyDepositsQuery, MyDepositsQueryVariables> {
    override document = MyDepositsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyRequestsDocument = gql`
    query MyRequests($status: request_status_enum_comparison_exp = {}, $serviceId: Int_comparison_exp = {}, $order_by: [request_order_by!] = {createdAt: desc}, $_gte: timestamp = "2022/01/01", $_lte: timestamp = "2099/01/01") {
  request(
    where: {status: $status, serviceId: $serviceId, createdAt: {_gte: $_gte, _lte: $_lte}}
    order_by: $order_by
  ) {
    service {
      name
      fields
    }
    id
    queryResult
    createdAt
    params
    status
    notes
    transactions {
      amount
      pending
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyRequestsGQL extends Apollo.Query<MyRequestsQuery, MyRequestsQueryVariables> {
    override document = MyRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyRequestParamsDocument = gql`
    query MyRequestParams {
  request(distinct_on: [status]) {
    status
  }
  service(
    distinct_on: name
    where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyRequestParamsGQL extends Apollo.Query<MyRequestParamsQuery, MyRequestParamsQueryVariables> {
    override document = MyRequestParamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyUsageDocument = gql`
    query MyUsage($month: numeric = 12, $year: numeric = 2022, $companyId: Int!, $userId: Int!) {
  service_usage_by_company(where: {month: {_eq: $month}, year: {_eq: $year}}) {
    companyId
    id
    month
    year
    count
    name
    limit_type
    limit_value
  }
  service_usage_by_user(where: {month: {_eq: $month}, year: {_eq: $year}}) {
    companyId
    userId
    id
    month
    year
    count
    name
    limit_type
    limit_value
  }
  companyScreeners: screeners_aggregate(
    where: {active: {_eq: true}, companyId: {_eq: $companyId}}
  ) {
    aggregate {
      count
    }
  }
  myScreeners: screeners_aggregate(
    where: {active: {_eq: true}, userId: {_eq: $userId}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyUsageGQL extends Apollo.Query<MyUsageQuery, MyUsageQueryVariables> {
    override document = MyUsageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsageFieldParamsDocument = gql`
    query UsageFieldParams {
  service_usage_by_company(
    distinct_on: [month, year]
    order_by: {year: desc, month: desc}
  ) {
    month
    year
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsageFieldParamsGQL extends Apollo.Query<UsageFieldParamsQuery, UsageFieldParamsQueryVariables> {
    override document = UsageFieldParamsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyCompleteBalanceDocument = gql`
    subscription MyCompleteBalance {
  company_balance {
    balance
    accountBalance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyCompleteBalanceGQL extends Apollo.Subscription<MyCompleteBalanceSubscription, MyCompleteBalanceSubscriptionVariables> {
    override document = MyCompleteBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyUserDocument = gql`
    query MyUser {
  user {
    id
    firstName
    lastName
    email
    isActive
    company {
      name
      active
      clientType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyUserGQL extends Apollo.Query<MyUserQuery, MyUserQueryVariables> {
    override document = MyUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }