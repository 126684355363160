import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from '../../shared.service';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, scale: 0, "border-radius": 100 }),
            animate('.2s ease-out',
              style({ opacity: 1, scale: 1, "border-radius": 0 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1, scale: 1, "border-radius": 0 }),
            animate('0.1s ease-in',
              style({ opacity: 0, scale: 0, "border-radius": 100 }))
          ]
        )
      ]
    )
  ]
})
export class LoadingComponent implements OnInit {

  constructor(
    private sharedService: SharedService) {
  }

  formLoading: string = ""
  ngOnInit(): void {
    this.sharedService.loadingMsgObservable$.subscribe(msg => {
      this.formLoading = msg
    })
  }

}
