import { Component, OnInit } from '@angular/core';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { catastoConfig } from './catasto.ui.config';


@Component({
  selector: 'app-catasto-consultazione',
  templateUrl: './consultazione.component.html',
  styles: [
  ]
})
export class ConsultazioneCatastoComponent implements OnInit {

  constructor(
  ) {

  }

  entrypoints: EntryPoint[] = catastoConfig

  ngOnInit(): void {

  }

}
