<div data-cy="background" class="flex justify-center items-center fixed inset-0 z-[1100] bg-black  bg-opacity-60"
    *ngIf="showPanel" (click)="onStay.emit()">
    <div
        class="flex flex-col rounded-lg shadow-md px-4 py-6 w-1/4 md:w-1/3 sm:w-3/5 xs:w-2/3 dark:bg-black bg-white border-primary border-t-2 border-b-2 text-black dark:text-white">
        <div class="flex items-center justify-between space-x-2 mb-4">

            <div>
            </div>
            <div class="text-xl">{{title}}</div>

            <button (click)="onStay.emit()" data-cy="dismiss"><svg
                    class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all" viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="12" cy="12" r="10" />
                    <line x1="15" y1="9" x2="9" y2="15" />
                    <line x1="9" y1="9" x2="15" y2="15" />
                </svg></button>

        </div>

        <div class="flex flex-grow items-center pb-2 mb-2 text-sm sm:space-x-12 justify-between ">
        </div>
        <div class="text-sm w-full font-semibold flex justify-around transition-opacity duration-300">
            <button *ngFor="let button of buttonsConfig" type="button"
                [routerLink]="possibleDestinations[button.destination]"
                class="py-2 px-4 border-primary border hover:bg-primary hover:text-white text-primary hover:bg-white-700 focus:ring-light-100 focus:ring-offset-light-700 transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring focus:ring-offset-2  rounded-lg ">
                {{button.label}}</button>
        </div>
    </div>
</div>