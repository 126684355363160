import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Route } from '@angular/router';
import { combineLatest, map, tap } from 'rxjs';
import { SharedService } from '../../shared.service';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';

@Component({
  selector: 'app-workarea',
  templateUrl: './workarea.component.html',
  //   styles: [`
  //  :host{
  //      height: 100%;
  //      position: relative;
  //  }`]
})
export class WorkareaComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) { }
  menuitem!: Route | null | undefined
  currentWorkarea?: any = null
  showIntroText: boolean = true
  ready: boolean = false
  ngOnInit(): void {
    this.menuitem = this.route.routeConfig?.children ? this.route.routeConfig : this.route.parent?.routeConfig

    this.subscriptionService.activations$.pipe(
      tap((activations) => {
        if (this.route.parent?.routeConfig?.data?.['workareaSlug']) {
          this.currentWorkarea = activations.workarea_activation.find(waa => waa.workarea.slug == this.route.parent?.routeConfig?.data?.['workareaSlug'])?.workarea
        }
        this.ready = true
      })
    ).subscribe()
  }

}
