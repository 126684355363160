import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { FieldPatterns, ServiceParamFieldModel } from 'src/app/models/service.model';

@Component({
  selector: 'app-navigazione-form',
  templateUrl: './navigazione-form.component.html',
  styles: [`
  :host{
      width: 100%;
      position: relative;
  }`
  ]
})
export class NavigazioneFormComponent implements OnInit {
  @Input() fields!: ServiceParamFieldModel[]
  @Input() submitText?: string
  @Input() paymentText?: string = ''
  @Input() initData?: any = null
  @Output() onSubmit = new EventEmitter<any>()
  formControlGroup!: UntypedFormGroup;
  fp = FieldPatterns

  constructor(private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.formControlGroup = this.formBuilder.group(
      Object.fromEntries(
        this.fields.
          map(
            (p: ServiceParamFieldModel) => [
              p.name,
              new FormControl(
                this.initData?.[p.name] || p.defaultValue,
                [])]
          ) || []
      )
    )
  }

  setUploadLocation(name: string, data: any) {
    this.setField(name, data.fileName)
  }

  setField(name: string, value: any) {
    this.formControlGroup.patchValue({ [name]: value })
  }

  setCascadeFields(cascadeFields: { incomingFieldName: string, outputFieldName: string }[], raw: any) {
    cascadeFields.map(cf => {
      this.setField(cf.outputFieldName, raw?.[cf.incomingFieldName] || null)
    })
  }

  emitFormValues() {
    this.onSubmit.emit(this.formControlGroup.value)
  }
  collectRequiredParams(data: { input: string, output: string }[]) {
    return Object.fromEntries(
      data.map(rf => [rf.output, this.formControlGroup.value[rf.input]])
    )
  }
  areDependenciesFullfilled(data: ServiceParamFieldModel | undefined) {
    if (!data) return true
    return (data.dependencies || []).every(k => {
      let check = function (value: any, expected?: any) {
        if (expected == undefined || expected == null) {
          return !!value
        } else {
          return value == expected
        }
      }
      if (typeof k === 'string') {
        return check(this.formControlGroup.value[k || ''])
      } else {
        return check(this.formControlGroup.value[k.fieldName || ''], k.value)
      }
    })
  }
}
