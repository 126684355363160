import { EntryPoint, NavigationConfig } from "src/app/models/navigation.shared.model"
import { FieldPatterns } from "src/app/models/service.model"


export const pregiudizievoliConfig: EntryPoint[] = [
    {
        name: "Verifica pregiudizievoli immobiliari",
        isActive: true,
        serviceSlug: 'pregiudizievoli-immobiliari-dettaglio',
        submitText: 'Richiedi',
        fieldsFromService: true,
        paymentRequired: true,
        fields: [{
            name: "codiceFiscale",
            description: "Codice Fiscale",
            pattern: "EXTENDED_CF",
            required: true
        }, {
            name: "cognome",
            description: "Cognome o Denominazione",
            required: true
        }, {
            name: "nome",
            description: "Nome"
        }, {
            name: "_template_report",
            description: "Template Report",
            type: 'hidden',
            defaultValue: 'SuntoPregiudizievoli'
        }],
        navigations: [
            {
                name: "Negatività",
                nameAfterSelect: "",
                channel: "pregiudizievoli:sunto",
                outputParamName: "pregiudizievoli",
                isSelectable: false,
                details: [
                    { title: "Soggetto", description: "", icon: "fa-regular fa-user", field: 'soggetto' },
                    { title: "Codice Fiscale", description: "", icon: "fa-regular fa-user", field: 'codiceFiscale' },
                    { title: "Pregiudizievoli immobiliari", description: "", icon: "fa-regular fa-user", field: '_pregiudizievoli', type: 'light' },
                ],
                actions: [
                    { title: "Salva PDF", slug: "pregiudizievoli-immobiliari-sunto", type: "request" },
                    { title: "Dettaglio Pregiudizievoli", slug: "pregiudizievoli-immobiliari-dettaglio", type: "request", params: [{ userParam: 'callId', requestParam: 'callId' }] }
                ]
            }
        ]
    }]
