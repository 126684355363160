import { Component, OnInit } from '@angular/core';
import { Route, ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: []
})
export class BreadcrumbsComponent implements OnInit {
  elements: any[] = []
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.elements = this.populateElements([])
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        this.elements = this.populateElements([])
      });
  }

  populateElements(acc: any[], activatedRoute = this.activatedRoute): any[] {
    if (activatedRoute.snapshot.data?.['name']) {
      acc.push({
        name: activatedRoute.snapshot.data?.['name'],
        icon: activatedRoute.snapshot.data?.['icon'],
        url: [...(acc[acc.length - 1]?.url || []), activatedRoute.snapshot.url[0]?.path || 'pippo']
      })
    }
    if (activatedRoute.firstChild) {
      return this.populateElements(acc, activatedRoute.firstChild)
    }
    return acc
  }
  ngOnInit(): void {
  }

}
