import { Component, OnInit } from '@angular/core';
import { gql, QueryRef } from 'apollo-angular'
import { combineLatest, map, Observable, of, Subject, tap } from 'rxjs';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { MyTransactionParamsGQL, MyTransactionsGQL, MyTransactionsQuery } from 'src/graphql/generated';
import { read, utils, writeFileXLSX } from "xlsx";
import { UserService } from '../user.service';

const TRANSACTIONS = gql`
query MyTransactions($order_by: [ledger_order_by!] = {createdAt: desc}, $pending: Boolean_comparison_exp = {}, $serviceId: Int_comparison_exp = {}, $_gte: timestamp = "2022/01/01", $_lte: timestamp = "2099/01/01") {
  ledger(where: { userId: {_is_null:false},amount: {_neq: 0}, pending: $pending, serviceId: $serviceId, createdAt: {_gte: $_gte, _lte: $_lte}}, order_by: $order_by) {
    createdAt
    service {
      name
      fields
    }
    amount
    description
    request {
      params
      notes
      status
    }
    pending
    userId
    requestId
    companyId
    walletId
  }
}
`

const TRANSACTION_PARAMS = gql`query MyTransactionParams {
  ledger(distinct_on:[pending]) {
    pending
  }
  service(distinct_on:name, where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}) {id name}
}
`
@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styles: [`
  :host{
      height: 100%;
      position: relative;
  }`]
})
export class BalanceComponent implements OnInit {

  constructor(
    private getRequests: MyTransactionsGQL,
    private getParams: MyTransactionParamsGQL,
    public userService: UserService) {
    combineLatest([this.selectedService, this.selectedStatus, this.orderCondition, this.selectedStartDate, this.selectedEndDate]).subscribe(a => {
      this.items$ = this.getRequests.watch({
        order_by: a[2] || undefined,
        serviceId: a[0] || undefined,
        pending: a[1] || undefined,
        _gte: a[3]?.text || undefined,
        _lte: a[4]?.text || undefined
      }).valueChanges.pipe(map(x => x.data.ledger))
    })
    this.params$ = this.getParams.watch().valueChanges.pipe(
      map(x => x.data),
      map(x => (
        {
          statuses: x.ledger.map(r => ({ text: r.pending ? 'Autorizzato' : 'Confermato', value: { _eq: r.pending } })),
          services: x.service.map(s => ({ text: s.name, value: { _eq: s.id } }))
        }
      ))
    )
    this.selectedStartDate.subscribe(x => {
      this.minTo = x?.text || ''
    })
    this.selectedEndDate.subscribe(x => {
      this.maxFrom = x?.text || ''
    })
    this.selectedService.next(null)
    this.selectedStatus.next(null)
    this.orderCondition.next(null)
    this.selectedStartDate.next(null)
    this.selectedEndDate.next(null)

  }

  minTo: string = ''
  maxFrom: string = ''
  p: number = 1;
  orderCondition = new Subject<any>()
  params$!: Observable<any>
  selectedStatus = new Subject<any>()
  selectedService = new Subject<any>()
  selectedStartDate = new Subject<any>()
  selectedEndDate = new Subject<any>()
  items$!: Observable<MyTransactionsQuery['ledger'] | any>
  details: { title: string, description?: string, order: number, sortable?: boolean, asc?: any, desc?: any }[] = [{
    description: "",
    title: "Data",
    sortable: true,
    order: 0,
    asc: { createdAt: 'asc' },
    desc: { createdAt: 'desc' },
  },
  {
    description: "",
    title: "Prodotto",
    order: 0,
    sortable: true,
    asc: { service: { name: 'asc' } },
    desc: { service: { name: 'desc' } }
  },
  {
    description: "",
    title: "Parametri",
    order: 0
  },
  {
    description: "",
    title: "Importo",
    order: 0
  }]

  ngOnInit(): void {
  }

  sortBy(idx: number) {
    this.details.forEach((item, idy) => {
      if (idx == idy) {
        item.order = item.order <= 0 ? 1 : -1
      } else {
        item.order = 0
      }
    }
    )
    let item = this.details[idx]
    this.orderCondition.next(item?.order == -1 ? item.asc : item?.order == 1 ? item.desc : {})

  }

  xmlExport(items: MyTransactionsQuery['ledger']) {

    /* flatten objects */
    const rows = items.map(row => ({
      createdAt: row.createdAt,
      service: row.service?.name || '',
      notes: this.userService._getParamByFieldPosition(row.service?.fields, row.request, 'title'),
      status: row.pending ? 'authorized' : 'confirmed',
      amount: row.amount
    }));

    /* generate worksheet and workbook */
    const ws = utils.json_to_sheet(rows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");


    /* calculate column width */
    const max_width = rows.reduce((w: any, r: any) => Math.max(w, r.service?.name?.length || 10), 10);
    ws["!cols"] = [{ wch: max_width }];

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFileXLSX(wb, "EstrattoConto.xlsx");

  }
}
