import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, delay, map, Observable, of, Subject, tap, throwIfEmpty } from 'rxjs';
import { PaginatedModel } from 'src/app/models/dto/PaginatedModel';
import { EntryPoint, NavigationActionsConfig, NavigationDetailsConfig } from 'src/app/models/navigation.shared.model';
import { RequestModel } from 'src/app/models/request.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { ActionComponentData } from 'src/app/shared/components/actionpopup/actionpopup.component';
import { QueriesService } from '../services/queries.service';
import { RequestService } from '../services/request.service';
import { SubscriptionService } from '../services/subscription.service';
import { pregiudizievoliConfig } from './pregiudizievoli.ui.config';
import { GetRichiestePerSlugGQL } from 'src/graphql/generated';


@Component({
  selector: 'app-conservatoria',
  templateUrl: './pregiudizievoli.component.html',
  styles: [
  ]
})
export class PregiudizievoliComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getRichiestePregiudizievoli: GetRichiestePerSlugGQL
  ) { }
  visuraSubscription?: SubscriptionModel
  entrypoints: EntryPoint[] = pregiudizievoliConfig
  shortcuts$?: Observable<{ title: string, subtitle: string, uuid: string, date: string | Date }[]>
  serviceSlug = 'pregiudizievoli-immobiliari-dettaglio'
  showPrevious: boolean = false
  showSubmissionConfirm: boolean = false

  ngOnInit(): void {

    this.shortcuts$ = this.getRichiestePregiudizievoli.watch({ _eq: this.serviceSlug }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
      map(s => s.data.request),
      map(s => s.map(
        r => ({
          title: [r.params.nome, r.params.cognome].join(' '),
          subtitle: r.params.codice_fiscale,
          uuid: r.queryResult?.uuid,
          date: r.createdAt || ''
        })
      ))
    )


  }

  onFormRequestSubmit(res: any) {
    this.showSubmissionConfirm = true
  }

}
