<div class="flex justify-between">
    <div class="flex justify-start gap-0.5">
        <!-- base operations selector -->
        <button *ngFor="let item of entrypoints; let j = index;" [attr.data-cy]="'tab-' + j"
            class="p-2 px-4 rounded-t-md  border-0  border-t-4 transition-all  text-black dark:text-white  dark:bg-reopera-900 bg-white disabled:italic disabled:cursor-not-allowed disabled:!text-gray-500"
            [ngClass]="[
        item.name == currentEntrypoint?.name ? 
        'border-black font-semibold !bg-opacity-100' : 
        'border-white !bg-opacity-40']" [routerLink]="[]" [queryParams]="{'tab': j}">
            {{item.name}}
        </button>
    </div>
    <div></div>
</div>
<div class="px-6 pt-4 ">
    <div class="w-full">
        <div class="-mx-4 sm:-mx-8 px-4 sm:px-4 overflow-x-auto relative">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden"
                *ngIf="monitoraggi$ | async as monitoraggi">
                <table class="min-w-full leading-normal">
                    <thead>
                        <tr>
                            <th scope="col"
                                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                                Tipo
                            </th>
                            <th *ngFor="let det of currentEntrypoint?.details || []" scope="col"
                                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                                {{det.name}}
                            </th>
                            <th scope="col"
                                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                                Eventi
                            </th>
                            <th scope="col"
                                class="px-5 py-3 bg-white  border-b border-gray-200 text-primary truncate text-left text-sm uppercase font-normal">
                                Stato
                            </th>

                            <th scope="col"
                                class="px-4 py-3 bg-white  border-b border-gray-200 text-primary text-left text-sm uppercase font-normal">
                            </th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <ng-container *ngIf="monitoraggi?.length; else empty">
                            <tr *ngFor="let item of monitoraggi; index as i"
                                class="bg-white hover:bg-gray-100 even:bg-gray-50">
                                <td class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <div *ngIf="item.subType">
                                        <i class="w-5 h-5 fa fa-solid" [ngClass]="iconMap[item.subType]"></i>
                                    </div>
                                </td>
                                <td *ngFor="let det of currentEntrypoint?.details"
                                    class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap uppercase">
                                    <div>{{det.field(item)}}</div>
                                </td>
                                <td class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <button *ngIf="item.checks_aggregate.aggregate?.count" (click)="currentEventList=i"
                                        class="rounded-full text-secondary p-1 relative">
                                        <i class="w-5 h-5 fa fa-solid fa-message"></i>
                                        <span
                                            class="text-xs leading-4 rounded-full bg-primary text-white w-4 h-4 text-center absolute -top-2 -right-2"></span>
                                    </button>
                                </td>
                                <td class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <div class="relative inline-block w-10 mr-2 align-middle select-none">
                                        <input type="checkbox" [checked]="item.active"
                                            (change)="switchActive(item.id, item.active)"
                                            class="checked:bg-pink-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
                                        <label
                                            class="block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer">
                                        </label>
                                    </div>
                                </td>
                                <td class="px-4 py-2 border-b border-gray-200 text-sm">

                                    <div class="flex items-center">
                                        <div class="flex-shrink flex flex-wrap">

                                            <a title="Vai alla pratica"
                                                [routerLink]="['/user/consolle-immobiliare/dossier/', item.dossierId]"><i
                                                    class="fa fa-solid fa-folder w-5 h-5 hover:rotate-6 transition-transform text-secondary"></i></a>
                                            <!--
                                        <button
                                            class="mx-2 my-1 px-2 py-1 rounded border-black border flex items-center text-black bg-white hover:text-white hover:bg-black transition-colors">
                                            bla</button> -->
                                            <!-- <button (click)="rimuovi(item.value)" [disabled]="item.raw.attiva"
                                            class="disabled:hover:opacity-30 disabled:cursor-not-allowed disabled:opacity-50 mx-2 my-1 px-2 py-1 rounded  border-primary border text-primary bg-white hover:text-white hover:bg-primary transition-colors">
                                            Rimuovi</button> -->

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #empty>
                            <tr class="bg-white hover:bg-gray-100 even:bg-gray-50">
                                <td colspan="9" class="px-4 py-3 border-b border-gray-200 text-sm whitespace-nowrap">
                                    <div
                                        class="flex flex-col items-center justify-center w-full text-gray-500 hover:animate-pulse gap-2">
                                        <i class="w-20 h-20 fa fa-solid"
                                            [ngClass]="[currentEntrypoint.subType == 'I' ? 'fa-snowplow' : 'fa-user-secret']"></i>
                                        <span class="text-2xl">Spiacenti, nessun record trovato!</span>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>

                <div class="flex justify-center items-center fixed inset-0 z-40 bg-black  bg-opacity-60"
                    *ngIf="currentEventList>-1">
                    <div
                        class="flex flex-col rounded-lg shadow-md px-4 py-6 w-1/3 md:w-1/2 sm:w-4/5 xs:w-5/6 dark:bg-black bg-white border-primary border-t-2 border-b-2 text-black dark:text-white">
                        <div class="flex items-center justify-between space-x-2 mb-4">
                            <div>
                                <div class="text-xl">Eventi</div>
                            </div>
                            <button (click)="currentEventList=-1"><svg
                                    class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="12" r="10" />
                                    <line x1="15" y1="9" x2="9" y2="15" />
                                    <line x1="9" y1="9" x2="15" y2="15" />
                                </svg></button>
                        </div>
                        <div class="flex flex-grow items-center pb-2 mb-2 text-sm sm:space-x-12 justify-between ">
                            <app-eventi [list]="monitoraggi[currentEventList].checks_aggregate.nodes"
                                (onRegisterAsRead)="setStatoLettura($event, true)" class="w-full h-full">
                            </app-eventi>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <a *ngIf="currentEntrypoint.subType == 'S'" title="Aggiungi nuovo soggetto"
            [routerLink]="['/user/service/','monitoraggio-soggetti']"
            class="text-white fixed bottom-4 right-4 p-3 w-12 h-12 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
            <i class="w-6 h-6 fa-solid fa-person-circle-check"></i>
        </a>
        <a *ngIf="currentEntrypoint.subType == 'I'" title="Aggiungi nuovo immobile"
            [routerLink]="['/user/service/','monitoraggio-immobili']"
            class="text-white fixed bottom-4 right-4 p-3 w-12 h-12 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
            <i class="w-6 h-6 fa-solid fa-house-circle-check"></i>
        </a>

        <!-- <button title="Caricamento Batch" disabled
            class="text-white fixed bottom-20 right-4 p-0 w-12 h-12 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
            <i class="w-6 h-6 fa-solid fa-file-circle-check"></i>
        </button> -->
    </div>
</div>