import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timeout } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';

export class QueryPostData {
  channel!: string
  data: any
  mock?: boolean = false
}
@Injectable({
  providedIn: 'root'
})
export class QueriesService {
  static URL = '/api/v1/queries';

  constructor(public http: HttpClient, private localstorageService: LocalStorageService) { }

  post(data: QueryPostData, textResponse: boolean = false): Observable<any> {
    let shouldMock = data.mock || false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data.channel) || lsData.includes('*')
    } catch (err) {

    }
    let q: Observable<any> = textResponse ? this.http.post(
      QueriesService.URL, data, {
      responseType: 'text'
    }) :
      this.http.post<QueryPostData>(
        QueriesService.URL, { ...data, mock: shouldMock });
    return q.pipe(
      timeout(30000)
    )
  }
}
