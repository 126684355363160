<div class="">
    <div class="relative flex items-center rounded-lg bg-white dark:bg-dark ">

        <input
            class=" rounded-lg flex-1 appearance-none border w-full pl-4 pr-9 py-4 border-gray-300  bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
            [placeholder]="title" [value]="keyword" #theInput data-cy="autocomplete-input">
        <div class="absolute right-2 h-5 w-10 text-primary flex">
            <button type="button" *ngIf="mode== 'select' && !isLoading" (click)="isOpen =!isOpen" data-cy="open-btn">
                <svg class="h-5 w-5 transition-transform" [class.rotate-90]="isOpen" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polyline points="6 9 12 15 18 9" />
                </svg>
            </button>
            <button type="button" *ngIf="!isLoading && keyword" (click)="clear()" data-cy="clear-btn">
                <svg class="h-5 w-5 " width=" 24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
            </button>
            <svg *ngIf="isLoading" class="h-5 w-5 animate-spin" width="24" height="24" viewBox="0 0 24 24"
                stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
                <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" />
            </svg>
        </div>
    </div>
    <div class="relative">
        <ul *ngIf="items.length && isOpen"
            class="absolute z-20 max-h-40 overflow-y-auto list-none rounded-lg flex-1 appearance-none border border-gray-300 w-full bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent">
            <li *ngFor="let item of items">
                <button data-cy="item-btn"
                    class="text-left w-full min-h-10 rounded-lg py-2 px-4 hover:bg-black hover:bg-opacity-20 hover:text-primary"
                    (click)="selectEvent(item)">
                    {{itemPrefix}} {{item.text}}&nbsp;
                </button>
            </li>
        </ul>
    </div>
</div>