<div class="w-full text-black dark:text-white">
    <div
        class="flex flex-col min-w-[240px] min-h-[240px] rounded-lg hover:drop-shadow-xl hover:scale-105 hover:bg-opacity-100 p-4 pb-3 w-full h-full shadow-primary  bg-white dark:bg-black border-primary dark:border-secondary border relative group transition-all">
        <div class="flex items-end space-x-2 mb-1">
            <div class="text-2xl font-bold flex max-w-full">
                <ng-content select="[header]"></ng-content>
            </div>
        </div>
        <div>
            <div *ngIf="showDetails || showPrice" class="flex items-center pb-2 mb-2 text-xs space-x-1  justify-start">
                <button *ngIf="showDetails" class="" (click)="toggleOpen()"><svg class="h-5 w-5  text-primary"
                        fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg></button>
                <button *ngIf="showPrice" class="" (click)="toggleOpen()"><svg class="h-5 w-5 text-primary" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg></button>
            </div>
        </div>
        <div class="flex-grow relative">
            <ng-content select="[body]"></ng-content>
        </div>
        <div class="dark:text-dark">
            <div class="flex items-center pb-2 mb-2 text-sm sm:space-x-12 justify-between border-b border-gray-200">
                <ng-content select="[footer]"></ng-content>

            </div>
        </div>
        <div class="text-sm w-full font-semibold flex justify-between gap-2 text-primary">
            <ng-content select="[actions]"></ng-content>
        </div>

    </div>
    <div *ngIf="open" [@inOutAnimation]
        class=" fixed z-40 inset-0 backdrop-blur-lg container transition-all duration-1000 w-full text-white dark:text-black">
        <div class="rounded-lg absolute shadow-xl container px-4 py-6 w-full bg-secondary group inset-4">
            <div class="flex items-center justify-between space-x-2 mb-6">
                <ng-content select="[backheader]"></ng-content>
                <button (click)="toggleOpen()"><svg class="h-5 w-5" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="15" y1="9" x2="9" y2="15" />
                        <line x1="9" y1="9" x2="15" y2="15" />
                    </svg></button>
            </div>
            <div class="dark:text-dark">
                <div
                    class="flex items-center pb-2 mb-2 text-sm sm:space-x-12 justify-between border-b border-white dark:border-black">
                    <ng-content select="[description]"></ng-content>
                </div>
            </div>
            <div
                class="text-sm w-full font-semibold flex justify-end transition-opacity duration-300 opacity-0 group-hover:opacity-100 group-hover:animate-pulse">
                <ng-content select="[actions]"></ng-content>
            </div>
        </div>
    </div>
</div>