<app-navigazione-container [entrypoints]="entrypoints" (onFormRequestSubmit)="searchSubjects($event)">
    <div class="-mt-10 rounded-xl border-t-4 border-primary flex flex-col mb-4 min-h-40 shadow hover:shadow-xl">
        <div class="text-xl font-bold text-center p-2">
            Richiama precedenti ricerche
        </div>
        <div *ngIf="shortcuts$ | async as shortcuts; else empty" class="p-3 flex flex-col">
            <a *ngFor="let shortcut of shortcuts" class="hover:bg-primary/5 p-2 rounded "
                [routerLink]="[shortcut.uuid]">
                <div class="flex justify-between">
                    <span class="font-bold">{{shortcut.title}}</span>
                    <span>{{shortcut.date | date:'dd.MM.yyyy HH:mm'}}</span>
                </div>
            </a>

        </div>
        <ng-template #empty>
            <div class="text-center p-2">Spiacenti, non ci sono ricerche effettuate</div>
        </ng-template>
    </div>
</app-navigazione-container>
<!-- select subject -->
<app-actionpopup *ngIf="firstStageItems" [data]="firstStageData" [showPanel]="firstStageDataSelectionPopupOpen"
    (onConfirm)="onSubjectSelection()" [disabled]="firstStageSelectedItem == -1"
    (onCancel)="firstStageDataSelectionPopupOpen=false" priceLabel="" buttonLabel="Procedi">
    <ng-container *ngIf="firstStageItems?.items?.length; else noItems">
        <button *ngFor="let item of firstStageItems.items; let i = index;"
            class="mb-3 py-2 px-4 bg-gray-200 disabled:bg-primary disabled:text-white hover:bg-gray-400 text-black hover:text-white focus:ring-primary focus:ring-offset-primary transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring focus:ring-offset-2  rounded-lg "
            [disabled]="i == firstStageSelectedItem" (click)="firstStageSelectedItem = i">{{item.text}}</button>
    </ng-container>
    <ng-template #noItems>
        <div class="text-xl text-center">
            Spiacenti, la ricerca non ha prodotto risultati.
        </div>
    </ng-template>
</app-actionpopup>