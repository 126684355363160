import { EntryPoint, NavigationConfig } from "src/app/models/navigation.shared.model"
import { FieldPatterns } from "src/app/models/service.model"

export const bilanciConfig: EntryPoint[] = [
    {
        name: "Ricerca aziende",
        isActive: true,
        fields: [{
            name: "codice_fiscale",
            description: "Codice Fiscale",
            required: true,
            pattern: "COMPANY_CF",
        }, {
            name: "denominazione",
            description: "Denominazione"
        }],
        navigations: [
            {
                name: "Presentazioni",
                nameAfterSelect: "Presentazione selezionata",
                channel: "cciaa:bilanci:presentazioni",
                outputParamName: "id_bilancio",
                isSelectable: false,
                details: [
                    { title: "Data chiusura", description: "", icon: "fa-regular fa-user", field: 'data_chiusura', colspan: 2 },
                    { title: "Tipo Bilancio", description: "", icon: "fa-regular fa-id-card", field: "descrizione_tipo_bilancio" }
                ],
                actions: [
                    {
                        title: "Bilancio ottico", slug: "cciaa-bilancio-ottico", type: 'request',
                        params: [
                            { requestParam: 'id', userParam: 'id' },
                            { requestParam: 'data_chiusura', userParam: 'data_chiusura' },
                            { requestParam: 'codice_tipo_bilancio', userParam: 'codice_tipo_bilancio' },
                            { requestParam: 'denominazione', userParam: 'denominazione' },
                            { requestParam: 'codiceFiscale', userParam: 'codice_fiscale' }]
                    },
                    // {
                    //     title: "Bilancio riclassificato", slug: "cciaa-bilancio-riclassificato", type: 'request',
                    //     params: [
                    //         { requestParam: 'id', userParam: 'id' },
                    //         { requestParam: 'data_chiusura', userParam: 'data_chiusura' },
                    //         { requestParam: 'codice_tipo_bilancio', userParam: 'codice_tipo_bilancio' },
                    //         { requestParam: 'denominazione', userParam: 'denominazione' },
                    //         { requestParam: 'codiceFiscale', userParam: 'codice_fiscale' }]
                    // }
                ]
            }]
    }]
