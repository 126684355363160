<div [@inOutAnimation] *ngIf="showToast"
    class="fixed z-50 bottom-2 right-2 px-4 py-2 w-96 flex flex-col rounded shadow-md"
    [ngClass]="[data.type == 'alert' ? 'bg-red-600 text-white' : data.type == 'warning' ? 'bg-orange-600 text-white' : 'bg-green-400 text-white']">
    <div class="flex items-center justify-between space-x-2 mb-4">
        <div class="flex items-center gap-1">
            <i class="fa w-8 h-8" [ngClass]="[iconMap[data.type|| 'notification']]"></i>
            <div class="text-xl">{{data.title}}</div>
        </div>
        <button (click)="showToast=false"><svg class="h-5 w-5 rounded-full hover:shadow hover:scale-110 transition-all"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10" />
                <line x1="15" y1="9" x2="9" y2="15" />
                <line x1="9" y1="9" x2="15" y2="15" />
            </svg></button>
    </div>
    <div class="flex flex-col flex-grow pb-2 mb-2 text-sm sm:space-x-12 justify-between">
        {{data.subTitle}}
    </div>
    <div class="flex flex-col flex-grow pb-2 mb-2 text-sm sm:space-x-12 justify-between italic">
        {{data.message}}
    </div>
</div>