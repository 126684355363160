<div class="shadow-md rounded-xl w-full h-full p-4 bg-white dark:bg-gray-800 relative overflow-hidden hover:shadow-2xl">
    <div class="relative">
        <div class="flex items-start text-sm  mb-4 rounded justify-between last:mb-0 text-primary"
            *ngFor="let item of details">
            <div class="rounded-lg flex">
                <i class="w-5 h-5 text-primary fa fa-regular" [ngClass]="[item.icon]"></i>
                <div class="flex flex-col  ml-2 items-start justify-between">
                    <p class="dark:text-white text-primary">
                        {{item.title}}
                    </p>
                    <p class="dark:text-white text-primary text-xs h-2">
                        {{item.description}}
                    </p>
                </div>

            </div>
            <div class="text-black dark:text-primary text-right pb-2">
                <span *ngIf="!!item.value; else nodata">
                    <div *ngIf="item.type == 'light'; else text" class="w-5 h-5 rounded-full"
                        [style.background]="item.value">
                        <div class="w-5 h-5 rounded-full animate-ping" [style.background]="item.value"> </div>
                    </div>
                    <ng-template #text>
                        <span>{{item.value}} {{item.suffix}}</span>
                    </ng-template>
                </span>
                <ng-template #nodata>-</ng-template>
            </div>
        </div>
        <div class="w-full flex items-center justify-between py-2">
            <p class="text-gray-800 dark:text-white text-xl">
            </p>
            <button (click)="selectedItem.emit()" *ngIf="selectable"
                class="flex items-center text-sm text-white border-0 focus:outline-none  rounded-md py-1 px-2 transition-all bg-secondary hover:bg-primary hover:shadow-lg hover:text-white">
                Lista <span class="lowercase hover:after:content[' >']">&nbsp;{{nextTitle}}</span>
            </button>
        </div>
        <div *ngIf="disabledWarning"
            class="absolute flex items-center justify-center z-10 inset-0 bg-white dark:bg-gray-800 bg-opacity-80">
            <div class="m-auto -rotate-45 font-bold text-center">{{disabledWarning}}</div>
        </div>
    </div>
    <div *ngIf="actions && actions.length" class="grid grid-cols-3 gap-4 border-t border-secondary pt-2 ">
        <div *ngFor="let item of actions" class="flex flex-col items-center"
            [ngClass]="[!item.availlableForDisabledItems && disabledWarning ? 'hidden': '']">
            <div class="relative group">
                <button (click)="selectedAction.emit(item)"
                    class="block relative rounded-full h-10 w-10 transition-all hover:bg-primary  bg-secondary  text-white  uppercase shadow-md">
                    {{getInitials(item.title)}}
                </button>
                <svg *ngIf="!item.isFree"
                    class="rounded-full p-0.5 absolute bottom-0 right-0 w-4 h-4 -mx-1 -my-1 text-white bg-primary group-hover:bg-secondary hover:scale-150 transition-transform"
                    width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M17.2 7a6 7 0 1 0 0 10" />
                    <path d="M13 10h-8m0 4h8" />
                </svg>
            </div>
            <div class="text-gray-600 dark:text-gray-400 text-xs mt-2 text-center truncate w-24">
                {{item.title}}
            </div>
        </div>
    </div>
</div>