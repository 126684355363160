import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, Observable, switchMap } from 'rxjs';
import { RequestModel } from 'src/app/models/request.model';
import { RequestService } from '../../services/request.service';
import { SubscriptionService } from '../../services/subscription.service';
import { GetRichiestePerSlugGQL } from 'src/graphql/generated';

@Component({
  selector: 'app-lista-pregiudizievoli',
  templateUrl: './lista-pregiudizievoli.component.html',
  styles: [`
  :host{
      height: 100%;
      position: relative;
  }`]
})
export class ListaPregiudizievoliComponent implements OnInit {
  @Input() listLayout: boolean = true
  @Input() uuid!: string
  request$?: Observable<any | undefined>
  serviceSlug = 'pregiudizievoli-immobiliari-dettaglio'

  constructor(private route: ActivatedRoute,
    private getRichiestePregiudizievoli: GetRichiestePerSlugGQL

  ) { }

  ngOnInit(): void {
    this.request$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.getRichiestePregiudizievoli.watch({ _eq: this.serviceSlug }, { fetchPolicy: 'no-cache' }).valueChanges.pipe(
          map(s => s.data.request),
          map(s => {
            return s.find(d => d.queryResult?.uuid == params.get('uuid')!)
          })
        )
      )


    )
  }

}
