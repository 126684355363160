<!--<h1 class="text-4xl font-semibold text-black dark:text-white">
    Good afternoon, <span class="capitalize">{{profileJson?.nickname}}</span>
</h1>
<h2 class="text-md text-black dark:text-white">
    Here&#x27;s what&#x27;s happening with your account today.

</h2> -->

<app-macroservice *ngIf="menuitems.length" [items]="menuitems || []" [columns]="2" title="ReOpera"
    class="h-full flex flex-col">
</app-macroservice>