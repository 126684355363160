import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DocumentModel } from '../../../models/document.model';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  static URL = '/api/v1/file-upload';

  constructor(public http: HttpClient) { }

  single(source: any): Observable<any> {
    const formData = new FormData();

    formData.append('file', source);

    return this.http.post(
      `${UploadService.URL}/single`, formData);
  }


}
