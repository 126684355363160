import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonConfirm } from 'src/app/models/service.model';


@Component({
  selector: 'app-submission-confirm',
  templateUrl: './submission-confirm.component.html',
  styleUrls: []
})
export class SubmissionConfirmComponent implements OnInit {
  @Input() showPanel: boolean = false
  @Output() onStay = new EventEmitter()
  @Input() title: string = "Richiesta eseguita!"
  @Input() buttonsConfig?: ButtonConfirm[]
  possibleDestinations: any = {
    'documents': ['/', 'user', 'consolle-immobiliare', 'documents'],
    'dossiers': ['/', 'user', 'consolle-immobiliare', 'dossier'],
    'monitoraggio-immobili': ['/', 'user', 'consolle-immobiliare', 'catasto', 'monitoraggio'],
    'monitoraggio-soggetti': ['/', 'user', 'consolle-immobiliare', 'catasto', 'monitoraggio']
  }
  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.buttonsConfig = this.buttonsConfig || [{
      label: "Vai all'archivio\ndocumenti",
      destination: 'documents'
    }]
  }

  onGotoHome() {
    this.router.navigate(['/', 'user'])
  }
}
