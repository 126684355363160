<div class="text-secondary w-full h-50 p-8 text-center border-dashed border border-primary relative my-auto mx-0 rounded-lg"
    appDndUpload (fileDropped)="onFileDropped($event)">
    <input class="opacity-0 absolute z-[2] w-full h-full top-0 left-0" type="file" #fileDropRef id="fileDropRef"
        [multiple]="multiple" (change)="fileBrowseHandler($event.target)" [required]="required" />
    <div class="flex justify-around mb-4">
        <i class="fa fa-solid fa-upload w-12 h-12"></i>
        <h3 class="text-2xl font-semibold ">Trascina qui<br>oppure</h3>
    </div>
    <label class="bg-primary py-2 px-4 rounded-full w-50 h-12 text-white" for="fileDropRef">Seleziona
        manualmente</label>
</div>
<div class="mt-6 text-secondary">
    <div class="flex-grow flex p-2 items-center justify-between border border-dashed border-primary"
        *ngFor="let file of files; let i = index">
        <i class="fa fa-solid fa-upload w-12"></i>
        <div class="w-full">
            <h4 class="text-lg font-medium  m-0">
                {{ file?.name }}
            </h4>
            <p class="text-sm font-medium text-primary m-0 mb-1">
                {{ formatBytes(file?.size) }}
            </p>
            <app-upload-progress [progress]="file?.progress"></app-upload-progress>
        </div>
        <button class="ml-2 self-end" (click)="deleteFile(i)"><i class="fa fa-solid fa-trash w-3 h-3"></i></button>
    </div>
</div>