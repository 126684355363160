import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home.component';
import { UserModule } from '../user/user.module';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { RouterModule } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { NavBarItemComponent } from './components/nav-bar-item/nav-bar-item.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  imports: [CommonModule, SharedModule, UserModule, RouterModule, BrowserAnimationsModule],
  declarations: [HomeComponent, TopBarComponent, NavBarComponent, NavBarItemComponent, BreadcrumbsComponent, ToasterComponent],
  providers: [SharedService]
})
export class HomeModule { }
