import { Component, OnInit } from '@angular/core';
import { gql } from 'apollo-angular'
import { combineLatest, map, Observable, Subject, tap } from 'rxjs';
import { NavigationDetailsConfig } from 'src/app/models/navigation.shared.model';
import { SubscriptionModel } from 'src/app/models/subscription.model';
import { SubscriptionService } from 'src/app/pages/vv/services/subscription.service';
import { MyRequestsGQL, MyRequestsQuery, MyUsageGQL, MyUsageQuery, Request, Subscription, UsageFieldParamsGQL, UsageFieldParamsQuery } from 'src/graphql/generated';
import { UserService } from '../user.service';
import { AuthService } from '@auth0/auth0-angular';

const USAGE = gql`
query MyUsage($month: numeric = 12, $year: numeric = 2022, $companyId: Int!, $userId:Int!) {
  service_usage_by_company(where: {month: {_eq: $month}, year: {_eq: $year}}) {
    companyId
    id
    month
    year
    count
    name
    limit_type
    limit_value
  }
  service_usage_by_user(where: {month: {_eq: $month}, year: {_eq: $year}}) {
    companyId
    userId
    id
    month
    year
    count
    name
    limit_type
    limit_value
  }
  companyScreeners: screeners_aggregate(where: {active: {_eq: true}, companyId: {_eq: $companyId}}) {
    aggregate { count }
  }
  myScreeners: screeners_aggregate(where: {active: {_eq: true}, userId: {_eq:$userId} }) {
    aggregate { count }
  }
}
`
const AVAILABLE_MONTHS = gql`query UsageFieldParams {
  service_usage_by_company(distinct_on: [month,year], order_by: {year: desc, month: desc}) {
      month
      year
     }
}`

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: []
})
export class UsageComponent implements OnInit {
  selectedMonth = new Subject<{ month: number, year: number }>()
  items$!: Observable<MyUsageQuery>
  months$!: Observable<{ text: string, value: any }[]>
  monitoraggio$!: Observable<any>
  details: { title: string, description?: string, span?: number }[] = [
    {
      span: 2,
      description: "",
      title: "Servizio"
    },
    {
      description: "",
      title: "Periodo"
    },
    {
      description: "",
      title: "Tetto"
    },
    {
      description: "Totale (Utente)",
      title: "Consumato"
    },
    {
      description: "",
      title: "Residuo"
    }]
  periodMap: any = {
    'NONE': '-',
    'year': 'Annuale',
    'month': 'Mensile'
  }

  constructor(
    private getRequests: MyUsageGQL,
    private getUsageParams: UsageFieldParamsGQL,
    private subscriptionService: SubscriptionService,
    private auth: AuthService) {
    combineLatest([this.selectedMonth, this.auth.getIdTokenClaims().pipe(tap(console.log))]).subscribe(x => {
      this.items$ = this.getRequests.watch({ ...x[0], userId: x[1]['https://tokens.reopera.it/metadata'].userId, companyId: x[1]['https://tokens.reopera.it/metadata'].companyId }).valueChanges.pipe(map(x => x.data))
    })
    this.months$ = this.getUsageParams.watch({}).valueChanges.pipe(
      map(x => x.data.service_usage_by_company),
      tap(x => {
        if (x.length) {
          this.selectedMonth.next({ month: x[0].month || new Date().getMonth(), year: x[0].year || new Date().getFullYear() })
        }
      }),
      map(x => x.map(i => ({ text: i.year + '-' + i.month, value: { month: i.month, year: i.year } })))
    )
    this.monitoraggio$ = this.subscriptionService.getByServiceSlug('monitoraggio-soggetti')
  }
  ngOnInit(): void {

  }

  getPersonalUsage(row: any, userData: MyUsageQuery['service_usage_by_user']) {
    return userData.find(u => u.year == row.year && u.month == row.month && u.id == row.id)?.count || 0
  }

}
