<div class="px-6">
    <div class="flex justify-between">
        <div class="flex justify-start gap-0.5">
            <!-- base operations selector -->
            <button *ngFor="let item of entrypoints; let j = index;" [attr.data-cy]="'tab-' + j"
                class="p-2 px-4 rounded-t-md  border-0  border-t-4 transition-all  text-black dark:text-white  dark:bg-reopera-900 bg-white disabled:italic disabled:cursor-not-allowed disabled:!text-gray-500"
                [ngClass]="[
            item.name == currentEntrypoint?.name ? 
            'border-black font-semibold !bg-opacity-100' : 
            'border-white !bg-opacity-40']" [disabled]="!item.isActive" [routerLink]="[]" [queryParams]="{'tab': j}">
                {{item.name}}
            </button>
        </div>
        <div>
            <button (click)="listLayout=!listLayout" title="Cambia vista"
                class="rounded-full shadow-sm bg-white p-2 hover:shadow-lg text-primary border-white border hover:border-primary hover:rotate-180 delay-300 duration-500 transition-all">
                <svg *ngIf="!listLayout" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1S"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
                <svg *ngIf="listLayout" class="h-6 w-6" width="24" height="24" viewBox="0 0 24 24" stroke-width="1"
                    stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <rect x="4" y="4" width="6" height="5" rx="2" />
                    <rect x="4" y="13" width="6" height="7" rx="2" />
                    <rect x="14" y="4" width="6" height="7" rx="2" />
                    <rect x="14" y="15" width="6" height="5" rx="2" />
                </svg>
            </button>
        </div>
    </div>
    <div class="bg-white dark:bg-reopera-900 flex flex-col justify-between ">
        <div class="p-4 transition-all max-w-full flex-grow overflow-y-auto "
            [ngClass]="[currentNavigation > -1 ? 'flex items-center': ' dark:bg-reopera-900 rounded-md my-6']">
            <div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white">
                Parametri di ricerca
            </div>
            <div *ngIf="currentEntrypoint" class="relative">

                <!-- input area -->
                <app-navigazione-form *ngIf="currentNavigation == -1; else compact"
                    [fields]="getFields(currentEntrypoint)" [submitText]="currentEntrypoint.submitText"
                    [paymentText]="getPaymentText(currentEntrypoint)" (onSubmit)="onFormSubmit($event)"
                    [initData]="formData">

                    <div class="w-2/5 self-start">
                        <div *ngIf="currentEntrypoint.notes" class="flex flex-wrap justify-center">
                            <div *ngFor="let note of currentEntrypoint.notes"
                                class="h-60 w-60 p-4 text-blue-900 block rotate-6 duration-150 ease-linear bg-[#ffc]  shadow-md hover:shadow-lg hover:rotate-0 hover:scale-125 ">
                                <div *ngIf="note.title" class="text-xl mb-2 uppercase font-bold">{{ note.title}}</div>
                                <div class="font-light" [innerHTML]="note.content"></div>
                            </div>
                        </div>
                        <ng-content></ng-content>
                        <img src="/assets/catasto.png" class="w-full">
                    </div>
                </app-navigazione-form>
                <ng-template #compact>
                    <div class="flex flex-wrap">
                        <div *ngFor="let field of currentEntrypoint.fields">
                            <button *ngIf="field.type !='hidden' && formData[field.name]" (click)="goBackTo(-1)"
                                class="text-sm border-gray-500 border py-0.5 px-4 m-1 hover:shadow-lg hover:text-primary text-gray-500 rounded-2xl">
                                {{field.description}}: {{getCompactViewData(field)}}
                            </button>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
        <div *ngFor="let navigation of currentEntrypoint?.navigations; let i = index" class="relative p-4"
            [ngClass]="[currentNavigation < i? 'hidden': '', currentNavigation > i ? 'bg-white': 'bg-reopera-white']">
            <!-- navigation panels -->
            <div class="dark:text-white text-black text-2xl flex items-center">
                <div class="p-2" [ngClass]="{'font-bold': currentNavigation == i}">{{currentNavigation > i?
                    navigation.nameAfterSelect: ''}}</div>
                <!-- compact view -->
                <button *ngIf="currentNavigation > i"
                    class="border-gray-500 border py-1.5 px-4 hover:shadow-lg hover:text-primary text-gray-500 rounded-2xl text-sm">
                    <span (click)="goBackTo(i)"> {{selectedNavigationData[i]?.[0]?.description}}</span>
                </button>
            </div>
            <app-navigazione-results *ngIf="currentNavigation == i; else nodata" [navigationIdx]="i"
                [currentNavigation]="currentNavigation" [items]="navigationItems[i]" [navigation]="navigation"
                [listLayout]="listLayout" [nextButtonTitle]="currentEntrypoint?.navigations?.[i+1]?.name || ''"
                [paramsAndNotes]="collectParamsAndNotes()" (onLaunchAction)="storeCurrentNavigationData(i, $event)"
                (onSelectedItem)="loadNavigation(i, $event)"></app-navigazione-results>
            <ng-template #nodata>
            </ng-template>
        </div>
    </div>
</div>