<div class="flex flex-col h-full ">
    <div class="flex flex-grow">
        <app-mappa class="h-full flex-grow relative" (mapClick)="onClick($event)" [markers]="marker ? [marker] : []">
        </app-mappa>
        <app-side-panel class="w-80" *ngIf="selectedPos && selectedCatastoInfo" (onDismiss)="clearMarkerData()"
            [selectedCoord]="selectedPos" [selectedCatastoInfo]="selectedCatastoInfo"
            (onToggleLower)="showLowerPanel = !showLowerPanel"
            [showToggleLowerButton]="!showLowerPanel"></app-side-panel>

    </div>
    <app-lower-panel *ngIf="showLowerPanel && selectedPos && selectedCatastoInfo"
        class="max-h-[50%] h-1/2 overflow-y-scroll overflow-x-hidden shadow-lg mt-2"
        [selectedCatastoInfo]="selectedCatastoInfo" (onDismiss)="showLowerPanel = false"></app-lower-panel>
</div>