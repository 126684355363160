import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { UserBalance, UserModel } from 'src/app/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import { UserService } from 'src/app/user/user.service';
import { MyActivationsGQL, MyActivationsQuery, MyUserGQL, MyUserQuery } from 'src/graphql/generated';
import { catchError, map, Observable, of } from 'rxjs';
import { EventType } from 'src/app/models/event.model';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styles: [
  ]
})
export class TopBarComponent implements OnInit {
  @Input() isNavBarOpen!: boolean
  @Output() onNavButtonClick = new EventEmitter()

  profileJson: User | null | undefined = null;
  balance: UserBalance = { balance: 0, accountBalance: 0 }
  activations$: Observable<MyActivationsQuery['workarea_activation']>
  pageTitle: string = ''
  disablePwdButton: boolean = false
  user?: any
  showProfilePanel: boolean = false

  constructor(public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService,
    private myActivations: MyActivationsGQL) {
    this.activations$ = this.myActivations.watch().valueChanges.pipe(map(x => x.data.workarea_activation))
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild)
    }
    return activatedRoute
  }
  ngOnInit(): void {
    this.auth.user$.subscribe(
      (profile) => (this.profileJson = profile),
    );
    this.userService.getProfile().subscribe((u: any) => {
      this.user = u
    })
    this.userService.getBalance().subscribe((balance => this.balance = balance))

  }
  logout(): void {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
  pwdReset() {
    this.disablePwdButton = true
    this.userService.passwordReset().pipe(
      map(x =>
        this.sharedService.publishLocalEvent({
          type: EventType.NOTICE,
          raw: {
            title: "Procedura cambio password avviata",
            description: "Verifica la tua casella di posta e segui le istruzioni che ti abbiamo inviato per email!"
          }
        })
      ),
      catchError(err => {
        this.sharedService.publishLocalEvent({
          type: EventType.ERROR,
          raw: {
            title: "Errore nel cambio password",
            description: "Contatta l'assistenza ReOpera per procedere ad un cambio manuale!"
          }
        })
        return of(true)
      })
    ).subscribe(x => {
      this.disablePwdButton = false;
    })
  }

}
