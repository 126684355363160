<div class="p-2 py-4 text-2xl rounded-md text-black dark:text-white"> Ricariche effettuate </div>
<div class="w-full">
    <div class="-mx-4 sm:-mx-8 px-4 sm:px-4 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table class="min-w-full leading-normal">
                <thead>
                    <tr>
                        <th *ngFor="let det of details; let i = index;" scope="col"
                            class="px-5 py-3 bg-white  border-b border-gray-200 text-primary  text-left text-sm uppercase font-normal">
                            {{det.title}}
                            <div class="text-xs normal-case" *ngIf="det.description">
                                {{det.description}}</div>
                            <button *ngIf="det.sortable" (click)="sortBy(i)" class="p-1"><i class="w-3 h-3 fa fa-solid"
                                    [ngClass]="{
                                'text-gray-800 fa-sort-up': det.order == -1, 
                                'text-gray-800 fa-sort-down': det.order == 1, 
                                'text-gray-400 fa-sort': !det.order}"></i>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody class="" *ngIf="items$ | async as rows; else nodata;">

                    <tr *ngFor="let item of rows | paginate: {itemsPerPage: 12 , currentPage: p}"
                        class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{item.createdAt| date: 'dd.MM.yyyy HH:mm'}}
                        </td>
                        <td class="px-4 py-3 border-b border-gray-200 text-sm">
                            {{item.amount}} €
                        </td>


                    </tr>
                </tbody>
                <ng-template #nodata>
                    <tbody data-cy="no-results">
                        <tr class="bg-white hover:bg-gray-100 even:bg-gray-50 relative">

                            <td [attr.colspan]="details.length"
                                class="absolute font-bold bg-white bg-opacity-80 px-4 py-3 left-0 top-0 bottom-0 pr-20">
                                Nessun risultato per i dati inseriti</td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
            <pagination-template #q="paginationApi" *ngIf="details && details.length"
                class="flex justify-center text-black dark:text-white gap-2 mt-2 border-t-secondary py-2 border-t "
                (pageChange)="p = $event" data-cy="pagination">

                <button data-cy="pagination-first"
                    class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                    *ngIf="!q.isFirstPage()" (click)="q.previous()" [class.disabled]="q.isFirstPage()">
                    < </button>

                        <div data-cy="pagination-item" class="flex" *ngFor="let page of q.pages">
                            <button class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                                (click)="q.setCurrent(page.value)" *ngIf="q.getCurrent() !== page.value">
                                <span>{{ page.label }}</span>
                            </button>
                            <div data-cy="pagination-current" class="flex" *ngIf="q.getCurrent() === page.value">
                                <span class="px-4 py-2 rounded-md bg-secondary text-white">{{
                                    page.label }}</span>
                            </div>
                        </div>

                        <button data-cy="pagination-last" *ngIf="!q.isLastPage()" (click)="q.next()"
                            class="px-4 py-2 rounded-md hover:text-white hover:bg-primary transition-colors"
                            [class.disabled]="q.isLastPage()"> > </button>
            </pagination-template>
        </div>
    </div>
</div>