import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/local-storage.service';
import { PaginatedModel } from 'src/app/models/dto/PaginatedModel';
import { RequestModel } from 'src/app/models/request.model';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  static URL = '/api/v1/request';

  constructor(public http: HttpClient, private localstorageService: LocalStorageService) { }

  create(data: RequestModel) {
    let shouldMock = false
    try {
      let lsData = this.localstorageService.getItem('mockQueries', [])
      shouldMock = lsData.includes(data._dataChannel) || lsData.includes('*')
    } catch (err) {

    }
    return this.http.post(RequestService.URL, { ...data, _mock: shouldMock });
  }


}
