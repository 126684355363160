<div>
    <div class="p-2 py-4 pb-0 text-2xl rounded-md text-black dark:text-white flex justify-between">
        <div></div>
        <div class="mb-2 text-2xl rounded-md text-black dark:text-white flex gap-2">
            <app-date-select title="Data (da)" min="2022-01-01" [max]="maxFrom"
                (selected)="selectedStartDate.next($event)"></app-date-select>
            <app-date-select title="Data  (a)" max="2023-12-31" (selected)="selectedEndDate.next($event)"
                [min]="minTo"></app-date-select>
            <app-select title="Ordina per..." [items]="availableOrderModes"
                (selected)="onSelectedOrder($event)"></app-select>
        </div>
    </div>
    <ng-container *ngIf="data$ | async as data">
        <div class="pt-0 p-2 py-4 text-2xl rounded-md text-black dark:text-white flex justify-between">
            <div class="-mt-14 w-full h-10 flex items-center justify-start text-xl mb-6">
                <a [routerLink]="['/','user','consolle-immobiliare', 'dossier']">
                    <i class="hover:text-primary fa fa-solid fa-home w-5 h-5"></i> /</a>

                <a *ngIf="data.current[0]?.parent?.parent?.parent?.parent?.parent as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']">
                    <span class="hover:text-primary">{{link?.title}}</span>/</a>
                <a *ngIf="data.current[0]?.parent?.parent?.parent?.parent as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']">
                    <span class="hover:text-primary">{{link?.title}}</span>/</a>
                <a *ngIf="data.current[0]?.parent?.parent?.parent as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']">
                    <span class="hover:text-primary">{{link?.title}}</span>/</a>
                <a *ngIf="data.current[0]?.parent?.parent as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']"><span
                        class="hover:text-primary">{{link?.title}}</span>/</a>
                <a *ngIf="data.current[0]?.parent as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']"><span
                        class="hover:text-primary">{{link?.title}}</span>/</a>
                <a *ngIf="data.current[0] as link"
                    [routerLink]="['/','user','consolle-immobiliare', 'dossier', link.id || '']"><span
                        class="hover:text-primary">{{link?.title}}</span>/</a>


            </div>
        </div>

        <div class="flex flex-wrap">
            <div *ngIf="data.current?.[0] as current" [@inOutAnimation] (drop)="onDrop(current.parent?.id, $event)"
                (dragover)="onDragOver(current.parent?.id, $event)" (dragleave)="onDragLeave($event )"
                (dragenter)="onDragEnter(current.parent?.id, $event)"
                class="w-60 h-40 flex flex-col rounded justify-center items-center group p-4">
                <button class="relative" (dblclick)="goto(current.parent?.id|| '')"><i
                        class="fa fa-solid fa-folder w-28 h-28 text-gray-500 group-hover:scale-110 group-hover:opacity-80 group-hover:rotate-6 transition-all">
                    </i>
                    <div *ngIf="current.screener?.type"
                        class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-white gap-1">
                        <i *ngFor="let icon of screenerTypeIconMap[current.screener?.type]?.icons"
                            class="fa fa-solid w-6 h-6 group-hover:scale-110 transition-all" [ngClass]="icon">
                        </i>
                        <i *ngFor="let icon of screenerSubTypeIconMap[current.screener?.subType]?.icons"
                            class="fa fa-solid w-6 h-6 group-hover:scale-110 transition-all" [ngClass]="icon">
                        </i>
                    </div>
                    <!-- <a title="Indietro"
                        [routerLink]="['/','user','consolle-immobiliare', 'dossier', current.parent?.id || '']"
                        class="absolute bottom-2 left-0 z-10 p-2 rounded-full w-9 h-9 text-center opacity-0 group-hover:opacity-50 bg-primary text-white hover:bg-secondary transition-all"><i
                            class="fa fa-solid fa-arrow-left w-5 h-5"></i></a> -->

                </button>
                <div class="flex-grow"></div>
                <div>..</div>
                <div>&nbsp;</div>
            </div>
            <div *ngFor="let folder of data.children" (drop)="onDrop(folder.id, $event)"
                (dragover)="onDragOver(folder.id, $event)" (dragleave)="onDragLeave($event)"
                (dragenter)="onDragEnter(folder.id, $event)">
                <button class="w-60 h-40 flex flex-col rounded justify-center items-center group p-4" [@inOutAnimation]
                    draggable="true" (dragend)="onDragEnd($event)" (dragstart)="onDragStart(folder.id, $event)"
                    [title]="(screenerTypeIconMap[folder.screener?.type]?.title || '') + ' ' + (screenerSubTypeIconMap[folder.screener?.subType]?.title || '')"
                    (dblclick)="goto(folder.id)">
                    <div class="relative">
                        <i
                            class="fa fa-solid fa-folder w-28 h-28 text-orange-400 group-hover:scale-110 group-hover:opacity-80 group-hover:rotate-6 transition-all">

                        </i>

                        <span
                            *ngIf="(folder.children_aggregate.aggregate?.count || 0)+ (folder.documents_aggregate.aggregate?.count || 0) as subelements"
                            class="absolute bottom-2 right-2 rounded-full w-6 h-6 text-center bg-primary text-white">{{subelements}}</span>
                        <div *ngIf="folder.screener?.type"
                            class="absolute top-0 left-0 w-full h-full flex justify-center items-center text-white gap-1">
                            <i *ngFor="let icon of screenerTypeIconMap[folder.screener?.type]?.icons"
                                class="fa fa-solid w-6 h-6 group-hover:scale-110 transition-all" [ngClass]="icon">
                            </i>
                            <i *ngFor="let icon of screenerSubTypeIconMap[folder.screener?.subType]?.icons"
                                class="fa fa-solid w-6 h-6 group-hover:scale-110 transition-all" [ngClass]="icon">
                            </i>
                        </div>

                        <!-- <a title="Apri" [routerLink]="['/','user','consolle-immobiliare', 'dossier', folder.id]"
                            class="absolute bottom-2 left-0 z-10 p-2 rounded-full w-9 h-9 text-center opacity-0 group-hover:opacity-50 bg-primary text-white hover:bg-secondary transition-all"><i
                                class="fa fa-solid fa-arrow-right w-5 h-5 "></i></a> -->
                        <button title="Elimina" (click)="removeFolder(folder.id)"
                            *ngIf="!folder.screener && ((folder.children_aggregate.aggregate?.count || 0)+ (folder.documents_aggregate.aggregate?.count || 0) == 0)"
                            class="absolute bottom-2 right-0 z-10 p-2 rounded-full w-9 h-9 text-center opacity-0 group-hover:opacity-100 bg-primary text-white hover:bg-secondary transition-all"><i
                                class="fa fa-solid fa-trash-can w-5 h-5"></i></button>

                    </div>
                    <div class="flex-grow"></div>
                    <div class="group/rename">
                        <div class="text-center">{{folder.title}}
                            <button title="Rinomina" (click)="prepareDossierRename(folder)"
                                class="px-2 rounded-full w-7 h-7 text-center opacity-0 group-hover/rename:opacity-100 bg-gray-200 text-gray-600 hover:bg-gray-300 transition-all">
                                <i class="fa fa-solid fa-pencil w-3 h-7"></i>
                            </button>
                        </div>
                        <div class=" text-center text-sm">{{folder.createdAt | date: 'dd.MM.YYYY HH:mm'}}</div>
                    </div>
                </button>
            </div>
            <div *ngFor="let file of data.document" [@inOutAnimation] draggable="true" (dragend)="onDragEnd($event)"
                (dragstart)="onDragStart(file.id, $event, 'DOC')"
                class="w-60 h-40 flex flex-col rounded justify-center items-center group p-4 gap-1 ">
                <button class=" relative" (dblclick)="open(file)" title="Doppio click per aprire">
                    <i
                        class="fa fa-solid fa-file-pdf  w-28 h-20 text-gray-500 group-hover:scale-110 group-hover:opacity-80 group-hover:rotate-6 transition-all">
                    </i>
                    <!-- <button title="Anteprima" disabled
                        class="absolute bottom-0 left-0 z-10 p-2 rounded-full disabled:bg-gray-400 w-9 h-9 text-center opacity-0 group-hover:opacity-100 bg-primary text-white hover:bg-secondary transition-all"><i
                            class="fa fa-regular fa-eye w-5 h-5 "></i></button> -->
                    <!-- <button title="Download" (click)="open(file)"
                        class="absolute top-0 right-0 z-10 p-2 rounded-full w-9 h-9 text-center opacity-0 group-hover:opacity-100 bg-primary text-white hover:bg-secondary transition-all"><i
                            class="fa fa-solid fa-cloud-arrow-down w-5 h-5"></i></button> -->
                    <!-- <button title="Elimina" (click)="remove(file)"
                        class="absolute bottom-0 right-0 z-10 p-2 rounded-full w-9 h-9 text-center opacity-0 group-hover:opacity-100 bg-primary text-white hover:bg-secondary transition-all"><i
                            class="fa fa-solid fa-trash-can w-5 h-5"></i></button> -->

                </button>
                <div class="flex-grow"></div>

                <div>{{file.name.split('/').pop()}}</div>
                <div class="text-sm">{{file.createdAt | date: 'dd.MM.YYYY HH:mm'}}</div>
            </div>

        </div>
    </ng-container>
</div>
<button title="Carica documento" disabled
    class="text-white disabled:saturate-0 fixed bottom-4 right-4 p-3 w-14 h-14 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
    <i class="w-6 h-6 fa-solid fa-cloud-arrow-up"></i>
</button>
<button title="Crea pratica" (click)="showNewDossierWindow=true"
    class="text-white fixed bottom-20 right-4 p-0 w-14 h-14 bg-reopera-600 rounded-full hover:bg-reopera-700 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none">
    <i class="w-6 h-6 fa-solid fa-folder-plus"></i>
</button>
<app-actionpopup [data]="moveData" [showPanel]="confirmMove" buttonLabel="Procedi" (onCancel)="confirmMove=false"
    (onConfirm)="doMove()"></app-actionpopup>
<app-actionpopup [data]="{title: 'Seleziona nome'}" [showPanel]="showNewDossierWindow" buttonLabel="Inserisci"
    (onCancel)="showNewDossierWindow=false" (onConfirm)="createDossier()">
    <input
        class="rounded-lg flex-1 appearance-none border border-gray-300 w-80 py-4 px-4 mb-4 bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
        placeholder="Inserisci il nome della cartella" required [(ngModel)]="folderName">
</app-actionpopup>
<app-actionpopup [data]="{title: 'Seleziona nome'}" [showPanel]="showRenameDossierWindow" buttonLabel="Rinomina"
    (onCancel)="showRenameDossierWindow=false" (onConfirm)="renameExistingDossier()">
    <input
        class="rounded-lg flex-1 appearance-none border border-gray-300 w-80 py-4 px-4 mb-4 bg-white dark:bg-dark text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent"
        placeholder="Inserisci il nome della cartella" required [(ngModel)]="folderName">
</app-actionpopup>