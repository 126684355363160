<div id="app" class="flex flex-column h-100">
    <div class="z-50 inset-0 fixed flex items-center justify-center bg-white dark:bg-black"
        *ngIf="auth.isLoading$ | async; else loaded">
        <div class="flex flex-col items-center justify-center gap-2 text-black dark:text-white">
            <svg class="w-64" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 439.33 140.45"
                style="enable-background:new 0 0 439.33 140.45;" xml:space="preserve">

                <g>
                    <path fill-rule="evenodd" clip-rule="evenodd" fill="#D91362" d="M92.63,108.74V28.08l17.8-19.54H19.16c-7.29,0-13.26,5.97-13.26,13.26v93.05c0,7.29,5.97,13.26,13.26,13.26
		h93.05c7.29,0,13.26-5.97,13.26-13.26v-6.11H92.63z M76.44,108.74L47.02,73.31v-5.54h13.61c9.81,0,14.65-7.04,14.65-14.07
		c0-6.92-4.84-13.96-14.65-13.96H42.75v69H31.21V27.96h29.42c17.54,0,26.31,12.92,26.31,25.73c0,11.42-7.04,22.27-20.89,24.69
		l25.5,30.35H76.44z M104.4,39.74h20.71v22.73H104.4V39.74z M104.4,74.24h20.71v22.85H104.4V74.24z" />
                    <g>
                        <path fill="currentColor" d="M149.01,43.07c6.39-6.5,15.39-10.59,25.18-10.59c9.89,0,18.79,4.1,25.28,10.59
			c6.3,6.49,10.39,15.39,10.39,25.28s-4.1,18.79-10.39,25.28c-6.49,6.6-15.39,10.59-25.28,10.59c-9.79,0-18.79-3.99-25.18-10.59
			c-6.29-6.49-10.39-15.39-10.39-25.28S142.72,49.56,149.01,43.07z M192.28,50.26c-4.7-4.69-11.19-7.59-18.09-7.59
			s-13.29,2.9-17.89,7.59c-4.59,4.6-7.49,10.99-7.49,18.09s2.9,13.49,7.49,18.09c4.6,4.69,10.99,7.59,17.89,7.59
			s13.39-2.9,18.09-7.59c4.5-4.6,7.4-10.89,7.4-18.09C199.67,61.16,196.77,54.86,192.28,50.26z" />
                        <path fill="currentColor" d="M243.68,33.38c15.09,0,22.68,11.69,22.68,23.38s-7.59,23.28-22.68,23.28h-15.39v23.28H218.1V33.38H243.68z
			 M243.68,70.05c8.49,0,12.69-6.59,12.69-13.19c0-6.69-4.19-13.29-12.69-13.29h-15.39v26.48H243.68z" />
                        <path fill="currentColor"
                            d="M281.37,93.23h32.47v10.09h-42.67V33.48h42.56v10.09h-32.37v19.68h31.67v10.19h-31.67V93.23z" />
                        <path fill="currentColor" d="M332.16,67.85h11.79c8.49,0,12.69-6.09,12.69-12.19c0-6-4.19-12.09-12.69-12.09h-15.49v59.75h-9.99V33.38
			h25.48c15.19,0,22.78,11.19,22.78,22.28c0,9.89-6.09,19.28-18.09,21.38l22.09,26.28h-13.09l-25.48-30.68V67.85z" />
                        <path fill="currentColor" d="M413.12,82.74h-25.08c-2.7,6.39-6.19,14.79-8.39,20.58h-10.89l28.08-70.84h7.4l28.08,70.84h-10.79
			L413.12,82.74z M391.84,73.25h17.39l-8.6-24.68L391.84,73.25z" />
                    </g>
                </g>
                <g>
                    <path fill="currentColor" d="M143.89,110.17h5.53c1.89,0,2.69,0.15,3.38,0.41c1.84,0.69,3.04,2.51,3.04,4.89c0,2.3-1.28,4.25-3.25,4.89
		v0.05c0,0,0.2,0.23,0.54,0.82l3.84,7.01h-2.87l-3.81-7.19h-3.86v7.19h-2.53V110.17z M150.06,118.84c1.97,0,3.2-1.25,3.2-3.28
		c0-1.33-0.51-2.28-1.43-2.79c-0.49-0.26-1.07-0.41-2.48-0.41h-2.92v6.47H150.06z" />
                    <path fill="currentColor"
                        d="M167.91,114.98c3.66,0,5.6,2.71,5.6,6.06c0,0.33-0.08,1.07-0.08,1.07H164c0.13,2.84,2.15,4.27,4.45,4.27
		c2.23,0,3.84-1.51,3.84-1.51l1.02,1.82c0,0-1.92,1.84-5.02,1.84c-4.07,0-6.86-2.94-6.86-6.78
		C161.44,117.64,164.23,114.98,167.91,114.98z M170.98,120.25c-0.08-2.23-1.46-3.3-3.12-3.3c-1.89,0-3.43,1.18-3.79,3.3H170.98z" />
                    <path fill="currentColor" d="M185.85,120.15h0.56v-0.23c0-2.12-1.2-2.84-2.84-2.84c-2,0-3.61,1.25-3.61,1.25l-1.02-1.82
		c0,0,1.87-1.54,4.84-1.54c3.28,0,5.12,1.79,5.12,5.07v8.19h-2.3V127c0-0.59,0.05-1.02,0.05-1.02h-0.05c0,0-1.05,2.56-4.14,2.56
		c-2.23,0-4.4-1.36-4.4-3.94C178.04,120.33,183.67,120.15,185.85,120.15z M182.98,126.54c2.1,0,3.45-2.2,3.45-4.12v-0.41h-0.64
		c-1.87,0-5.25,0.13-5.25,2.46C180.55,125.52,181.37,126.54,182.98,126.54z" />
                    <path fill="currentColor" d="M195.01,110.17h2.48v14.1c0,1.56,0.61,1.84,1.38,1.84c0.23,0,0.43-0.03,0.43-0.03v2.2c0,0-0.38,0.05-0.82,0.05
		c-1.41,0-3.48-0.38-3.48-3.63V110.17z" />
                    <path fill="currentColor"
                        d="M214.14,110.17h10.49v2.2h-7.96v5.65h6.47v2.2h-6.47v5.81h8.39v2.2h-10.93V110.17z" />
                    <path fill="currentColor" d="M231,124.88c0,0,1.46,1.51,3.71,1.51c1.07,0,2.15-0.56,2.15-1.61c0-2.38-6.65-1.89-6.65-6.12
		c0-2.35,2.1-3.68,4.68-3.68c2.84,0,4.17,1.43,4.17,1.43l-1,1.87c0,0-1.15-1.15-3.2-1.15c-1.07,0-2.12,0.46-2.12,1.59
		c0,2.33,6.65,1.82,6.65,6.06c0,2.15-1.84,3.76-4.68,3.76c-3.17,0-4.91-1.89-4.91-1.89L231,124.88z" />
                    <path fill="currentColor" d="M245.41,117.46h-1.69v-2h1.77v-3.74h2.4v3.74h3.12v2h-3.12v5.76c0,2.58,1.82,2.92,2.76,2.92
		c0.36,0,0.59-0.05,0.59-0.05v2.2c0,0-0.36,0.05-0.87,0.05c-1.66,0-4.96-0.51-4.96-4.84V117.46z" />
                    <path fill="currentColor" d="M263.34,120.15h0.56v-0.23c0-2.12-1.2-2.84-2.84-2.84c-2,0-3.61,1.25-3.61,1.25l-1.02-1.82
		c0,0,1.87-1.54,4.84-1.54c3.28,0,5.12,1.79,5.12,5.07v8.19h-2.3V127c0-0.59,0.05-1.02,0.05-1.02h-0.05c0,0-1.05,2.56-4.14,2.56
		c-2.23,0-4.4-1.36-4.4-3.94C255.54,120.33,261.17,120.15,263.34,120.15z M260.48,126.54c2.1,0,3.45-2.2,3.45-4.12v-0.41h-0.64
		c-1.87,0-5.25,0.13-5.25,2.46C258.05,125.52,258.87,126.54,260.48,126.54z" />
                    <path fill="currentColor" d="M273.12,117.46h-1.69v-2h1.77v-3.74h2.41v3.74h3.12v2h-3.12v5.76c0,2.58,1.82,2.92,2.76,2.92
		c0.36,0,0.59-0.05,0.59-0.05v2.2c0,0-0.36,0.05-0.87,0.05c-1.66,0-4.96-0.51-4.96-4.84V117.46z" />
                    <path fill="currentColor"
                        d="M289.87,114.98c3.66,0,5.6,2.71,5.6,6.06c0,0.33-0.08,1.07-0.08,1.07h-9.44c0.13,2.84,2.15,4.27,4.45,4.27
		c2.23,0,3.84-1.51,3.84-1.51l1.02,1.82c0,0-1.92,1.84-5.02,1.84c-4.07,0-6.86-2.94-6.86-6.78
		C283.4,117.64,286.19,114.98,289.87,114.98z M292.95,120.25c-0.08-2.23-1.46-3.3-3.12-3.3c-1.89,0-3.43,1.18-3.79,3.3H292.95z" />
                    <path fill="currentColor" d="M310.7,110.17h6.6c3.3,0,5.68,2.23,5.68,5.65c0,3.43-2.38,5.73-5.68,5.73h-4.07v6.68h-2.53V110.17z
		 M316.89,119.35c2.17,0,3.51-1.36,3.51-3.53c0-2.17-1.33-3.45-3.48-3.45h-3.68v6.99H316.89z" />
                    <path fill="currentColor" d="M328.12,110.17h2.48v14.1c0,1.56,0.61,1.84,1.38,1.84c0.23,0,0.43-0.03,0.43-0.03v2.2c0,0-0.38,0.05-0.82,0.05
		c-1.41,0-3.48-0.38-3.48-3.63V110.17z" />
                    <path fill="currentColor" d="M344.37,120.15h0.56v-0.23c0-2.12-1.2-2.84-2.84-2.84c-2,0-3.61,1.25-3.61,1.25l-1.02-1.82
		c0,0,1.87-1.54,4.84-1.54c3.28,0,5.12,1.79,5.12,5.07v8.19h-2.3V127c0-0.59,0.05-1.02,0.05-1.02h-0.05c0,0-1.05,2.56-4.15,2.56
		c-2.23,0-4.4-1.36-4.4-3.94C336.57,120.33,342.2,120.15,344.37,120.15z M341.5,126.54c2.1,0,3.45-2.2,3.45-4.12v-0.41h-0.64
		c-1.87,0-5.25,0.13-5.25,2.46C339.07,125.52,339.89,126.54,341.5,126.54z" />
                    <path fill="currentColor" d="M354.14,117.46h-1.69v-2h1.77v-3.74h2.4v3.74h3.12v2h-3.12v5.76c0,2.58,1.82,2.92,2.76,2.92
		c0.36,0,0.59-0.05,0.59-0.05v2.2c0,0-0.36,0.05-0.87,0.05c-1.66,0-4.96-0.51-4.96-4.84V117.46z" />
                    <path fill="currentColor" d="M366.01,117.46h-1.61v-2h1.61v-0.61c0-4.27,3.22-4.81,4.86-4.81c0.56,0,0.95,0.08,0.95,0.08v2.12
		c0,0-0.26-0.05-0.64-0.05c-0.95,0-2.71,0.31-2.71,2.71v0.56h3.07v2h-3.07v10.77h-2.46V117.46z" />
                    <path fill="currentColor" d="M382.52,114.98c3.86,0,6.98,2.84,6.98,6.76c0,3.94-3.12,6.81-6.98,6.81c-3.86,0-6.99-2.87-6.99-6.81
		C375.53,117.82,378.65,114.98,382.52,114.98z M382.52,126.39c2.46,0,4.45-1.94,4.45-4.66c0-2.69-2-4.61-4.45-4.61
		c-2.43,0-4.45,1.92-4.45,4.61C378.06,124.45,380.09,126.39,382.52,126.39z" />
                    <path fill="currentColor" d="M395,115.29h2.41v2.25c0,0.54-0.05,0.97-0.05,0.97h0.05c0.59-1.89,2.1-3.38,4.09-3.38
		c0.33,0,0.64,0.05,0.64,0.05v2.46c0,0-0.33-0.08-0.72-0.08c-1.59,0-3.05,1.13-3.63,3.04c-0.23,0.74-0.31,1.54-0.31,2.33v5.3H395
		V115.29z" />
                    <path fill="currentColor" d="M407.16,115.29h2.41V117c0,0.51-0.05,0.95-0.05,0.95h0.05c0.61-1.51,2.43-2.97,4.45-2.97
		c2.17,0,3.45,1,3.89,2.94h0.05c0.72-1.56,2.51-2.94,4.61-2.94c2.92,0,4.27,1.66,4.27,4.96v8.29h-2.48v-7.75
		c0-1.82-0.36-3.25-2.3-3.25c-1.82,0-3.17,1.54-3.63,3.3c-0.13,0.54-0.18,1.13-0.18,1.79v5.91h-2.48v-7.75
		c0-1.69-0.26-3.25-2.25-3.25c-1.89,0-3.22,1.56-3.71,3.4c-0.13,0.51-0.15,1.1-0.15,1.69v5.91h-2.48V115.29z" />
                </g>
            </svg>
            <div class="flex  text-xl gap-1">
                <span class="animate-blurtext1">L</span>
                <span class="animate-blurtext2">O</span>
                <span class="animate-blurtext3">A</span>
                <span class="animate-blurtext4">D</span>
                <span class="animate-blurtext5">I</span>
                <span class="animate-blurtext6">N</span>
                <span class="animate-blurtext7">G</span>
            </div>
        </div>
    </div>

    <ng-template #loaded>

        <main class="bg-reopera-white dark:bg-black h-screen overflow-hidden flex relative w-full"
            *ngIf="auth.isAuthenticated$ | async; else login">
            <div class="flex items-start w-full justify-between">
                <app-nav-bar [isNavBarOpen]="isNavBarOpen"></app-nav-bar>
                <div class="flex flex-col flex-grow md:space-y-4"
                    [ngClass]="[isNavBarOpen?  'max-w-[calc(100%-256px)]' : 'max-w-full']">
                    <app-top-bar [isNavBarOpen]="isNavBarOpen" (onNavButtonClick)="isNavBarOpen = !isNavBarOpen">
                    </app-top-bar>
                    <div class="overflow-auto h-screen pb-24 px-4 md:px-6 flex flex-col w-full">
                        <router-outlet class="inset-0"></router-outlet>
                    </div>
                </div>
            </div>
        </main>
        <ng-template #login>
            <div class="inset-0 w-full">

            </div>
        </ng-template>
        <app-loading></app-loading>
        <global-actionpopup></global-actionpopup>
        <app-toaster></app-toaster>
    </ng-template>
</div>