import { Component, OnInit } from '@angular/core';
import { EntryPoint } from 'src/app/models/navigation.shared.model';
import { bilanciConfig } from './bilanci.ui.config';


@Component({
  selector: 'app-bilanci-consultazione',
  templateUrl: './bilanci.component.html',
  styles: [
  ]
})
export class BilanciComponent implements OnInit {

  constructor(
  ) {

  }

  entrypoints: EntryPoint[] = bilanciConfig

  ngOnInit(): void {

  }

}
