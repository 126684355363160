import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject, catchError, delay, map, of, tap } from 'rxjs';

import { EventModel } from '../models/event.model';
import { RequestService } from '../pages/vv/services/request.service';
import { AuthService } from '@auth0/auth0-angular';
export class ActionComponentData {
  subscriptionId?: number
  title?: string
  description?: string
  price?: number
  priceOnFail?: number
  showZeroPrice?: boolean
  type?: string
  channel?: string
  params?: { userParam?: string, requestParam: string, value?: string }[]
  extra?: { params: any, notes: any }
  submissionButtons?: any
}

export class ActionPopupData {
  showPanel!: boolean
  data?: ActionComponentData | undefined
  priceLabel?: string
  buttonLabel?: string
  disabled?: boolean
  ev?: EventEmitter<any>
}
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  get events$() {
    return this._events$.asObservable()
  }
  get loadingMsgObservable$(): Observable<string> {
    return this._loadingMsg$.asObservable()
  }
  set loadingMsg$(msg: string) {
    this._loadingMsg$.next(msg)
  }
  get actionPopupDataObservable$(): Observable<ActionPopupData> {
    return this._actionPopupData$.asObservable()
  }

  public isAdmin$: Observable<boolean> = this.authService.idTokenClaims$.pipe(
    map(u => u?.['https://tokens.reopera.it/metadata']?.['roles']?.includes('admin')
    )
  )

  private _actionPopupData$ = new Subject<ActionPopupData>()
  private _events$ = new Subject<EventModel>()
  private _loadingMsg$ = new Subject<string>()
  constructor(
    private requestService: RequestService,
    private authService: AuthService
  ) { }

  publishLocalEvent(data: EventModel) {
    this._events$.next(data)
  }

  showActionPopup(data: Partial<ActionPopupData>): EventEmitter<any> {
    let ev = new EventEmitter()
    this._actionPopupData$.next({
      priceLabel: 'Costo',
      buttonLabel: "RICHIEDI",
      ...data,
      showPanel: true,
      ev
    })
    return ev
  }
  hideActionPopup() {
    this._actionPopupData$.next({ showPanel: false })
  }

  submitRequestWithParamsAndNotes(params: any, notes: any, confirmActionData: ActionComponentData, msg: string = "Invio richiesta...") {
    // make request!
    this.loadingMsg$ = msg
    return this.requestService.create({
      subscriptionId: confirmActionData?.subscriptionId || 0,
      params,
      notes,
      _cost: confirmActionData.price,
      _dataChannel: confirmActionData.channel
    }).pipe(
      tap(a => console.log("REQUEST OUTCOME", a)),
      map((res: any) => {
        this.loadingMsg$ = (res?.status == 'queued' || res?.status == 'completed') ? "Richiesta inviata!" : "Abbiamo riscontrato un problema, riprova più tardi"
        return res
      }),
      catchError((err) => {
        if (err.status == 402) {
          this.loadingMsg$ = "Attenzione, non hai abbastanza credito per usare il servizio.\n Effettua una ricarica!"
        } else {
          this.loadingMsg$ = "Abbiamo riscontrato un problema, riprova più tardi\n"
        }
        return of(false)
      }),
      delay(3000),
    )
  }

}
