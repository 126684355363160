import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { gql } from 'apollo-angular';
import { combineLatest, map, Observable } from 'rxjs';
import { AckCheckGQL, MyScreenersGQL, MyScreenersQuery, Screeners, SwitchActiveGQL } from 'src/graphql/generated';
const GET_MONITORAGGI = gql`query MyScreeners($type: bpchar_comparison_exp = {},$subType: bpchar_comparison_exp = {}) {
  screeners(where: {type: $type, subType: $subType}) {
    id
    checks_aggregate {
      aggregate { count  }
      nodes { createdAt detectedChanges id response userAck }
    }
    active
    configuration
    createdAt
    expiresOn
    subType
    type
    dossierId
  }
}
`

const ADD_MONITORAGGIO = `mutation AddScreener {
  insert_screeners_one(object: {
    active: true,
    subType:"S",type:"i",
    configuration:{},
    dossier: {data: {name:"PIPPO", createdBy:21}},
    createdBy: 21 ,
    companyId:1,
    userId:21,
    expiresOn: "2024-01-01"
  }) {
    dossierId
    dossier {
      id
      title
    }
  }
}`

const ACK_CHECK = gql`mutation AckCheck($id: Int = 10, $userAck: Boolean = false) {
  update_screener_check_by_pk(pk_columns: {id: $id}, _set: {userAck: $userAck}) {
    userAck
    id
  }
}`

const SWITCH_ACTIVE = gql`mutation SwitchActive($active: Boolean = false, $id: uuid = "") {
  update_screeners_by_pk(_set: {active: $active}, pk_columns: {id: $id}) {
    id
    active
  }
}`
@Component({
  selector: 'app-monitoraggio',
  templateUrl: './monitoraggio.component.html',
  styles: [
  ]
})
export class MonitoraggioReoperaComponent implements OnInit {

  constructor(private getMonitoraggi: MyScreenersGQL,
    private ackCheckGQL: AckCheckGQL,
    private switchActiveGQL: SwitchActiveGQL,
    private route: ActivatedRoute) {
    combineLatest([this.route.data, this.route.queryParamMap]).subscribe(d => {
      this.currentEntrypoint = (this.entrypoints[+(d[1].get('tab') || 0)])
      this.monitoraggi$ = this.getMonitoraggi.watch({ type: { _eq: d[0]['screenerType'] }, subType: { _eq: this.currentEntrypoint?.subType || 'I' } }).valueChanges.pipe(map(x => x.data.screeners))
    })
    this.route.queryParamMap.pipe(
      map((params: ParamMap) =>
        this.currentEntrypoint = (this.entrypoints[+(params.get('tab') || 0)])
      )).subscribe()
  }
  entrypoints = [{
    name: 'Monitoraggio Soggetti',
    subType: 'S',
    details: [{
      name: 'Nominativo',
      field: (x: Screeners) => [x.configuration.nome || '', x.configuration.cognome || ''].join(' ')
    }, {
      name: 'Codice Fiscale',
      field: (x: Screeners) => x.configuration.codice_fiscale
    }, {
      name: 'Rif.',
      field: (x: Screeners) => x.configuration.riferimento || '-'
    }, {
      name: 'Monit. dal',
      field: (x: Screeners) => new DatePipe('en').transform(x.createdAt, 'dd.MM.yyyy')
    }, {
      name: 'Ultima variazione',
      field: (x: Screeners) => x.checks_aggregate.nodes[0] ? new DatePipe('en').transform(x.checks_aggregate.nodes[0].createdAt, 'dd.MM.yyyy') : '-'
    }]
  }, {
    name: 'Monitoraggio Immobili',
    subType: 'I',
    details: [{
      name: 'Tipo catasto',
      field: (x: Screeners) => x.configuration.tipo_catasto
    }, {
      name: 'Ufficio',
      field: (x: Screeners) => x.configuration.nome_comune
    }, {
      name: 'Comune',
      field: (x: Screeners) => x.configuration.nome_comune
    }, {
      name: 'Foglio/Part/Sub',
      field: (x: Screeners) => ['foglio', 'particella', 'particella_secondaria', 'subalterno'].map((y) => x.configuration[y]).filter(z => !!z).join('/')
    }, {
      name: 'Rif.',
      field: (x: Screeners) => x.configuration.riferimento
    }, {
      name: 'Monit. dal',
      field: (x: Screeners) => new DatePipe('en').transform(x.createdAt, 'dd.MM.yyyy')
    }, {
      name: 'Ultima variazione',
      field: (x: Screeners) => x.checks_aggregate.nodes[0] ? new DatePipe('en').transform(x.checks_aggregate.nodes[0].createdAt, 'dd.MM.yyyy') : '-'
    }]
  }]
  currentEntrypoint?: any
  monitoraggi$?: Observable<MyScreenersQuery['screeners']>;
  currentEventList = -1
  iconMap: any = {
    'S': ['fa-person'],
    'I': ['fa-building']
  }

  ngOnInit(): void {

  }

  switchActive(id: string, active: boolean) {
    this.switchActiveGQL.mutate({ active: !active, id }).subscribe()
  }

  setStatoLettura(id: number, userAck: boolean) {
    this.ackCheckGQL.mutate({ id, userAck }).subscribe()
  }

}
