<div class="h-screen shadow-lg shadow-black dark:shadow-secondary relative w-64 max-h-screen"
    [ngClass]="[isNavBarOpen ? 'block': 'hidden']">
    <div class="h-full bg-navbar text-white flex flex-col">
        <div class="flex items-center justify-start pt-6 ml-6">
            <img src="assets/logo_reopera.svg" class="w-48">
        </div>
        <nav class="mt-6 flex-grow flex flex-col max-h-fit overflow-y-auto">
            <ul class="relative" *ngIf="items.length">
                <li class="relative"><a
                        class="w-full flex items-center pl-3 p-2 my-2 transition-colors duration-200 justify-start border-l-4 border-transparent hover:!border-primary hover:text-primary hover:bg-black hover:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-10"
                        [routerLink]="['/']">
                        <span class="text-left">
                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 1792 1792"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1472 992v480q0 26-19 45t-45 19h-384v-384h-256v384h-384q-26 0-45-19t-19-45v-480q0-1 .5-3t.5-3l575-474 575 474q1 2 1 6zm223-69l-62 74q-8 9-21 11h-3q-13 0-21-7l-692-577-692 577q-12 8-24 7-13-2-21-11l-62-74q-8-10-7-23.5t11-21.5l719-599q32-26 76-26t76 26l244 204v-195q0-14 9-23t23-9h192q14 0 23 9t9 23v408l219 182q10 8 11 21.5t-7 23.5z">
                                </path>
                            </svg>
                        </span>
                        <span class="mx-2 text-sm font-normal">
                            Home
                        </span>
                    </a>
                </li>
                <app-nav-bar-item *ngFor="let item of items" [data]="item" basePath="/user/">
                </app-nav-bar-item>
            </ul>
            <div class="flex-grow"></div>
            <a class="w-full flex items-center pl-6 p-2 my-2 transition-colors duration-200 justify-start border-l-4 border-transparent hover:!border-primary hover:text-primary hover:bg-white hover:bg-opacity-10 "
                [routerLink]="['/assistenza/']" routerLinkActive="!border-gray-200">

                <span class="text-left">
                    <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <rect x="4" y="13" rx="2" width="4" height="6" />
                        <rect x="16" y="13" rx="2" width="4" height="6" />
                        <path d="M4 15v-3a8 8 0 0 1 16 0v3" />
                        <path d="M18 19a6 3 0 0 1 -6 3" />
                    </svg>
                </span>
                <span class="mx-2 text-sm font-normal">
                    Assistenza
                </span>
            </a>
            <a class="w-full flex items-center pl-6 p-2 my-2 transition-colors duration-200 justify-start border-l-4 border-transparent hover:!border-primary hover:text-primary hover:bg-white hover:bg-opacity-10 "
                (click)="checkNotification()" [routerLink]="['/user/messages/']" routerLinkActive="!border-gray-200">

                <span class="text-left">
                    <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                        stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
                        <line x1="12" y1="11" x2="12" y2="11.01" />
                        <line x1="8" y1="11" x2="8" y2="11.01" />
                        <line x1="16" y1="11" x2="16" y2="11.01" />
                    </svg>
                </span>
                <span class="mx-2 text-sm font-normal relative">
                    Messaggi
                </span>
            </a>
            <ng-container *ngIf="sharedService.isAdmin$ | async as isAdmin">
                <div class="text-xs w-full flex items-center pl-6 p-2 my-2 justify-start border-l-4 border-transparent "
                    *ngIf="isAdmin">
                    <a target="reoperaadmin" [href]="admin_url">Admin Console</a>
                </div>
            </ng-container>
            <div class="text-xs w-full flex items-center pl-6 p-2 my-2 justify-start border-l-4 border-transparent ">
                {{version}}
            </div>
        </nav>
    </div>
</div>