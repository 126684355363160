import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, debounce, delay, interval, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { ServiceParamFieldModel } from 'src/app/models/service.model';
import { DocumentService } from '../services/document.service';
import { RequestService } from '../services/request.service';
import { gql } from '@apollo/client'
import { DeleteDocGQL, GetRequestsGQL, HideRequestGQL, MyDocParamsGQL, MyDocumentsGQL } from 'src/graphql/generated';
import { RequestModel } from 'src/app/models/request.model';
import { DocumentModel } from 'src/app/models/document.model';

const GET_REQUESTS = gql`subscription GetRequests(
  $status: request_status_enum_comparison_exp = {}, 
  $archived: Boolean_comparison_exp = {}, 
  $_gte: timestamp = "2022/01/01", 
  $_lte: timestamp = "2099/01/01",
  $serviceId: Int_comparison_exp = {},
  $_ilike: String = "%") {
  request(where: {
    status: $status, 
    archived: $archived, 
    createdAt: {_gte: $_gte, _lte: $_lte},
    serviceId: $serviceId,
    params: {_cast: {String: {_ilike: $_ilike}}}
  }, order_by: {createdAt: desc}) {
    service {
      name
      fields
    }
    errorDescription
    params
    notes
    status
    archived
    id
    createdAt
  }
}
`
const GET_DOCUMENTS = gql`subscription MyDocuments(
  $_gte: timestamp = "2022/01/01", 
  $_lte: timestamp = "2099/01/01", 
  $serviceId: Int_comparison_exp = {}, 
  $_ilike: String = "%"
  ) {
  document(
    where: {
      createdAt: {_gte: $_gte, _lte: $_lte }, 
      dossierId: {_is_null: true }, 
      request: {
        serviceId: $serviceId, 
        params: {_cast: {String: {_ilike: $_ilike}}}
      }
    }, 
    order_by: {createdAt: desc}) {
    id
    name
    lastOpened
    requestId
    request {
      createdAt
      params
      notes
      service {
        name
        fields
      }
    }
  }
}`

const HIDE_REQUEST = gql`mutation HideRequest($id: Int = 10, $archived: Boolean = false) {
  update_request_by_pk(pk_columns: {id: $id}, _set: {archived: $archived}) {
    id
  }
}`



const DOC_PARAMS = gql`query MyDocParams {
  service(distinct_on:name, where: {requests_aggregate: {count: {predicate: {_gt: 0}}}}) {id name}
}`




class CardData {
  title!: string
  subtitle!: string
  body!: string
  footer!: any
  id!: number
  extra: any
}
@Component({
  selector: 'app-documents',
  templateUrl: './document.component.html',
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ],
  styles: [` :host{
    width: 100%;
    position: relative;
}`
  ]
})
export class DocumentsComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  minTo: string = ''
  maxFrom: string = ''
  setSearchString = new Subject<any>()
  selectedStartDate = new Subject<any>()
  selectedEndDate = new Subject<any>()
  selectedService = new Subject<any>()
  params$!: Observable<any>

  items$!: Observable<CardData[]>;
  pendingItems$!: Observable<CardData[]>;
  failedItems$!: Observable<CardData[]>;
  archivedItems$!: Observable<CardData[]>;
  documentWatch!: Observable<any>;
  pendingWatch!: Observable<any>;
  archivedWatch!: Observable<any>;
  failedWatch!: Observable<any>;
  showArchived: boolean = false
  iconStatusMap: any = {
    'queued': 'fa-regular fa-folder-open',
    'printing': 'fa-solid fa-print',
    'error': 'fa-solid fa-alert',
    'processing': 'fa-solid fa-car-side',
    'long_processing': 'fa-solid fa-headset',
    'registered': 'fa-solid fa-floppy-disk'
  }
  constructor(
    private documentService: DocumentService,
    private hideRequest: HideRequestGQL,
    private getRequestsGQL: GetRequestsGQL,
    private getDocuments: MyDocumentsGQL,
    private getParams: MyDocParamsGQL,
    private deleteDocument: DeleteDocGQL
  ) {

    combineLatest([this.selectedStartDate, this.selectedEndDate, this.selectedService, this.setSearchString.pipe(debounce(() => interval(800)))]).subscribe(a => {
      this.documentWatch = this.getDocuments.subscribe({
        _gte: a[0]?.text || undefined,
        _lte: a[1]?.text || undefined,
        serviceId: a[2] || undefined,
        _ilike: a[3] ? `%${a[3]}%` : undefined
      })
      this.items$ = this.documentWatch.pipe(
        map(a => a.data?.document.map((d: DocumentModel) => ({
          title: this._getParamByFieldPosition(d.request?.service?.fields, d.request, 'title'),
          subtitle: this._getParamByFieldPosition(d.request?.service?.fields, d.request, 'subtitle'),
          body: (d.request?.service?.name || "@") + ' ' + this._getParamByFieldPosition(d.request?.service?.fields, d.request, 'header'),
          footer: d.request?.createdAt || "",
          id: d.id || 0,
          extra: {
            name: d.name,
            lastOpened: d.lastOpened,
            requestId: d.requestId,

          }
        })))
      )

      this.pendingWatch = this.getRequestsGQL.subscribe({
        "status": { "_nin": ['completed', 'failed', 'error'] },
        "archived": { _eq: false },
        _gte: a[0]?.text || undefined,
        _lte: a[1]?.text || undefined,
        serviceId: a[2] || undefined,
        _ilike: a[3] ? `%${a[3]}%` : undefined
      }, { fetchPolicy: 'no-cache' })
      this.pendingItems$ = this.pendingWatch.pipe(
        map(x => x.data?.request.map((d: any) => ({
          title: this._getParamByFieldPosition(d.service?.fields, d, 'title'),
          subtitle: this._getParamByFieldPosition(d.service?.fields, d, 'subtitle'),
          body: (d.service?.name || "@") + ' ' + this._getParamByFieldPosition(d.service?.fields, d, 'header'),
          footer: d.createdAt || "#",
          id: d.id || 0,
          extra: {
            status: d.status,
            notes: d.notes
          }
        })))
      );

      this.failedWatch = this.getRequestsGQL.subscribe({
        "status": { "_in": ['failed', 'error'] },
        archived: { _eq: false },
        _gte: a[0]?.text || undefined,
        _lte: a[1]?.text || undefined,
        serviceId: a[2] || undefined,
        _ilike: a[3] ? `%${a[3]}%` : undefined
      }, { fetchPolicy: 'no-cache' })
      this.failedItems$ = this.failedWatch.pipe(
        map((x) => x.data?.request.map((d: RequestModel) => ({
          title: this._getParamByFieldPosition(d.service?.fields, d, 'title'),
          subtitle: this._getParamByFieldPosition(d.service?.fields, d, 'subtitle'),
          body: (d.service?.name || "@") + ' ' + this._getParamByFieldPosition(d.service?.fields, d, 'header'),
          footer: d.createdAt || "#",
          id: d.id || 0,
          extra: {
            status: d.status,
            notes: d.notes,
            error: d.errorDescription
          }
        })))
      );

      this.archivedWatch = this.getRequestsGQL.subscribe({
        "archived": { _eq: true },
        _gte: a[0]?.text || undefined,
        _lte: a[1]?.text || undefined,
        serviceId: a[2] || undefined,
        _ilike: a[3] ? `%${a[3]}%` : undefined
      }, { fetchPolicy: 'no-cache' })
      this.archivedItems$ = this.archivedWatch.pipe(
        map(x => x.data?.request.map((d: RequestModel) => ({
          title: this._getParamByFieldPosition(d.service?.fields, d, 'title'),
          subtitle: this._getParamByFieldPosition(d.service?.fields, d, 'subtitle'),
          body: (d.service?.name || "@") + ' ' + this._getParamByFieldPosition(d.service?.fields, d, 'header'),
          footer: d.createdAt || "#",
          id: d.id || 0,
          extra: {
            status: d.status,
            notes: d.notes,
            error: d.errorDescription
          }
        })))
      );
    })
    this.params$ = this.getParams.watch({}).valueChanges.pipe(
      map(x => x.data),
      map(x => (
        {
          services: x.service.map(s => ({ text: s.name, value: { _eq: s.id } }))
        }
      ))
    )
  }

  ngOnInit(): void {
    this.selectedStartDate.subscribe(x => {
      this.minTo = x?.text || ''
    })
    this.selectedEndDate.subscribe(x => {
      this.maxFrom = x?.text || ''
    })
    this.selectedStartDate.next(null)
    this.selectedEndDate.next(null)
    this.selectedService.next(null)
    this.setSearchString.next(null)
  }

  _getParamByFieldPosition(fields: ServiceParamFieldModel[] = [], request: any = { params: {}, notes: {} }, position: string): string {
    let { params, notes } = request
    // find field with requested position
    let filteredFields: Map<string, ServiceParamFieldModel> = fields.filter(f => f.cardPosition == position).reduce((a, v) => ({ ...a, [v.name]: v }), {}) as Map<string, ServiceParamFieldModel>
    // get its name and extract data from params
    return Object.values(filteredFields).map(
      (ff: ServiceParamFieldModel) => {
        let content = params[ff.name] || notes[ff.name]
        if (ff.type == 'radio' || ff.type == 'select-static') {
          content = ff.items?.find(i => i.value == content)?.text || content
        }
        return content ? `${ff.cardPrefix || ''}${content}${ff.cardSuffix || ' '}` : ''
      })
      .filter(a => !!a).join("")
  }

  delete = (id: any) => {
    this.deleteDocument.mutate({ id }).subscribe()
  }

  deleteRequest = (id: any, restore: boolean = false) => {
    this.hideRequest.mutate({ id, archived: !restore }).subscribe()
  }

  open = (item: CardData) => {
    this.documentService.open(item.id || 0).pipe(
      map(res => {
        // create anchor, populate link, click and download
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = item.extra.name.split("/").pop() || '';
        link.click();
      }),
    ).subscribe()
  }

  toggleShowArchived() {
    of(true).pipe(
      delay(500),
    ).subscribe(() => {
      this.showArchived = !this.showArchived
    })

  }

  trackFn(item: any) {
    return item.id
  }

  /**
 * On destroy
 */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
